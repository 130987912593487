import { useEffect, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import styled from 'styled-components';

import { useCancelActiveSurvey } from '../../common/mutation/__generated__/cancel-active-survey';
import { useGetNewAnalyzesCount } from '../../common/query/__generated__/get-new-analyzes-count';
import { useGetPassedSurveyTemplatesForPatient } from '../../common/query/__generated__/get-passed-survey-templates-for-patient';
import { GetPatientDocument, useGetPatient } from '../../common/query/__generated__/get-patient';
import { SearchPatientsDocument } from '../../common/query/__generated__/search-patients';
import { modalVar } from '../../libs/Apollo';
import { ModalContainer, ModalWithButton } from '../../ui';
import { BackContainer, CompletedSurveys, SurveyDetails } from './components';

export const PatientPage = () => {
  const { id } = useParams<{ id: string }>();
  const history = useHistory();

  const createNewSurvey = async () => {
    try {
      await cancelActiveSurvey();
      history.push(`/patient/${id}/create-quiz`);
    } catch (e) {
      const error = e as Error;
      console.warn(error.message);
    }
  };

  useEffect(() => {
    modalVar({ pathnameFromEmail: '/patients' });
  }, []);

  //modals
  const [isOpenModalCreateNewSurvey, setIsOpenModalCreateNewSurvey] = useState(false);
  const [isOpenModalStopSurvey, setIsOpenModalStopSurvey] = useState(false);
  const closeModalCreateNewSurvey = () => {
    setIsOpenModalCreateNewSurvey(false);
    modalVar({ isOpenModal: false });
  };
  const closeOpenModalStopSurvey = () => {
    setIsOpenModalStopSurvey(false);
    modalVar({ isOpenModal: false });
  };

  //current patient
  const { data: currentPatient } = useGetPatient({
    variables: {
      patientId: id,
    },
  });
  const patient = currentPatient?.doctorFindHospitalPatient;
  const patientName =
    patient?.firstName || patient?.lastName ? `${patient?.firstName} ${patient?.lastName}` : undefined;

  //surveys
  const [surveyId, setSurveyId] = useState<string | undefined>(undefined);
  const { data } = useGetPassedSurveyTemplatesForPatient({
    variables: {
      patientId: id,
    },
    fetchPolicy: 'network-only',
    pollInterval: 60000,
  });
  const surveyTemplates = data?.doctorFindPatientPrivateSurveyTemplates;

  const [cancelActiveSurvey, { loading }] = useCancelActiveSurvey({
    variables: {
      patientId: id,
    },
    refetchQueries: [
      { query: GetPatientDocument, variables: { patientId: id } },
      { query: SearchPatientsDocument, variables: { filter: patient?.medicalCardNumber, first: 1 } },
    ],
  });

  const { data: analyzesCount } = useGetNewAnalyzesCount({
    variables: {
      patientId: id,
    },
    fetchPolicy: 'network-only',
    pollInterval: 600000,
  });

  return (
    <Root>
      <Container $isOpenModal={isOpenModalStopSurvey || isOpenModalCreateNewSurvey}>
        <BackContainer
          cardNumber={patient?.medicalCardNumber || 'undefined'}
          hasActiveSurvey={Boolean(patient?.hasActiveSurvey)}
          analyzesNumber={analyzesCount?.doctorGetNewPatientAnalyzesCount || 0}
          patientName={patientName}
          patientId={id}
        />
        <MainContainer>
          <SurveyDetails
            hasActiveSurvey={Boolean(patient?.hasActiveSurvey)}
            setIsOpenModalStopSurvey={setIsOpenModalStopSurvey}
            setIsOpenModalCreateNewSurvey={setIsOpenModalCreateNewSurvey}
            patientId={id}
            surveyId={surveyId}
            hasCompletedSurveys={Boolean(surveyTemplates && surveyTemplates?.length > 0)}
          />
          <CompletedSurveys setSurveyId={setSurveyId} surveyTemplates={surveyTemplates} />
        </MainContainer>
      </Container>
      {isOpenModalCreateNewSurvey && (
        <ModalContainer closeModal={closeModalCreateNewSurvey}>
          <ModalWithButton
            closeModal={closeModalCreateNewSurvey}
            question="Пациент уже проходит опрос"
            description="Создание нового опроса остановит отправку текущего"
            onClick={createNewSurvey}
          />
        </ModalContainer>
      )}
      {isOpenModalStopSurvey && (
        <ModalContainer closeModal={closeOpenModalStopSurvey}>
          <ModalWithButton
            closeModal={closeOpenModalStopSurvey}
            question="Вы действительно хотите остановить отправку последнего опроса?"
            description="Действие навсегда остановит возобновление последнего опроса"
            onClick={cancelActiveSurvey}
            loading={loading}
          />
        </ModalContainer>
      )}
    </Root>
  );
};

const Root = styled.div`
  width: 100%;
  height: calc(100vh - 100px);
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  z-index: 1;
`;
const Container = styled.div<{ $isOpenModal: boolean }>`
  width: 100%;
  height: calc(100vh - 100px);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-color: ${({ theme: { colors } }) => colors.blueExtraLight};
  ${({ $isOpenModal }) => $isOpenModal && `filter: blur(3px)`}
`;
const MainContainer = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: 39px;
  width: 1280px;
  height: calc(100vh - 100px - 90px);
`;
