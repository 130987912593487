import * as Apollo from '@apollo/client';
import { DocumentNode } from 'graphql';

import * as Types from '../../../__generated__/types';

const defaultOptions = {};
export type GetQuestionsCategoriesVariables = Types.Exact<{ [key: string]: never }>;

export type GetQuestionsCategories = {
  __typename: 'Query';
  findAllQuestionCategories: Array<{ __typename: 'QuestionCategoryModel'; id: string; name: string }>;
};

export const GetQuestionsCategoriesDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'GetQuestionsCategories' },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'findAllQuestionCategories' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode;

/**
 * __useGetQuestionsCategories__
 *
 * To run a query within a React component, call `useGetQuestionsCategories` and pass it any options that fit your needs.
 * When your component renders, `useGetQuestionsCategories` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetQuestionsCategories({
 *   variables: {
 *   },
 * });
 */
export function useGetQuestionsCategories(
  baseOptions?: Apollo.QueryHookOptions<GetQuestionsCategories, GetQuestionsCategoriesVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetQuestionsCategories, GetQuestionsCategoriesVariables>(
    GetQuestionsCategoriesDocument,
    options,
  );
}
export function useGetQuestionsCategoriesLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<GetQuestionsCategories, GetQuestionsCategoriesVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetQuestionsCategories, GetQuestionsCategoriesVariables>(
    GetQuestionsCategoriesDocument,
    options,
  );
}
export type GetQuestionsCategoriesHookResult = ReturnType<typeof useGetQuestionsCategories>;
export type GetQuestionsCategoriesLazyQueryHookResult = ReturnType<typeof useGetQuestionsCategoriesLazyQuery>;
export type GetQuestionsCategoriesQueryResult = Apollo.QueryResult<
  GetQuestionsCategories,
  GetQuestionsCategoriesVariables
>;
