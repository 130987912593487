import { Form, FormikProvider, useFormik } from 'formik';
import { useEffect } from 'react';
import { Redirect, useHistory, useLocation } from 'react-router-dom';
import styled from 'styled-components';
import * as Yup from 'yup';

import { useEmailSignUp } from '../../common/mutation/__generated__/email-sign-up';
import { useForgotPassword } from '../../common/mutation/__generated__/forgot-password';
import useAuthContext from '../../libs/Apollo/hooks/useAuthContext';
import { storageClient } from '../../libs/StorageClient';
import { ROUTES } from '../../routers';
import { Button, Input } from '../../ui';

type Values = {
  password: string;
  confirmPassword: string;
};

export const CreatingPassword = () => {
  const { authContext } = useAuthContext();
  const hashRecovery = storageClient.getHashRecovery();
  const history = useHistory();
  const oldLocalToken = storageClient.getAuthToken();
  const oldSessionToken = sessionStorage.getItem('token');
  const location = useLocation();
  const hash = new URLSearchParams(location.search).get('hash');
  const [emailSignUp, { data: dataSignUp, loading: loadingSignUp, error: errorSignUp }] = useEmailSignUp();
  const [forgotPassword, { data: dataRecovery, loading: loadingForgotPass, error: errorForgotPass }] =
    useForgotPassword();

  const formik = useFormik({
    initialValues: {
      password: '',
      confirmPassword: '',
    },
    validationSchema: Yup.object({
      password: Yup.string()
        .required('Пожалуйста, введите Ваш пароль')
        /*.matches(
          /^(?=.*[A-Za-z])(?=.*\d)(?=.*[@$!%*#?&])[A-Za-z\d@$!%*#?&]{8,}$/,
          "Must Contain 8 Characters, One Uppercase, One Lowercase, One Number and one special case Character"
        )*/
        .min(8, 'Пароль должен содержать минимум 8 символов'),
      confirmPassword: Yup.string()
        .required('Не должно быть пустым')
        .oneOf([Yup.ref('password'), null], 'Пароли должны совпадать'),
    }),
    onSubmit: async (values: Values) => {
      if (hash) {
        const res = await emailSignUp({
          variables: {
            input: {
              hash: hash || 'undefined',
              password: values.password,
            },
          },
        });
        const token = res.data?.doctorEmailSignUp.token;
        const refreshToken = res.data?.doctorEmailSignUp.refreshToken;
        if (token && refreshToken) {
          if (oldLocalToken || oldSessionToken) {
            await authContext.signOut();
          }
          await authContext.signUp(token, refreshToken);
        }
      }
      if (hashRecovery) {
        const res = await forgotPassword({
          variables: {
            input: {
              hash: hashRecovery || 'undefined',
              password: values.password,
            },
          },
        });
        if (res.data?.doctorRecoverPassword.success) {
          storageClient.removeHashRecovery();
          history.push(ROUTES['/sign-in']);
        }
      }
    },
  });

  //errors
  let errorResponse: string | undefined = undefined;
  if (dataRecovery?.doctorRecoverPassword.problem) {
    errorResponse = dataRecovery?.doctorRecoverPassword.problem.message;
  }
  if (dataSignUp?.doctorEmailSignUp.problem) {
    errorResponse = dataSignUp?.doctorEmailSignUp.problem.message;
  }

  useEffect(() => {
    if (hash) storageClient.setHash(hash);
  }, [hash]);

  if (!hashRecovery && !hash) {
    return <Redirect to={ROUTES['/sign-in']} />;
  }

  return (
    <Root>
      <EmptyDiv />
      <Container>
        <Wrapper>
          <Modal>
            <Title>{hashRecovery ? 'Новый пароль' : 'Создайте пароль'}</Title>
            <FormikProvider value={formik}>
              <Form onSubmit={formik.handleSubmit}>
                <InputBlock>
                  <Input
                    placeholder="Введите пароль"
                    label="Пароль"
                    type="password"
                    {...formik.getFieldProps('password')}
                    error={formik.touched.password ? formik.errors.password : ''}
                  />
                  <Input
                    placeholder="Введите пароль"
                    label="Подтвердить пароль"
                    type="password"
                    {...formik.getFieldProps('confirmPassword')}
                    error={
                      (formik.touched.confirmPassword ? formik.errors.confirmPassword : '') ||
                      errorSignUp?.message ||
                      errorForgotPass?.message ||
                      errorResponse
                    }
                  />
                </InputBlock>
                <Button
                  variant="primary"
                  label="Создать"
                  isDisabled={!(formik.isValid && formik.dirty)}
                  isLoading={loadingSignUp || loadingForgotPass}
                  type="submit"
                />
              </Form>
            </FormikProvider>
          </Modal>
        </Wrapper>
      </Container>
    </Root>
  );
};

const Root = styled.div`
  width: 100%;
  background-color: ${({ theme: { colors } }) => colors.blueExtraLight};
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;
const EmptyDiv = styled.div`
  width: 100%;
  height: 64px;
`;
const Container = styled.div`
  width: 1280px;
  height: calc(100vh - 100px - 64px);
`;
const Wrapper = styled.div`
  max-width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
`;
const Modal = styled.div`
  width: 420px;
  background-color: ${({ theme: { colors } }) => colors.white};
  box-sizing: border-box;
  border: 1px solid ${({ theme: { colors } }) => colors.blueExtraLight};
  box-shadow: 0 6px 30px #e4ecf7;
  border-radius: 10px;
  padding: 40px;
  height: fit-content;
  position: relative;
  top: -32px;
`;
const Title = styled.span`
  color: ${({ theme: { colors } }) => colors.grayDark};
  ${({ theme: { typography } }) => typography.title2};
  line-height: 44px;
`;
const InputBlock = styled.div`
  margin-top: 32px;
`;
// const BackText = styled.div`
//   margin-left: 16px;
//   color: ${({ theme: { colors } }) => colors.grayDark};
//   ${({ theme: { typography } }) => typography.title2};
//   line-height: 44px;

//   &:hover {
//     color: ${({ theme: { colors } }) => colors.blueDark};
//   }
// `;
