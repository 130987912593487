import * as Apollo from '@apollo/client';
import { DocumentNode } from 'graphql';

import * as Types from '../../../__generated__/types';

const defaultOptions = {};
export type ReadAllNotificationsVariables = Types.Exact<{ [key: string]: never }>;

export type ReadAllNotifications = { __typename: 'Mutation'; doctorReadAllNotifications: boolean };

export const ReadAllNotificationsDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'ReadAllNotifications' },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [{ kind: 'Field', name: { kind: 'Name', value: 'doctorReadAllNotifications' } }],
      },
    },
  ],
} as unknown as DocumentNode;
export type ReadAllNotificationsMutationFn = Apollo.MutationFunction<
  ReadAllNotifications,
  ReadAllNotificationsVariables
>;

/**
 * __useReadAllNotifications__
 *
 * To run a mutation, you first call `useReadAllNotifications` within a React component and pass it any options that fit your needs.
 * When your component renders, `useReadAllNotifications` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [readAllNotifications, { data, loading, error }] = useReadAllNotifications({
 *   variables: {
 *   },
 * });
 */
export function useReadAllNotifications(
  baseOptions?: Apollo.MutationHookOptions<ReadAllNotifications, ReadAllNotificationsVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<ReadAllNotifications, ReadAllNotificationsVariables>(ReadAllNotificationsDocument, options);
}
export type ReadAllNotificationsHookResult = ReturnType<typeof useReadAllNotifications>;
export type ReadAllNotificationsMutationResult = Apollo.MutationResult<ReadAllNotifications>;
export type ReadAllNotificationsMutationOptions = Apollo.BaseMutationOptions<
  ReadAllNotifications,
  ReadAllNotificationsVariables
>;
