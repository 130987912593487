import { Form, FormikProvider, useFormik } from 'formik';
import React, { useEffect, useState } from 'react';
import { Redirect, useHistory, useLocation } from 'react-router-dom';
import styled from 'styled-components';
import * as Yup from 'yup';

import { useSendEmailForPasswordRecovery } from '../../common/mutation/__generated__/send-email-for-password-recovery';
import { modalVar } from '../../libs/Apollo';
import { storageClient } from '../../libs/StorageClient';
import { ROUTES } from '../../routers';
import { BackBlock, Button, Input, ModalChecked, ModalContainer } from '../../ui';

export const PasswordRecovery = () => {
  const [isOpenModalSentEmail, setIsOpenModalSentEmail] = useState(false);
  const openModalSentEmail = () => {
    setIsOpenModalSentEmail(true);
    modalVar({ isOpenModal: true });
  };
  const closeModalSentEmail = () => {
    setIsOpenModalSentEmail(false);
    modalVar({ isOpenModal: false });
  };

  const [sendEmailForPasswordRecovery, { loading, error }] = useSendEmailForPasswordRecovery();
  const formik = useFormik({
    initialValues: {
      email: '',
    },
    validationSchema: Yup.object({
      email: Yup.string()
        .required('Пожалуйста, введите Ваш e-mail')
        .email('E-mail должен соответствовать типу example@mail.com'),
    }),
    onSubmit: async (values: { email: string }) => {
      const res = await sendEmailForPasswordRecovery({
        variables: {
          input: {
            email: values.email,
          },
        },
      });
      if (!res.data?.doctorEmailRecoveryPasswordSendLink.problem?.message) {
        openModalSentEmail();
      }
    },
  });

  const history = useHistory();

  const location = useLocation();
  const hash = new URLSearchParams(location.search).get('hash');

  useEffect(() => {
    if (hash) storageClient.setHashRecovery(hash);
  }, [hash]);

  if (hash) {
    return <Redirect to={ROUTES['/sign-up']} />;
  }

  return (
    <Root>
      <BackBlock isOpenModal={isOpenModalSentEmail}>
        <BackText onClick={() => history.goBack()}>Вернуться к форме входа</BackText>
      </BackBlock>
      <Container $isOpenModal={isOpenModalSentEmail}>
        <Modal>
          <Title>Восстановление пароля</Title>
          <Description>
            Введите ваш электронный адрес, и мы отправим на него ссылку для создания нового пароля
          </Description>
          <FormikProvider value={formik}>
            <Form onSubmit={formik.handleSubmit}>
              <InputBlock>
                <Input
                  placeholder="Введите почту"
                  label="E-mail"
                  {...formik.getFieldProps('email')}
                  error={(formik.touched.email ? formik.errors.email : '') || error?.message}
                />
              </InputBlock>
              <Button
                variant="primary"
                label="Отправить"
                isDisabled={!(formik.isValid && formik.dirty)}
                type="submit"
                isLoading={loading}
              />
            </Form>
          </FormikProvider>
        </Modal>
      </Container>
      {isOpenModalSentEmail && (
        <ModalContainer closeModal={closeModalSentEmail}>
          <ModalChecked text="Ссылка для восстановления пароля отправлена на указанную электронную почту" />
        </ModalContainer>
      )}
    </Root>
  );
};

const Root = styled.div`
  width: 100%;
  background-color: ${({ theme: { colors } }) => colors.blueExtraLight};
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;
const Container = styled.div<{ $isOpenModal: boolean }>`
  width: 1280px;
  height: calc(100vh - 100px - 84px);
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  ${({ $isOpenModal }) => $isOpenModal && `filter: blur(3px)`}
`;
const BackText = styled.div`
  margin-left: 16px;
  color: ${({ theme: { colors } }) => colors.grayDark};
  ${({ theme: { typography } }) => typography.title2};
  line-height: 44px;

  &:hover {
    color: ${({ theme: { colors } }) => colors.blueDark};
  }
`;
const Modal = styled.div`
  width: 420px;
  background-color: ${({ theme: { colors } }) => colors.white};
  box-sizing: border-box;
  border: 1px solid ${({ theme: { colors } }) => colors.blueExtraLight};
  box-shadow: 0 6px 30px #e4ecf7;
  border-radius: 10px;
  padding: 40px;
  height: fit-content;
  position: relative;
  top: -42px;
`;
const Title = styled.span`
  color: ${({ theme: { colors } }) => colors.grayDark};
  ${({ theme: { typography } }) => typography.title2};
  line-height: 44px;
`;
const InputBlock = styled.div`
  margin-top: 32px;
`;
const Description = styled.p`
  color: ${({ theme: { colors } }) => colors.grayDarkMode};
  ${({ theme: { typography } }) => typography.body3};
`;
