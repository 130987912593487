import React from 'react';
import styled from 'styled-components';

function AccountBoxIcon({ size }: { size: string }) {
  return (
    <Root>
      <svg width={size} height={size} viewBox="0 0 36 36" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M15.5923 6.97892C16.3556 6.66274 17.1738 6.5 18 6.5C19.6687 6.5 21.269 7.16287 22.4489 8.34279C23.6288 9.5227 24.2917 11.123 24.2917 12.7917C24.2917 14.4603 23.6288 16.0606 22.4489 17.2405C21.269 18.4205 19.6687 19.0833 18 19.0833C17.1738 19.0833 16.3556 18.9206 15.5923 18.6044C14.8289 18.2882 14.1354 17.8248 13.5511 17.2405C12.9669 16.6563 12.5034 15.9627 12.1873 15.1994C11.8711 14.436 11.7083 13.6179 11.7083 12.7917C11.7083 11.9654 11.8711 11.1473 12.1873 10.384C12.5034 9.62061 12.9669 8.92702 13.5511 8.34279C14.1354 7.75855 14.8289 7.29511 15.5923 6.97892ZM18 8.66667C16.906 8.66667 15.8568 9.10126 15.0832 9.87485C14.3096 10.6484 13.875 11.6976 13.875 12.7917C13.875 13.8857 14.3096 14.9349 15.0832 15.7085C15.8568 16.4821 16.906 16.9167 18 16.9167C19.094 16.9167 20.1432 16.4821 20.9168 15.7085C21.6904 14.9349 22.125 13.8857 22.125 12.7917C22.125 11.6976 21.6904 10.6484 20.9168 9.87485C20.1432 9.10126 19.094 8.66667 18 8.66667Z"
          fill="#3380EE"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M0.25 3.40625C0.25 1.6627 1.6627 0.25 3.40625 0.25H32.5937C34.3373 0.25 35.75 1.6627 35.75 3.40625V32.5937C35.75 33.4308 35.4175 34.2336 34.8256 34.8256C34.2336 35.4175 33.4308 35.75 32.5937 35.75H3.40625C2.56916 35.75 1.76636 35.4175 1.17444 34.8256C0.582533 34.2336 0.25 33.4308 0.25 32.5937V3.40625ZM2.41667 2.41667V33.5833H33.5833V2.41667H2.41667Z"
          fill="#3380EE"
        />
        <path
          d="M18.1545 23.1668C15.9043 23.1749 13.6912 23.7425 11.7144 24.8188C10.0108 25.7463 8.5293 27.0264 7.36637 28.5693C7.98651 28.9577 8.62776 29.3115 9.28716 29.6289C11.3718 27.0195 14.583 25.3424 18.1831 25.3334C21.7832 25.3424 24.9946 27.0196 27.0792 29.6289C27.7386 29.3115 28.3799 28.9578 29 28.5694C27.8371 27.0264 26.3555 25.7463 24.6519 24.8188C22.6751 23.7425 20.4621 23.1748 18.2119 23.1667C18.2024 23.1667 18.1927 23.1667 18.1831 23.1667C18.1736 23.1667 18.164 23.1668 18.1545 23.1668Z"
          fill="#3380EE"
        />
      </svg>
    </Root>
  );
}

const Root = styled.div`
  padding: 7px 7px 3px 7px;
`;

export default AccountBoxIcon;
