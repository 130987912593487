import React from 'react';

import { defaultTheme } from '../../../themes';

type LabelPropsType = {
  x: number;
  y: number;
  value: string;
  fill: string;
};

export const renderLabelPres = ({ x, y, value, fill }: LabelPropsType) => {
  const color =
    fill === defaultTheme.colors.blueExtraLight || fill === defaultTheme.colors.blueAverage
      ? defaultTheme.colors.blueDark
      : defaultTheme.colors.white;
  return (
    <text
      x={x + 26}
      y={y}
      dy={26}
      fontSize="16"
      fontFamily="Lato, sans-serif"
      fontWeight={700}
      fill={color}
      textAnchor="middle">
      {value}
    </text>
  );
};
