import { ApolloClient, NormalizedCacheObject, Observable } from '@apollo/client';
import { onError } from '@apollo/client/link/error';
import { LocalStorageWrapper, persistCache } from 'apollo3-cache-persist';
import axios from 'axios';
import Apollo from 'libs/Apollo';
import { storageClient } from 'libs/StorageClient';
import { useEffect, useState } from 'react';
import { REDIRECT_PATHS_EXCEPTIONS, ROUTES } from 'routers';
import { API_URL } from 'utils/constants';

let apolloClient: ApolloClient<NormalizedCacheObject>;

export const CODE_UNAUTHENTICATED = 'UNAUTHENTICATED';

export const useSetupApolloClient = (): ApolloClient<NormalizedCacheObject> | undefined => {
  const [client, setClient] = useState<ApolloClient<NormalizedCacheObject>>();

  const hardLogOut = async () => {
    sessionStorage.removeItem('token');
    storageClient.removeApolloCachePersist();
    storageClient.removeHash();
    await client?.clearStore();
    await Apollo.logOut();
  };

  const refreshAccessToken = async () => {
    try {
      const refreshToken = storageClient.getRefreshToken();
      const sessionRefreshToken = sessionStorage.getItem('refreshToken');

      if (!refreshToken && !sessionRefreshToken) {
        return null;
      }

      const response = await axios.post(`${API_URL}/auth/refresh`, {
        refreshToken: refreshToken || sessionRefreshToken,
      });

      const { token, refreshToken: newRefreshToken } = response.data;

      if (token && newRefreshToken) {
        storageClient.setAuthToken(token);
        storageClient.setRefreshToken(newRefreshToken);
        return token;
      } else {
        return null;
      }
    } catch (error) {
      console.error('Ошибка при обновлении токена:', error);
      throw error;
    }
  };

  // const errorLink = onError(({ graphQLErrors, networkError }) => {
  //   if (graphQLErrors) {
  //     graphQLErrors.forEach(({ message }) => {
  //       if (!REDIRECT_PATHS_EXCEPTIONS.includes(window.location.pathname as keyof typeof ROUTES)) {
  //         if (message === 'Invalid token') {
  //           hardLogOut();
  //         }
  //       }
  //     });
  //   }

  //   if (networkError) console.error(`[Network error]: ${networkError}`);
  // });

  const errorLink = onError(({ forward, graphQLErrors, operation }) => {
    const unauthorizedError = graphQLErrors?.some(error => error.extensions?.code === CODE_UNAUTHENTICATED);

    if (REDIRECT_PATHS_EXCEPTIONS.includes(window.location.pathname as keyof typeof ROUTES)) {
      return;
    }

    if (!unauthorizedError || !operation.getContext().headers.authorization) {
      return;
    }

    return new Observable(observer => {
      refreshAccessToken()
        .then(accessToken => {
          operation.setContext({
            headers: {
              ...operation.getContext().headers,
              authorization: accessToken && `Bearer ${accessToken}`,
            },
          });

          return forward(operation).subscribe(observer);
        })
        .catch(() => {
          hardLogOut();
        });
    });
  });

  useEffect(() => {
    apolloClient = new ApolloClient({
      cache: Apollo.cache,
      link: errorLink.concat(Apollo.link),
    });
    persistCache({
      cache: Apollo.cache,
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      storage: new LocalStorageWrapper(window.localStorage) as any,
      maxSize: false,
      debug: false,
    }).then(() => {
      apolloClient.onResetStore(Apollo.refetchAll);
      apolloClient.onClearStore(Apollo.logOut);
      setClient(apolloClient);
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return client;
};
