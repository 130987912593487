import React from 'react';
import { Bar, BarChart, Cell, XAxis, YAxis } from 'recharts';

import { defaultTheme } from '../../themes';
import { renderBarTemp, renderLabelTemp, renderTick } from './utils';

type Props = {
  data: TempDataType;
  minDataYAxis: number;
};
const ItemWidth = 52;
const SpaceBetweenItems = 20;
const LeftRightPadding = 60;

function TemperatureChart({ data, minDataYAxis }: Props) {
  const width = data && data.length * ItemWidth + (data.length - 1) * SpaceBetweenItems + LeftRightPadding;
  return (
    <BarChart
      width={width}
      height={353}
      data={data}
      margin={{
        top: 20,
        right: 30,
        left: 30,
        bottom: 5,
      }}>
      <XAxis
        dataKey="name"
        tickSize={0}
        tick={renderTick}
        axisLine={{ stroke: defaultTheme.colors.grayAverage, strokeWidth: 2, strokeLinecap: 'round' }}
      />
      <YAxis type={'number'} domain={[minDataYAxis, 'maxData']} hide />
      <Bar dataKey="value" label={renderLabelTemp} barSize={52} shape={renderBarTemp} isAnimationActive={false}>
        {data?.map((entry, index) => (
          <Cell
            fill={entry.isCritical ? defaultTheme.colors.white : defaultTheme.colors.blueDark}
            key={`cell-${index}`}
          />
        ))}
      </Bar>
    </BarChart>
  );
}

export type TempDataType =
  | Array<{
      name: string;
      value: number | undefined;
      isCritical: boolean;
    }>
  | undefined;

export default React.memo(TemperatureChart);
