export type Maybe<T> = T | null;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string;
  String: string;
  Boolean: boolean;
  Int: number;
  Float: number;
  /** A date-time string at UTC, such as 2019-12-03T09:54:33Z, compliant with the date-time format. */
  DateTime: any;
  /** A string or number represents float */
  FloatString: any;
  /** A string represents string UUID v4 */
  UUID: any;
};

export type BaseProblem = {
  __typename?: 'BaseProblem';
  message: Scalars['String'];
};

export type CreateAnalyzesInput = {
  doctorId: Scalars['UUID'];
  files: Array<PatientCreateAnalyzesInput>;
};

export type CreateCustomQuestionInput = {
  options?: Maybe<Array<Scalars['String']>>;
  scaleIndicators?: Maybe<ScaleQuestionIndicatorsInput>;
  title: Scalars['String'];
  type: QuestionType;
};

export type CreateCustomQuestionPayload = {
  __typename?: 'CreateCustomQuestionPayload';
  problem?: Maybe<EmailSignUpSendCodeProblemUnion>;
  question?: Maybe<QuestionModel>;
};

export type DoctorConnection = {
  __typename?: 'DoctorConnection';
  nodes: Array<DoctorInvitationModel>;
  pageInfo: PageInfo;
};

export type DoctorCreateSurveyTemplateInput = {
  drugsIds: Array<Scalars['UUID']>;
  endAt: Scalars['DateTime'];
  patientId: Scalars['UUID'];
  period: SurveyTemplatePeriod;
  questions: Array<QuestionInput>;
  startAt: Scalars['DateTime'];
  timezoneOffset?: Maybe<Scalars['Int']>;
  title: Scalars['String'];
};

export type DoctorCreateSurveyTemplatePayload = {
  __typename?: 'DoctorCreateSurveyTemplatePayload';
  problem?: Maybe<BaseProblem>;
  surveyTemplate?: Maybe<SurveyTemplateModel>;
};

export type DoctorEmailPasswordRecoveryInput = {
  hash: Scalars['String'];
  password: Scalars['String'];
};

export type DoctorEmailPasswordRecoveryPayload = {
  __typename?: 'DoctorEmailPasswordRecoveryPayload';
  problem?: Maybe<NotExistDoctorProblem>;
  success?: Maybe<Scalars['Boolean']>;
};

export type DoctorEmailPasswordRecoverySendLinkInput = {
  email: Scalars['String'];
};

export type DoctorEmailPasswordRecoverySendLinkPayload = {
  __typename?: 'DoctorEmailPasswordRecoverySendLinkPayload';
  hash?: Maybe<Scalars['String']>;
  problem?: Maybe<EmailSignUpSendCodeProblemUnion>;
};

export type DoctorEmailSignInInput = {
  email: Scalars['String'];
  password: Scalars['String'];
};

export type DoctorEmailSignInPayload = {
  __typename?: 'DoctorEmailSignInPayload';
  problem?: Maybe<NotExistEmailProblem>;
  refreshToken?: Maybe<Scalars['String']>;
  token?: Maybe<Scalars['String']>;
  user?: Maybe<DoctorModel>;
};

export type DoctorEmailSignUpInput = {
  hash: Scalars['String'];
  password: Scalars['String'];
};

export type DoctorEmailSignUpPayload = {
  __typename?: 'DoctorEmailSignUpPayload';
  problem?: Maybe<InvalidVerificationEmailHashProblem>;
  refreshToken?: Maybe<Scalars['String']>;
  token?: Maybe<Scalars['String']>;
  user?: Maybe<DoctorModel>;
};

export type DoctorEmailSignUpSendCodePayload = {
  __typename?: 'DoctorEmailSignUpSendCodePayload';
  hash?: Maybe<Scalars['String']>;
  problem?: Maybe<EmailSignUpSendCodeProblemUnion>;
};

export type DoctorEmailSignUpSendLinkInput = {
  email: Scalars['String'];
};

export type DoctorInvitationModel = {
  __typename?: 'DoctorInvitationModel';
  createdAt?: Maybe<Scalars['DateTime']>;
  deletedAt?: Maybe<Scalars['DateTime']>;
  doctorId?: Maybe<Scalars['String']>;
  email?: Maybe<Scalars['String']>;
  hospital?: Maybe<HospitalModel>;
  id: Scalars['String'];
  isInviteAccepted?: Maybe<Scalars['Boolean']>;
  language?: Maybe<Language>;
  notificationsSettings?: Maybe<DoctorNotificationSettingsModel>;
  role?: Maybe<PatientRole>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type DoctorModel = {
  __typename?: 'DoctorModel';
  createdAt?: Maybe<Scalars['DateTime']>;
  deletedAt?: Maybe<Scalars['DateTime']>;
  email?: Maybe<Scalars['String']>;
  hospital?: Maybe<HospitalModel>;
  id: Scalars['String'];
  language?: Maybe<Language>;
  notificationsSettings?: Maybe<DoctorNotificationSettingsModel>;
  role?: Maybe<PatientRole>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type DoctorNotificationConnection = {
  __typename?: 'DoctorNotificationConnection';
  nodes: Array<DoctorNotificationModel>;
  pageInfo: PageInfo;
};

export enum DoctorNotificationKind {
  ContactMeRequest = 'CONTACT_ME_REQUEST',
  CriticalIndicators = 'CRITICAL_INDICATORS',
  UploadAnalyzesByPatient = 'UPLOAD_ANALYZES_BY_PATIENT',
}

export type DoctorNotificationModel = {
  __typename?: 'DoctorNotificationModel';
  createdAt: Scalars['DateTime'];
  description: Scalars['String'];
  doctorId: Scalars['UUID'];
  extraData?: Maybe<DoctorNotificationsExtraData>;
  hospitalPatient?: Maybe<HospitalPatientModel>;
  id: Scalars['UUID'];
  isRead: Scalars['Boolean'];
  kind: DoctorNotificationKind;
  patientId: Scalars['UUID'];
  title: Scalars['String'];
};

export type DoctorNotificationSettingsModel = {
  __typename?: 'DoctorNotificationSettingsModel';
  contactMeRequest?: Maybe<Scalars['Boolean']>;
  criticalIndicators?: Maybe<Scalars['Boolean']>;
  uploadAnalyzesByPatients?: Maybe<Scalars['Boolean']>;
};

export type DoctorNotificationsExtraData = {
  __typename?: 'DoctorNotificationsExtraData';
  surveyTemplateId: Scalars['String'];
};

export type DoctorPasswordUpdateInput = {
  newPassword: Scalars['String'];
  password: Scalars['String'];
};

export type DoctorPasswordUpdatePayload = {
  __typename?: 'DoctorPasswordUpdatePayload';
  problem?: Maybe<PasswordsNotMatchProblem>;
  refreshToken?: Maybe<Scalars['String']>;
  token?: Maybe<Scalars['String']>;
};

export type DoctorPatientModel = {
  __typename?: 'DoctorPatientModel';
  doctorId: Scalars['UUID'];
  id: Scalars['UUID'];
  patientId: Scalars['UUID'];
};

export type DoctorPatientSurveyAnswerConnection = {
  __typename?: 'DoctorPatientSurveyAnswerConnection';
  nodes: Array<SurveyAnswerModel>;
  pageInfo: PageInfo;
};

export type DoctorPatientSurveyAnswerModel = {
  __typename?: 'DoctorPatientSurveyAnswerModel';
  answerQuestionOptionText?: Maybe<Scalars['String']>;
  answerQuestionOptionsTexts?: Maybe<Array<Scalars['String']>>;
  answerValue?: Maybe<SurveyAnswerValue>;
  isCritical: Scalars['Boolean'];
  isQuestionCustom: Scalars['Boolean'];
  maxAnswer?: Maybe<Scalars['String']>;
  minAnswer?: Maybe<Scalars['String']>;
  questionId: Scalars['UUID'];
  questionTitle: Scalars['String'];
  questionType: QuestionType;
};

export type DoctorRemoveByIdInput = {
  doctorId: Scalars['String'];
};

export type DoctorRemoveByIdPayload = {
  __typename?: 'DoctorRemoveByIdPayload';
  problem?: Maybe<NotExistDoctorProblem>;
  success?: Maybe<Scalars['Boolean']>;
};

export type DoctorUpdateLanguageInput = {
  language?: Maybe<Language>;
};

export type DoctorUpdateLanguagePayload = {
  __typename?: 'DoctorUpdateLanguagePayload';
  user?: Maybe<DoctorModel>;
};

export type DoctorUpdateNotificationsInput = {
  contactMeRequestNotifications: Scalars['Boolean'];
  criticalIndicatorsNotifications: Scalars['Boolean'];
  uploadAnalyzesByPatientsNotifications: Scalars['Boolean'];
};

export type DoctorUpdateNotificationsPayload = {
  __typename?: 'DoctorUpdateNotificationsPayload';
  user?: Maybe<DoctorModel>;
};

export type DrugModel = {
  __typename?: 'DrugModel';
  id: Scalars['String'];
  name: Scalars['String'];
};

export type EmailSignUpSendCodeProblemUnion = ExistEmailProblem | TooManyRequestsProblem;

export type ExistEmailProblem = {
  __typename?: 'ExistEmailProblem';
  message: Scalars['String'];
};

export type HospitalManagerEmailSignInInput = {
  email: Scalars['String'];
  password: Scalars['String'];
};

export type HospitalManagerEmailSignInPayload = {
  __typename?: 'HospitalManagerEmailSignInPayload';
  problem?: Maybe<NotExistEmailProblem>;
  refreshToken?: Maybe<Scalars['String']>;
  token?: Maybe<Scalars['String']>;
  user?: Maybe<MeHospitalManagerModel>;
};

export type HospitalModel = {
  __typename?: 'HospitalModel';
  id: Scalars['String'];
  name: Scalars['String'];
  patientsLimit: Scalars['Int'];
};

export type HospitalPatientModel = {
  __typename?: 'HospitalPatientModel';
  createdAt: Scalars['DateTime'];
  doctorId?: Maybe<Scalars['String']>;
  firstName?: Maybe<Scalars['String']>;
  hasActiveSurvey: Scalars['Boolean'];
  hospitalId: Scalars['UUID'];
  id: Scalars['UUID'];
  lastName?: Maybe<Scalars['String']>;
  medicalCardNumber: Scalars['String'];
  patientId: Scalars['UUID'];
};

export type HospitalsPatientsConnection = {
  __typename?: 'HospitalsPatientsConnection';
  nodes: Array<HospitalPatientModel>;
  pageInfo: PageInfo;
};

export type InvalidVerificationEmailHashProblem = {
  __typename?: 'InvalidVerificationEmailHashProblem';
  message: Scalars['String'];
};

export type InvalidVerificationEmailPasswordProblem = {
  __typename?: 'InvalidVerificationEmailPasswordProblem';
  message: Scalars['String'];
};

export enum Language {
  De = 'DE',
  En = 'EN',
  Kz = 'KZ',
  Ru = 'RU',
}

export type MeHospitalManagerModel = {
  __typename?: 'MeHospitalManagerModel';
  createdAt?: Maybe<Scalars['DateTime']>;
  deletedAt?: Maybe<Scalars['DateTime']>;
  email?: Maybe<Scalars['String']>;
  id: Scalars['String'];
  role?: Maybe<PatientRole>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type MePatientModel = {
  __typename?: 'MePatientModel';
  createdAt?: Maybe<Scalars['DateTime']>;
  deletedAt?: Maybe<Scalars['DateTime']>;
  email?: Maybe<Scalars['String']>;
  fcmToken?: Maybe<Scalars['String']>;
  hospitals: Array<HospitalPatientModel>;
  id: Scalars['String'];
  isFirstSignUp: Scalars['Boolean'];
  notificationsSettings: PatientNotificationsSettingsModel;
  role?: Maybe<PatientRole>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type Mutation = {
  __typename?: 'Mutation';
  /** create patient analyzes */
  createPatientAnalyze: Array<PatientAnalyzeModel>;
  /** create signed url */
  createSignedUrl: PatientCreateSignedUrlPayload;
  /** Cancel active doctor patient survey */
  doctorCancelActivePatientSurvey: Scalars['Boolean'];
  /** Create custom question */
  doctorCreateCustomQuestion: CreateCustomQuestionPayload;
  /** Create private survey template */
  doctorCreatePrivateSurveyTemplate: DoctorCreateSurveyTemplatePayload;
  /** Create public survey template */
  doctorCreatePublicSurveyTemplate: DoctorCreateSurveyTemplatePayload;
  /** Send link to change password and retrieve hash */
  doctorEmailRecoveryPasswordSendLink: DoctorEmailPasswordRecoverySendLinkPayload;
  /** Sign-in with email retrive user with bearer token */
  doctorEmailSignIn: DoctorEmailSignInPayload;
  /** Sign-up doctor with email and retrieve user with bearer token */
  doctorEmailSignUp: DoctorEmailSignUpPayload;
  /** Send code to email for sign-in with email */
  doctorEmailSignUpSendCode: DoctorEmailSignUpSendCodePayload;
  /** Mark all patient analyzes as viewed */
  doctorMarkPatientAnalyzesAsViewed: Scalars['Boolean'];
  /** Remove doctor notification by id */
  doctorNotificationRemove: Scalars['Boolean'];
  /** Update password */
  doctorPasswordUpdate: DoctorPasswordUpdatePayload;
  /** Read all doctor notifications */
  doctorReadAllNotifications: Scalars['Boolean'];
  /** Change password and retrieve doctor */
  doctorRecoverPassword: DoctorEmailPasswordRecoveryPayload;
  /** Remove doctor by id */
  doctorRemoveById: DoctorRemoveByIdPayload;
  /** Remove survey template by id */
  doctorRemoveSurveyTemplate: Scalars['Boolean'];
  /** Change language */
  doctorUpdateLanguage: DoctorUpdateLanguagePayload;
  /** Update notification settings */
  doctorUpdateNotificationsSettings: DoctorUpdateNotificationsPayload;
  /** Sign-in with email retrive user with bearer token */
  hospitalManagerEmailSignIn: HospitalManagerEmailSignInPayload;
  /** Assign patient to doctor */
  patientAssignToDoctor: PatientAssignToDoctorPayload;
  /** Check recovery code */
  patientCheckRecoveryCode: PatientCheckRecoveryCodePayload;
  /** Complete survey as patient */
  patientCompleteSurvey: PatientCompleteSurveyPayload;
  /** Create patient and send temp password to email */
  patientCreate: PatientCreatePayload;
  /** Create password for patient retrive patient with bearer token */
  patientCreatePassword: PatientCreatePasswordPayload;
  /** Send code to change password and retrieve code */
  patientEmailRecoveryPasswordSendCode: PatientEmailPasswordRecoverySendCodePayload;
  /** Sign-in with email retrive user with bearer token */
  patientEmailSignIn: PatientEmailSignInPayload;
  /** Update my email */
  patientEmailUpdate: PatientEmailUpdatePayload;
  /** Send code for update email */
  patientEmailUpdateSendCode: PatientEmailUpdateSendPasswordPayload;
  /** Update patient firebase token */
  patientFcmTokenUpdate: PatientFcmTokenUpdatePayload;
  /** Remove patient notification by id */
  patientNotificationRemove: Scalars['Boolean'];
  /** Update password */
  patientPasswordUpdate: PatientPasswordUpdatePayload;
  /** Read all patient notifications */
  patientReadAllNotifications: Scalars['Boolean'];
  /** Change password and retrieve patient */
  patientRecoverPassword: PatientEmailPasswordRecoveryPayload;
  /** Remove patient by id */
  patientRemove: PatientRemovePayload;
  /** Send contact me request to doctor */
  patientSendContactMeRequest: PatientSendContactMeRequestPayload;
  /** Change language */
  patientUpdateLanguage: PatientUpdateLanguagePayload;
  /** Update notification settings */
  patientUpdateNotificationsSettings: PatientUpdateNotificationsPayload;
};

export type MutationCreatePatientAnalyzeArgs = {
  input: CreateAnalyzesInput;
};

export type MutationCreateSignedUrlArgs = {
  filename: Scalars['String'];
};

export type MutationDoctorCancelActivePatientSurveyArgs = {
  patientId: Scalars['UUID'];
};

export type MutationDoctorCreateCustomQuestionArgs = {
  input: CreateCustomQuestionInput;
};

export type MutationDoctorCreatePrivateSurveyTemplateArgs = {
  input: DoctorCreateSurveyTemplateInput;
};

export type MutationDoctorCreatePublicSurveyTemplateArgs = {
  input: DoctorCreateSurveyTemplateInput;
};

export type MutationDoctorEmailRecoveryPasswordSendLinkArgs = {
  input: DoctorEmailPasswordRecoverySendLinkInput;
};

export type MutationDoctorEmailSignInArgs = {
  input: DoctorEmailSignInInput;
};

export type MutationDoctorEmailSignUpArgs = {
  input: DoctorEmailSignUpInput;
};

export type MutationDoctorEmailSignUpSendCodeArgs = {
  input: DoctorEmailSignUpSendLinkInput;
};

export type MutationDoctorMarkPatientAnalyzesAsViewedArgs = {
  patientId: Scalars['String'];
};

export type MutationDoctorNotificationRemoveArgs = {
  id: Scalars['String'];
};

export type MutationDoctorPasswordUpdateArgs = {
  input: DoctorPasswordUpdateInput;
};

export type MutationDoctorRecoverPasswordArgs = {
  input: DoctorEmailPasswordRecoveryInput;
};

export type MutationDoctorRemoveByIdArgs = {
  input: DoctorRemoveByIdInput;
};

export type MutationDoctorRemoveSurveyTemplateArgs = {
  id: Scalars['UUID'];
};

export type MutationDoctorUpdateLanguageArgs = {
  input: DoctorUpdateLanguageInput;
};

export type MutationDoctorUpdateNotificationsSettingsArgs = {
  input: DoctorUpdateNotificationsInput;
};

export type MutationHospitalManagerEmailSignInArgs = {
  input: HospitalManagerEmailSignInInput;
};

export type MutationPatientAssignToDoctorArgs = {
  input: PatientAssignToDoctorInput;
};

export type MutationPatientCheckRecoveryCodeArgs = {
  input: PatientCheckRecoveryCodeInput;
};

export type MutationPatientCompleteSurveyArgs = {
  input: PatientCompleteSurveyInput;
};

export type MutationPatientCreateArgs = {
  input: PatientCreateInput;
};

export type MutationPatientCreatePasswordArgs = {
  input: PatientCreatePasswordInput;
};

export type MutationPatientEmailRecoveryPasswordSendCodeArgs = {
  input: PatientEmailPasswordRecoverySendCodeInput;
};

export type MutationPatientEmailSignInArgs = {
  input: PatientEmailSignInInput;
};

export type MutationPatientEmailUpdateArgs = {
  input: PatientEmailSignInInput;
};

export type MutationPatientEmailUpdateSendCodeArgs = {
  input: PatientEmailUpdateSendCodeInput;
};

export type MutationPatientFcmTokenUpdateArgs = {
  input: PatientFcmTokenUpdateInput;
};

export type MutationPatientNotificationRemoveArgs = {
  id: Scalars['String'];
};

export type MutationPatientPasswordUpdateArgs = {
  input: PatientPasswordUpdateInput;
};

export type MutationPatientRecoverPasswordArgs = {
  input: PatientEmailPasswordRecoveryInput;
};

export type MutationPatientRemoveArgs = {
  id: Scalars['String'];
};

export type MutationPatientSendContactMeRequestArgs = {
  doctorId: Scalars['String'];
  message: Scalars['String'];
};

export type MutationPatientUpdateLanguageArgs = {
  input: PatientUpdateLanguageInput;
};

export type MutationPatientUpdateNotificationsSettingsArgs = {
  input: PatientUpdateNotificationsInput;
};

export type NotExistDoctorProblem = {
  __typename?: 'NotExistDoctorProblem';
  message: Scalars['String'];
};

export type NotExistEmailProblem = {
  __typename?: 'NotExistEmailProblem';
  message: Scalars['String'];
};

export type NotExistPatientProblem = {
  __typename?: 'NotExistPatientProblem';
  message: Scalars['String'];
};

export type NumericQuestionIndicators = {
  __typename?: 'NumericQuestionIndicators';
  maxValue: Scalars['FloatString'];
  minValue: Scalars['FloatString'];
};

export type NumericQuestionIndicatorsInput = {
  maxValue: Scalars['FloatString'];
  minValue: Scalars['FloatString'];
};

export type PageInfo = {
  __typename?: 'PageInfo';
  endCursor?: Maybe<Scalars['String']>;
  hasNextPage: Scalars['Boolean'];
};

export type PasswordsNotMatchProblem = {
  __typename?: 'PasswordsNotMatchProblem';
  message: Scalars['String'];
};

export type PatientAnalyzeConnection = {
  __typename?: 'PatientAnalyzeConnection';
  nodes: Array<PatientAnalyzeModel>;
  pageInfo: PageInfo;
};

export type PatientAnalyzeModel = {
  __typename?: 'PatientAnalyzeModel';
  createdAt: Scalars['DateTime'];
  doctorId: Scalars['String'];
  fileKey: Scalars['String'];
  filename: Scalars['String'];
  id: Scalars['String'];
  isViewed: Scalars['Boolean'];
  patientId: Scalars['String'];
};

export type PatientAssignToDoctorInput = {
  patientId: Scalars['String'];
};

export type PatientAssignToDoctorPayload = {
  __typename?: 'PatientAssignToDoctorPayload';
  problem?: Maybe<NotExistPatientProblem>;
  success?: Maybe<Scalars['Boolean']>;
};

export type PatientCheckRecoveryCodeInput = {
  code: Scalars['String'];
  email: Scalars['String'];
};

export type PatientCheckRecoveryCodePayload = {
  __typename?: 'PatientCheckRecoveryCodePayload';
  problem?: Maybe<NotExistPatientProblem>;
  success?: Maybe<Scalars['Boolean']>;
};

export type PatientCompleteSurveyAnswerInput = {
  /** Pass if question type is radio */
  answerQuestionOptionId?: Maybe<Scalars['UUID']>;
  /** Pass if question type is checkbox */
  answerQuestionOptionsIds?: Maybe<Array<Scalars['UUID']>>;
  /** Pass if question type is not checkbox or radio */
  answerValue?: Maybe<SurveyAnswerValueInput>;
  questionId: Scalars['UUID'];
  questionType: QuestionType;
};

export type PatientCompleteSurveyInput = {
  answers?: Maybe<Array<PatientCompleteSurveyAnswerInput>>;
  surveyId: Scalars['UUID'];
};

export type PatientCompleteSurveyPayload = {
  __typename?: 'PatientCompleteSurveyPayload';
  problem?: Maybe<BaseProblem>;
  success?: Maybe<Scalars['Boolean']>;
};

export type PatientCreateAnalyzesInput = {
  fileKey: Scalars['String'];
  fileName: Scalars['String'];
};

export type PatientCreateInput = {
  email: Scalars['String'];
  firstname?: Maybe<Scalars['String']>;
  lastname?: Maybe<Scalars['String']>;
  medicalCardNumber: Scalars['String'];
};

export type PatientCreatePasswordInput = {
  password: Scalars['String'];
};

export type PatientCreatePasswordPayload = {
  __typename?: 'PatientCreatePasswordPayload';
  problem?: Maybe<InvalidVerificationEmailPasswordProblem>;
  refreshToken?: Maybe<Scalars['String']>;
  token?: Maybe<Scalars['String']>;
  user?: Maybe<MePatientModel>;
};

export type PatientCreatePayload = {
  __typename?: 'PatientCreatePayload';
  password?: Maybe<Scalars['String']>;
  problem?: Maybe<EmailSignUpSendCodeProblemUnion>;
};

export type PatientCreateSignedUrlPayload = {
  __typename?: 'PatientCreateSignedUrlPayload';
  fileKey: Scalars['String'];
  signedUrl: Scalars['String'];
};

export type PatientDashboardModel = {
  __typename?: 'PatientDashboardModel';
  pressure?: Maybe<PatientDashboardPressureFieldModel>;
  pulse?: Maybe<Scalars['Float']>;
  temperature?: Maybe<Scalars['Float']>;
  weight?: Maybe<Scalars['Float']>;
};

export type PatientDashboardPressureFieldModel = {
  __typename?: 'PatientDashboardPressureFieldModel';
  lowerValue: Scalars['Float'];
  upperValue: Scalars['Float'];
};

export type PatientEmailPasswordRecoveryInput = {
  code: Scalars['String'];
  email: Scalars['String'];
  password: Scalars['String'];
};

export type PatientEmailPasswordRecoveryPayload = {
  __typename?: 'PatientEmailPasswordRecoveryPayload';
  problem?: Maybe<NotExistPatientProblem>;
  success?: Maybe<Scalars['Boolean']>;
};

export type PatientEmailPasswordRecoverySendCodeInput = {
  email: Scalars['String'];
};

export type PatientEmailPasswordRecoverySendCodePayload = {
  __typename?: 'PatientEmailPasswordRecoverySendCodePayload';
  code?: Maybe<Scalars['String']>;
  problem?: Maybe<EmailSignUpSendCodeProblemUnion>;
};

export type PatientEmailSignInInput = {
  email: Scalars['String'];
  password: Scalars['String'];
};

export type PatientEmailSignInPayload = {
  __typename?: 'PatientEmailSignInPayload';
  problem?: Maybe<NotExistEmailProblem>;
  refreshToken?: Maybe<Scalars['String']>;
  token?: Maybe<Scalars['String']>;
  user?: Maybe<MePatientModel>;
};

export type PatientEmailUpdatePayload = {
  __typename?: 'PatientEmailUpdatePayload';
  problem?: Maybe<InvalidVerificationEmailPasswordProblem>;
  refreshToken?: Maybe<Scalars['String']>;
  token?: Maybe<Scalars['String']>;
  user?: Maybe<MePatientModel>;
};

export type PatientEmailUpdateSendCodeInput = {
  email: Scalars['String'];
};

export type PatientEmailUpdateSendCodeProblemUnion = ExistEmailProblem | TooManyRequestsProblem;

export type PatientEmailUpdateSendPasswordPayload = {
  __typename?: 'PatientEmailUpdateSendPasswordPayload';
  /** send when DEVELOPMENT environment */
  password?: Maybe<Scalars['String']>;
  problem?: Maybe<PatientEmailUpdateSendCodeProblemUnion>;
};

export type PatientFcmTokenUpdateInput = {
  fcmToken: Scalars['String'];
};

export type PatientFcmTokenUpdatePayload = {
  __typename?: 'PatientFcmTokenUpdatePayload';
  problem?: Maybe<BaseProblem>;
  user?: Maybe<MePatientModel>;
};

export type PatientModel = {
  __typename?: 'PatientModel';
  createdAt?: Maybe<Scalars['DateTime']>;
  deletedAt?: Maybe<Scalars['DateTime']>;
  email?: Maybe<Scalars['String']>;
  id: Scalars['String'];
  notificationsSettings: PatientNotificationsSettingsModel;
  role?: Maybe<PatientRole>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type PatientNotificationConnection = {
  __typename?: 'PatientNotificationConnection';
  nodes: Array<PatientNotificationModel>;
  pageInfo: PageInfo;
};

export enum PatientNotificationKind {
  NewSurvey = 'NEW_SURVEY',
}

export type PatientNotificationModel = {
  __typename?: 'PatientNotificationModel';
  createdAt: Scalars['DateTime'];
  description: Scalars['String'];
  extraData: PatientNotificationsExtraData;
  id: Scalars['UUID'];
  isRead: Scalars['Boolean'];
  kind: PatientNotificationKind;
  patientId: Scalars['UUID'];
  title: Scalars['String'];
};

export type PatientNotificationsExtraData = {
  __typename?: 'PatientNotificationsExtraData';
  surveyId: Scalars['String'];
};

export type PatientNotificationsSettingsModel = {
  __typename?: 'PatientNotificationsSettingsModel';
  newSurvey?: Maybe<Scalars['Boolean']>;
};

export type PatientPasswordUpdateInput = {
  oldPassword: Scalars['String'];
  password: Scalars['String'];
};

export type PatientPasswordUpdatePayload = {
  __typename?: 'PatientPasswordUpdatePayload';
  problem?: Maybe<PasswordsNotMatchProblem>;
  refreshToken?: Maybe<Scalars['String']>;
  token?: Maybe<Scalars['String']>;
  user?: Maybe<MePatientModel>;
};

export type PatientPressureHistoryConnection = {
  __typename?: 'PatientPressureHistoryConnection';
  nodes: Array<PatientPressureHistoryItemModel>;
  pageInfo: PageInfo;
};

export type PatientPressureHistoryItemModel = {
  __typename?: 'PatientPressureHistoryItemModel';
  createdAt: Scalars['DateTime'];
  lowerValue: Scalars['Float'];
  upperValue: Scalars['Float'];
};

export type PatientPulseHistoryConnection = {
  __typename?: 'PatientPulseHistoryConnection';
  nodes: Array<PatientPulseHistoryItemModel>;
  pageInfo: PageInfo;
};

export type PatientPulseHistoryItemModel = {
  __typename?: 'PatientPulseHistoryItemModel';
  createdAt: Scalars['DateTime'];
  value: Scalars['Float'];
};

export type PatientRemovePayload = {
  __typename?: 'PatientRemovePayload';
  problem?: Maybe<NotExistPatientProblem>;
  success?: Maybe<Scalars['Boolean']>;
};

export enum PatientRole {
  Admin = 'ADMIN',
  Doctor = 'DOCTOR',
  Manager = 'MANAGER',
  Patient = 'PATIENT',
}

export type PatientSendContactMeRequestPayload = {
  __typename?: 'PatientSendContactMeRequestPayload';
  problem?: Maybe<NotExistPatientProblem>;
  success?: Maybe<Scalars['Boolean']>;
};

export type PatientTemperatureHistoryConnection = {
  __typename?: 'PatientTemperatureHistoryConnection';
  nodes: Array<PatientTemperatureHistoryItemModel>;
  pageInfo: PageInfo;
};

export type PatientTemperatureHistoryItemModel = {
  __typename?: 'PatientTemperatureHistoryItemModel';
  createdAt: Scalars['DateTime'];
  value: Scalars['Float'];
};

export type PatientUpdateLanguageInput = {
  language?: Maybe<Language>;
};

export type PatientUpdateLanguagePayload = {
  __typename?: 'PatientUpdateLanguagePayload';
  user?: Maybe<PatientModel>;
};

export type PatientUpdateNotificationsInput = {
  newSurveyNotifications: Scalars['Boolean'];
};

export type PatientUpdateNotificationsPayload = {
  __typename?: 'PatientUpdateNotificationsPayload';
  user?: Maybe<PatientModel>;
};

export type PatientWeightHistoryConnection = {
  __typename?: 'PatientWeightHistoryConnection';
  nodes: Array<PatientWeightHistoryItemModel>;
  pageInfo: PageInfo;
};

export type PatientWeightHistoryItemModel = {
  __typename?: 'PatientWeightHistoryItemModel';
  createdAt: Scalars['DateTime'];
  value: Scalars['Float'];
};

export type PressureQuestionIndicators = {
  __typename?: 'PressureQuestionIndicators';
  lowerMaxValue: Scalars['FloatString'];
  lowerMinValue: Scalars['FloatString'];
  upperMaxValue: Scalars['FloatString'];
  upperMinValue: Scalars['FloatString'];
};

export type PressureQuestionIndicatorsInput = {
  lowerMaxValue: Scalars['FloatString'];
  lowerMinValue: Scalars['FloatString'];
  upperMaxValue: Scalars['FloatString'];
  upperMinValue: Scalars['FloatString'];
};

export type Query = {
  __typename?: 'Query';
  /** Check doctor email for uniqueness */
  doctorEmailIsUniq: Scalars['Boolean'];
  /** Retrieve hospital patient for current doctor */
  doctorFindHospitalPatient: HospitalPatientModel;
  /** Retrieve private survey templates for patient */
  doctorFindPatientPrivateSurveyTemplates: Array<SurveyTemplateModel>;
  /** Retrieve public survey templates for patient */
  doctorFindPatientPublicSurveyTemplates: Array<SurveyTemplateModel>;
  /** Retrieve doctor patient question answers */
  doctorFindPatientQuestionAnswers: DoctorPatientSurveyAnswerConnection;
  /** Retrieve doctor patient survey answers */
  doctorFindPatientSurveyAnswers: Array<DoctorPatientSurveyAnswerModel>;
  /** Find question by id */
  doctorFindQuestionById: QuestionModel;
  /** Retrieve new patient analyzes count */
  doctorGetNewPatientAnalyzesCount: Scalars['Int'];
  /** Retrieve doctor notifications */
  doctorGetNotifications: DoctorNotificationConnection;
  /** Retrieve unread doctor notifications count */
  doctorGetUnreadNotificationsCount: Scalars['Int'];
  /** Retrieve current doctor */
  doctorMe: DoctorModel;
  /** Find questions by title and category id */
  doctorSearch: Array<QuestionModel>;
  /** Find drug questions */
  drugFindQuestions: Array<QuestionModel>;
  /** Search drugs */
  drugsSearch: Array<DrugModel>;
  /** Return all categories */
  findAllQuestionCategories: Array<QuestionCategoryModel>;
  getSignedUrl: Scalars['String'];
  /** Check hospital limit before creating a patient. If result is true, doctor can add patient, if false - limit is exhausted */
  hospitalLimitNotExhausted: Scalars['Boolean'];
  /** Retrieve patient dashboard */
  patientDashboard: PatientDashboardModel;
  /** Retrieve patient doctors */
  patientDoctors: Array<DoctorPatientModel>;
  /** Check patient email for uniqueness */
  patientEmailIsUniq: Scalars['Boolean'];
  /** Retrieve active patient survey */
  patientFindActiveSurvey?: Maybe<SurveyModel>;
  /** Retrieve patient notifications */
  patientGetNotifications: PatientNotificationConnection;
  /** Retrieve patient pressure history */
  patientGetPressureHistory: PatientPressureHistoryConnection;
  /** Retrieve patient pulse history */
  patientGetPulseHistory: PatientPulseHistoryConnection;
  /** Retrieve patient temperature history */
  patientGetTemperatureHistory: PatientTemperatureHistoryConnection;
  /** Retrieve patient notifications */
  patientGetUnreadNotificationsCount: Scalars['Int'];
  /** Retrieve patient weight history */
  patientGetWeightHistory: PatientWeightHistoryConnection;
  /** Retrieve current patient */
  patientMe: MePatientModel;
  /** Check patient medical card number for uniqueness */
  patientMedicalCardNumberIsUniq: Scalars['Boolean'];
  /** Search doctors */
  searchDoctors: DoctorConnection;
  /** Search patient */
  searchPatient: HospitalsPatientsConnection;
  /** Search patient analyze */
  searchPatientAnalyzes: PatientAnalyzeConnection;
  /** Retrieve one survey template */
  surveyTemplateFindOne: SurveyTemplateModel;
};

export type QueryDoctorEmailIsUniqArgs = {
  email: Scalars['String'];
};

export type QueryDoctorFindHospitalPatientArgs = {
  patientId: Scalars['UUID'];
};

export type QueryDoctorFindPatientPrivateSurveyTemplatesArgs = {
  patientId: Scalars['UUID'];
};

export type QueryDoctorFindPatientPublicSurveyTemplatesArgs = {
  patientId: Scalars['UUID'];
};

export type QueryDoctorFindPatientQuestionAnswersArgs = {
  after?: Maybe<Scalars['String']>;
  endAt?: Maybe<Scalars['DateTime']>;
  patientId: Scalars['UUID'];
  questionId: Scalars['UUID'];
  startAt?: Maybe<Scalars['DateTime']>;
  surveyTemplateId?: Maybe<Scalars['String']>;
  take?: Maybe<Scalars['Int']>;
};

export type QueryDoctorFindPatientSurveyAnswersArgs = {
  patientId: Scalars['UUID'];
  surveyTemplateId?: Maybe<Scalars['UUID']>;
};

export type QueryDoctorFindQuestionByIdArgs = {
  id: Scalars['String'];
};

export type QueryDoctorGetNewPatientAnalyzesCountArgs = {
  patientId: Scalars['String'];
};

export type QueryDoctorGetNotificationsArgs = {
  after?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
};

export type QueryDoctorSearchArgs = {
  categoryId?: Maybe<Scalars['String']>;
  title: Scalars['String'];
};

export type QueryDrugFindQuestionsArgs = {
  id: Scalars['UUID'];
};

export type QueryDrugsSearchArgs = {
  filter?: Maybe<Scalars['String']>;
};

export type QueryGetSignedUrlArgs = {
  fileKey: Scalars['String'];
};

export type QueryPatientEmailIsUniqArgs = {
  email: Scalars['String'];
};

export type QueryPatientGetNotificationsArgs = {
  after?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
};

export type QueryPatientGetPressureHistoryArgs = {
  after?: Maybe<Scalars['String']>;
  endAt?: Maybe<Scalars['DateTime']>;
  first?: Maybe<Scalars['Int']>;
  startAt?: Maybe<Scalars['DateTime']>;
};

export type QueryPatientGetPulseHistoryArgs = {
  after?: Maybe<Scalars['String']>;
  endAt?: Maybe<Scalars['DateTime']>;
  first?: Maybe<Scalars['Int']>;
  startAt?: Maybe<Scalars['DateTime']>;
};

export type QueryPatientGetTemperatureHistoryArgs = {
  after?: Maybe<Scalars['String']>;
  endAt?: Maybe<Scalars['DateTime']>;
  first?: Maybe<Scalars['Int']>;
  startAt?: Maybe<Scalars['DateTime']>;
};

export type QueryPatientGetWeightHistoryArgs = {
  after?: Maybe<Scalars['String']>;
  endAt?: Maybe<Scalars['DateTime']>;
  first?: Maybe<Scalars['Int']>;
  startAt?: Maybe<Scalars['DateTime']>;
};

export type QueryPatientMedicalCardNumberIsUniqArgs = {
  hospitalId: Scalars['UUID'];
  medicalCardNumber: Scalars['String'];
};

export type QuerySearchDoctorsArgs = {
  after?: Maybe<Scalars['String']>;
  filter?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  orderBy?: Maybe<SearchOrder>;
};

export type QuerySearchPatientArgs = {
  after?: Maybe<Scalars['String']>;
  filter: Scalars['String'];
  first?: Maybe<Scalars['Int']>;
};

export type QuerySearchPatientAnalyzesArgs = {
  after?: Maybe<Scalars['String']>;
  endAt?: Maybe<Scalars['DateTime']>;
  first?: Maybe<Scalars['Int']>;
  orderBy?: Maybe<SearchOrder>;
  patientId: Scalars['String'];
  startAt?: Maybe<Scalars['DateTime']>;
};

export type QuerySurveyTemplateFindOneArgs = {
  id: Scalars['UUID'];
};

export type QuestionCategoryModel = {
  __typename?: 'QuestionCategoryModel';
  id: Scalars['String'];
  name: Scalars['String'];
};

export type QuestionIndicators = {
  __typename?: 'QuestionIndicators';
  numeric?: Maybe<NumericQuestionIndicators>;
  pressure?: Maybe<PressureQuestionIndicators>;
  scale?: Maybe<ScaleQuestionIndicators>;
  temperature?: Maybe<TemperatureQuestionIndicators>;
};

export type QuestionIndicatorsInput = {
  numeric?: Maybe<NumericQuestionIndicatorsInput>;
  pressure?: Maybe<PressureQuestionIndicatorsInput>;
  scale?: Maybe<ScaleQuestionIndicatorsInput>;
  temperature?: Maybe<TemperatureQuestionIndicatorsInput>;
};

export type QuestionInput = {
  /** Pass if question type is radio */
  criticalAnswerId?: Maybe<Scalars['UUID']>;
  /** Pass if question type is checkbox */
  criticalAnswersIds?: Maybe<Array<Scalars['UUID']>>;
  /** Pass if question type is not radio or checkbox */
  criticalIndicators?: Maybe<SurveyTemplateQuestionCriticalIndicatorsInput>;
  questionId: Scalars['UUID'];
  questionType: QuestionType;
};

export type QuestionModel = {
  __typename?: 'QuestionModel';
  id: Scalars['String'];
  indicators?: Maybe<QuestionIndicators>;
  isActual: Scalars['Boolean'];
  isCustom: Scalars['Boolean'];
  options?: Maybe<Array<QuestionOptionModel>>;
  title: Scalars['String'];
  type: QuestionType;
};

export type QuestionOptionModel = {
  __typename?: 'QuestionOptionModel';
  id: Scalars['String'];
  index: Scalars['Int'];
  text: Scalars['String'];
};

export enum QuestionType {
  Checkbox = 'CHECKBOX',
  Numeric = 'NUMERIC',
  Pressure = 'PRESSURE',
  Pulse = 'PULSE',
  Radio = 'RADIO',
  Scale = 'SCALE',
  Temperature = 'TEMPERATURE',
  Weight = 'WEIGHT',
}

export type ScaleQuestionIndicators = {
  __typename?: 'ScaleQuestionIndicators';
  maxValue: Scalars['FloatString'];
  minValue: Scalars['FloatString'];
};

export type ScaleQuestionIndicatorsInput = {
  maxValue: Scalars['FloatString'];
  minValue: Scalars['FloatString'];
};

export enum SearchOrder {
  Asc = 'ASC',
  Desc = 'DESC',
}

export type SurveyAnswerModel = {
  __typename?: 'SurveyAnswerModel';
  answerQuestionOption?: Maybe<QuestionOptionModel>;
  answerQuestionOptionId?: Maybe<Scalars['UUID']>;
  answerQuestionOptions?: Maybe<Array<QuestionOptionModel>>;
  answerQuestionOptionsIds?: Maybe<Array<Scalars['UUID']>>;
  answerValue?: Maybe<SurveyAnswerValue>;
  createdAt: Scalars['DateTime'];
  id: Scalars['String'];
  isCritical: Scalars['Boolean'];
  questionId: Scalars['UUID'];
  surveyId: Scalars['UUID'];
};

export type SurveyAnswerValue = {
  __typename?: 'SurveyAnswerValue';
  numeric?: Maybe<SurveyNumericAnswerValue>;
  pressure?: Maybe<SurveyPressureAnswerValue>;
  pulse?: Maybe<SurveyPulseAnswerValue>;
  scale?: Maybe<SurveyScaleAnswerValue>;
  temperature?: Maybe<SurveyTemperatureAnswerValue>;
  weight?: Maybe<SurveyWeightAnswerValue>;
};

export type SurveyAnswerValueInput = {
  numeric?: Maybe<SurveyNumericAnswerValueInput>;
  pressure?: Maybe<SurveyPressureAnswerValueInput>;
  pulse?: Maybe<SurveyPulseAnswerValueInput>;
  scale?: Maybe<SurveyScaleAnswerValueInput>;
  temperature?: Maybe<SurveyTemperatureAnswerValueInput>;
  weight?: Maybe<SurveyWeightAnswerValueInput>;
};

export type SurveyModel = {
  __typename?: 'SurveyModel';
  createdAt: Scalars['DateTime'];
  id: Scalars['String'];
  template: SurveyTemplateModel;
};

export type SurveyNumericAnswerValue = {
  __typename?: 'SurveyNumericAnswerValue';
  value: Scalars['Float'];
};

export type SurveyNumericAnswerValueInput = {
  value: Scalars['Float'];
};

export type SurveyPressureAnswerValue = {
  __typename?: 'SurveyPressureAnswerValue';
  lowerValue: Scalars['Float'];
  upperValue: Scalars['Float'];
};

export type SurveyPressureAnswerValueInput = {
  lowerValue: Scalars['Float'];
  upperValue: Scalars['Float'];
};

export type SurveyPulseAnswerValue = {
  __typename?: 'SurveyPulseAnswerValue';
  value: Scalars['Float'];
};

export type SurveyPulseAnswerValueInput = {
  value: Scalars['Float'];
};

export type SurveyScaleAnswerValue = {
  __typename?: 'SurveyScaleAnswerValue';
  value: Scalars['Float'];
};

export type SurveyScaleAnswerValueInput = {
  value: Scalars['Float'];
};

export type SurveyTemperatureAnswerValue = {
  __typename?: 'SurveyTemperatureAnswerValue';
  value: Scalars['Float'];
};

export type SurveyTemperatureAnswerValueInput = {
  value: Scalars['Float'];
};

export enum SurveyTemplateKind {
  Private = 'PRIVATE',
  Public = 'PUBLIC',
}

export type SurveyTemplateModel = {
  __typename?: 'SurveyTemplateModel';
  createdAt: Scalars['DateTime'];
  drugs: Array<DrugModel>;
  endAt: Scalars['DateTime'];
  id: Scalars['String'];
  kind: SurveyTemplateKind;
  period: SurveyTemplatePeriod;
  questions: Array<SurveyTemplateQuestionModel>;
  questionsCount: Scalars['Int'];
  startAt: Scalars['DateTime'];
  timezoneOffset: Scalars['Int'];
  title: Scalars['String'];
};

export type SurveyTemplateNumericQuestionCriticalIndicators = {
  __typename?: 'SurveyTemplateNumericQuestionCriticalIndicators';
  maxValue?: Maybe<Scalars['FloatString']>;
  minValue?: Maybe<Scalars['FloatString']>;
};

export type SurveyTemplateNumericQuestionCriticalIndicatorsInput = {
  maxValue?: Maybe<Scalars['FloatString']>;
  minValue?: Maybe<Scalars['FloatString']>;
};

export enum SurveyTemplatePeriod {
  Everyday = 'EVERYDAY',
  EveryTwoDays = 'EVERY_TWO_DAYS',
  OnceAWeek = 'ONCE_A_WEEK',
  OnceInTwoWeeks = 'ONCE_IN_TWO_WEEKS',
}

export type SurveyTemplatePressureQuestionCriticalIndicators = {
  __typename?: 'SurveyTemplatePressureQuestionCriticalIndicators';
  maxLowerValue: Scalars['FloatString'];
  maxUpperValue: Scalars['FloatString'];
  minLowerValue: Scalars['FloatString'];
  minUpperValue: Scalars['FloatString'];
};

export type SurveyTemplatePressureQuestionCriticalIndicatorsInput = {
  maxLowerValue: Scalars['FloatString'];
  maxUpperValue: Scalars['FloatString'];
  minLowerValue: Scalars['FloatString'];
  minUpperValue: Scalars['FloatString'];
};

export type SurveyTemplatePulseQuestionCriticalIndicators = {
  __typename?: 'SurveyTemplatePulseQuestionCriticalIndicators';
  maxValue: Scalars['FloatString'];
  minValue: Scalars['FloatString'];
};

export type SurveyTemplatePulseQuestionCriticalIndicatorsInput = {
  maxValue: Scalars['FloatString'];
  minValue: Scalars['FloatString'];
};

export type SurveyTemplateQuestionCriticalIndicators = {
  __typename?: 'SurveyTemplateQuestionCriticalIndicators';
  numeric?: Maybe<SurveyTemplateNumericQuestionCriticalIndicators>;
  pressure?: Maybe<SurveyTemplatePressureQuestionCriticalIndicators>;
  pulse?: Maybe<SurveyTemplatePulseQuestionCriticalIndicators>;
  scale?: Maybe<SurveyTemplateScaleQuestionCriticalIndicators>;
  temperature?: Maybe<SurveyTemplateTemperatureQuestionCriticalIndicators>;
  weight?: Maybe<SurveyTemplateWeightQuestionCriticalIndicators>;
};

export type SurveyTemplateQuestionCriticalIndicatorsInput = {
  numeric?: Maybe<SurveyTemplateNumericQuestionCriticalIndicatorsInput>;
  pressure?: Maybe<SurveyTemplatePressureQuestionCriticalIndicatorsInput>;
  pulse?: Maybe<SurveyTemplatePulseQuestionCriticalIndicatorsInput>;
  scale?: Maybe<SurveyTemplateScaleQuestionCriticalIndicatorsInput>;
  temperature?: Maybe<SurveyTemplateTemperatureQuestionCriticalIndicatorsInput>;
  weight?: Maybe<SurveyTemplateWeightQuestionCriticalIndicatorsInput>;
};

export type SurveyTemplateQuestionModel = {
  __typename?: 'SurveyTemplateQuestionModel';
  criticalAnswerId?: Maybe<Scalars['UUID']>;
  criticalAnswersIds?: Maybe<Array<Scalars['UUID']>>;
  criticalIndicators?: Maybe<SurveyTemplateQuestionCriticalIndicators>;
  question?: Maybe<QuestionModel>;
};

export type SurveyTemplateScaleQuestionCriticalIndicators = {
  __typename?: 'SurveyTemplateScaleQuestionCriticalIndicators';
  value: Scalars['FloatString'];
};

export type SurveyTemplateScaleQuestionCriticalIndicatorsInput = {
  value: Scalars['FloatString'];
};

export type SurveyTemplateTemperatureQuestionCriticalIndicators = {
  __typename?: 'SurveyTemplateTemperatureQuestionCriticalIndicators';
  maxValue: Scalars['FloatString'];
  minValue: Scalars['FloatString'];
};

export type SurveyTemplateTemperatureQuestionCriticalIndicatorsInput = {
  maxValue: Scalars['FloatString'];
  minValue: Scalars['FloatString'];
};

export type SurveyTemplateWeightQuestionCriticalIndicators = {
  __typename?: 'SurveyTemplateWeightQuestionCriticalIndicators';
  maxValue: Scalars['FloatString'];
  minValue: Scalars['FloatString'];
};

export type SurveyTemplateWeightQuestionCriticalIndicatorsInput = {
  maxValue: Scalars['FloatString'];
  minValue: Scalars['FloatString'];
};

export type SurveyWeightAnswerValue = {
  __typename?: 'SurveyWeightAnswerValue';
  value: Scalars['Float'];
};

export type SurveyWeightAnswerValueInput = {
  value: Scalars['Float'];
};

export type TemperatureQuestionIndicators = {
  __typename?: 'TemperatureQuestionIndicators';
  maxValue: Scalars['FloatString'];
  minValue: Scalars['FloatString'];
};

export type TemperatureQuestionIndicatorsInput = {
  maxValue: Scalars['FloatString'];
  minValue: Scalars['FloatString'];
};

export type TooManyRequestsProblem = {
  __typename?: 'TooManyRequestsProblem';
  message: Scalars['String'];
};
