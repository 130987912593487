import React, { useEffect, useState } from 'react';
import styled from 'styled-components';

import { HospitalPatientModel, Maybe } from '../../__generated__/types';
import { useCancelActiveSurvey } from '../../common/mutation/__generated__/cancel-active-survey';
import { useRemovePatient } from '../../common/mutation/__generated__/remove-patient';
import { useCheckPatientLimitLazyQuery } from '../../common/query/__generated__/check-patient-limit';
import { SearchPatientsDocument, useSearchPatients } from '../../common/query/__generated__/search-patients';
import { modalVar } from '../../libs/Apollo';
import { defaultTheme } from '../../themes';
import {
  Button,
  Loader,
  ModalAddingPatient,
  ModalChecked,
  ModalContainer,
  ModalWithButton,
  Patient,
  Scrollbar,
  Search,
} from '../../ui';

export const PatientsList = () => {
  //modals
  const [isOpenModalDeletingPatient, setIsOpenModalDeletingPatient] = useState(false);
  const [isOpenModalAddingPatient, setIsOpenModalAddingPatient] = useState(false);
  const [isOpenModalInvitation, setIsOpenModalInvitation] = useState(false);
  const [isOpenModalPatientLimit, setIsOpenModalPatientLimit] = useState(false);

  const [checkLimit, { loading }] = useCheckPatientLimitLazyQuery({
    fetchPolicy: 'network-only',
    onCompleted: res => {
      if (!res.hospitalLimitNotExhausted) {
        openModalPatientLimit();
      } else {
        setIsOpenModalAddingPatient(true);
        modalVar({ isOpenModal: true });
      }
    },
  });
  const closeModalAddingPatient = () => {
    setIsOpenModalAddingPatient(false);
    modalVar({ isOpenModal: false });
  };
  const openModalDeletingPatient = () => {
    setIsOpenModalDeletingPatient(true);
    modalVar({ isOpenModal: true });
  };
  const closeModalDeletingPatient = () => {
    setIsOpenModalDeletingPatient(false);
    modalVar({ isOpenModal: false });
  };
  const openModalInvitation = () => {
    setIsOpenModalInvitation(true);
    modalVar({ isOpenModal: true });
  };
  const closeModalInvitation = () => {
    setIsOpenModalInvitation(false);
    modalVar({ isOpenModal: false });
  };
  const openModalPatientLimit = () => {
    setIsOpenModalPatientLimit(true);
    modalVar({ isOpenModal: true });
  };
  const closeModalPatientLimit = () => {
    setIsOpenModalPatientLimit(false);
    modalVar({ isOpenModal: false });
  };

  const isOpenModal = isOpenModalAddingPatient || isOpenModalDeletingPatient || isOpenModalInvitation;

  //search patients
  const [inputValue, setInputValue] = useState('');
  const {
    data: patientsList,
    loading: loadingPatients,
    fetchMore,
    refetch,
  } = useSearchPatients({
    fetchPolicy: 'network-only',
    variables: {
      filter: inputValue,
      first: 20,
    },
  });

  const [patients, setPatients] = useState<HospitalPatientModel[]>([]);
  const [cursor, setCursor] = useState<Maybe<string> | undefined>(undefined);
  const [hasNextPage, setHasNextPage] = useState<boolean>(false);

  useEffect(() => {
    if (patientsList) {
      setPatients(patientsList?.searchPatient.nodes);
      setCursor(patientsList?.searchPatient.pageInfo.endCursor);
      setHasNextPage(patientsList?.searchPatient.pageInfo.hasNextPage);
    }
  }, [patientsList]);

  const onLoadMorePatients = async () => {
    if (hasNextPage) {
      const { data } = await fetchMore({
        variables: {
          after: cursor,
        },
      });
      setPatients([...patients, ...data.searchPatient.nodes]);
      if (data.searchPatient.pageInfo.hasNextPage) {
        setCursor(data.searchPatient.pageInfo.endCursor);
      } else {
        setCursor(undefined);
      }
      setHasNextPage(data.searchPatient.pageInfo.hasNextPage);
    }
  };

  //search field
  const [isFocusedSearch, setIsFocusedSearch] = useState(false);
  const onFocus = () => setIsFocusedSearch(true);
  const onBlur = () => setIsFocusedSearch(false);

  //delete patient
  const [deletingPatientId, setDeletingPatientId] = useState<string | undefined>(undefined);
  const [removePatient, { loading: loadingRemove }] = useRemovePatient();
  const [cancelActiveSurvey] = useCancelActiveSurvey();

  const deletePatient = async () => {
    const deletingPatient = patients.find(patient => patient.patientId === deletingPatientId);
    const res = await removePatient({
      variables: {
        id: deletingPatientId || '',
      },
      refetchQueries: [
        {
          query: SearchPatientsDocument,
          variables: {
            filter: deletingPatient?.medicalCardNumber,
          },
        },
      ],
    });

    if (res.data?.patientRemove.success) {
      if (deletingPatient?.hasActiveSurvey) {
        await cancelActiveSurvey({
          variables: {
            patientId: deletingPatientId,
          },
        });
      }
    }
    setDeletingPatientId(undefined);
    refetch();
  };

  //add patient
  const addPatient = () => {
    closeModalAddingPatient();
    refetch();
  };

  const returnPatient = (patient: HospitalPatientModel) => {
    return (
      <Patient
        key={patient.patientId}
        cardNumber={patient.medicalCardNumber}
        lastName={patient.lastName}
        firstName={patient.firstName}
        isAddedPatient={Boolean(patient.doctorId)}
        hasActiveSurvey={patient.hasActiveSurvey}
        openModal={openModalDeletingPatient}
        id={patient.patientId}
        setDeletingPatientId={setDeletingPatientId}
        updatePatientList={refetch}
      />
    );
  };

  //add new patient
  const addNewPatient = (newPatient: HospitalPatientModel) => {
    setPatients([newPatient, ...patients]);
  };

  return (
    <Root>
      <Container $isOpenModal={isOpenModal}>
        <Content>
          <Title>Список пациентов</Title>
          <SearchWrapper>
            <Search
              placeholder="Поиск"
              onFocus={onFocus}
              onBlur={onBlur}
              inputValue={inputValue}
              setInputValue={setInputValue}
            />
            <ButtonWrapper>
              <Button
                variant="primary"
                isLoading={loading}
                label="Добавить пациента"
                width={270}
                onClick={checkLimit}
              />
            </ButtonWrapper>
          </SearchWrapper>
          <MainContainer>
            {loadingPatients ? (
              <CenteringWrapper>
                <Loader color={defaultTheme.colors.grayDarkMode} />
              </CenteringWrapper>
            ) : isFocusedSearch ? (
              inputValue.length === 0 ? (
                <CenteringWrapper>
                  <NoPatients>Начните вводить имя или номер карты пациента, чтобы начать поиск</NoPatients>
                </CenteringWrapper>
              ) : patients?.length ? (
                <ScrollbarWrapper>
                  <Scrollbar onLoadMore={onLoadMorePatients}>
                    {patients?.map(patient => returnPatient(patient))}
                  </Scrollbar>
                </ScrollbarWrapper>
              ) : (
                <CenteringWrapper>
                  <NoPatients>Пациентов не найдено</NoPatients>
                </CenteringWrapper>
              )
            ) : patients?.length ? (
              <ScrollbarWrapper>
                <Scrollbar onLoadMore={onLoadMorePatients}>
                  {patients?.map(patient => returnPatient(patient))}
                </Scrollbar>
              </ScrollbarWrapper>
            ) : (
              <CenteringWrapper>
                <NoPatients>
                  У Вас нет пациентов
                  <br />
                  Пожалуйста, добавьте нового пациента
                </NoPatients>
              </CenteringWrapper>
            )}
          </MainContainer>
        </Content>
      </Container>
      {isOpenModalAddingPatient && (
        <ModalContainer closeModal={closeModalAddingPatient} minHeightForMediaQuery="674px">
          <ModalAddingPatient
            closeModal={addPatient}
            openNextModal={openModalInvitation}
            addNewPatient={addNewPatient}
          />
        </ModalContainer>
      )}
      {isOpenModalInvitation && (
        <ModalContainer closeModal={closeModalInvitation}>
          <ModalChecked text="Приглашение отправлено на указанную электронную почту" />
        </ModalContainer>
      )}
      {isOpenModalDeletingPatient && (
        <ModalContainer closeModal={closeModalDeletingPatient}>
          <ModalWithButton
            closeModal={closeModalDeletingPatient}
            question="Вы уверены, что хотите удалить пациента?"
            description="Пациент будет удален из списка Ваших пациентов и отправлен в базу данных"
            onClick={deletePatient}
            loading={loadingRemove}
          />
        </ModalContainer>
      )}
      {isOpenModalPatientLimit && (
        <ModalContainer closeModal={closeModalPatientLimit}>
          <ModalWithButton
            closeModal={closeModalPatientLimit}
            question="Пациент не может быть добавлен"
            description="Лимит подключений новых пациентов исчерпан. Пожалуйста, обратитесь к менеджеру сервиса в вашем учреждении."
            onClick={closeModalPatientLimit}
          />
        </ModalContainer>
      )}
    </Root>
  );
};

type StyleType = {
  $isOpenModal: boolean;
};

const Root = styled.div`
  width: 100%;
`;
const Container = styled.div<StyleType>`
  width: 100%;
  height: calc(100vh - 100px);
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  z-index: 1;
  background-color: ${({ theme: { colors } }) => colors.blueExtraLight};
  ${({ $isOpenModal }) => $isOpenModal && `filter: blur(3px)`}
`;
const Content = styled.div`
  width: 1280px;
  height: calc(100vh - 180px);
  background-color: ${({ theme: { colors } }) => colors.white};
  box-sizing: border-box;
  border: 1px solid ${({ theme: { colors } }) => colors.blueExtraLight};
  box-shadow: 0 6px 30px #e4ecf7;
  border-radius: 10px;
  padding: 32px;
  margin: 40px 0;
`;
const Title = styled.span`
  color: ${({ theme: { colors } }) => colors.grayDark};
  ${({ theme: { typography } }) => typography.title2};
  line-height: 44px;
`;
const SearchWrapper = styled.div`
  display: flex;
  margin-top: 20px;
`;
const ButtonWrapper = styled.div`
  margin-left: 32px;
`;
const MainContainer = styled.div`
  width: 100%;
  height: calc(100vh - 180px - 183px);
`;
const CenteringWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  height: inherit;
`;
const NoPatients = styled.p`
  width: 256px;
  color: ${({ theme: { colors } }) => colors.grayDarkMode};
  ${({ theme: { typography } }) => typography.title3};
  text-align: center;
`;
const ScrollbarWrapper = styled.div`
  width: calc(100% + 32px);
  height: inherit;
`;
