import React, { ReactNode } from 'react';
import styled from 'styled-components';

import { Header } from '../../ui';

type Props = {
  children: ReactNode;
};

function UserLayout({ children }: Props) {
  return (
    <Root>
      <Header isAuth={true} />
      {children}
    </Root>
  );
}

export default UserLayout;

const Root = styled.div`
  width: 100%;
  max-height: 100vh;
`;
