import * as Apollo from '@apollo/client';
import { DocumentNode } from 'graphql';

import * as Types from '../../../__generated__/types';
import { Doctor } from '../../fragments/__generated__/doctor.fragment';

const defaultOptions = {};
export type EmailSignUpVariables = Types.Exact<{
  input: Types.DoctorEmailSignUpInput;
}>;

export type EmailSignUp = {
  __typename: 'Mutation';
  doctorEmailSignUp: {
    __typename: 'DoctorEmailSignUpPayload';
    token?: Types.Maybe<string>;
    refreshToken?: Types.Maybe<string>;
    problem?: Types.Maybe<{ __typename: 'InvalidVerificationEmailHashProblem'; message: string }>;
    user?: Types.Maybe<{
      __typename: 'DoctorModel';
      id: string;
      role?: Types.Maybe<Types.PatientRole>;
      email?: Types.Maybe<string>;
      language?: Types.Maybe<Types.Language>;
      createdAt?: Types.Maybe<any>;
      deletedAt?: Types.Maybe<any>;
      updatedAt?: Types.Maybe<any>;
      hospital?: Types.Maybe<{ __typename: 'HospitalModel'; id: string; name: string; patientsLimit: number }>;
      notificationsSettings?: Types.Maybe<{
        __typename: 'DoctorNotificationSettingsModel';
        contactMeRequest?: Types.Maybe<boolean>;
        criticalIndicators?: Types.Maybe<boolean>;
        uploadAnalyzesByPatients?: Types.Maybe<boolean>;
      }>;
    }>;
  };
};

export const EmailSignUpDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'EmailSignUp' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'input' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'DoctorEmailSignUpInput' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'doctorEmailSignUp' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'input' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'input' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'problem' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [{ kind: 'Field', name: { kind: 'Name', value: 'message' } }],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'user' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'Doctor' } }],
                  },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'token' } },
                { kind: 'Field', name: { kind: 'Name', value: 'refreshToken' } },
              ],
            },
          },
        ],
      },
    },
    ...Doctor.definitions,
  ],
} as unknown as DocumentNode;
export type EmailSignUpMutationFn = Apollo.MutationFunction<EmailSignUp, EmailSignUpVariables>;

/**
 * __useEmailSignUp__
 *
 * To run a mutation, you first call `useEmailSignUp` within a React component and pass it any options that fit your needs.
 * When your component renders, `useEmailSignUp` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [emailSignUp, { data, loading, error }] = useEmailSignUp({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useEmailSignUp(baseOptions?: Apollo.MutationHookOptions<EmailSignUp, EmailSignUpVariables>) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<EmailSignUp, EmailSignUpVariables>(EmailSignUpDocument, options);
}
export type EmailSignUpHookResult = ReturnType<typeof useEmailSignUp>;
export type EmailSignUpMutationResult = Apollo.MutationResult<EmailSignUp>;
export type EmailSignUpMutationOptions = Apollo.BaseMutationOptions<EmailSignUp, EmailSignUpVariables>;
