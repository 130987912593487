import { useEffect, useState } from 'react';
import { RangeModifier } from 'react-day-picker';
import { useParams } from 'react-router-dom';
import styled from 'styled-components';

import { Maybe } from '../../__generated__/types';
import { useMarkAnalyzesAsViewed } from '../../common/mutation/__generated__/mark-analyzes-as-viewed';
import { useGetAnalyzes } from '../../common/query/__generated__/get-analyzes';
import {
  GetNewAnalyzesCountDocument,
  useGetNewAnalyzesCount,
} from '../../common/query/__generated__/get-new-analyzes-count';
import { useGetPatient } from '../../common/query/__generated__/get-patient';
import { defaultTheme } from '../../themes';
import { Analysis, BackBlock, Calendar, Loader, Scrollbar } from '../../ui';

export type AnalysisType = {
  id: string;
  fileKey: string;
  filename: string;
  createdAt: Date;
};

export const Analyzes = () => {
  const { id: patientId } = useParams<{ id: string }>();
  const { data } = useGetPatient({
    variables: {
      patientId,
    },
  });
  const patient = data?.doctorFindHospitalPatient;
  const patientName =
    patient?.firstName || patient?.lastName ? `${patient?.firstName} ${patient?.lastName}` : patient?.medicalCardNumber;

  const [cursor, setCursor] = useState<Maybe<string> | undefined>(undefined);
  const [hasNextPage, setHasNextPage] = useState<boolean>(false);
  const [analyzes, setAnalyzes] = useState<AnalysisType[]>([]);
  const [dateRange, setDateRange] = useState<RangeModifier | undefined>(undefined);
  const {
    data: dataAnalyzes,
    loading: loadingAnalyzes,
    error: errorAnalyzes,
    fetchMore,
  } = useGetAnalyzes({
    variables: {
      patientId,
      first: 10,
      startAt: dateRange?.from,
      endAt: dateRange?.to,
    },
    fetchPolicy: 'network-only',
  });

  useEffect(() => {
    if (dataAnalyzes) {
      const analyzes: AnalysisType[] = dataAnalyzes?.searchPatientAnalyzes.nodes.map(analysis => {
        const { id, fileKey, createdAt, filename } = analysis;
        return { id, createdAt, fileKey, filename };
      });
      setAnalyzes([...analyzes]);
      setCursor(dataAnalyzes?.searchPatientAnalyzes.pageInfo.endCursor);
      setHasNextPage(dataAnalyzes?.searchPatientAnalyzes.pageInfo.hasNextPage);
    }
  }, [dataAnalyzes]);

  const onLoadMoreAnalyzes = async () => {
    if (hasNextPage) {
      const { data } = await fetchMore({
        variables: {
          first: 10,
          after: cursor,
        },
      });
      const loadedAnalyzes: AnalysisType[] = data.searchPatientAnalyzes.nodes.map(analysis => {
        const { id, fileKey, createdAt, filename } = analysis;
        return { id, createdAt, fileKey, filename };
      });
      setAnalyzes([...analyzes, ...loadedAnalyzes]);
      if (data.searchPatientAnalyzes.pageInfo.hasNextPage) {
        setCursor(data.searchPatientAnalyzes.pageInfo.endCursor);
      } else {
        setCursor(undefined);
      }
      setHasNextPage(data.searchPatientAnalyzes.pageInfo.hasNextPage);
    }
  };

  const { data: analyzesCount } = useGetNewAnalyzesCount({
    variables: { patientId },
  });

  const hasNotViewedAnalyzes = analyzesCount && analyzesCount.doctorGetNewPatientAnalyzesCount > 0;

  const [markAnalyzesAsViewed] = useMarkAnalyzesAsViewed({
    variables: { patientId },
    refetchQueries: [{ query: GetNewAnalyzesCountDocument, variables: { patientId } }],
  });

  useEffect(() => {
    (async () => {
      if (hasNotViewedAnalyzes) {
        await markAnalyzesAsViewed();
      }
    })();
  }, [markAnalyzesAsViewed, hasNotViewedAnalyzes]);

  return (
    <Root>
      <Container>
        <BackBlock>
          <PatientName>{patientName}</PatientName>
          <Slash>/</Slash>
          <PatientName>Анализы</PatientName>
        </BackBlock>
        <Content>
          <Calendar setDateRange={setDateRange} />
          <MainContainer>
            {(loadingAnalyzes || errorAnalyzes) && (
              <CenteringWrapper>
                {loadingAnalyzes ? (
                  <Loader color={defaultTheme.colors.grayDarkMode} />
                ) : (
                  <CenteringText>{errorAnalyzes?.message}</CenteringText>
                )}
              </CenteringWrapper>
            )}
            {analyzes.length > 0 ? (
              <FilesBlock>
                <Scrollbar onLoadMore={onLoadMoreAnalyzes}>
                  {analyzes.map(analysis => {
                    return <Analysis key={analysis.id} data={analysis} />;
                  })}
                </Scrollbar>
              </FilesBlock>
            ) : (
              <CenteringWrapper>
                <CenteringText>У этого пациента пока нет анализов</CenteringText>
              </CenteringWrapper>
            )}
          </MainContainer>
        </Content>
      </Container>
    </Root>
  );
};

const Root = styled.div`
  width: 100%;
`;
const Container = styled.div`
  width: 100%;
  height: calc(100vh - 100px);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-color: ${({ theme: { colors } }) => colors.blueExtraLight};
`;
const Content = styled.div`
  width: 1280px;
  background-color: ${({ theme: { colors } }) => colors.white};
  box-sizing: border-box;
  border: 1px solid ${({ theme: { colors } }) => colors.blueExtraLight};
  box-shadow: 0 6px 30px #e4ecf7;
  border-radius: 10px;
  padding: 31px;
  height: calc(100vh - 100px - 124px);
  margin-bottom: 40px;
`;
const Title = styled.span`
  color: ${({ theme: { colors } }) => colors.grayDark};
  ${({ theme: { typography } }) => typography.title2};
  line-height: 44px;
`;
const PatientName = styled(Title)`
  margin: 0 16px;
`;
const Slash = styled(Title)`
  color: ${({ theme: { colors } }) => colors.grayDarkMode};
`;
const MainContainer = styled.div`
  width: 100%;
  height: calc(100vh - 100px - 124px - 86px);
`;
const CenteringWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
`;
const CenteringText = styled.div`
  width: 256px;
  text-align: center;
  color: ${({ theme: { colors } }) => colors.grayDarkMode};
  ${({ theme: { typography } }) => typography.title3};
  letter-spacing: -0.165px;
`;
const FilesBlock = styled.div`
  margin-top: 32px;
  height: calc(100% - 17px);
`;
