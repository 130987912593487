import React from 'react';
import styled from 'styled-components';

function OneAnswerOption({ isChecked, isFakeChecked = false, onClick, text }: OneAnswerOptionPropsType) {
  return (
    <Root $isChecked={isChecked || isFakeChecked} type="button" onClick={onClick}>
      <Text $isChecked={isChecked || isFakeChecked}>{text}</Text>
    </Root>
  );
}

export type OneAnswerOptionPropsType = {
  isChecked: boolean;
  isFakeChecked?: boolean;
  onClick?: () => void;
  text: string;
};

type StylePropsType = {
  $isChecked?: boolean;
};

export default OneAnswerOption;

const Root = styled.button<StylePropsType>`
  width: fit-content;
  height: fit-content;
  border-radius: 6px;
  display: flex;
  cursor: pointer;
  overflow: hidden;
  border: none;
  margin-right: 12px;
  margin-bottom: 20px;
  background-color: ${({ $isChecked, theme: { colors } }) => ($isChecked ? colors.blueAverage : colors.grayExtraLight)};
`;

const Text = styled.span<StylePropsType>`
  color: ${({ $isChecked, theme: { colors } }) => ($isChecked ? colors.blue : colors.grayDarkMode)};
  font-family: 'lato', sans-serif;
  overflow: hidden;
  word-wrap: break-word;
  text-overflow: ellipsis;
  font-weight: 400;
  font-size: 16px;
  line-height: 20px;
  margin: 6px 12px;
`;
