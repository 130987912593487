import { Form, Formik } from 'formik';
import React from 'react';
import { useParams } from 'react-router-dom';
import styled from 'styled-components';

import { SurveyTemplateModel } from '../../../../__generated__/types';
import { Scrollbar } from '../../../../ui';
import { fakeIdCompletedSurvey } from '../../../../utils/constants';
import { SurveyItem } from './components';

type Props = {
  setSurveyId: (surveyId: string | undefined) => void;
  surveyTemplates: SurveyTemplateModel[] | undefined;
};
type CompletedSurvey = {
  id: string;
  title: string;
  questionsCount?: number;
  createdAt?: Date;
};

function CompletedSurveys({ setSurveyId, surveyTemplates }: Props) {
  const { surveyId } = useParams<{ surveyId: string }>();

  if (!surveyTemplates) {
    return null;
  }

  const mappedSurveysFromDB: CompletedSurvey[] | undefined = surveyTemplates?.map(survey => ({
    id: survey.id,
    title: survey.title,
    questionsCount: survey.questionsCount,
    createdAt: survey.createdAt,
  }));

  let surveys: CompletedSurvey[] = [];

  if (mappedSurveysFromDB) {
    if (mappedSurveysFromDB.length > 1) {
      surveys = [{ title: 'Все опросы', id: fakeIdCompletedSurvey }, ...mappedSurveysFromDB];
    } else {
      surveys = [...mappedSurveysFromDB];
    }
  }
  const initialValues = {
    surveys: surveyId !== undefined ? surveyId : surveys.length > 0 ? surveys[0].id : undefined,
  };

  return (
    <Root>
      <Formik initialValues={initialValues} onSubmit={() => {}}>
        {values => {
          const id = values.values.surveys;
          if (id === fakeIdCompletedSurvey) {
            setSurveyId(undefined);
          } else {
            setSurveyId(id);
          }
          return (
            <Form style={{ height: '100%' }}>
              <Title>Показать опросы</Title>
              {mappedSurveysFromDB && mappedSurveysFromDB.length > 0 ? (
                <Container>
                  <Scrollbar>
                    {surveys &&
                      surveys.map(survey => {
                        return (
                          <SurveyItem
                            key={survey.id}
                            id={survey.id}
                            title={survey.title}
                            questionsCount={survey.questionsCount}
                            createdAt={survey.createdAt}
                          />
                        );
                      })}
                  </Scrollbar>
                </Container>
              ) : (
                <NoSurveysWrapper>
                  <NoSurveys>Нет опросов для отображения</NoSurveys>
                </NoSurveysWrapper>
              )}
            </Form>
          );
        }}
      </Formik>
    </Root>
  );
}

export default CompletedSurveys;

const Root = styled.div`
  width: 470px;
  height: calc(100vh - 100px - 129px);
  background-color: ${({ theme: { colors } }) => colors.white};
  border: 1px solid ${({ theme: { colors } }) => colors.blueExtraLight};
  box-sizing: border-box;
  box-shadow: 0 6px 30px #e4ecf7;
  border-radius: 10px;
  padding: 31px;
`;
const Title = styled.span`
  ${({ theme: { typography } }) => typography.title2};
  color: ${({ theme: { colors } }) => colors.grayDark};
  line-height: 44px;
  font-weight: 600;
`;
const NoSurveysWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  position: relative;
  top: -40px;
`;
const NoSurveys = styled.p`
  width: 256px;
  color: ${({ theme: { colors } }) => colors.grayDarkMode};
  ${({ theme: { typography } }) => typography.title3};
  text-align: center;
  letter-spacing: -0.165px;
`;
const Container = styled.div`
  margin-top: 20px;
  width: calc(100% + 32px);
  height: calc(100% - 16px);
`;
