import React from 'react';

function FileTextBigIcon() {
  return (
    <svg width="56" height="56" viewBox="0 0 56 56" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M48.65 48.9836L48.65 48.9861C48.6518 49.2465 48.6023 49.5048 48.5044 49.7461C48.4064 49.9875 48.2618 50.2071 48.0789 50.3926C47.896 50.5781 47.6784 50.7257 47.4385 50.827C47.1989 50.9282 46.9417 50.9813 46.6816 50.9833H9.317C8.79566 50.9833 8.29565 50.7763 7.92682 50.4079C7.55803 50.0395 7.35057 49.5397 7.35 49.0184V6.98129C7.35 5.9225 8.24005 5.01663 9.338 5.01663H34.8481L48.65 18.8117L48.65 48.9836ZM44.6833 21V20.65H44.3333H33.0167V9.33329V8.98329H32.6667H11.6667H11.3167V9.33329V46.6666V47.0166H11.6667H44.3333H44.6833V46.6666V21ZM19.0167 16.6833H25.3167V20.65H19.0167V16.6833ZM19.0167 26.0166H36.9833V29.9833H19.0167V26.0166ZM19.0167 35.35H36.9833V39.3166H19.0167V35.35Z"
        fill="#3380EE"
        stroke="white"
        strokeWidth="0.7"
      />
    </svg>
  );
}

export default FileTextBigIcon;
