import { Form, Formik } from 'formik';
import React from 'react';
import styled from 'styled-components';

import { Maybe, QuestionOptionModel } from '../../__generated__/types';
import { CheckboxText } from './components';

export type CheckboxTextGroupPropsType = {
  options: Maybe<Array<QuestionOptionModel>>;
  type: 'radio' | 'checkbox';
  criticalValues?: QuestionOptionModel[];
  setCriticalValues?: (values: QuestionOptionModel[]) => void;
  checkboxValues?: string | string[] | undefined;
  setCheckboxValues?: (values: string | string[] | undefined) => void;
};

function CheckboxTextGroup({ options, type, criticalValues, setCheckboxValues }: CheckboxTextGroupPropsType) {
  const initialValues = criticalValues?.map(value => value.text);

  return (
    <Formik initialValues={{ checked: initialValues }} onSubmit={() => {}}>
      {values => {
        if (setCheckboxValues) {
          setCheckboxValues(values.values.checked);
        }

        return (
          <Form>
            <Wrapper>
              {options &&
                options.map(option => {
                  return (
                    option.text !== '' && (
                      <CheckboxText key={option.id} value={option.text} type={type} name="checked" />
                    )
                  );
                })}
            </Wrapper>
          </Form>
        );
      }}
    </Formik>
  );
}

export default React.memo(CheckboxTextGroup);

const Wrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  margin-top: 20px;
`;
