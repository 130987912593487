import { DocumentNode } from 'graphql';

import * as Types from '../../../__generated__/types';

export type Patient = {
  __typename: 'HospitalPatientModel';
  createdAt: any;
  hospitalId: any;
  doctorId?: Types.Maybe<string>;
  patientId: any;
  medicalCardNumber: string;
  firstName?: Types.Maybe<string>;
  lastName?: Types.Maybe<string>;
  id: any;
  hasActiveSurvey: boolean;
};

export const Patient = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'Patient' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'HospitalPatientModel' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'createdAt' } },
          { kind: 'Field', name: { kind: 'Name', value: 'hospitalId' } },
          { kind: 'Field', name: { kind: 'Name', value: 'doctorId' } },
          { kind: 'Field', name: { kind: 'Name', value: 'patientId' } },
          { kind: 'Field', name: { kind: 'Name', value: 'medicalCardNumber' } },
          { kind: 'Field', name: { kind: 'Name', value: 'firstName' } },
          { kind: 'Field', name: { kind: 'Name', value: 'lastName' } },
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'hasActiveSurvey' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode;
