import { Analyzes, CreatingQuiz, Notifications, PatientPage, PatientsList, Profile } from 'pages';
import { FC } from 'react';
import { Redirect, Route, Switch } from 'react-router-dom';

import { UserLayout } from '../layouts/user';
import { ROUTES } from './index';

export const UserSwitch: FC = () => {
  return (
    <UserLayout>
      <Switch>
        <Route path={ROUTES['/patients']} component={PatientsList} />
        <Route path={ROUTES['/patient/:id/analyzes']} component={Analyzes} />
        <Route path={ROUTES['/notifications']} component={Notifications} />
        <Route path={ROUTES['/patient/:id/create-quiz']} component={CreatingQuiz} />
        <Route path={ROUTES['/profile']} component={Profile} />
        <Route path={ROUTES['/patient/:id/:surveyId']} component={PatientPage} />
        <Redirect from="/" to={ROUTES['/patients']} />
        {/*<Route component={NotFound} />*/}
      </Switch>
    </UserLayout>
  );
};
