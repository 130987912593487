import React from 'react';
import styled from 'styled-components';

const Toggle: React.VFC<ToggleProps> = ({ isDisabled, isChecked = false, onClickToggle }) => {
  return (
    <Root>
      <ToggleBox $isDisabled={isDisabled} $isChecked={isChecked} checked={isChecked} onClick={onClickToggle} />
    </Root>
  );
};

export interface ToggleProps {
  isDisabled?: boolean;
  isChecked?: boolean;
  onClickToggle?: () => void;
}

interface RootProps {
  $isDisabled?: boolean;
  $isChecked?: boolean;
}
const Root = styled.div`
  width: 72px;
  height: 44px;
  display: flex;
  align-items: center;
  justify-content: center;
`;
const ToggleBox = styled.input.attrs({ type: 'checkbox' })<RootProps>`
  all: unset;
  position: relative;
  display: inline-block;
  width: 64px;
  height: 36px;
  background-color: ${({ theme: { colors } }) => colors.blue};
  border-radius: 40px;
  ${({ $isChecked, theme: { colors } }) => !$isChecked && `background-color: ${colors.grayAverage}`};

  &::after {
    content: '';
    box-sizing: border-box;
    position: absolute;
    width: 36px;
    height: 36px;
    border: 2px solid ${({ theme: { colors } }) => colors.blue};
    border-radius: 40px;
    background-color: ${({ theme: { colors } }) => colors.white};

    ${({ $isChecked, theme: { colors } }) => !$isChecked && `border: 2px solid ${colors.grayAverage}`};
  }

  &:hover::after {
    border: 2px solid ${({ theme: { colors } }) => colors.blueDark};

    ${({ $isChecked, theme: { colors } }) => !$isChecked && `border: 2px solid ${colors.grayDarkMode};`};
  }

  &:hover {
    background-color: ${({ theme: { colors } }) => colors.blueDark};

    ${({ $isChecked, theme: { colors } }) => !$isChecked && `background-color: ${colors.grayDarkMode};`};
  }

  &:focus {
    border: 4px solid ${({ theme: { colors } }) => colors.blueAverage};

    ${({ $isChecked, theme: { colors } }) => !$isChecked && `border: 4px solid ${colors.grayExtraLight}`};
  }

  &:checked::after {
    right: 0;
  }

  ${({ $isDisabled, theme: { colors } }) =>
    $isDisabled &&
    `
    box-sizing: border-box;
    background-color: ${colors.grayAverage};
    border: 1px solid ${colors.grayDarkMode};
    pointer-events: none;

    &::after {
      background-color: ${colors.grayLight};
      width: 34px;
      height: 34px;
      border: 1px solid ${colors.grayAverage};
    }
  `}
`;

export default Toggle;
