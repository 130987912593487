import React from 'react';
import styled from 'styled-components';

import { CrossIcon } from '../../assets/svg';
import { defaultTheme } from '../../themes';
import { Button } from '../Button';

type Props = {
  closeModal: () => void;
  onClick: () => void;
  question: string;
  description: string;
  loading?: boolean;
};

function ModalWithButton({ closeModal, question, description, onClick, loading = false }: Props) {
  const onClickHandler = () => {
    closeModal();
    onClick();
  };
  return (
    <Root onClick={e => e.stopPropagation()}>
      <Header>
        <TextHeader>{question}</TextHeader>
        <CloseIcon onClick={closeModal}>
          <CrossIcon color={defaultTheme.colors.grayDarkMode} />
        </CloseIcon>
      </Header>
      <Text>{description}</Text>
      <Button variant="primary" label="Подтвердить" onClick={onClickHandler} isLoading={loading} />
    </Root>
  );
}

export default ModalWithButton;

const Root = styled.div`
  width: 420px;
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: ${({ theme: { colors } }) => colors.white};
  border: 1px solid ${({ theme: { colors } }) => colors.blueExtraLight};
  box-sizing: border-box;
  box-shadow: 0 6px 30px #e4ecf7;
  border-radius: 10px;
  padding: 40px;
  position: relative;
  z-index: 100;
  top: -50px;
`;
const Header = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-bottom: 22px;
`;
const TextHeader = styled.span`
  ${({ theme: { typography } }) => typography.title2};
  color: ${({ theme: { colors } }) => colors.grayDark};
`;
const CloseIcon = styled.div`
  padding: 5px;
  cursor: pointer;
  height: fit-content;
`;
const Text = styled.div`
  ${({ theme: { typography } }) => typography.body3};
  color: ${({ theme: { colors } }) => colors.grayDarkMode};
  letter-spacing: -0.165px;
  margin-bottom: 32px;
`;
