import React from 'react';
import { Link } from 'react-router-dom';
import styled from 'styled-components';

import { DoctorNotificationKind, DoctorNotificationModel } from '../../__generated__/types';
import { CalendarIcon, TrashIcon } from '../../assets/svg';
import AccountBoxIcon from '../../assets/svg/account-box-icon/AccountBoxIcon';
import { useRemoveNotification } from '../../common/mutation/__generated__/remove-notification';
import { GetNotificationsDocument } from '../../common/query/__generated__/get-notifications';
import { defaultTheme } from '../../themes';
import { getDate } from '../../utils/get-date';

function NotificationItem({ notification }: { notification: DoctorNotificationModel }) {
  const { title, kind, createdAt, description, extraData, hospitalPatient, patientId, id } = notification;
  const patientName =
    hospitalPatient?.firstName || hospitalPatient?.lastName
      ? `${hospitalPatient?.firstName} ${hospitalPatient?.lastName}`
      : undefined;
  const linkToAnalyzes = `/patient/${patientId}/analyzes`;
  const linkToSurvey = `/patient/${patientId}/${extraData?.surveyTemplateId}`;

  const date = getDate(new Date(createdAt));

  const [removeNotification] = useRemoveNotification({
    variables: { id },
    refetchQueries: [{ query: GetNotificationsDocument, variables: { first: 10 } }],
  });

  const deleteNotification = async () => {
    await removeNotification();
  };

  return (
    <Root>
      <DateBlock>
        <CalendarIcon color={defaultTheme.colors.blue} />
        <DateText>{date.day}</DateText>
      </DateBlock>
      <Container>
        <Heading>
          <HeadingText>Новое уведомление</HeadingText>
          <Trash onClick={deleteNotification}>
            <TrashIcon />
          </Trash>
        </Heading>
        <MainContainer>
          <NotificationContainer>
            <PatientDateWrapper>
              <AccountBoxIcon size={'42'} />
              <PatientDate>
                <PatientNameSurvey>
                  <PatientName>
                    {patientName
                      ? `${patientName}, ${hospitalPatient?.medicalCardNumber}`
                      : hospitalPatient?.medicalCardNumber}
                  </PatientName>
                </PatientNameSurvey>
                {patientName && <CardNumber>{hospitalPatient?.medicalCardNumber}</CardNumber>}
              </PatientDate>
            </PatientDateWrapper>
            <Notification>
              <NotificationText>{title}</NotificationText>
              {kind === DoctorNotificationKind.ContactMeRequest && (
                <DetailsText>
                  <ReasonText>{description}</ReasonText>
                </DetailsText>
              )}
              {kind === DoctorNotificationKind.CriticalIndicators && (
                <LinkText to={linkToSurvey}>Перейти на опрос</LinkText>
              )}
              {kind === DoctorNotificationKind.UploadAnalyzesByPatient && (
                <LinkText to={linkToAnalyzes}>Посмотреть результаты</LinkText>
              )}
            </Notification>
          </NotificationContainer>
          <Time>{date.time}</Time>
        </MainContainer>
      </Container>
    </Root>
  );
}

export default NotificationItem;

const Root = styled.div`
  width: 100%;
  margin-bottom: 20px;

  &:last-child {
    margin-bottom: 0;
  }
`;
const DateBlock = styled.div`
  display: flex;
  align-items: center;
  padding: 3px 0;
  margin-bottom: 20px;
`;
const DateText = styled.span`
  color: ${({ theme: { colors } }) => colors.grayDarkMode};
  ${({ theme: { typography } }) => typography.title3};
  margin-left: 14px;
`;
const MainContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
`;
const Container = styled.div`
  background-color: ${({ theme: { colors } }) => colors.grayExtraLight};
  border-radius: 10px;
  padding: 32px;
`;
const Heading = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 22px;
`;
const HeadingText = styled.span`
  color: ${({ theme: { colors } }) => colors.grayDark};
  ${({ theme: { typography } }) => typography.title2};
`;
const Trash = styled.div`
  margin-right: 2px;
  cursor: pointer;
`;
const NotificationContainer = styled.div`
  display: flex;
  align-items: center;
`;
const PatientDateWrapper = styled.div`
  display: flex;
  align-items: center;
  width: 300px;
  margin-right: 43px;
`;
const PatientDate = styled.div`
  display: flex;
  flex-direction: column;
  margin-left: 16px;
`;
const PatientNameSurvey = styled.div`
  display: flex;
`;
const PatientName = styled.span`
  color: ${({ theme: { colors } }) => colors.grayDark};
  ${({ theme: { typography } }) => typography.title3};
  margin-bottom: 2px;
`;
const CardNumber = styled.span`
  color: ${({ theme: { colors } }) => colors.grayDarkMode};
  ${({ theme: { typography } }) => typography.body2};
`;
const Notification = styled.div`
  max-width: 740px;
`;
const NotificationText = styled.div`
  color: ${({ theme: { colors } }) => colors.grayDark};
  ${({ theme: { typography } }) => typography.title3};
  margin-bottom: 2px;
`;
const DetailsText = styled.div`
  color: ${({ theme: { colors } }) => colors.grayDarkMode};
  ${({ theme: { typography } }) => typography.body3};
`;
const ReasonText = styled.span`
  ${({ theme: { typography } }) => typography.body1};
`;
const LinkText = styled(Link)`
  color: ${({ theme: { colors } }) => colors.blue};
  ${({ theme: { typography } }) => typography.body1};
  text-decoration: none;
`;
const Time = styled.div`
  color: ${({ theme: { colors } }) => colors.grayDarkMode};
  ${({ theme: { typography } }) => typography.title3};
`;
