import React, { useEffect, useState } from 'react';
import { RangeModifier } from 'react-day-picker';
import { useParams } from 'react-router-dom';
import styled from 'styled-components';
import { resetParentEvents } from 'utils/resetParentEvents';

import { Maybe, QuestionType, SurveyAnswerModel, SurveyAnswerValue } from '../../../../../__generated__/types';
import { ArrowDownIcon } from '../../../../../assets/svg';
import { useGetQuestionAnswers } from '../../../../../common/query/__generated__/get-question-answers';
import { AnswerTable, Calendar, HorizontalScrollbar, PressureChart, TemperatureChart } from '../../../../../ui';

type Props = {
  question: string;
  answerRadio?: Maybe<string>;
  answerCheckbox?: Maybe<string[]>;
  answerValue?: Maybe<SurveyAnswerValue>;
  minValue?: Maybe<string>;
  maxValue?: Maybe<string>;
  isCustom?: boolean;
  isCritical?: boolean;
  isShowAllSurveys: boolean;
  questionType: QuestionType;
  questionId: string;
  surveyTemplateId: string | undefined;
};

export const AnswerItem = ({
  question,
  answerRadio,
  answerCheckbox,
  answerValue,
  minValue,
  maxValue,
  isCustom = false,
  isCritical = false,
  isShowAllSurveys,
  questionType,
  questionId,
  surveyTemplateId,
}: Props) => {
  const [dateRange, setDateRange] = useState<RangeModifier | undefined>(undefined);
  const [cursor, setCursor] = useState<Maybe<string> | undefined>(undefined);
  const [hasNextPage, setHasNextPage] = useState<boolean>(false);
  const [answers, setAnswers] = useState<SurveyAnswerModel[]>([]);
  const { id: patientId } = useParams<{ id: string }>();
  const { data, fetchMore } = useGetQuestionAnswers({
    variables: {
      patientId,
      questionId,
      surveyTemplateId,
      startAt: dateRange?.from,
      endAt: dateRange?.to,
      take: questionType === QuestionType.Radio || questionType === QuestionType.Checkbox ? 5 : 9,
    },
    fetchPolicy: 'network-only',
    pollInterval: 100000,
  });
  useEffect(() => {
    if (data) {
      setAnswers(data.doctorFindPatientQuestionAnswers.nodes);
      setCursor(data.doctorFindPatientQuestionAnswers.pageInfo.endCursor);
      setHasNextPage(data.doctorFindPatientQuestionAnswers.pageInfo.hasNextPage);
    }
  }, [data]);
  const onLoadMoreAnswers = async () => {
    if (hasNextPage) {
      const { data } = await fetchMore({
        variables: {
          after: cursor,
        },
      });
      setAnswers([...answers, ...data.doctorFindPatientQuestionAnswers.nodes]);
      if (data.doctorFindPatientQuestionAnswers.pageInfo.hasNextPage) {
        setCursor(data.doctorFindPatientQuestionAnswers.pageInfo.endCursor);
      } else {
        setCursor(undefined);
      }
      setHasNextPage(data.doctorFindPatientQuestionAnswers.pageInfo.hasNextPage);
    }
  };

  //displaying data of answers
  let minMaxValues: string;
  if (minValue && maxValue) {
    minMaxValues = `${minValue} / ${maxValue}`;
  } else {
    minMaxValues = 'Пациент еще не ответил';
  }
  const lastAnswer = () => {
    switch (questionType) {
      case QuestionType.Radio:
        return answerRadio;
      case QuestionType.Checkbox:
        const answers = answerCheckbox
          ?.map((answer, index) => (index === 0 ? answer : answer.toLowerCase()))
          .join(', ');
        return answers;
      case QuestionType.Scale:
        return answerValue?.scale?.value;
      case QuestionType.Weight:
        return answerValue?.weight?.value;
      case QuestionType.Pulse:
        return answerValue?.pulse?.value;
      case QuestionType.Numeric:
        return answerValue?.numeric?.value;
      case QuestionType.Temperature:
        return answerValue?.temperature?.value;
      case QuestionType.Pressure:
        return `${answerValue?.pressure?.upperValue} / ${answerValue?.pressure?.lowerValue}`;
    }
  };

  //displaying answer details
  const [isOpenDetails, setIsOpenDetails] = useState(false);
  const answerOptions =
    questionType === QuestionType.Radio
      ? answers.map(answer => {
          return {
            id: answer.answerQuestionOptionId,
            createdAt: new Date(answer.createdAt).toLocaleDateString(),
            answer: answer.answerQuestionOption?.text,
            isCritical: answer.isCritical,
          };
        })
      : questionType === QuestionType.Checkbox
      ? answers.map(answer => {
          const answers = answerCheckbox
            ?.map((answer, index) => (index === 0 ? answer : answer.toLowerCase()))
            .join(', ');
          return {
            id: answer.answerQuestionOptionsIds,
            createdAt: new Date(answer.createdAt).toLocaleDateString(),
            answer: answers,
            isCritical: answer.isCritical,
          };
        })
      : undefined;

  const valueOptions =
    questionType === QuestionType.Weight ||
    questionType === QuestionType.Scale ||
    questionType === QuestionType.Pulse ||
    questionType === QuestionType.Numeric ||
    questionType === QuestionType.Temperature
      ? answers.map(answer => {
          return {
            name: new Date(answer.createdAt).toLocaleDateString().toString().slice(0, 5),
            value:
              answer.answerValue?.temperature?.value ||
              answer.answerValue?.numeric?.value ||
              answer.answerValue?.pulse?.value ||
              answer.answerValue?.weight?.value ||
              answer.answerValue?.scale?.value,
            isCritical: answer.isCritical,
          };
        })
      : undefined;

  const pressureOptions =
    questionType === QuestionType.Pressure
      ? answers.map(answer => {
          return {
            name: new Date(answer.createdAt).toLocaleDateString().toString().slice(0, 5),
            sys: answer.answerValue?.pressure?.upperValue,
            dia: answer.answerValue?.pressure?.lowerValue,
            isCritical: answer.isCritical,
          };
        })
      : undefined;

  return (
    <Root
      $isCustom={isCustom}
      $isCritical={isCritical}
      $isOpenDetails={isOpenDetails}
      onClick={() => setIsOpenDetails(!isOpenDetails)}>
      <AnswersWrapper>
        <Data>
          <BodyItem>{question}</BodyItem>
          <BodyItem>
            {lastAnswer() || lastAnswer() === 0 ? lastAnswer() : <NoAnswer>Пациент еще не ответил</NoAnswer>}
          </BodyItem>
          {!isShowAllSurveys && <BodyItem>{<NoAnswer>{minMaxValues}</NoAnswer>}</BodyItem>}
        </Data>
        <ArrowWrapper $isOpenDetails={isOpenDetails}>
          <ArrowDownIcon />
        </ArrowWrapper>
      </AnswersWrapper>
      {isOpenDetails && (
        <Details onClick={resetParentEvents}>
          <Calendar setDateRange={setDateRange} disabledDays={[{ after: new Date(), before: new Date(2021, 8) }]} />
          {data?.doctorFindPatientQuestionAnswers.nodes.length === 0 ? (
            <NoAnswersWrapper>
              <NoAnswersText>За выбранный период данные не указаны</NoAnswersText>
            </NoAnswersWrapper>
          ) : questionType === QuestionType.Radio || questionType === QuestionType.Checkbox ? (
            <AnswerTable options={answerOptions} onLoadMoreAnswers={onLoadMoreAnswers} />
          ) : (
            <Chart>
              <ChartWrapper>
                <HorizontalScrollbar onLoadMore={onLoadMoreAnswers}>
                  <ChartWrapper2>
                    {questionType === QuestionType.Pressure ? (
                      <PressureChart data={pressureOptions} />
                    ) : (
                      <TemperatureChart
                        data={valueOptions}
                        minDataYAxis={questionType === QuestionType.Temperature ? 30 : 0}
                      />
                    )}
                  </ChartWrapper2>
                </HorizontalScrollbar>
              </ChartWrapper>
            </Chart>
          )}
        </Details>
      )}
    </Root>
  );
};

const Root = styled.div<{ $isCustom: boolean; $isOpenDetails: boolean; $isCritical: boolean }>`
  border-radius: 10px;
  background-color: ${({ theme: { colors }, $isCritical }) =>
    $isCritical ? colors.blueAverage : colors.grayExtraLight};
  display: flex;
  flex-direction: column;
  margin-bottom: 20px;
  box-sizing: border-box;
  position: relative;
  cursor: pointer;

  &:last-child {
    margin-bottom: 32px;
  }

  ${({ $isCustom, theme: { colors } }) =>
    $isCustom &&
    `
    &:before {
    content: "";
    display: block;
    background-color: ${colors.blue};
    width: 4.64px;
    height: 100%;
    border-radius: 20px;
    position: absolute;
  }
  `};
  padding-bottom: ${({ $isOpenDetails }) => $isOpenDetails && '20px'};
`;
const AnswersWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 6px 20px 6px 0;
  height: fit-content;
`;
const Data = styled.div`
  display: flex;
  padding-left: 20px;
`;
const BodyItem = styled.div`
  ${({ theme: { typography } }) => typography.body1};
  color: ${({ theme: { colors } }) => colors.grayDark};
  box-sizing: border-box;
  width: 214px;
  padding: 20px 20px 20px 0;
  overflow: hidden;
  word-wrap: break-word;
  text-overflow: ellipsis;
`;
const ArrowWrapper = styled.div<{ $isOpenDetails: boolean }>`
  padding: 3px 5px;
  cursor: pointer;

  ${({ $isOpenDetails }) =>
    $isOpenDetails ? 'transform: rotate(180deg); transition: 0.3s;' : 'transform: rotate(0deg); transition: 0.3s;'}
`;
const NoAnswer = styled.div`
  ${({ theme: { typography } }) => typography.body2};
  color: ${({ theme: { colors } }) => colors.grayDarkMode};
  letter-spacing: -0.165px;
`;
const Details = styled.div`
  margin: 0 20px;
  box-sizing: border-box;
  height: fit-content;
  border-radius: 10px;
  background-color: ${({ theme: { colors } }) => colors.white};
  padding: 32px 32px 0 32px;
`;
const NoAnswersWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 357px;
`;
const NoAnswersText = styled.div`
  width: 256px;
  text-align: center;
  color: ${({ theme: { colors } }) => colors.grayDarkMode};
  ${({ theme: { typography } }) => typography.title3};
  letter-spacing: -0.165px;
`;
const Chart = styled.div`
  height: 100%;
  overflow: hidden;
`;
const ChartWrapper = styled.div`
  overflow-x: scroll;
  overflow-y: hidden;
  margin: 0 0 -17px 0;
  height: 390px;
`;
const ChartWrapper2 = styled.div`
  padding-bottom: 32px;
  height: 353px;
`;
