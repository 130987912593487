import * as React from 'react';

function ButtonFileIcon({ color, ...props }: React.SVGProps<SVGSVGElement>) {
  return (
    <svg width={24} height={24} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path
        d="M9.00002 2.003V2H19.998C20.55 2 21 2.455 21 2.992V21.008C20.9998 21.2712 20.895 21.5235 20.7088 21.7095C20.5226 21.8955 20.2702 22 20.007 22H3.99302C3.8617 21.9991 3.73185 21.9723 3.61087 21.9212C3.48989 21.8701 3.38017 21.7957 3.28796 21.7022C3.19575 21.6087 3.12286 21.4979 3.07346 21.3762C3.02406 21.2545 2.9991 21.1243 3.00002 20.993V8L9.00002 2.003ZM5.83002 8H9.00002V4.83L5.83002 8ZM11 4V9C11 9.26522 10.8947 9.51957 10.7071 9.70711C10.5196 9.89464 10.2652 10 10 10H5.00002V20H19V4H11Z"
        fill={color}
      />
    </svg>
  );
}

export default ButtonFileIcon;
