import * as Apollo from '@apollo/client';
import { DocumentNode } from 'graphql';

import * as Types from '../../../__generated__/types';
import { AnswerValue } from '../../fragments/__generated__/answer-value.fragment';

const defaultOptions = {};
export type GetSurveyAnswersVariables = Types.Exact<{
  patientId: Types.Scalars['UUID'];
  surveyTemplateId?: Types.Maybe<Types.Scalars['UUID']>;
}>;

export type GetSurveyAnswers = {
  __typename: 'Query';
  doctorFindPatientSurveyAnswers: Array<{
    __typename: 'DoctorPatientSurveyAnswerModel';
    answerQuestionOptionText?: Types.Maybe<string>;
    answerQuestionOptionsTexts?: Types.Maybe<Array<string>>;
    maxAnswer?: Types.Maybe<string>;
    minAnswer?: Types.Maybe<string>;
    questionId: any;
    questionTitle: string;
    questionType: Types.QuestionType;
    isQuestionCustom: boolean;
    isCritical: boolean;
    answerValue?: Types.Maybe<{
      __typename: 'SurveyAnswerValue';
      numeric?: Types.Maybe<{ __typename: 'SurveyNumericAnswerValue'; value: number }>;
      pressure?: Types.Maybe<{ __typename: 'SurveyPressureAnswerValue'; lowerValue: number; upperValue: number }>;
      pulse?: Types.Maybe<{ __typename: 'SurveyPulseAnswerValue'; value: number }>;
      scale?: Types.Maybe<{ __typename: 'SurveyScaleAnswerValue'; value: number }>;
      temperature?: Types.Maybe<{ __typename: 'SurveyTemperatureAnswerValue'; value: number }>;
      weight?: Types.Maybe<{ __typename: 'SurveyWeightAnswerValue'; value: number }>;
    }>;
  }>;
};

export const GetSurveyAnswersDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'GetSurveyAnswers' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'patientId' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'UUID' } } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'surveyTemplateId' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'UUID' } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'doctorFindPatientSurveyAnswers' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'patientId' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'patientId' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'surveyTemplateId' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'surveyTemplateId' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'answerQuestionOptionText' } },
                { kind: 'Field', name: { kind: 'Name', value: 'answerQuestionOptionsTexts' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'answerValue' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'AnswerValue' } }],
                  },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'maxAnswer' } },
                { kind: 'Field', name: { kind: 'Name', value: 'minAnswer' } },
                { kind: 'Field', name: { kind: 'Name', value: 'questionId' } },
                { kind: 'Field', name: { kind: 'Name', value: 'questionTitle' } },
                { kind: 'Field', name: { kind: 'Name', value: 'questionType' } },
                { kind: 'Field', name: { kind: 'Name', value: 'isQuestionCustom' } },
                { kind: 'Field', name: { kind: 'Name', value: 'isCritical' } },
              ],
            },
          },
        ],
      },
    },
    ...AnswerValue.definitions,
  ],
} as unknown as DocumentNode;

/**
 * __useGetSurveyAnswers__
 *
 * To run a query within a React component, call `useGetSurveyAnswers` and pass it any options that fit your needs.
 * When your component renders, `useGetSurveyAnswers` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetSurveyAnswers({
 *   variables: {
 *      patientId: // value for 'patientId'
 *      surveyTemplateId: // value for 'surveyTemplateId'
 *   },
 * });
 */
export function useGetSurveyAnswers(baseOptions: Apollo.QueryHookOptions<GetSurveyAnswers, GetSurveyAnswersVariables>) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetSurveyAnswers, GetSurveyAnswersVariables>(GetSurveyAnswersDocument, options);
}
export function useGetSurveyAnswersLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<GetSurveyAnswers, GetSurveyAnswersVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetSurveyAnswers, GetSurveyAnswersVariables>(GetSurveyAnswersDocument, options);
}
export type GetSurveyAnswersHookResult = ReturnType<typeof useGetSurveyAnswers>;
export type GetSurveyAnswersLazyQueryHookResult = ReturnType<typeof useGetSurveyAnswersLazyQuery>;
export type GetSurveyAnswersQueryResult = Apollo.QueryResult<GetSurveyAnswers, GetSurveyAnswersVariables>;
