import React from 'react';

function HeartbeatLogoIcon() {
  return (
    <svg width="60" height="60" viewBox="0 0 60 60" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M44.0625 3.86719C36.954 3.86719 32.3912 8.79047 30 13.2245C27.6088 8.79035 23.046 3.86719 15.9375 3.86719C6.9184 3.86719 0 11.1851 0 20.8908C0 23.4232 0.50707 25.7612 1.4407 28.0078H14.8509L17.8807 21.9482C18.4781 20.7568 20.4281 20.7568 21.0255 21.9482L26.4844 32.8659L28.4276 28.9795C29.025 27.7882 30.975 27.7882 31.5724 28.9795L33.5156 32.8659L38.9745 21.9483C39.5719 20.757 41.5219 20.757 42.1193 21.9483L45.1491 28.0078H58.5593C59.4929 25.7612 60 23.4232 60 20.8908C60 11.1851 53.0816 3.86719 44.0625 3.86719Z"
        fill="#C6DBFA"
      />
      <path
        d="M44.0621 31.5235C43.396 31.5235 42.7884 31.1476 42.4896 30.5519L40.5464 26.6655L35.0876 37.5832C34.7889 38.1788 34.1813 38.5548 33.5152 38.5548C32.8491 38.5548 32.2415 38.1788 31.9428 37.5832L29.9996 33.6968L28.0564 37.5832C27.4589 38.7745 25.5089 38.7745 24.9115 37.5832L19.4527 26.6655L17.5095 30.5519C17.2108 31.1476 16.6032 31.5235 15.9371 31.5235H3.28516C6.90309 37.2158 13.4188 42.6227 21.5692 49.5016C23.8574 51.4327 26.2916 53.4876 28.8476 55.7038C29.1789 55.9904 29.5892 56.1329 29.9994 56.1329C30.4097 56.1329 30.82 55.9904 31.1513 55.7038C33.7074 53.4876 36.1415 51.4329 38.4297 49.5016C46.5802 42.6227 53.0958 37.2158 56.7137 31.5235H44.0621Z"
        fill="#3380EE"
      />
    </svg>
  );
}

export default HeartbeatLogoIcon;
