import React from 'react';
import styled from 'styled-components';

import { IconButtonProps, RootProps } from '../IconButton';

interface BadgeProps extends Omit<IconButtonProps, 'label'> {}

const Badge: React.FC<BadgeProps> = ({ children, badgeContent, isDisabled, isLoading }) => (
  <BadgeWrapper>
    {children}
    {badgeContent !== undefined && badgeContent > 0 && !isLoading && (
      <BadgeRoot $isDisabled={isDisabled}>+{badgeContent}</BadgeRoot>
    )}
  </BadgeWrapper>
);
const BadgeWrapper = styled.div`
  position: relative;
`;

const BadgeRoot = styled.div<RootProps>`
  position: relative;
  top: -15px;
  right: -10px;
  box-sizing: border-box;
  background-color: ${({ $isDisabled, theme: { colors } }) => ($isDisabled ? colors.grayAverage : colors.blue)};
  border-radius: 4px;
  padding: 0 6px;
  ${({ theme: { typography } }) => typography.footnote1}
  color: ${({ theme: { colors } }) => colors.white};
`;

export default Badge;
