import { useQuery } from '@apollo/client';
import { MeIsAuthDocument } from 'common/query/__generated__/me-is-auth';
import { usePatientMedicalCardNumberIsUniq } from 'common/query/__generated__/patient-medical-card-number-is-uniq';
import { Form, FormikProvider, useFormik } from 'formik';
import React from 'react';
import styled from 'styled-components';
import * as Yup from 'yup';

import { DoctorModel, HospitalPatientModel } from '../../__generated__/types';
import { CrossIcon } from '../../assets/svg';
import { useCreatePatient } from '../../common/mutation/__generated__/create-patient';
import { usePatientEmailIsUniq } from '../../common/query/__generated__/patient-email-is-uniq';
import { SearchPatientsDocument, useSearchPatients } from '../../common/query/__generated__/search-patients';
import { defaultTheme } from '../../themes';
import { Button } from '../Button';
import { Input } from '../Input';

type Values = {
  cardNumber: string;
  firstName?: string;
  lastName?: string;
  email: string;
};

type Props = {
  closeModal: () => void;
  openNextModal: () => void;
  addNewPatient?: (newPatient: HospitalPatientModel) => void;
};

function ModalAddingPatient({ closeModal, openNextModal, addNewPatient }: Props) {
  const onClickHandler = () => {
    closeModal();
    openNextModal();
  };

  const { data: doctor } = useQuery<{ doctorMe: DoctorModel }>(MeIsAuthDocument, { fetchPolicy: 'network-only' });
  const [createPatient, { loading }] = useCreatePatient();

  const formik = useFormik({
    initialValues: {
      cardNumber: '',
      firstName: '',
      lastName: '',
      email: '',
    },
    validationSchema: Yup.object({
      cardNumber: Yup.string()
        .required('Пожалуйста, введите номер карты')
        .max(50, 'Номер карты должен содержать не более 50 символов'),
      email: Yup.string()
        .required('Пожалуйста, введите Ваш e-mail')
        .email('E-mail должен соответствовать типу example@mail.com')
        .matches(/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i, 'E-mail должен соответствовать типу example@mail.com'),
      firstName: Yup.string(),
      lastName: Yup.string(),
    }),
    onSubmit: async (values: Values) => {
      const res = await createPatient({
        variables: {
          input: {
            email: values.email,
            medicalCardNumber: values.cardNumber,
            firstname: values.firstName,
            lastname: values.lastName,
          },
        },
        refetchQueries: [
          {
            query: SearchPatientsDocument,
            variables: {
              filter: values.cardNumber,
            },
          },
        ],
      });
      if (!res.data?.patientCreate.problem?.message) {
        const result = await refetch();
        const patient = result.data.searchPatient.nodes[0];
        patient && addNewPatient && addNewPatient(patient);
        onClickHandler();
      }
    },
  });
  const { refetch } = useSearchPatients({
    variables: {
      filter: formik.values.cardNumber,
    },
  });

  //uniq email
  const { data } = usePatientEmailIsUniq({
    fetchPolicy: 'network-only',
    variables: {
      email: formik.values.email,
    },
  });
  const errorResponse =
    data?.patientEmailIsUniq !== undefined && !data?.patientEmailIsUniq
      ? 'Пациент с таким адресом электронной почты уже существует'
      : '';

  //uniq card nubmer
  const { data: cardNumbersData } = usePatientMedicalCardNumberIsUniq({
    fetchPolicy: 'network-only',
    variables: {
      hospitalId: doctor?.doctorMe?.hospital?.id,
      medicalCardNumber: formik.values.cardNumber,
    },
  });
  const errorCardNumberResponse =
    cardNumbersData?.patientMedicalCardNumberIsUniq !== undefined && !cardNumbersData?.patientMedicalCardNumberIsUniq
      ? 'Пациент с таким номером амбулаторной карты уже существует'
      : '';

  return (
    <Root onClick={e => e.stopPropagation()}>
      <Header>
        <TextHeader>Добавить пациента</TextHeader>
        <CloseIcon onClick={closeModal}>
          <CrossIcon color={defaultTheme.colors.grayDarkMode} />
        </CloseIcon>
      </Header>
      <Text>* - Обязательное поле для заполнения</Text>
      <FormikProvider value={formik}>
        <Form onSubmit={formik.handleSubmit}>
          <Input
            placeholder={'№'}
            label={'*Номер амбулаторной карты'}
            {...formik.getFieldProps('cardNumber')}
            error={(formik.touched.cardNumber ? formik.errors.cardNumber : '') || errorCardNumberResponse}
          />
          <Input
            placeholder={'Введите фамилию'}
            label={'Фамилия'}
            {...formik.getFieldProps('lastName')}
            error={formik.touched.firstName ? formik.errors.firstName : ''}
          />
          <Input
            placeholder={'Введите имя и отчество'}
            label={'Имя и Отчество'}
            {...formik.getFieldProps('firstName')}
            error={formik.touched.lastName ? formik.errors.lastName : ''}
          />
          <Input
            placeholder={'.com'}
            label={'*E-mail'}
            {...formik.getFieldProps('email')}
            error={(formik.touched.email ? formik.errors.email : '') || errorResponse}
          />
          <Button
            variant={'primary'}
            label={'Добавить'}
            type={'submit'}
            isDisabled={!(formik.isValid && formik.dirty) || !!errorCardNumberResponse || !!errorResponse}
            isLoading={loading}
          />
        </Form>
      </FormikProvider>
    </Root>
  );
}

export default ModalAddingPatient;

const Root = styled.div`
  width: 420px;
  height: fit-content;
  display: flex;
  flex-direction: column;
  background-color: ${({ theme: { colors } }) => colors.white};
  border: 1px solid ${({ theme: { colors } }) => colors.blueExtraLight};
  box-sizing: border-box;
  box-shadow: 0 6px 30px #e4ecf7;
  border-radius: 10px;
  padding: 39px;
  margin: 20px 0;
`;
const Header = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-bottom: 16px;
`;
const TextHeader = styled.span`
  ${({ theme: { typography } }) => typography.title2};
  color: ${({ theme: { colors } }) => colors.grayDark};
`;
const CloseIcon = styled.div`
  padding: 5px;
  cursor: pointer;
`;
const Text = styled.span`
  ${({ theme: { typography } }) => typography.body3};
  color: ${({ theme: { colors } }) => colors.grayDarkMode};
  margin-bottom: 32px;
`;
