const months = [
  'января',
  'февраля',
  'марта',
  'апреля',
  'мая',
  'июня',
  'июля',
  'августа',
  'сентября',
  'октября',
  'ноября',
  'декабря',
];
const weekdays = ['Воскресенье', 'Понедельник', 'Вторник', 'Среда', 'Четверг', 'Пятница', 'Суббота'];

export const getDate = (date: Date) => {
  const now = new Date();
  const dayNow = now.getDate();

  const day = date.getDate();
  const weekdayIndex = date.getDay();
  const monthIndex = date.getMonth();
  const time = date.toLocaleTimeString().substring(0, 5);

  let weekday = weekdays[weekdayIndex];
  if (day === dayNow) {
    weekday = 'Сегодня';
  }
  if (day + 1 === dayNow) {
    weekday = 'Вчера';
  }

  const month = months[monthIndex];

  const currentDay = `${weekday}, ${day} ${month}`;

  return {
    day: currentDay,
    time,
  };
};
