/*
 * Inspired by
 * https://dev.to/0916dhkim/type-safe-usage-of-react-router-5c44
 */
/* eslint-disable */
export const PATHS = [
  '/',
  '/sign-in',
  '/sign-up',
  '/recovery-password',
  '/profile',
  '/patient/:id/:surveyId',
  '/post/:id/comment/:commentid',
  '/patients',
  '/patient/:id/analyzes',
  '/notifications',
  '/patient/:id/create-quiz',
] as const;

export const REDIRECT_PATHS_EXCEPTIONS = PATHS.slice(1, 4);

export const ROUTES = PATHS.reduce(function (result, item, index) {
  result[item] = item;
  return result;
}, {} as Record<RoutesPath, string>);

export type ExtractRouteParams<T> = string extends T
  ? Record<string, string>
  : T extends `${infer _Start}:${infer Param}/${infer Rest}`
  ? { [k in Param | keyof ExtractRouteParams<Rest>]: string }
  : T extends `${infer _Start}:${infer Param}`
  ? { [k in Param]: string }
  : {};

export type RoutesPath = typeof PATHS[number];

export type PathParams<P extends RoutesPath> = ExtractRouteParams<P>;

export const buildUrl = <P extends RoutesPath>(path: P, params: PathParams<P>): string => {
  let ret: string = path;

  // Upcast `params` to be used in string replacement.
  const paramObj: { [i: string]: string } = params;
  for (const key of Object.keys(paramObj)) {
    ret = ret.replace(`:${key}`, paramObj[key]);
  }

  return ret;
};
