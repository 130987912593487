import _ from 'lodash';

import { Maybe, SurveyTemplateQuestionCriticalIndicatorsInput } from '../__generated__/types';

export const deleteTypename = (
  criticalIndicators: Maybe<SurveyTemplateQuestionCriticalIndicatorsInput> | undefined,
) => {
  criticalIndicators = _.omit(criticalIndicators, ['__typename']);
  // @ts-ignore
  Object.keys(criticalIndicators).forEach(key => criticalIndicators[key] == null && delete criticalIndicators[key]);
  for (let key in criticalIndicators) {
    // @ts-ignore
    criticalIndicators[key] = _.omit(criticalIndicators[key], ['__typename']);
  }
  return criticalIndicators;
};
