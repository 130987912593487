import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';
import styled from 'styled-components';
import ModalAlertMessage from 'ui/ModalAlertMessage';
import { ModalContainer } from 'ui/ModalContainer';

import { Maybe } from '../../__generated__/types';
import AccountBoxIcon from '../../assets/svg/account-box-icon/AccountBoxIcon';
import PlusIcon from '../../assets/svg/plus-icon/PlusIcon';
import VerticalLineIcon from '../../assets/svg/vertical-line-icon/VerticalLineIcon';
import { useAssignPatientToDoctor } from '../../common/mutation/__generated__/assign-patient-to-doctor';
import { SearchPatientsDocument } from '../../common/query/__generated__/search-patients';
import { defaultTheme } from '../../themes';
import { Loader } from '../Loader';

export type PatientPropsType = {
  firstName?: Maybe<string>;
  lastName?: Maybe<string>;
  cardNumber: string;
  hasActiveSurvey?: boolean;
  isAddedPatient?: boolean;
  openModal: () => void;
  id: string;
  setDeletingPatientId: (id: string) => void;
  updatePatientList: () => void;
};

function Patient({
  cardNumber,
  firstName,
  lastName,
  hasActiveSurvey = false,
  isAddedPatient = false,
  openModal,
  id,
  setDeletingPatientId,
  updatePatientList,
}: PatientPropsType) {
  const history = useHistory();

  const addPatient = async (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
    e.stopPropagation();
    const res = await assignPatientToDoctor({
      variables: {
        input: {
          patientId: id,
        },
      },
      refetchQueries: [
        {
          query: SearchPatientsDocument,
          variables: {
            filter: cardNumber,
          },
        },
      ],
    });

    if (res.data?.patientAssignToDoctor.success) {
      updatePatientList();
    }
  };

  const onClickDeletePatient = (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
    e.stopPropagation();
    openModal();
    setDeletingPatientId(id);
  };

  const [assignPatientToDoctor, { loading: loadingAdding }] = useAssignPatientToDoctor({
    onError: err => {
      if (err.message === 'patient already assigned to doctor') {
        setIsModalAlertVisible(true);
      }
    },
  });

  const onClickHandler = () => {
    if (isAddedPatient) {
      history.push(`/patient/${id}/1`);
    } else {
      showTooltip();
    }
  };

  const patientName = `${lastName} ${firstName}`;

  const [isShowTooltip, setIsShowTooltip] = useState(false);
  const [isModalAlertVisible, setIsModalAlertVisible] = useState(false);

  const showTooltip = () => {
    setIsShowTooltip(true);
    setTimeout(() => setIsShowTooltip(false), 3000);
  };

  return (
    <>
      <Root onClick={onClickHandler}>
        <Wrapper>
          <AccountBoxIcon size={'42'} />
          <Vertical color={isAddedPatient ? defaultTheme.colors.blue : defaultTheme.colors.grayAverage} />
          <PatientDate>
            <PatientNameSurvey>
              <PatientName>{patientName?.length > 1 ? patientName : cardNumber}</PatientName>
              {isAddedPatient && hasActiveSurvey && (
                <SurveyInfo>
                  <Text>Проходит опрос</Text>
                </SurveyInfo>
              )}
            </PatientNameSurvey>
            {patientName?.length > 1 && <CardNumber>{cardNumber}</CardNumber>}
          </PatientDate>
          {isShowTooltip && <TooltipText>{'Для просмотра данных добавьте пациента'}</TooltipText>}
        </Wrapper>
        {isAddedPatient ? (
          <DeleteButton onClick={onClickDeletePatient}>Удалить из списка</DeleteButton>
        ) : loadingAdding ? (
          <Loader color={defaultTheme.colors.grayDarkMode} />
        ) : (
          <Plus onClick={addPatient}>
            <PlusIcon />
          </Plus>
        )}
      </Root>
      {isModalAlertVisible && (
        <ModalContainer closeModal={() => setIsModalAlertVisible(false)}>
          <ModalAlertMessage text="Пациент уже назначен другому доктору" />
        </ModalContainer>
      )}
    </>
  );
}

export default Patient;

const Root = styled.div`
  width: calc(100% - 32px);
  height: 96px;
  box-sizing: border-box;
  border-radius: 10px;
  background-color: ${({ theme: { colors } }) => colors.grayExtraLight};
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 20px;
  margin-bottom: 20px;
  cursor: pointer;

  &:last-child {
    margin-bottom: 32px;
  }

  &:hover {
    border: 1px solid ${({ theme: { colors } }) => colors.blueDarkMode};
    padding: 0 19px;
  }
`;
const Wrapper = styled.div`
  display: flex;
  align-items: center;
  position: relative;
`;
const Vertical = styled(VerticalLineIcon)`
  margin: 0 16px;
`;
const PatientDate = styled.div`
  display: flex;
  flex-direction: column;
`;
const PatientNameSurvey = styled.div`
  display: flex;
`;
const PatientName = styled.span`
  color: ${({ theme: { colors } }) => colors.grayDark};
  ${({ theme: { typography } }) => typography.title3};
  margin: 0 20px 2px 0;
`;
const SurveyInfo = styled.div`
  width: fit-content;
  height: 32px;
  border-radius: 6px;
  display: flex;
  background-color: ${({ theme: { colors } }) => colors.blueAverage};
`;
const Text = styled.span`
  color: ${({ theme: { colors } }) => colors.blue};
  font-family: 'lato', sans-serif;
  font-weight: 400;
  font-size: 16px;
  line-height: 20px;
  margin: 6px 15px;
`;
const CardNumber = styled.span`
  color: ${({ theme: { colors } }) => colors.grayDarkMode};
  ${({ theme: { typography } }) => typography.body2};
`;
const Plus = styled.div`
  margin-right: 5px;
  cursor: pointer;
`;
const DeleteButton = styled.span`
  cursor: pointer;
  color: ${({ theme: { colors } }) => colors.blue};
  ${({ theme: { typography } }) => typography.body2};
`;
const TooltipText = styled.span`
  color: ${({ theme: { colors } }) => colors.grayDark};
  ${({ theme: { typography } }) => typography.body3};
  letter-spacing: -0.165px;
  width: 200px;
  padding: 16px 12px;
  background-color: ${({ theme: { colors } }) => colors.white};
  border-radius: 10px;
  filter: drop-shadow(0px 6px 30px #e4ecf7);
  position: absolute;
  z-index: 1;
  left: 105%;
  text-align: center;

  &::after {
    content: '';
    position: absolute;
    top: 50%;
    right: 100%;
    border-width: 10px;
    border-style: solid;
    border-color: transparent transparent ${({ theme: { colors } }) => colors.white} transparent;
    transform: translatey(-50%) rotate(270deg);
  }
`;
