import * as Apollo from '@apollo/client';
import { DocumentNode } from 'graphql';

import * as Types from '../../../__generated__/types';

const defaultOptions = {};
export type GetDrugsFromDBVariables = Types.Exact<{
  filter?: Types.Maybe<Types.Scalars['String']>;
}>;

export type GetDrugsFromDB = {
  __typename: 'Query';
  drugsSearch: Array<{ __typename: 'DrugModel'; id: string; name: string }>;
};

export const GetDrugsFromDBDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'GetDrugsFromDB' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'filter' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'drugsSearch' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'filter' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'filter' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode;

/**
 * __useGetDrugsFromDB__
 *
 * To run a query within a React component, call `useGetDrugsFromDB` and pass it any options that fit your needs.
 * When your component renders, `useGetDrugsFromDB` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetDrugsFromDB({
 *   variables: {
 *      filter: // value for 'filter'
 *   },
 * });
 */
export function useGetDrugsFromDB(baseOptions?: Apollo.QueryHookOptions<GetDrugsFromDB, GetDrugsFromDBVariables>) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetDrugsFromDB, GetDrugsFromDBVariables>(GetDrugsFromDBDocument, options);
}
export function useGetDrugsFromDBLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<GetDrugsFromDB, GetDrugsFromDBVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetDrugsFromDB, GetDrugsFromDBVariables>(GetDrugsFromDBDocument, options);
}
export type GetDrugsFromDBHookResult = ReturnType<typeof useGetDrugsFromDB>;
export type GetDrugsFromDBLazyQueryHookResult = ReturnType<typeof useGetDrugsFromDBLazyQuery>;
export type GetDrugsFromDBQueryResult = Apollo.QueryResult<GetDrugsFromDB, GetDrugsFromDBVariables>;
