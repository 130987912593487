import { useFormik } from 'formik';
import React, { useEffect, useState } from 'react';
import styled from 'styled-components';

import { Maybe, QuestionIndicators, QuestionInput, QuestionOptionModel, QuestionType } from '../../__generated__/types';
import { ArrowDownIcon } from '../../assets/svg';
import { CheckboxTextGroup } from '../CheckboxText';
import { Input } from '../Input';
import { OneAnswerOptionGroup } from '../OneAnswerOptionGroup';
import { MultiSlider } from '../Slider';
import { TextButton } from '../TextButton';

type Props = {
  id: string;
  title: string;
  isCustom?: boolean;
  type: QuestionType;
  deleteQuestion: () => void;
  indicators?: Maybe<QuestionIndicators>;
  options?: Maybe<Array<QuestionOptionModel>>;
  setCriticalParams: (questions: QuestionInput[]) => void;
  criticalParams: QuestionInput[];
  isShowTopTooltip: boolean;
  criticalParameter: QuestionInput;
};
type FormikErrorType = {
  minValue?: string;
  maxValue?: string;
};

function Question({
  title,
  isCustom = false,
  type,
  deleteQuestion,
  indicators,
  options = [],
  id,
  criticalParams,
  setCriticalParams,
  isShowTopTooltip,
  criticalParameter,
}: Props) {
  const [isOpenDetails, setIsOpenDetails] = useState(false);
  const [isShowTooltip, setIsShowTooltip] = useState(false);

  const { criticalAnswersIds, criticalIndicators, criticalAnswerId } = criticalParameter || {
    criticalAnswersIds: undefined,
    criticalIndicators: undefined,
    criticalAnswerId: undefined,
  };

  const [criticalValue, setCriticalValue] = useState<number | undefined>(criticalIndicators?.scale?.value);

  const [criticalMinLowerValue, setCriticalMinLowerValue] = useState<number | undefined>(
    criticalIndicators?.pressure?.minLowerValue,
  );
  const [criticalMaxLowerValue, setCriticalMaxLowerValue] = useState<number | undefined>(
    criticalIndicators?.pressure?.maxLowerValue,
  );
  const [criticalMinUpperValue, setCriticalMinUpperValue] = useState<number | undefined>(
    criticalIndicators?.pressure?.minUpperValue,
  );
  const [criticalMaxUpperValue, setCriticalMaxUpperValue] = useState<number | undefined>(
    criticalIndicators?.pressure?.maxUpperValue,
  );

  const [criticalMaxValue, setCriticalMaxValue] = useState<number | undefined>(
    criticalIndicators?.numeric?.maxValue ||
      criticalIndicators?.pulse?.maxValue ||
      criticalIndicators?.temperature?.maxValue ||
      criticalIndicators?.weight?.maxValue,
  );
  const [criticalMinValue, setCriticalMinValue] = useState<number | undefined>(
    criticalIndicators?.numeric?.minValue ||
      criticalIndicators?.pulse?.minValue ||
      criticalIndicators?.temperature?.minValue ||
      criticalIndicators?.weight?.minValue,
  );

  const foundCriticalAnswer = options?.filter(option => option.id === criticalAnswerId);
  const [criticalAnswer, setCriticalAnswer] = useState<QuestionOptionModel | undefined>(
    (foundCriticalAnswer && foundCriticalAnswer[0]) || undefined,
  );
  const foundCriticalAnswers = options?.filter(option => criticalAnswersIds?.includes(option.id));
  const criticalAnswers = foundCriticalAnswers && foundCriticalAnswers.length > 0 ? foundCriticalAnswers : undefined;

  const [checkboxValues, setCheckboxValues] = useState<string | string[] | undefined>([]);

  const formik = useFormik({
    initialValues: {
      minValue: criticalMinValue,
      maxValue: criticalMaxValue,
    },
    validate: values => {
      const errors: FormikErrorType = {};
      if (values?.minValue && !/^[\d.,]*$/.test(values?.minValue.toString())) {
        errors.minValue = 'Должно быть числом';
      }
      if (values?.maxValue && !/^[\d.,]*$/.test(values?.maxValue.toString())) {
        errors.maxValue = 'Должно быть числом';
      }
      return errors;
    },
    onSubmit: () => {},
  });

  useEffect(() => {
    const ids = criticalParams.map(crit => crit.questionId);
    const filteredCritical = ids.includes(id) ? criticalParams.filter(crit => crit.questionId !== id) : criticalParams;
    const critIndicators = () => {
      switch (type) {
        case QuestionType.Numeric:
          return {
            numeric: {
              maxValue: formik.values.maxValue,
              minValue: formik.values.minValue,
            },
          };
        case QuestionType.Pulse:
          return {
            pulse: {
              maxValue: formik.values.maxValue,
              minValue: formik.values.minValue,
            },
          };
        case QuestionType.Weight:
          return {
            weight: {
              maxValue: formik.values.maxValue,
              minValue: formik.values.minValue,
            },
          };
        case QuestionType.Pressure:
          return {
            pressure: {
              maxLowerValue: criticalMaxLowerValue,
              minLowerValue: criticalMinLowerValue,
              maxUpperValue: criticalMaxUpperValue,
              minUpperValue: criticalMinUpperValue,
            },
          };
        case QuestionType.Scale:
          return {
            scale: {
              value: criticalValue,
            },
          };
        case QuestionType.Temperature:
          return {
            temperature: {
              maxValue: criticalMaxValue,
              minValue: criticalMinValue,
            },
          };
      }
    };
    const criticalCheckboxIds = options
      ?.filter(option => checkboxValues?.includes(option.text))
      .map(option => option.id);

    setCriticalParams([
      // @ts-ignore
      ...filteredCritical,
      {
        questionId: id,
        questionType: type,
        criticalAnswerId: criticalAnswer?.id,
        criticalAnswersIds: criticalCheckboxIds,
        // @ts-ignore
        criticalIndicators: critIndicators(),
      },
    ]);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    criticalAnswer,
    criticalMaxValue,
    criticalMinValue,
    id,
    criticalValue,
    criticalMaxLowerValue,
    criticalMaxUpperValue,
    checkboxValues,
    formik.values.maxValue,
    formik.values.minValue,
  ]);

  return (
    <Root>
      <Container $isCustom={isCustom}>
        <Wrapper>
          <Tooltip
            onMouseEnter={() => setIsShowTooltip(true)}
            onMouseLeave={() => setIsShowTooltip(false)}
            $isOpenDetails={isOpenDetails}>
            <Title $isOpenDetails={isOpenDetails}>{title}</Title>
          </Tooltip>
          {isShowTooltip && <TooltipText $isShowTopTooltip={isShowTopTooltip}>{title}</TooltipText>}
          <ButtonsBlock>
            <TextButton label="Удалить из опроса" onClick={deleteQuestion} />
            <Arrow $isOpenDetails={isOpenDetails} onClick={() => setIsOpenDetails(!isOpenDetails)}>
              <ArrowDownIcon />
            </Arrow>
          </ButtonsBlock>
        </Wrapper>
        {isOpenDetails && (
          <CriticalBlock>
            <div>
              <TitleCriticalValues $isOpenDetails={isOpenDetails}>Выберите критические показатели</TitleCriticalValues>
              {type === QuestionType.Checkbox && (
                <CheckboxTextGroup
                  options={options}
                  type="checkbox"
                  checkboxValues={checkboxValues}
                  setCheckboxValues={setCheckboxValues}
                  criticalValues={criticalAnswers}
                />
              )}
              {type === QuestionType.Radio && (
                <OneAnswerOptionGroup
                  options={options}
                  criticalValue={criticalAnswer}
                  setCriticalValue={setCriticalAnswer}
                />
              )}

              {type === QuestionType.Scale && (
                <MultiSlider
                  minValue={indicators?.scale?.minValue}
                  maxValue={indicators?.scale?.maxValue}
                  typeSlider={'scale'}
                  criticalMaxValue={criticalValue}
                  setCriticalMaxValue={setCriticalValue}
                />
              )}

              {type === QuestionType.Temperature && (
                <MultiSlider
                  typeSlider={'range'}
                  isThermometer
                  minValue={33}
                  maxValue={44}
                  criticalMaxValue={criticalMaxValue}
                  criticalMinValue={criticalMinValue}
                  setCriticalMaxValue={setCriticalMaxValue}
                  setCriticalMinValue={setCriticalMinValue}
                />
              )}

              {type === QuestionType.Pressure && (
                <PressureWrapper>
                  <PressureText>Систолическое артериальное давление</PressureText>
                  <MultiSlider
                    typeSlider={'range'}
                    minValue={100}
                    maxValue={200}
                    criticalMaxValue={criticalMaxUpperValue}
                    criticalMinValue={criticalMinUpperValue}
                    setCriticalMaxValue={setCriticalMaxUpperValue}
                    setCriticalMinValue={setCriticalMinUpperValue}
                  />
                  <PressureText>Диастолическое артериальное давление</PressureText>
                  <MultiSlider
                    typeSlider={'range'}
                    minValue={50}
                    maxValue={150}
                    criticalMaxValue={criticalMaxLowerValue}
                    criticalMinValue={criticalMinLowerValue}
                    setCriticalMaxValue={setCriticalMaxLowerValue}
                    setCriticalMinValue={setCriticalMinLowerValue}
                  />
                </PressureWrapper>
              )}

              {(type === QuestionType.Weight || type === QuestionType.Pulse || type === QuestionType.Numeric) && (
                <CriticalWrapper>
                  <InputWrapper>
                    <Input
                      placeholder="Введите значение"
                      label="Минимальное значение"
                      {...formik.getFieldProps('minValue')}
                      error={formik.touched && formik.errors.minValue}
                    />
                  </InputWrapper>
                  <InputWrapper>
                    <Input
                      placeholder="Введите значение"
                      label="Максимальное значение"
                      {...formik.getFieldProps('maxValue')}
                      error={formik.touched && formik.errors.maxValue}
                    />
                  </InputWrapper>
                </CriticalWrapper>
              )}
            </div>
          </CriticalBlock>
        )}
      </Container>
    </Root>
  );
}

export default Question;

const Root = styled.div`
  margin-bottom: 20px;
  border-radius: 10px;
  background-color: ${({ theme: { colors } }) => colors.grayExtraLight};
  position: relative;
  padding: 20px;

  &:hover {
    padding: 19px;
    border: 1px solid ${({ theme: { colors } }) => colors.blueDarkMode};
    box-sizing: border-box;
  }

  &:last-child {
    margin-bottom: 32px;
  }
`;
const Container = styled.div<{ $isCustom: boolean }>`
  display: flex;
  flex-direction: column;

  ${({ $isCustom, theme: { colors } }) =>
    $isCustom &&
    `
  &:before {
  content: "";
  display: block;
  background-color: ${colors.blue};
  width: 8px;
  min-height: 70px;
  height: 100%;
  border-radius: 20px;
  position: absolute;
  top: 0;
  left: 0;
  ${Root}:hover & {
    top: -1px;
    left: -1px;
    height: calc(100% + 2px);
    }
  }
`};
`;
const Wrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;
const Title = styled.div<{ $isOpenDetails: boolean }>`
  color: ${({ theme: { colors } }) => colors.grayDark};
  ${({ theme: { typography } }) => typography.title3};
  overflow: hidden;
  text-overflow: ellipsis;
  word-wrap: break-word;
  max-width: 450px;

  ${({ $isOpenDetails }) => ($isOpenDetails ? 'white-space: wrap' : 'white-space: nowrap')}
`;
const ButtonsBlock = styled.div`
  display: flex;
  align-items: center;
`;
const Arrow = styled.div<{ $isOpenDetails: boolean }>`
  margin: 0 5px 0 25px;
  width: 14px;

  ${({ $isOpenDetails }) =>
    $isOpenDetails ? 'transform: rotate(180deg); transition: 0.3s;' : 'transform: rotate(0deg); transition: 0.3s;'}
`;
const CriticalBlock = styled.div`
  margin-top: 20px;
  background-color: ${({ theme: { colors } }) => colors.white};
  padding: 20px 20px 0;
  border-radius: 10px;
`;
const TitleCriticalValues = styled(Title)`
  line-height: 26px;
`;
const PressureWrapper = styled.div`
  margin-top: 20px;
`;
const PressureText = styled.div`
  color: ${({ theme: { colors } }) => colors.grayDarkMode};
  ${({ theme: { typography } }) => typography.body3};
`;
const CriticalWrapper = styled.div`
  display: flex;
  justify-content: space-around;
  margin-top: 20px;
`;
const InputWrapper = styled.div`
  width: 225px;
`;
const Tooltip = styled(Title)`
  position: relative;
`;
const TooltipText = styled.span<{ $isShowTopTooltip: boolean }>`
  color: ${({ theme: { colors } }) => colors.grayDark};
  ${({ theme: { typography } }) => typography.body3};
  letter-spacing: -0.165px;
  max-width: 392px;
  padding: 16px 12px;
  background-color: ${({ theme: { colors } }) => colors.white};
  border-radius: 10px;
  filter: drop-shadow(0px 6px 30px #e4ecf7);
  position: absolute;
  z-index: 1;
  ${({ $isShowTopTooltip }) => ($isShowTopTooltip ? 'bottom: 80px;' : 'top: 80px;')}
  left: 148px;
  margin-left: -60px;

  &::after {
    content: '';
    position: absolute;
    bottom: 100%;
    left: 50%;
    margin-left: -10px;
    border-width: 10px;
    border-style: solid;
    border-color: transparent transparent ${({ theme: { colors } }) => colors.white} transparent;
    ${({ $isShowTopTooltip }) => ($isShowTopTooltip ? 'top: 100%; transform: rotate(180deg);' : 'bottom: 100%;')}
  }
`;
