import { Form, FormikProvider, useFormik } from 'formik';
import styled from 'styled-components';
import * as Yup from 'yup';

import { useUpdatePassword } from '../../../../common/mutation/__generated__/update-password';
import { storageClient } from '../../../../libs/StorageClient';
import { Button, Input, Scrollbar } from '../../../../ui';

type Values = {
  oldPassword: string;
  newPassword: string;
  confirmNewPassword: string;
};

type Props = {
  openModal: () => void;
  email: string;
};

export const ProfileSettings = ({ openModal, email }: Props) => {
  const [updatePassword, { data, loading, error }] = useUpdatePassword();
  let errorResponse: string | undefined = undefined;
  let errorOldPassword: string | undefined = undefined;
  if (data?.doctorPasswordUpdate.problem) {
    errorResponse = data?.doctorPasswordUpdate.problem.message;
  }
  if (errorResponse === 'passwords not match') {
    errorOldPassword = 'Введен неверный пароль';
    errorResponse = undefined;
  }

  const formik = useFormik({
    initialValues: {
      oldPassword: '',
      newPassword: '',
      confirmNewPassword: '',
    },
    validationSchema: Yup.object({
      oldPassword: Yup.string().required('Пожалуйста, введите Ваш пароль'),
      newPassword: Yup.string()
        .required('Пожалуйста, введите Ваш пароль')
        .min(8, 'Пароль должен содержать минимум 8 символов')
        .notOneOf([Yup.ref('oldPassword'), null], 'Пароли не должны совпадать'),
      confirmNewPassword: Yup.string()
        .required('Не должно быть пустым')
        .oneOf([Yup.ref('newPassword'), null], 'Пароли должны совпадать'),
    }),
    onSubmit: async (values: Values) => {
      const res = await updatePassword({
        variables: {
          input: {
            password: values.oldPassword,
            newPassword: values.newPassword,
          },
        },
      });
      const token = res.data?.doctorPasswordUpdate.token;
      const refreshToken = res.data?.doctorPasswordUpdate.refreshToken;
      if (token && refreshToken) {
        storageClient.setAuthToken(token);
        storageClient.setRefreshToken(refreshToken);
        formik.resetForm();
        openModal();
      }
    },
  });

  return (
    <Root>
      <Title>Настройки профиля</Title>
      <ScrollbarWrapper>
        <Scrollbar>
          <FormWrapper>
            <FormikProvider value={formik}>
              <Form onSubmit={formik.handleSubmit}>
                <Input label="Email" placeholder={email || ''} isDisabled={true} />
                <Input
                  type="password"
                  placeholder="Введите пароль"
                  label="Старый пароль"
                  {...formik.getFieldProps('oldPassword')}
                  error={(formik.touched.oldPassword ? formik.errors.oldPassword : '') || errorOldPassword}
                />
                <Input
                  type="password"
                  placeholder="Введите пароль"
                  label="Новый пароль"
                  {...formik.getFieldProps('newPassword')}
                  error={formik.touched.newPassword ? formik.errors.newPassword : ''}
                />
                <Input
                  type="password"
                  placeholder="Введите пароль"
                  label="Подтвердить пароль"
                  {...formik.getFieldProps('confirmNewPassword')}
                  error={
                    (formik.touched.confirmNewPassword ? formik.errors.confirmNewPassword : '') ||
                    error?.message ||
                    errorResponse
                  }
                />
                <Button
                  variant="primary"
                  label="Сохранить"
                  type="submit"
                  width={340}
                  isDisabled={!(formik.isValid && formik.dirty)}
                  isLoading={loading}
                />
              </Form>
            </FormikProvider>
          </FormWrapper>
        </Scrollbar>
      </ScrollbarWrapper>
    </Root>
  );
};

const Root = styled.div`
  background-color: ${({ theme: { colors } }) => colors.white};
  width: 620px;
  height: 100%;
  box-sizing: border-box;
  border: 1px solid ${({ theme: { colors } }) => colors.blueExtraLight};
  box-shadow: 0 6px 30px #e4ecf7;
  border-radius: 10px;
  padding: 32px;
`;
const Title = styled.div`
  color: ${({ theme: { colors } }) => colors.grayDark};
  ${({ theme: { typography } }) => typography.title2};
  line-height: 44px;
  margin-bottom: 32px;
`;
const ScrollbarWrapper = styled.div`
  width: calc(100% + 32px);
  height: calc(100% - 22px);
`;
const FormWrapper = styled.div`
  width: calc(100% - 32px);
  margin-bottom: 32px;
`;
