import { FullModelType } from 'pages/CreatingQuiz/CreatingQuiz';
import { Dispatch, SetStateAction, useState } from 'react';
import styled from 'styled-components';

import {
  DrugModel,
  QuestionInput,
  SurveyTemplatePeriod,
  SurveyTemplateQuestionModel,
} from '../../../../__generated__/types';
import { CustomQuestion, QuestionsList, SurveyTemplates } from './components';

type TabType = 'surveyTemplates' | 'questionsList' | 'customQuestion';

type Props = {
  setSelectedDrug: (value: string | undefined) => void;
  setQuestion: (question: SurveyTemplateQuestionModel) => void;
  setQuizTitle: (quizTitle: string) => void;
  setCriticalParams: (questions: QuestionInput[]) => void;
  setSurveyTemplateTitle: () => void;
  setPeriod: Dispatch<SetStateAction<SurveyTemplatePeriod | undefined>>;
  addedQuestionIds: (string | undefined)[];
  fullModel: FullModelType;
  setFullModel: Dispatch<SetStateAction<[DrugModel, SurveyTemplateQuestionModel[]][]>>;
};

export const AddingAnswers = ({
  setSelectedDrug,
  setQuestion,
  setQuizTitle,
  setCriticalParams,
  setSurveyTemplateTitle,
  setPeriod,
  addedQuestionIds,
  fullModel,
  setFullModel,
}: Props) => {
  const [isSelectedTab, setIsSelectedTab] = useState<TabType>('surveyTemplates');
  const selectedTab = () => {
    switch (isSelectedTab) {
      case 'customQuestion':
        return <CustomQuestion setQuestion={setQuestion} />;
      case 'questionsList':
        return <QuestionsList setQuestion={setQuestion} addedQuestionIds={addedQuestionIds} />;
      case 'surveyTemplates':
        return (
          <SurveyTemplates
            setSelectedDrug={setSelectedDrug}
            setQuizTitle={setQuizTitle}
            setCriticalParams={setCriticalParams}
            addQuizTemplate={setSurveyTemplateTitle}
            setPeriod={setPeriod}
            fullModel={fullModel}
            setFullModel={setFullModel}
          />
        );
    }
  };
  return (
    <Root>
      <Title>Добавление вопросов</Title>
      <Container>
        <TabsBlock>
          <Tab>
            <TabTitle
              $isSelected={isSelectedTab === 'surveyTemplates'}
              onClick={() => setIsSelectedTab('surveyTemplates')}>
              Шаблоны опросов
            </TabTitle>
          </Tab>
          <Tab>
            <TabTitle $isSelected={isSelectedTab === 'questionsList'} onClick={() => setIsSelectedTab('questionsList')}>
              Список вопросов
            </TabTitle>
          </Tab>
          <Tab>
            <TabTitle
              $isSelected={isSelectedTab === 'customQuestion'}
              onClick={() => setIsSelectedTab('customQuestion')}>
              Свой вопрос
            </TabTitle>
          </Tab>
        </TabsBlock>
      </Container>
      <MainContainer>{selectedTab()}</MainContainer>
    </Root>
  );
};

const Title = styled.div`
  color: ${({ theme: { colors } }) => colors.grayDark};
  ${({ theme: { typography } }) => typography.title2};
  line-height: 44px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;
const Root = styled.div`
  background-color: ${({ theme: { colors } }) => colors.white};
  width: 470px;
  height: 100%;
  box-sizing: border-box;
  border: 1px solid ${({ theme: { colors } }) => colors.blueExtraLight};
  box-shadow: 0 6px 30px #e4ecf7;
  border-radius: 10px;
  padding: 31px;
`;
const Container = styled.div`
  margin: 20px 0 32px;

  &:after {
    width: 100%;
    content: '';
    display: block;
    height: 4px;
    border-radius: 4px;
    background: ${({ theme: { colors } }) => colors.grayLight};
    position: relative;
    top: -4px;
  }
`;
const TabsBlock = styled.div`
  display: flex;
  justify-content: space-between;
  height: 100%;
`;
const Tab = styled.div`
  width: 132px;
  height: 40px;
`;
const TabTitle = styled.div<{ $isSelected: boolean }>`
  color: ${({ theme: { colors } }) => colors.grayDarkMode};
  ${({ theme: { typography } }) => typography.footnote2};
  line-height: 26px;
  text-align: center;
  cursor: pointer;

  ${({ $isSelected, theme: { colors } }) =>
    $isSelected &&
    `
    color: ${colors.grayDark};
    &:after {
    content: '';
    display: block;
    height: 4px;
    border-radius: 4px;
    background: ${colors.blue};
    position: relative;
    top: 10px;
    z-index: 2;
  }
  `}
`;
const MainContainer = styled.div`
  height: calc(100% - 108px);
`;
