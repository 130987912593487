import React, { useState } from 'react';
import styled from 'styled-components';

import { RadioIndicator } from '../../../../../ui/Radio/components';
import { wordDeclension } from '../../../../../utils/word-declension';

type Props = {
  title: string;
  id: string;
  createdAt?: Date | string;
  questionsCount?: number;
};

function SurveyItem({ title, id, createdAt, questionsCount }: Props) {
  const [isChecked, setIsChecked] = useState(false);
  const questionWord = questionsCount && wordDeclension(questionsCount, ['Вопрос', 'Вопроса', 'Вопросов']);

  return (
    <label>
      <Root $isChecked={isChecked}>
        <Header>
          <SurveyTitle>{title}</SurveyTitle>
          <RadioIndicator name="surveys" value={id} setIsChecked={setIsChecked} />
        </Header>
        {createdAt && (
          <Details>{`${new Date(createdAt).toLocaleDateString()} • ${questionsCount} ${questionWord}`}</Details>
        )}
      </Root>
    </label>
  );
}

export default SurveyItem;

const Root = styled.div<{ $isChecked: boolean }>`
  background-color: ${({ theme: { colors } }) => colors.grayExtraLight};
  border-radius: 10px;
  padding: 20px;
  box-sizing: border-box;
  margin-bottom: 20px;
  width: calc(100% - 32px);

  &:last-child {
    margin-bottom: 32px;
  }

  &:hover {
    padding: 19px;
    border: 1px solid ${({ theme: { colors } }) => colors.blue};
  }

  ${({ $isChecked, theme: { colors } }) =>
    $isChecked &&
    `
                  padding: 19px;
        background-color: ${colors.blueExtraLight};
        border: 1px solid ${colors.blue};
      `}
`;
const Header = styled.div`
  display: flex;
  align-items: start;
  justify-content: space-between;
`;
const SurveyTitle = styled.span`
  color: ${({ theme: { colors } }) => colors.grayDarkMode};
  ${({ theme: { typography } }) => typography.title3};
  font-weight: 400;
  width: 326px;
  letter-spacing: -0.165px;
  overflow: hidden;
  word-wrap: break-word;
  text-overflow: ellipsis;
`;
const Details = styled.div`
  ${({ theme: { typography } }) => typography.footnote1};
  color: ${({ theme: { colors } }) => colors.grayDarkMode};
  margin-top: 20px;
`;
