import { DocumentNode } from 'graphql';

import * as Types from '../../../__generated__/types';
import { Drug } from './drug.fragment';
import { Question } from './question.fragment';

export type SurveyTemplate = {
  __typename: 'SurveyTemplateModel';
  id: string;
  kind: Types.SurveyTemplateKind;
  period: Types.SurveyTemplatePeriod;
  title: string;
  createdAt: any;
  endAt: any;
  startAt: any;
  questionsCount: number;
  timezoneOffset: number;
  drugs: Array<{ __typename: 'DrugModel'; id: string; name: string }>;
  questions: Array<{
    __typename: 'SurveyTemplateQuestionModel';
    criticalAnswerId?: Types.Maybe<any>;
    criticalAnswersIds?: Types.Maybe<Array<any>>;
    criticalIndicators?: Types.Maybe<{
      __typename: 'SurveyTemplateQuestionCriticalIndicators';
      numeric?: Types.Maybe<{
        __typename: 'SurveyTemplateNumericQuestionCriticalIndicators';
        maxValue?: Types.Maybe<any>;
        minValue?: Types.Maybe<any>;
      }>;
      scale?: Types.Maybe<{ __typename: 'SurveyTemplateScaleQuestionCriticalIndicators'; value: any }>;
    }>;
    question?: Types.Maybe<{
      __typename: 'QuestionModel';
      id: string;
      title: string;
      type: Types.QuestionType;
      isCustom: boolean;
      isActual: boolean;
      indicators?: Types.Maybe<{
        __typename: 'QuestionIndicators';
        numeric?: Types.Maybe<{ __typename: 'NumericQuestionIndicators'; maxValue: any; minValue: any }>;
        scale?: Types.Maybe<{ __typename: 'ScaleQuestionIndicators'; maxValue: any; minValue: any }>;
      }>;
      options?: Types.Maybe<Array<{ __typename: 'QuestionOptionModel'; id: string; index: number; text: string }>>;
    }>;
  }>;
};

export const SurveyTemplate = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'SurveyTemplate' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'SurveyTemplateModel' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'kind' } },
          { kind: 'Field', name: { kind: 'Name', value: 'period' } },
          { kind: 'Field', name: { kind: 'Name', value: 'title' } },
          { kind: 'Field', name: { kind: 'Name', value: 'createdAt' } },
          { kind: 'Field', name: { kind: 'Name', value: 'endAt' } },
          { kind: 'Field', name: { kind: 'Name', value: 'startAt' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'drugs' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'Drug' } }],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'questionsCount' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'questions' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'criticalAnswerId' } },
                { kind: 'Field', name: { kind: 'Name', value: 'criticalAnswersIds' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'criticalIndicators' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'numeric' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            { kind: 'Field', name: { kind: 'Name', value: 'maxValue' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'minValue' } },
                          ],
                        },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'scale' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [{ kind: 'Field', name: { kind: 'Name', value: 'value' } }],
                        },
                      },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'question' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'Question' } }],
                  },
                },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'timezoneOffset' } },
        ],
      },
    },
    ...Drug.definitions,
    ...Question.definitions,
  ],
} as unknown as DocumentNode;
