import React from 'react';
import styled from 'styled-components';

import { AttentionIcon } from '../../assets/svg';

interface ModalAlertMessageProps {
  text: string;
}

const ModalAlertMessage: React.FC<ModalAlertMessageProps> = ({ text }) => {
  return (
    <Root>
      <AttentionIcon />
      <Text>{text}</Text>
    </Root>
  );
};

export default ModalAlertMessage;

const Root = styled.div`
  width: 420px;
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: ${({ theme: { colors } }) => colors.white};
  border: 1px solid ${({ theme: { colors } }) => colors.blueExtraLight};
  box-sizing: border-box;
  box-shadow: 0 6px 30px #e4ecf7;
  border-radius: 10px;
  padding: 50px 38px;
`;

const Text = styled.div`
  ${({ theme: { typography } }) => typography.title2};
  color: ${({ theme: { colors } }) => colors.grayDark};
  text-align: center;
  letter-spacing: -0.165px;
  margin-top: 10px;
`;
