import React from 'react';
import styled from 'styled-components';

function NotificationWithDotIcon() {
  return (
    <Root>
      <svg width="28" height="30" viewBox="0 0 28 30" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
          d="M27.3337 24.6667H0.666992V22H2.00033V12.708C2.00033 6.05735 7.37366 0.666687 14.0003 0.666687C20.627 0.666687 26.0003 6.05735 26.0003 12.708V22H27.3337V24.6667ZM4.66699 22H23.3337V12.708C23.3337 7.53069 19.155 3.33335 14.0003 3.33335C8.84566 3.33335 4.66699 7.53069 4.66699 12.708V22ZM10.667 26H17.3337C17.3337 26.8841 16.9825 27.7319 16.3573 28.357C15.7322 28.9822 14.8844 29.3334 14.0003 29.3334C13.1163 29.3334 12.2684 28.9822 11.6433 28.357C11.0182 27.7319 10.667 26.8841 10.667 26Z"
          fill="#777998"
        />
      </svg>
    </Root>
  );
}

const Root = styled.div`
  padding: 0 2px;
`;

export default NotificationWithDotIcon;
