import * as Apollo from '@apollo/client';
import { DocumentNode } from 'graphql';

import * as Types from '../../../__generated__/types';
import { Question } from '../../fragments/__generated__/question.fragment';

const defaultOptions = {};
export type GetQuestionsByCategoryAndTitleVariables = Types.Exact<{
  categoryId?: Types.Maybe<Types.Scalars['String']>;
  title: Types.Scalars['String'];
}>;

export type GetQuestionsByCategoryAndTitle = {
  __typename: 'Query';
  doctorSearch: Array<{
    __typename: 'QuestionModel';
    id: string;
    title: string;
    type: Types.QuestionType;
    isCustom: boolean;
    isActual: boolean;
    indicators?: Types.Maybe<{
      __typename: 'QuestionIndicators';
      numeric?: Types.Maybe<{ __typename: 'NumericQuestionIndicators'; maxValue: any; minValue: any }>;
      scale?: Types.Maybe<{ __typename: 'ScaleQuestionIndicators'; maxValue: any; minValue: any }>;
    }>;
    options?: Types.Maybe<Array<{ __typename: 'QuestionOptionModel'; id: string; index: number; text: string }>>;
  }>;
};

export const GetQuestionsByCategoryAndTitleDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'GetQuestionsByCategoryAndTitle' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'categoryId' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'title' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'doctorSearch' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'categoryId' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'categoryId' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'title' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'title' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'Question' } }],
            },
          },
        ],
      },
    },
    ...Question.definitions,
  ],
} as unknown as DocumentNode;

/**
 * __useGetQuestionsByCategoryAndTitle__
 *
 * To run a query within a React component, call `useGetQuestionsByCategoryAndTitle` and pass it any options that fit your needs.
 * When your component renders, `useGetQuestionsByCategoryAndTitle` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetQuestionsByCategoryAndTitle({
 *   variables: {
 *      categoryId: // value for 'categoryId'
 *      title: // value for 'title'
 *   },
 * });
 */
export function useGetQuestionsByCategoryAndTitle(
  baseOptions: Apollo.QueryHookOptions<GetQuestionsByCategoryAndTitle, GetQuestionsByCategoryAndTitleVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetQuestionsByCategoryAndTitle, GetQuestionsByCategoryAndTitleVariables>(
    GetQuestionsByCategoryAndTitleDocument,
    options,
  );
}
export function useGetQuestionsByCategoryAndTitleLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<GetQuestionsByCategoryAndTitle, GetQuestionsByCategoryAndTitleVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetQuestionsByCategoryAndTitle, GetQuestionsByCategoryAndTitleVariables>(
    GetQuestionsByCategoryAndTitleDocument,
    options,
  );
}
export type GetQuestionsByCategoryAndTitleHookResult = ReturnType<typeof useGetQuestionsByCategoryAndTitle>;
export type GetQuestionsByCategoryAndTitleLazyQueryHookResult = ReturnType<
  typeof useGetQuestionsByCategoryAndTitleLazyQuery
>;
export type GetQuestionsByCategoryAndTitleQueryResult = Apollo.QueryResult<
  GetQuestionsByCategoryAndTitle,
  GetQuestionsByCategoryAndTitleVariables
>;
