import * as Apollo from '@apollo/client';
import { DocumentNode } from 'graphql';

import * as Types from '../../../__generated__/types';
import { Patient } from '../../fragments/__generated__/patient.fragment';

const defaultOptions = {};
export type GetPatientVariables = Types.Exact<{
  patientId: Types.Scalars['UUID'];
}>;

export type GetPatient = {
  __typename: 'Query';
  doctorFindHospitalPatient: {
    __typename: 'HospitalPatientModel';
    createdAt: any;
    hospitalId: any;
    doctorId?: Types.Maybe<string>;
    patientId: any;
    medicalCardNumber: string;
    firstName?: Types.Maybe<string>;
    lastName?: Types.Maybe<string>;
    id: any;
    hasActiveSurvey: boolean;
  };
};

export const GetPatientDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'GetPatient' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'patientId' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'UUID' } } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'doctorFindHospitalPatient' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'patientId' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'patientId' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'Patient' } }],
            },
          },
        ],
      },
    },
    ...Patient.definitions,
  ],
} as unknown as DocumentNode;

/**
 * __useGetPatient__
 *
 * To run a query within a React component, call `useGetPatient` and pass it any options that fit your needs.
 * When your component renders, `useGetPatient` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetPatient({
 *   variables: {
 *      patientId: // value for 'patientId'
 *   },
 * });
 */
export function useGetPatient(baseOptions: Apollo.QueryHookOptions<GetPatient, GetPatientVariables>) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetPatient, GetPatientVariables>(GetPatientDocument, options);
}
export function useGetPatientLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetPatient, GetPatientVariables>) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetPatient, GetPatientVariables>(GetPatientDocument, options);
}
export type GetPatientHookResult = ReturnType<typeof useGetPatient>;
export type GetPatientLazyQueryHookResult = ReturnType<typeof useGetPatientLazyQuery>;
export type GetPatientQueryResult = Apollo.QueryResult<GetPatient, GetPatientVariables>;
