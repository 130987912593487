import * as Apollo from '@apollo/client';
import { DocumentNode } from 'graphql';

import * as Types from '../../../__generated__/types';

const defaultOptions = {};
export type PatientEmailIsUniqVariables = Types.Exact<{
  email: Types.Scalars['String'];
}>;

export type PatientEmailIsUniq = { __typename: 'Query'; patientEmailIsUniq: boolean };

export const PatientEmailIsUniqDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'PatientEmailIsUniq' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'email' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'patientEmailIsUniq' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'email' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'email' } },
              },
            ],
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode;

/**
 * __usePatientEmailIsUniq__
 *
 * To run a query within a React component, call `usePatientEmailIsUniq` and pass it any options that fit your needs.
 * When your component renders, `usePatientEmailIsUniq` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePatientEmailIsUniq({
 *   variables: {
 *      email: // value for 'email'
 *   },
 * });
 */
export function usePatientEmailIsUniq(
  baseOptions: Apollo.QueryHookOptions<PatientEmailIsUniq, PatientEmailIsUniqVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<PatientEmailIsUniq, PatientEmailIsUniqVariables>(PatientEmailIsUniqDocument, options);
}
export function usePatientEmailIsUniqLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<PatientEmailIsUniq, PatientEmailIsUniqVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<PatientEmailIsUniq, PatientEmailIsUniqVariables>(PatientEmailIsUniqDocument, options);
}
export type PatientEmailIsUniqHookResult = ReturnType<typeof usePatientEmailIsUniq>;
export type PatientEmailIsUniqLazyQueryHookResult = ReturnType<typeof usePatientEmailIsUniqLazyQuery>;
export type PatientEmailIsUniqQueryResult = Apollo.QueryResult<PatientEmailIsUniq, PatientEmailIsUniqVariables>;
