import React, { VFC, useEffect, useState } from 'react';
import styled from 'styled-components';

const CodeInput: VFC<CodeInputProps> = ({ value, isDisabled, error }) => {
  const CODE_LENGTH = [0, 0, 0, 0];
  const [_value, setValue] = useState(value ?? '');
  const [isFocus, setIsFocus] = useState(false);
  const [selectedIndex, setSelectedIndex] = useState(
    _value.length < CODE_LENGTH.length ? _value.length : CODE_LENGTH.length,
  );
  const hasError = !!error;

  useEffect(() => {
    setSelectedIndex(_value.length < CODE_LENGTH.length ? _value.length : CODE_LENGTH.length);
  }, [_value, CODE_LENGTH.length]);

  const input = React.createRef<HTMLInputElement>();

  const onFocus = () => {
    setIsFocus(true);
  };

  const onBlur = () => {
    setIsFocus(false);
  };

  const onChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setValue(state => {
      if (state.length >= CODE_LENGTH.length) return state;
      return `${state}${e.target.value}`.slice(0, CODE_LENGTH.length);
    });
  };

  const onBackspaceKeyUp = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.key === 'Backspace') {
      setValue(state => state.slice(0, state.length - 1));
    }
  };

  return (
    <Root>
      <Code>
        {CODE_LENGTH.map((_, index) => (
          <Cell
            $isFocus={(selectedIndex > 3 ? 3 : selectedIndex) === index && isFocus}
            $hasError={hasError}
            $isDisabled={isDisabled}
            onClick={() => {
              setSelectedIndex(index);
              input?.current?.focus();
            }}>
            {_value.toString().charAt(index)}
          </Cell>
        ))}
        <Input
          $position={selectedIndex > 3 ? 3 : selectedIndex}
          ref={input}
          onChange={onChange}
          onFocus={onFocus}
          onBlur={onBlur}
          pattern="[0-9]"
          maxLength={CODE_LENGTH.length}
          onKeyUp={onBackspaceKeyUp}
          value=""
          disabled={isDisabled}
        />
      </Code>
      {error && <Error>{`*${error}`}</Error>}
    </Root>
  );
};

export interface CodeInputProps {
  value?: string;
  isDisabled?: boolean;
  error?: string;
}

const Root = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
`;

const Code = styled.div`
  display: flex;

  & > *:not(:last-child) {
    margin-right: 21px;
  }
`;

interface CellProps {
  $isFocus?: boolean;
  $hasError?: boolean;
  $isDisabled?: boolean;
}

const Cell = styled.div<CellProps>`
  width: 56px;
  height: 56px;
  background-color: ${({ theme: { colors } }) => colors.blueExtraLight};
  border: 1px solid ${({ theme: { colors } }) => colors.blueAverage};
  border-radius: 10px;
  text-align: center;
  color: ${({ theme: { colors } }) => colors.grayDark};
  ${({ theme: { typography } }) => typography.title2};
  line-height: 56px;
  cursor: default;
  ${({ $isFocus, theme: { colors } }) => $isFocus && `border-color: ${colors.blue}`};
  ${({ $hasError, theme: { colors } }) => $hasError && `border-color: ${colors.colorError}`};
  ${({ $isDisabled, theme: { colors } }) =>
    $isDisabled && `border-color: ${colors.grayExtraLight}; background-color: ${colors.grayExtraLight};`};
`;

interface InputProps {
  $position: number;
}

const Input = styled.input<InputProps>`
  all: unset;
  cursor: default;
  position: absolute;
  width: 58px;
  height: 58px;
  padding: 0 36px;
  ${({ theme: { typography } }) => typography.title2};
  caret-color: ${({ theme: { colors } }) => colors.blue};
  left: ${({ $position }) => $position * 58 + ($position !== 0 ? 21 * $position : 0)}px;
`;

const Error = styled.div`
  color: ${({ theme: { colors } }) => `${colors.colorError}`};
  ${({ theme: { typography } }) => typography.body3};
  margin-left: 20px;
  margin-top: 6px;
`;

export default CodeInput;
