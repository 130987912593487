import { DocumentNode } from 'graphql';

import * as Types from '../../../__generated__/types';
import { Patient } from './patient.fragment';

export type Notification = {
  __typename: 'DoctorNotificationModel';
  createdAt: any;
  description: string;
  doctorId: any;
  id: any;
  isRead: boolean;
  kind: Types.DoctorNotificationKind;
  patientId: any;
  title: string;
  extraData?: Types.Maybe<{ __typename: 'DoctorNotificationsExtraData'; surveyTemplateId: string }>;
  hospitalPatient?: Types.Maybe<{
    __typename: 'HospitalPatientModel';
    createdAt: any;
    hospitalId: any;
    doctorId?: Types.Maybe<string>;
    patientId: any;
    medicalCardNumber: string;
    firstName?: Types.Maybe<string>;
    lastName?: Types.Maybe<string>;
    id: any;
    hasActiveSurvey: boolean;
  }>;
};

export const Notification = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'Notification' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'DoctorNotificationModel' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'createdAt' } },
          { kind: 'Field', name: { kind: 'Name', value: 'description' } },
          { kind: 'Field', name: { kind: 'Name', value: 'doctorId' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'extraData' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'Field', name: { kind: 'Name', value: 'surveyTemplateId' } }],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'hospitalPatient' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'Patient' } }],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'isRead' } },
          { kind: 'Field', name: { kind: 'Name', value: 'kind' } },
          { kind: 'Field', name: { kind: 'Name', value: 'patientId' } },
          { kind: 'Field', name: { kind: 'Name', value: 'title' } },
        ],
      },
    },
    ...Patient.definitions,
  ],
} as unknown as DocumentNode;
