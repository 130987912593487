import React from 'react';

import { defaultTheme } from '../../../themes';
import { getPath } from './getPath';

type RenderBarType = {
  x: number;
  y: number;
  width: number;
  height: number;
  color?: string;
  isCritical?: boolean;
  stroke?: string;
};

const returnPath = ({ x, y, width, height, color, stroke }: RenderBarType) => {
  return (
    <path
      fill={color}
      width={width}
      height={height}
      x={x}
      y={y}
      radius="0"
      className="recharts-rectangle"
      stroke={stroke}
      d={getPath({ x, y, width, height })}
    />
  );
};
export const renderBarTemp = ({ x, y, width, height, isCritical }: RenderBarType) => {
  const color = isCritical ? defaultTheme.colors.blue : defaultTheme.colors.blueAverage;
  const stroke = isCritical ? defaultTheme.colors.blue : defaultTheme.colors.blueDark;
  return returnPath({ x, y, width, height, color, stroke });
};

export const renderBarPress = ({ x, y, width, height, isCritical }: RenderBarType) => {
  const color = isCritical ? defaultTheme.colors.blueDark : defaultTheme.colors.blueAverage;
  const stroke = defaultTheme.colors.blueDark;
  return returnPath({ x, y, width, height, color, stroke });
};
