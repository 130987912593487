export const defaultTheme = {
  name: 'light',
  colors: {
    // Primary
    blue: '#3380ee', // Default
    blueDark: '#1562cd', // Dark
    blueDarkMode: '#81B0F5', //DarkMode
    blueAverage: '#c6dbfa', // Average
    blueLight: '#ebf2fd', // Light
    blueExtraLight: '#f5f8ff', // ExtraLight
    // Grayscale
    grayDark: '#2d2d40', // Dark
    grayDarkMode: '#777998', // DarkMode
    grayAverage: '#c2cadf', // Average
    grayLight: '#f1f3f8', // Light
    grayExtraLight: '#f7f7f9', // ExtraLight
    white: '#ffffff', // White
    // Others
    colorError: '#f07087', // Error
    colorNormal: '#f1e397', // Normal
    colorSuccess: '#63d7bc', // Success
  },
  typography: {
    // Title/#1_SemiBold_38pt
    title1: `
      font-family: 'Lato', sans-serif;
      font-weight: 600;
      font-size: 38px;
      line-height: 50px;
    `,
    // Title/#2_SemiBold_24pt
    title2: `
      font-family: 'Lato', sans-serif;
      font-weight: 600;
      font-size: 24px;
      line-height: 36px;
    `,
    // Title/#3_SemiBold_18pt
    title3: `
    font-family: 'Lato', sans-serif;
    font-weight: 600;
    font-size: 18px;
    line-height: 30px;
    `,
    // Body/#1_Bold_16pt
    body1: `
      font-family: 'Lato', sans-serif;
      font-weight: 700;
      font-size: 16px;
      line-height: 28px;
    `,
    // Body/#2_SemiBold_16pt
    body2: `
      font-family: 'Lato', sans-serif;
      font-weight: 600;
      font-size: 16px;
      line-height: 28px;
    `,
    // Body/#3_Regular_16pt
    body3: `
      font-family: 'Lato', sans-serif;
      font-weight: 400;
      font-size: 16px;
      line-height: 24px;
    `,
    // Footnote/#1_Regular_14pt
    footnote1: `
      font-family: 'Lato', sans-serif;
      font-weight: 400;
      font-size: 14px;
      line-height: 22px;
    `,
    // Footnote / #2_SemiBold_14pt
    footnote2: `
      font-family: 'Lato', sans-serif;
      font-weight: 600;
      font-size: 14px;
      line-height: 22px;
    `,
  },
};

export type ThemeType = typeof defaultTheme;
