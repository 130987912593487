import React from 'react';

function VerticalLineIcon({ color, ...props }: React.SVGProps<SVGSVGElement>) {
  return (
    <svg width="6" height="40" viewBox="0 0 6 40" xmlns="http://www.w3.org/2000/svg" {...props}>
      <rect width="6" height="40" rx="3" fill={color} />
    </svg>
  );
}

export default VerticalLineIcon;
