import { CreatingPassword, PasswordRecovery, SignIn } from 'pages';
import { FC } from 'react';
import { Redirect, Route, Switch } from 'react-router-dom';

import { AuthLayout } from '../layouts/auth';
import { ROUTES } from './index';

export const GuestSwitch: FC = () => {
  return (
    <AuthLayout>
      <Switch>
        <Route path={ROUTES['/sign-in']} component={SignIn} />
        <Route path={ROUTES['/sign-up']} component={CreatingPassword} />
        <Route path={ROUTES['/recovery-password']} component={PasswordRecovery} />
        <Route
          render={props => (
            <Redirect
              to={{
                pathname: ROUTES['/sign-in'],
                state: { from: props.location },
              }}
            />
          )}
        />
      </Switch>
    </AuthLayout>
  );
};
