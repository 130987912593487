import React, { useState } from 'react';
import styled from 'styled-components';

import { RadioIndicator } from '../RadioIndicator';

function Radio({ isDisabled = false, value, name }: RadioProps) {
  const [isChecked, setIsChecked] = useState(false);

  return (
    <Root $isDisabled={isDisabled}>
      <RadioIndicator isDisabled={isDisabled} name={name} value={value} setIsChecked={setIsChecked} />
      <Label $isChecked={isChecked}>{value}</Label>
    </Root>
  );
}

export type RadioProps = {
  isDisabled?: boolean;
  value: string;
  name: string;
};
type StyleProps = {
  $isChecked?: boolean;
  $isDisabled?: boolean;
};

const Root = styled.label<StyleProps>`
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  align-items: center;
  cursor: pointer;
  margin: 13px 0;
  ${({ $isDisabled }) => $isDisabled && `cursor: default`}
`;
const Label = styled.span<StyleProps>`
  margin-left: 16px;

  ${({ $isChecked, theme: { colors, typography } }) =>
    $isChecked
      ? `
    ${typography.body1};
    color: ${colors.grayDark};
    `
      : `
    ${typography.body2};
    color: ${colors.grayDarkMode};
    `}
`;

export default Radio;
