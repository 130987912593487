import './Slider.css';

import { Slider } from '@material-ui/core';
import React, { useEffect, useState } from 'react';
import styled from 'styled-components';

type SliderType = 'scale' | 'range';

interface MultiSliderProps {
  minValue?: number;
  maxValue?: number;
  isThermometer?: boolean;
  changeValues?: (values: number | number[]) => void;
  typeSlider: SliderType;
  criticalMaxValue?: number;
  criticalMinValue?: number;
  setCriticalMaxValue?: (value: number | undefined) => void;
  setCriticalMinValue?: (value: number | undefined) => void;
}

const MultiSlider: React.FC<MultiSliderProps> = ({
  minValue = 0,
  maxValue = 10,
  typeSlider,
  criticalMinValue = minValue,
  criticalMaxValue = maxValue,
  setCriticalMaxValue,
  setCriticalMinValue,
  isThermometer = false,
}) => {
  const [value, setValue] = useState(typeSlider === 'scale' ? criticalMaxValue : [criticalMinValue, criticalMaxValue]);
  const changeValue = (value: number | number[]) => {
    setValue(value);
    if (typeof value === 'object') {
      if (setCriticalMinValue) {
        setCriticalMinValue(value[0]);
      }
      if (setCriticalMaxValue) {
        setCriticalMaxValue(value[1]);
      }
    } else {
      if (setCriticalMaxValue) {
        setCriticalMaxValue(value);
      }
    }
  };
  const customLabel = ({ ...data }) => {
    return (
      <Marker {...data.children.props}>
        <Label>{data.value}</Label>
      </Marker>
    );
  };

  useEffect(() => {
    setCriticalMinValue && setCriticalMinValue(criticalMinValue);
    setCriticalMaxValue && setCriticalMaxValue(criticalMaxValue);
    // eslint-disable-next-line
  }, []);

  return (
    <Root>
      <Range>{minValue}</Range>
      <Slider
        value={value}
        aria-labelledby="slider"
        onChange={(event: object, value: number | number[]) => changeValue(value)}
        valueLabelDisplay="on"
        min={minValue}
        max={maxValue}
        step={isThermometer ? 0.1 : 1}
        ValueLabelComponent={customLabel}
      />
      <Range>{maxValue}</Range>
    </Root>
  );
};
const Root = styled.div`
  display: flex;
  align-items: center;
  margin-top: 38px;
  margin-bottom: 21px;
`;
const Range = styled.p`
  ${({ theme: { typography } }) => typography.footnote1};
  color: ${({ theme: { colors } }) => colors.grayDarkMode};
`;
const Label = styled.div`
  position: absolute;
  top: -35px;
  ${({ theme: { typography } }) => typography.body2};
  color: ${({ theme: { colors } }) => colors.grayDark};
`;
const Marker = styled.div`
  position: relative;
  width: 20px !important;
  height: 20px !important;
  border: 4px solid ${({ theme: { colors } }) => colors.blue} !important;
  border-radius: 10px !important;
  background-color: #fff !important;
  outline: none !important;
  margin-top: -8px !important;
`;
export default React.memo(MultiSlider);
