import { Field, Form, Formik } from 'formik';
import React from 'react';
import styled from 'styled-components';

import { useMeIsAuth } from '../../../../common/query/__generated__/me-is-auth';
import { languages } from '../../../../utils/constants';
import { SelectField } from './components';

export const LanguageSettings = () => {
  const { data } = useMeIsAuth({
    variables: {},
  });
  const initialLanguage = data?.doctorMe.language;

  return (
    <Root>
      <Formik initialValues={{ language: initialLanguage }} onSubmit={() => {}}>
        {() => (
          <Form>
            <Field name="language" component={SelectField} options={languages} initialLanguage={initialLanguage} />
          </Form>
        )}
      </Formik>
    </Root>
  );
};

const Root = styled.div`
  width: 170px;
  position: relative;
`;
