import React, { Dispatch, SetStateAction, useState } from 'react';
import Select from 'react-select';
import styled from 'styled-components';

import { QuestionType, SurveyTemplatePeriod } from '../../__generated__/types';

type OptionType = {
  value: string;
  label: string;
};

type Props = {
  placeholder: string;
  width?: string;
  options: OptionType[] | undefined;
  setSelectedValue?:
    | ((value: string) => void)
    | Dispatch<SetStateAction<QuestionType | undefined | null>>
    | Dispatch<SetStateAction<SurveyTemplatePeriod | undefined>>;
  selectedValue?: string | QuestionType | null;
  inputValue?: string;
  setInputValue?: (value: React.SetStateAction<string>) => void;
  isEmpty?: boolean;
  setIsEmpty?: (isEmpty: boolean) => void;
  setSelectedDrug?: (value: string | undefined) => void;
  isLoading?: boolean;
  isSearchable?: boolean;
  isClearable?: boolean;
};

function Dropdown({
  placeholder,
  options,
  width = '343px',
  setSelectedValue,
  selectedValue,

  isEmpty = false,
  setIsEmpty,
  setSelectedDrug,
  isLoading,
  isSearchable,
  isClearable,
}: Props) {
  const [inputValue, setInputValue] = useState('');
  const handleChange = (e: OptionType) => {
    setInputValue(e.label);
    if (setSelectedValue) {
      const value = e ? e.value : undefined;
      // @ts-ignore
      setSelectedValue(value);
      if (setSelectedDrug) {
        setSelectedDrug(e.value);
      }
      if (setIsEmpty) {
        setIsEmpty(false);
      }
    }
  };
  return (
    <Root $width={width}>
      <CustomSelect
        closeMenuOnScroll={false}
        menuShouldBlockScroll
        menuShouldScrollIntoView
        placeholder={inputValue || placeholder}
        options={options}
        classNamePrefix={'Select'}
        value={options?.find(obj => obj.value === selectedValue) || null}
        onChange={handleChange}
        $isEmpty={isEmpty}
        isLoading={isLoading}
        isSearchable={isSearchable}
        isClearable={isClearable}
      />
    </Root>
  );
}

export default React.memo(Dropdown);

const Root = styled.div<{ $width: string }>`
  width: ${({ $width }) => $width};
`;

const CustomSelect = styled(Select)<{ $isEmpty: boolean }>`
  .Select__indicator-separator {
    width: 0;
  }

  .Select__indicator {
    padding: 0;
    color: ${({ theme: { colors } }) => colors.grayDarkMode};
  }

  .Select__control {
    background-color: ${({ theme: { colors } }) => colors.blueExtraLight};
    border: 1px solid ${({ theme: { colors } }) => colors.blueAverage};
    ${({ theme: { typography } }) => typography.body3};
    height: 54px;
    border-radius: 10px;
    padding: 0 20px;
    ${({ theme: { typography } }) => typography.body3};
    box-shadow: none;

    &:hover {
      border: 1px solid ${({ theme: { colors } }) => colors.blue};
    }
    ${({ $isEmpty, theme: { colors } }) => $isEmpty && `border-color: ${colors.colorError}`};
  }

  .Select__value-container {
    padding: 0;
  }

  .Select__control--is-focused {
    border: 1px solid ${({ theme: { colors } }) => colors.blue};
  }

  .Select__control--menu-is-open {
    border: 1px solid ${({ theme: { colors } }) => colors.blue};
  }

  .Select__placeholder {
    color: ${({ theme: { colors } }) => colors.grayDarkMode};
    caret-color: ${({ theme: { colors } }) => colors.blue};
  }

  .Select__menu {
    background-color: ${({ theme: { colors } }) => colors.white};
    border: 1px solid ${({ theme: { colors } }) => colors.blueLight};
    border-radius: 10px;
    box-shadow: none;
    max-height: 240px;
  }

  .Select__menu-list {
    padding: 0;
    ${({ theme: { typography } }) => typography.body3};
    color: ${({ theme: { colors } }) => colors.grayDark};
    max-height: 240px;
  }

  @media (max-width: 1200px) {
    .Select__menu {
      max-height: 160px;
    }

    .Select__menu-list {
      max-height: 160px;
    }
  }

  .Select__option {
    background-color: transparent;

    &:first-child {
      border-top-left-radius: 10px;
      border-top-right-radius: 10px;
    }

    &:last-child {
      border-bottom-left-radius: 10px;
      border-bottom-right-radius: 10px;
    }

    &:hover {
      background-color: ${({ theme: { colors } }) => colors.blueLight};
    }
  }

  .Select__option--is-selected {
    color: ${({ theme: { colors } }) => colors.grayDark};
    background-color: ${({ theme: { colors } }) => colors.blueLight};
  }

  .Select__input {
    caret-color: ${({ theme: { colors } }) => colors.blue};
  }
`;
