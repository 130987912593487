import * as Apollo from '@apollo/client';
import { DocumentNode } from 'graphql';

import * as Types from '../../../__generated__/types';
import { Doctor } from '../../fragments/__generated__/doctor.fragment';

const defaultOptions = {};
export type MeIsAuthVariables = Types.Exact<{ [key: string]: never }>;

export type MeIsAuth = {
  __typename: 'Query';
  doctorMe: {
    __typename: 'DoctorModel';
    id: string;
    role?: Types.Maybe<Types.PatientRole>;
    email?: Types.Maybe<string>;
    language?: Types.Maybe<Types.Language>;
    createdAt?: Types.Maybe<any>;
    deletedAt?: Types.Maybe<any>;
    updatedAt?: Types.Maybe<any>;
    hospital?: Types.Maybe<{ __typename: 'HospitalModel'; id: string; name: string; patientsLimit: number }>;
    notificationsSettings?: Types.Maybe<{
      __typename: 'DoctorNotificationSettingsModel';
      contactMeRequest?: Types.Maybe<boolean>;
      criticalIndicators?: Types.Maybe<boolean>;
      uploadAnalyzesByPatients?: Types.Maybe<boolean>;
    }>;
  };
};

export const MeIsAuthDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'MeIsAuth' },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'doctorMe' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'Doctor' } }],
            },
          },
        ],
      },
    },
    ...Doctor.definitions,
  ],
} as unknown as DocumentNode;

/**
 * __useMeIsAuth__
 *
 * To run a query within a React component, call `useMeIsAuth` and pass it any options that fit your needs.
 * When your component renders, `useMeIsAuth` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useMeIsAuth({
 *   variables: {
 *   },
 * });
 */
export function useMeIsAuth(baseOptions?: Apollo.QueryHookOptions<MeIsAuth, MeIsAuthVariables>) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<MeIsAuth, MeIsAuthVariables>(MeIsAuthDocument, options);
}
export function useMeIsAuthLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<MeIsAuth, MeIsAuthVariables>) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<MeIsAuth, MeIsAuthVariables>(MeIsAuthDocument, options);
}
export type MeIsAuthHookResult = ReturnType<typeof useMeIsAuth>;
export type MeIsAuthLazyQueryHookResult = ReturnType<typeof useMeIsAuthLazyQuery>;
export type MeIsAuthQueryResult = Apollo.QueryResult<MeIsAuth, MeIsAuthVariables>;
