import React from 'react';
import styled from 'styled-components';

import { CheckedIcon } from '../../assets/svg';
import { defaultTheme } from '../../themes';

function QuestionNotification() {
  return (
    <Root>
      <CheckedIcon color={defaultTheme.colors.blue} />
      <Text>В опрос были добавлены вопросы</Text>
    </Root>
  );
}

export default QuestionNotification;

const Root = styled.div`
  width: 343px;
  min-height: 68px;
  border-radius: 10px;
  background-color: ${({ theme: { colors } }) => colors.white};
  border: 1px solid ${({ theme: { colors } }) => colors.blueLight};
  box-sizing: border-box;
  box-shadow: 0 6px 30px #e4ecf7;
  display: flex;
  align-items: center;
  justify-content: center;
`;
const Text = styled.span`
  ${({ theme: { typography } }) => typography.body3};
  color: ${({ theme: { colors } }) => colors.grayDark};
  margin-left: 13px;
`;
