import { DocumentNode } from 'graphql';

import * as Types from '../../../__generated__/types';

export type AnswerValue = {
  __typename: 'SurveyAnswerValue';
  numeric?: Types.Maybe<{ __typename: 'SurveyNumericAnswerValue'; value: number }>;
  pressure?: Types.Maybe<{ __typename: 'SurveyPressureAnswerValue'; lowerValue: number; upperValue: number }>;
  pulse?: Types.Maybe<{ __typename: 'SurveyPulseAnswerValue'; value: number }>;
  scale?: Types.Maybe<{ __typename: 'SurveyScaleAnswerValue'; value: number }>;
  temperature?: Types.Maybe<{ __typename: 'SurveyTemperatureAnswerValue'; value: number }>;
  weight?: Types.Maybe<{ __typename: 'SurveyWeightAnswerValue'; value: number }>;
};

export const AnswerValue = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'AnswerValue' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'SurveyAnswerValue' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'numeric' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'Field', name: { kind: 'Name', value: 'value' } }],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'pressure' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'lowerValue' } },
                { kind: 'Field', name: { kind: 'Name', value: 'upperValue' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'pulse' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'Field', name: { kind: 'Name', value: 'value' } }],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'scale' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'Field', name: { kind: 'Name', value: 'value' } }],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'temperature' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'Field', name: { kind: 'Name', value: 'value' } }],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'weight' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'Field', name: { kind: 'Name', value: 'value' } }],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode;
