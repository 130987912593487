import { Form, FormikProvider, useFormik } from 'formik';
import { FC } from 'react';
import { RouteComponentProps } from 'react-router';
import { Link, useLocation } from 'react-router-dom';
import { PathParams, ROUTES } from 'routers';
import styled from 'styled-components';
import * as Yup from 'yup';

import { useEmailSignIn } from '../../common/mutation/__generated__/email-sign-in';
import { PRIVACY_POLICY_LINK, TERMS_OF_USE_LINK } from '../../constants';
import { modalVar } from '../../libs/Apollo';
import useAuthContext from '../../libs/Apollo/hooks/useAuthContext';
import { storageClient } from '../../libs/StorageClient';
import { Button, Input, SingleCheckbox } from '../../ui';

type RouteParams = PathParams<'/sign-in'>;

type Values = {
  email: string;
  password: string;
  rememberMe: boolean;
};

export const SignIn: FC<RouteComponentProps<RouteParams>> = props => {
  const [emailSignIn, { data, loading, error }] = useEmailSignIn();
  const { authContext } = useAuthContext();
  const oldLocalToken = storageClient.getAuthToken();
  const oldSessionToken = sessionStorage.getItem('token');

  const location = useLocation();

  const formik = useFormik({
    initialValues: {
      email: '',
      password: '',
      rememberMe: false,
    },
    validationSchema: Yup.object({
      password: Yup.string()
        .required('Пожалуйста, введите Ваш пароль')
        .min(8, 'Пароль должен содержать минимум 8 символов'),
      email: Yup.string()
        .required('Пожалуйста, введите Ваш e-mail')
        .email('E-mail должен соответствовать типу example@mail.com')
        .matches(/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i, 'E-mail должен соответствовать типу example@mail.com'),
    }),
    onSubmit: async (values: Values) => {
      const res = await emailSignIn({
        variables: {
          input: {
            email: values.email,
            password: values.password,
          },
        },
      });
      const token = res.data?.doctorEmailSignIn.token;
      const refreshToken = res.data?.doctorEmailSignIn.refreshToken;
      if (token && refreshToken) {
        if (oldLocalToken || oldSessionToken) {
          await authContext.signOut();
        }
        values.rememberMe
          ? await authContext.signIn(token, refreshToken)
          : await authContext.signInWithoutRemember(token, refreshToken);
        if (location.pathname.includes('patient')) {
          modalVar({ pathnameFromEmail: location.pathname });
        }
        window.location.reload();
      }
    },
  });

  let errorResponse: string | undefined = undefined;
  if (data?.doctorEmailSignIn.problem) {
    errorResponse = data.doctorEmailSignIn.problem.message;
  }

  return (
    <Root>
      <Container>
        <Modal>
          <Title>Вход</Title>
          <FormikProvider value={formik}>
            <Form onSubmit={formik.handleSubmit}>
              <InputBlock>
                <Input
                  placeholder="Введите почту"
                  label="E-mail"
                  {...formik.getFieldProps('email')}
                  error={formik.touched.email ? formik.errors.email : ''}
                />
                <Input
                  placeholder="Введите пароль"
                  label="Пароль"
                  type="password"
                  {...formik.getFieldProps('password')}
                  error={(formik.touched.password ? formik.errors.password : '') || error?.message || errorResponse}
                />
                <SingleCheckbox
                  label="Запомнить логин и пароль"
                  type={'checkbox'}
                  {...formik.getFieldProps('rememberMe')}
                  isChecked={formik.values.rememberMe}
                />
              </InputBlock>
              <Button
                variant="primary"
                label="Войти"
                type="submit"
                isDisabled={!(formik.isValid && formik.dirty)}
                isLoading={loading}
              />
            </Form>
          </FormikProvider>
          <ForgotWrapper>
            <ForgotLink to={ROUTES['/recovery-password']}>Забыли пароль?</ForgotLink>
          </ForgotWrapper>
          <Conditions>
            Нажимая <b>Войти</b>, вы соглашаетесь с&nbsp;
            <TermsLink href={TERMS_OF_USE_LINK} target="_blank" rel="noopener">
              Правилами пользования
            </TermsLink>
            &nbsp;и&nbsp;
            <TermsLink href={PRIVACY_POLICY_LINK} target="_blank" rel="noopener">
              Политикой конфиденциальности
            </TermsLink>
          </Conditions>
        </Modal>
      </Container>
    </Root>
  );
};

const Root = styled.div`
  width: 100%;
  background-color: ${({ theme: { colors } }) => colors.blueExtraLight};
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;
const Container = styled.div`
  width: 1280px;
  min-height: calc(100vh - 100px - 80px);
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  margin: 40px 0;
`;
const Modal = styled.div`
  width: 420px;
  background-color: ${({ theme: { colors } }) => colors.white};
  border: 1px solid ${({ theme: { colors } }) => colors.blueExtraLight};
  box-shadow: 0 6px 30px #e4ecf7;
  border-radius: 10px;
  padding: 39px;
  height: fit-content;
  box-sizing: border-box;
`;
const Title = styled.span`
  color: ${({ theme: { colors } }) => colors.grayDark};
  ${({ theme: { typography } }) => typography.title2};
  line-height: 44px;
`;
const InputBlock = styled.div`
  margin-top: 32px;
`;
const ForgotWrapper = styled.div`
  margin-top: 92px;
  margin-bottom: 22px;
`;
const ForgotLink = styled(Link)`
  color: ${({ theme: { colors } }) => colors.blue};
  ${({ theme: { typography } }) => typography.body3};
  letter-spacing: -0.165px;
  text-decoration: none;

  &:hover {
    text-decoration: underline;
  }
`;
const Conditions = styled.div`
  letter-spacing: -0.165px;
  color: ${({ theme: { colors } }) => colors.grayDarkMode};
  ${({ theme: { typography } }) => typography.footnote1};
`;
const TermsLink = styled.a`
  color: ${({ theme: { colors } }) => colors.blue};
  text-decoration: none;

  &:hover {
    text-decoration: underline;
  }
`;
