import { useField } from 'formik';
import React from 'react';
import styled from 'styled-components';

import { ThemeType, defaultTheme } from '../../themes';

const LanguageOption: React.VFC<LanguageOptionProps> = ({ label, value }) => {
  const [field] = useField({ name: 'language', type: 'radio', value });
  const isChecked = field.checked || false;
  const variant = isChecked ? 'primary' : 'secondary';
  const themeCSS = themes[variant](defaultTheme);
  return (
    <label>
      <Root $variant={variant} $themeCSS={themeCSS}>
        <Input checked={field.value} {...field} />
        {label}
      </Root>
    </label>
  );
};

export default LanguageOption;

export interface LanguageOptionProps {
  label: string;
  value: string;
}

type RadioVariant = 'primary' | 'secondary';

interface RootProps {
  $themeCSS: string;
  $variant: RadioVariant;
}

const themes = {
  primary: (theme: ThemeType) => `
    color: ${theme.colors.blue};
    background-color: ${theme.colors.blueAverage};
  `,
  secondary: (theme: ThemeType) => `
    color: ${theme.colors.grayDarkMode};
    background-color: ${theme.colors.white};
    border: 1px solid ${theme.colors.blueAverage};
  `,
};

const Root = styled.div<RootProps>`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  box-sizing: border-box;
  width: 287px;
  height: 54px;
  border-radius: 10px;
  padding: 12px 22px;
  margin-bottom: 20px;
  ${({ theme: { typography } }) => typography.title3}
  ${({ $themeCSS }) => $themeCSS}
`;

const Input = styled.input.attrs({ type: 'radio' })`
  opacity: 0;
  width: 0;
  height: 0;
`;
