import React, { ReactNode } from 'react';
import { useHistory } from 'react-router-dom';
import styled from 'styled-components';

import { ArrowLeftIcon } from '../../assets/svg';

type Props = {
  title?: string;
  children?: ReactNode;
  isOpenModal?: boolean;
};

function BackBlock({ title, children, isOpenModal = false }: Props) {
  const history = useHistory();
  return (
    <Root $isOpenModal={isOpenModal}>
      <Container>
        <Back>
          <div onClick={() => history.goBack()}>
            <ArrowLeftIcon />
          </div>
          {title && <BackText>{title}</BackText>}
          {children && children}
        </Back>
      </Container>
    </Root>
  );
}

export default BackBlock;

const Root = styled.header<{ $isOpenModal: boolean }>`
  width: 100%;
  display: flex;
  justify-content: center;
  background-color: ${({ theme: { colors } }) => colors.blueExtraLight};
  margin: 20px 0;
  ${({ $isOpenModal }) => $isOpenModal && `filter: blur(3px)`}
`;
const Container = styled.div`
  width: 1280px;
  height: 100%;
  padding: 0 80px;
  display: flex;
  justify-content: space-between;
  align-items: center;
`;
const Back = styled.div`
  width: 100%;
  display: inline-flex;
  align-items: center;
  position: relative;
  left: 0;
`;
const BackText = styled.div`
  margin-left: 16px;
  color: ${({ theme: { colors } }) => colors.grayDark};
  ${({ theme: { typography } }) => typography.title2};
  line-height: 44px;
`;
