import { DocumentNode } from 'graphql';

import * as Types from '../../../__generated__/types';

export type Drug = { __typename: 'DrugModel'; id: string; name: string };

export const Drug = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'Drug' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'DrugModel' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode;
