import { useQuery } from '@apollo/client';
import { MeIsAuthDocument } from 'common/query/__generated__/me-is-auth';
import { storageClient } from 'libs/StorageClient';
import React, { useEffect, useState } from 'react';
import useOnclickOutside from 'react-cool-onclickoutside';
import { useHistory } from 'react-router-dom';
import styled from 'styled-components';

import {
  AccountBoxIcon,
  FeedbackIcon,
  HeartbeatLogoIcon,
  LogoutIcon,
  NotificationWithDotIcon,
  SettingsIcon,
} from '../../assets/svg';
import { EllipseIcon } from '../../assets/svg/notification-with-dot-icon/utils';
import { useGetUnreadNotificationsCount } from '../../common/query/__generated__/get-unread-notifications-count';
import useAuthContext from '../../libs/Apollo/hooks/useAuthContext';
import { GET_MODAL_STATUS } from '../../libs/Apollo/query/get-modal-status';
import { ROUTES } from '../../routers';

type Notifications = {
  contactMeRequest: boolean;
  criticalIndicators: boolean;
  uploadAnalyzesByPatients: boolean;
};
type PropsType = {
  isAuth?: boolean;
};

function Header({ isAuth = true }: PropsType) {
  const [isOpenMenu, setIsOpenMenu] = useState(false);
  const { authContext } = useAuthContext();
  const history = useHistory();

  const ref = useOnclickOutside(() => {
    setIsOpenMenu(false);
  });

  const goToProfile = () => {
    setIsOpenMenu(false);
    history.push(ROUTES['/profile']);
  };
  const goToNotifications = () => {
    history.push(ROUTES['/notifications']);
  };
  const goToMainPage = () => {
    history.push(ROUTES['/']);
  };
  const { data: dataModalStatus } = useQuery(GET_MODAL_STATUS);
  const isOpenModal: boolean = dataModalStatus.dataModalVar.isOpenModal;

  const logout = async () => {
    await authContext.signOut();
    storageClient.removeApolloCachePersist();
    storageClient.removeHash();
  };

  let notificationSettings: Notifications = {
    contactMeRequest: false,
    criticalIndicators: false,
    uploadAnalyzesByPatients: false,
  };
  const { data: doctor } = useQuery(MeIsAuthDocument, { fetchPolicy: 'network-only' });
  if (doctor) {
    notificationSettings = doctor.doctorMe.notificationsSettings;
  }

  const { data: dataNotificationsCount } = useGetUnreadNotificationsCount({
    pollInterval: 10000,
    fetchPolicy: 'network-only',
    skip:
      !isAuth ||
      (!notificationSettings.contactMeRequest &&
        !notificationSettings.criticalIndicators &&
        !notificationSettings.uploadAnalyzesByPatients),
  });

  let hasNotification = Boolean(dataNotificationsCount?.doctorGetUnreadNotificationsCount);

  useEffect(() => {}, [hasNotification, isAuth]);

  return (
    <Root $isOpenModal={isOpenModal}>
      <Container>
        <div onClick={goToMainPage}>
          <HeartbeatLogoIcon />
        </div>
        {isAuth && (
          <Wrapper>
            <Notification onClick={goToNotifications}>
              <NotificationWithDotIcon />
              {hasNotification && <StylizedEllipseIcon />}
            </Notification>
            <Div ref={ref}>
              <UserIcon onClick={() => setIsOpenMenu(!isOpenMenu)}>
                <AccountBoxIcon size={'38'} />
              </UserIcon>
              {isOpenMenu && (
                <Menu>
                  <MenuItem onClick={goToProfile}>
                    <SettingsIcon />
                    <MenuItemTitle>Настройки</MenuItemTitle>
                  </MenuItem>
                  <MenuItem>
                    <FeedbackIcon />
                    <MenuItemTitle>Помощь</MenuItemTitle>
                  </MenuItem>
                  <MenuItem onClick={logout}>
                    <LogoutIcon />
                    <MenuItemTitle>Выход</MenuItemTitle>
                  </MenuItem>
                </Menu>
              )}
            </Div>
          </Wrapper>
        )}
      </Container>
    </Root>
  );
}

export default Header;

const Root = styled.header<{ $isOpenModal: boolean }>`
  width: 100%;
  height: 100px;
  display: flex;
  justify-content: center;
  background-color: ${({ theme: { colors } }) => colors.white};
  ${({ $isOpenModal }) => $isOpenModal && `filter: blur(3px)`}
`;
const Container = styled.div`
  width: 1280px;
  height: 100%;
  padding: 0 80px;
  display: flex;
  justify-content: space-between;
  align-items: center;
`;
const Wrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 156px;
`;
const Notification = styled.div`
  display: flex;
  align-items: center;
  cursor: pointer;
`;
const StylizedEllipseIcon = styled(EllipseIcon)`
  position: relative;
  top: -18px;
  right: 5px;
`;
const UserIcon = styled.div`
  position: relative;
`;
const Menu = styled.div`
  width: 176px;
  background-color: ${({ theme: { colors } }) => colors.white};
  border: 1px solid ${({ theme: { colors } }) => colors.blueLight};
  box-sizing: border-box;
  border-radius: 10px;
  position: absolute;
  z-index: 100;
  top: 56px;
  left: -123px;
`;
const MenuItem = styled.div`
  display: flex;
  align-items: center;
  padding: 0 20px;
  cursor: pointer;

  &:first-child {
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
  }

  &:last-child {
    border-bottom-left-radius: 10px;
    border-bottom-right-radius: 10px;
  }

  &:hover {
    background-color: ${({ theme: { colors } }) => colors.blueLight};
  }
`;
const MenuItemTitle = styled.span`
  ${({ theme: { typography } }) => typography.body3}
  color: ${({ theme: { colors } }) => colors.grayDark};
  margin: 10px;
`;
const Div = styled.div`
  position: relative;
`;
