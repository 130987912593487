import * as Apollo from '@apollo/client';
import { DocumentNode } from 'graphql';

import * as Types from '../../../__generated__/types';
import { SurveyTemplate } from '../../fragments/__generated__/survey-template.fragment';

const defaultOptions = {};
export type SaveSurveyTemplateVariables = Types.Exact<{
  input: Types.DoctorCreateSurveyTemplateInput;
}>;

export type SaveSurveyTemplate = {
  __typename: 'Mutation';
  doctorCreatePublicSurveyTemplate: {
    __typename: 'DoctorCreateSurveyTemplatePayload';
    problem?: Types.Maybe<{ __typename: 'BaseProblem'; message: string }>;
    surveyTemplate?: Types.Maybe<{
      __typename: 'SurveyTemplateModel';
      id: string;
      kind: Types.SurveyTemplateKind;
      period: Types.SurveyTemplatePeriod;
      title: string;
      createdAt: any;
      endAt: any;
      startAt: any;
      questionsCount: number;
      timezoneOffset: number;
      drugs: Array<{ __typename: 'DrugModel'; id: string; name: string }>;
      questions: Array<{
        __typename: 'SurveyTemplateQuestionModel';
        criticalAnswerId?: Types.Maybe<any>;
        criticalAnswersIds?: Types.Maybe<Array<any>>;
        criticalIndicators?: Types.Maybe<{
          __typename: 'SurveyTemplateQuestionCriticalIndicators';
          numeric?: Types.Maybe<{
            __typename: 'SurveyTemplateNumericQuestionCriticalIndicators';
            maxValue?: Types.Maybe<any>;
            minValue?: Types.Maybe<any>;
          }>;
          scale?: Types.Maybe<{ __typename: 'SurveyTemplateScaleQuestionCriticalIndicators'; value: any }>;
        }>;
        question?: Types.Maybe<{
          __typename: 'QuestionModel';
          id: string;
          title: string;
          type: Types.QuestionType;
          isCustom: boolean;
          isActual: boolean;
          indicators?: Types.Maybe<{
            __typename: 'QuestionIndicators';
            numeric?: Types.Maybe<{ __typename: 'NumericQuestionIndicators'; maxValue: any; minValue: any }>;
            scale?: Types.Maybe<{ __typename: 'ScaleQuestionIndicators'; maxValue: any; minValue: any }>;
          }>;
          options?: Types.Maybe<Array<{ __typename: 'QuestionOptionModel'; id: string; index: number; text: string }>>;
        }>;
      }>;
    }>;
  };
};

export const SaveSurveyTemplateDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'SaveSurveyTemplate' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'input' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'DoctorCreateSurveyTemplateInput' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'doctorCreatePublicSurveyTemplate' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'input' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'input' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'problem' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [{ kind: 'Field', name: { kind: 'Name', value: 'message' } }],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'surveyTemplate' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'SurveyTemplate' } }],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    ...SurveyTemplate.definitions,
  ],
} as unknown as DocumentNode;
export type SaveSurveyTemplateMutationFn = Apollo.MutationFunction<SaveSurveyTemplate, SaveSurveyTemplateVariables>;

/**
 * __useSaveSurveyTemplate__
 *
 * To run a mutation, you first call `useSaveSurveyTemplate` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSaveSurveyTemplate` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [saveSurveyTemplate, { data, loading, error }] = useSaveSurveyTemplate({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useSaveSurveyTemplate(
  baseOptions?: Apollo.MutationHookOptions<SaveSurveyTemplate, SaveSurveyTemplateVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<SaveSurveyTemplate, SaveSurveyTemplateVariables>(SaveSurveyTemplateDocument, options);
}
export type SaveSurveyTemplateHookResult = ReturnType<typeof useSaveSurveyTemplate>;
export type SaveSurveyTemplateMutationResult = Apollo.MutationResult<SaveSurveyTemplate>;
export type SaveSurveyTemplateMutationOptions = Apollo.BaseMutationOptions<
  SaveSurveyTemplate,
  SaveSurveyTemplateVariables
>;
