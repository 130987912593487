import React, { ChangeEvent, Dispatch, SetStateAction, useEffect, useState } from 'react';
import { RangeModifier } from 'react-day-picker';
import { useParams } from 'react-router-dom';
import styled from 'styled-components';

import {
  DrugModel,
  QuestionInput,
  SurveyTemplatePeriod,
  SurveyTemplateQuestionModel,
} from '../../../../__generated__/types';
import { PencilIcon, SaveIcon } from '../../../../assets/svg';
import { useSaveSurveyTemplate } from '../../../../common/mutation/__generated__/save-survey-template';
import { GetSavedSurveyTemplatesForPatientDocument } from '../../../../common/query/__generated__/get-saved-survey-templates-for-patient';
import { defaultTheme } from '../../../../themes';
import { Calendar, Dropdown, Loader, Question, Scrollbar } from '../../../../ui';
import { DrugTitleItem } from '../../../../ui/QuizTitleItem';
import { periodOptions } from '../../../../utils/constants';
import { deleteTypename } from '../../../../utils/deleteTypename';

type Props = {
  addedDrugs: DrugModel[];
  setAddedDrugs: (drugs: DrugModel[]) => void;
  addedQuestions: SurveyTemplateQuestionModel[];
  setAddedQuestions: (questions: SurveyTemplateQuestionModel[]) => void;
  quizTitle: string;
  setQuizTitle: (quizTitle: string) => void;
  setCriticalParams: (questions: QuestionInput[]) => void;
  criticalParams: QuestionInput[];
  isSurveyTemplateTitle: boolean;
  period: SurveyTemplatePeriod | undefined;
  setPeriod: Dispatch<SetStateAction<SurveyTemplatePeriod | undefined>>;
  dateRange: RangeModifier | undefined;
  setDateRange: (dateRange: RangeModifier | undefined) => void;
  isEmptyPeriod: boolean;
  setIsEmptyPeriod: (isEmptyPeriod: boolean) => void;
  isEmptyDateRange: boolean;
  setIsEmptyDateRange: (isEmptyDateRange: boolean) => void;
  openModalSaveSurveyTemplate: () => void;
  setDeletingDrugId: (deletingDrugId: undefined | string) => void;
  setDeletingQuestionId: (deletingDrugId: undefined | string) => void;
};

export const NewQuiz = ({
  addedDrugs,
  setAddedDrugs,
  addedQuestions,
  setAddedQuestions,
  quizTitle,
  setQuizTitle,
  criticalParams,
  setCriticalParams,
  isSurveyTemplateTitle,
  period,
  setPeriod,
  dateRange,
  setDateRange,
  isEmptyPeriod,
  setIsEmptyPeriod,
  isEmptyDateRange,
  setIsEmptyDateRange,
  openModalSaveSurveyTemplate,
  setDeletingDrugId,
  setDeletingQuestionId,
}: Props) => {
  //title
  const [isEditMode, setIsEditMode] = useState(false);
  const [isShowTooltip, setIsShowTooltip] = useState(false);
  const changeTitle = (e: ChangeEvent<HTMLInputElement>) => {
    setQuizTitle(e.currentTarget.value);
  };
  useEffect(() => {
    if (addedDrugs.length > 0 && !addedDrugs.every(drug => drug.id === '')) {
      const drugNames = addedDrugs.map(drug => drug.name).filter(name => name !== '');
      let title = drugNames.join(' + ') || 'Новый опрос';
      title = title[0].toUpperCase() + title.substr(1).toLowerCase();
      if (!isSurveyTemplateTitle) {
        setQuizTitle(title);
      }
    }
  }, [addedDrugs, setQuizTitle, isSurveyTemplateTitle]);

  //delete drug
  const deleteDrug = (id: string) => {
    setAddedDrugs(addedDrugs.filter(drug => drug.id !== id));
    setDeletingDrugId(id);
    if (addedDrugs.length === 1) {
      setAddedQuestions([]);
      setCriticalParams([]);
    }
  };

  const deleteQuestion = (id: string | undefined) => {
    setAddedQuestions(addedQuestions.filter(({ question }) => question?.id !== id));
    setCriticalParams(criticalParams.filter(crit => crit.questionId !== id));
    setDeletingQuestionId(id);
  };

  //save survey template
  const { id: patientId } = useParams<{ id: string }>();
  const [saveSurveyTemplate, { loading }] = useSaveSurveyTemplate();

  const onClickSaveSurveyTemplate = async () => {
    try {
      if (dateRange === undefined || period === undefined) {
        setIsEmptyDateRange(!dateRange);
        setIsEmptyPeriod(!period);
        return null;
      }
      if (!isEmptyDateRange && !isEmptyPeriod) {
        const questionsCriticalParams = criticalParams.map(param => {
          const indicators = deleteTypename(param.criticalIndicators);
          return { ...param, criticalIndicators: indicators };
        });
        const res = await saveSurveyTemplate({
          variables: {
            input: {
              title: quizTitle,
              drugsIds: addedDrugs.map(drug => drug.id).filter(id => id !== ''),
              patientId,
              period: period || SurveyTemplatePeriod.Everyday,
              questions: questionsCriticalParams,
              startAt: dateRange?.from,
              endAt: dateRange?.to,
            },
          },
          refetchQueries: [
            { query: GetSavedSurveyTemplatesForPatientDocument, variables: { patientId }, fetchPolicy: 'network-only' },
          ],
        });
        if (!res.data?.doctorCreatePublicSurveyTemplate.problem) {
          openModalSaveSurveyTemplate();
        }
      }
    } catch (e) {
      const error = e as Error;
      console.log(error.message);
    }
  };

  //displaying top tooltip
  const [isShowTopTooltip, setIsShowTopTooltip] = useState(false);
  const onMouseMoveQuestionListHandler = (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
    const bottomMargin = 34;
    const twoQuestionsBlocksHeight = 160;
    // @ts-ignore
    const acceptableHeight = e.view.outerHeight - bottomMargin - twoQuestionsBlocksHeight;
    if (e.screenY > acceptableHeight) {
      setIsShowTopTooltip(true);
    } else {
      setIsShowTopTooltip(false);
    }
  };

  return (
    <Root>
      <NewQuizHeader>
        <QuizTitle>
          {isEditMode ? (
            <TitleInput onBlur={() => setIsEditMode(false)} autoFocus value={quizTitle} onChange={changeTitle} />
          ) : (
            <>
              <Tooltip onMouseEnter={() => setIsShowTooltip(true)} onMouseLeave={() => setIsShowTooltip(false)}>
                {quizTitle}
              </Tooltip>
              <PencilIconWrapper onClick={() => setIsEditMode(true)}>
                <PencilIcon />
              </PencilIconWrapper>
              {isShowTooltip && <TooltipText>{quizTitle}</TooltipText>}
            </>
          )}
        </QuizTitle>
        <SavingQuiz>
          {loading ? (
            <Loader color={defaultTheme.colors.grayDarkMode} />
          ) : (
            <SaveButton onClick={onClickSaveSurveyTemplate}>
              <SaveIconWrapper>
                <SaveIcon />
              </SaveIconWrapper>
              <Text>Сохранить опрос</Text>
            </SaveButton>
          )}
        </SavingQuiz>
      </NewQuizHeader>
      <FrequencyBlock>
        <Dropdown
          placeholder="Периодичность"
          options={periodOptions}
          setSelectedValue={setPeriod}
          isEmpty={isEmptyPeriod}
          setIsEmpty={setIsEmptyPeriod}
          isSearchable={false}
          selectedValue={period}
        />
        <Calendar
          setDateRange={setDateRange}
          isEmpty={isEmptyDateRange}
          setIsEmpty={setIsEmptyDateRange}
          disabledDays={[{ before: new Date() }]}
        />
      </FrequencyBlock>
      <QuestionsContainer>
        {addedQuestions.length ? (
          <QuestionsBlock>
            <Scrollbar>
              <Wrapper>
                <DrugTitlesContainer>
                  {addedDrugs.map(drug => {
                    return (
                      drug.name !== '' && (
                        <DrugTitleItem title={drug.name} key={drug.id} deleteDrug={() => deleteDrug(drug.id)} />
                      )
                    );
                  })}
                </DrugTitlesContainer>
                <QuestionsWrapper onMouseMove={onMouseMoveQuestionListHandler}>
                  {addedQuestions.map(({ question }) => {
                    const criticalParameter = criticalParams.filter(param => param.questionId === question?.id);

                    return question ? (
                      <Question
                        key={question.id}
                        title={question.title}
                        id={question.id}
                        type={question.type}
                        deleteQuestion={() => deleteQuestion(question?.id)}
                        indicators={question.indicators}
                        isCustom={question.isCustom}
                        options={question?.options}
                        criticalParameter={criticalParameter[0]}
                        setCriticalParams={setCriticalParams}
                        criticalParams={criticalParams}
                        isShowTopTooltip={isShowTopTooltip}
                      />
                    ) : null;
                  })}
                </QuestionsWrapper>
              </Wrapper>
            </Scrollbar>
          </QuestionsBlock>
        ) : (
          <NoQuestionsWrapper>
            <NoQuestionsText>Нет вопросов</NoQuestionsText>
          </NoQuestionsWrapper>
        )}
      </QuestionsContainer>
    </Root>
  );
};

const Title = styled.div`
  color: ${({ theme: { colors } }) => colors.grayDark};
  ${({ theme: { typography } }) => typography.title2};
  line-height: 44px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;
const Root = styled.div`
  background-color: ${({ theme: { colors } }) => colors.white};
  width: 770px;
  height: 100%;
  box-sizing: border-box;
  border: 1px solid ${({ theme: { colors } }) => colors.blueExtraLight};
  box-shadow: 0 6px 30px #e4ecf7;
  border-radius: 10px;
  padding: 31px;
`;
const NewQuizHeader = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 25px;
`;
const QuizTitle = styled.div`
  display: flex;
  align-items: center;
  justify-content: start;
  max-width: 461px;
`;
const TitleInput = styled.input`
  color: ${({ theme: { colors } }) => colors.grayDark};
  ${({ theme: { typography } }) => typography.title2};
  line-height: 44px;
  caret-color: ${({ theme: { colors } }) => colors.blue};
  outline: none;
  border: none;
  width: 400px;
  padding: 0;
`;
const PencilIconWrapper = styled.div`
  margin-left: 16px;
  padding: 3px;
  cursor: pointer;
`;
const Tooltip = styled(Title)`
  position: relative;
`;
const TooltipText = styled.span`
  color: ${({ theme: { colors } }) => colors.grayDark};
  ${({ theme: { typography } }) => typography.body3};
  letter-spacing: -0.165px;
  max-width: 392px;
  padding: 16px 12px;
  background-color: ${({ theme: { colors } }) => colors.white};
  border-radius: 10px;
  filter: drop-shadow(0px 6px 30px #e4ecf7);
  position: absolute;
  z-index: 1;
  top: 180px;
  left: 280px;
  margin-left: -60px;

  &::after {
    content: '';
    position: absolute;
    bottom: 100%;
    left: 50%;
    margin-left: -10px;
    border-width: 10px;
    border-style: solid;
    border-color: transparent transparent ${({ theme: { colors } }) => colors.white} transparent;
  }
`;
const SavingQuiz = styled.div`
  min-width: 150px;
`;
const SaveButton = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
`;
const SaveIconWrapper = styled.div`
  margin-right: 12px;
  position: relative;
  top: 2px;
`;
const Text = styled.span`
  color: ${({ theme: { colors } }) => colors.grayDark};
  ${({ theme: { typography } }) => typography.title3};

  &:hover {
    color: ${({ theme: { colors } }) => colors.blueDark};
  }
`;
const FrequencyBlock = styled.div`
  display: flex;
  justify-content: space-between;
  padding-bottom: 20px;
`;
const QuestionsContainer = styled.div`
  width: 100%;
  height: calc(100vh - 100px - 120px - 176px);
`;
const QuestionsBlock = styled.div`
  width: calc(100% + 32px);
  height: 100%;
`;
const Wrapper = styled.div`
  width: calc(100% - 32px);
`;
const NoQuestionsWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
`;
const NoQuestionsText = styled.div`
  width: 256px;
  text-align: center;
  color: ${({ theme: { colors } }) => colors.grayDarkMode};
  ${({ theme: { typography } }) => typography.title3};
  letter-spacing: -0.165px;
  position: relative;
  top: -20px;
`;
const DrugTitlesContainer = styled.div`
  margin: 0 0 10px;
  display: flex;
  flex-wrap: wrap;
`;
const QuestionsWrapper = styled.div`
  padding-top: 12px;
  min-height: calc(100vh - 100px - 120px - 176px);
`;
