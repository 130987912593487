import React from 'react';

function CalendarIcon({ color, ...props }: React.SVGProps<SVGSVGElement>) {
  return (
    <svg width="20" height="20" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path
        d="M15 2H19C19.2652 2 19.5196 2.10536 19.7071 2.29289C19.8946 2.48043 20 2.73478 20 3V19C20 19.2652 19.8946 19.5196 19.7071 19.7071C19.5196 19.8946 19.2652 20 19 20H1C0.734784 20 0.48043 19.8946 0.292893 19.7071C0.105357 19.5196 0 19.2652 0 19V3C0 2.73478 0.105357 2.48043 0.292893 2.29289C0.48043 2.10536 0.734784 2 1 2H5V0H7V2H13V0H15V2ZM18 10H2V18H18V10ZM13 4H7V6H5V4H2V8H18V4H15V6H13V4ZM4 12H6V14H4V12ZM9 12H11V14H9V12ZM14 12H16V14H14V12Z"
        fill={color}
      />
    </svg>
  );
}

export default CalendarIcon;
