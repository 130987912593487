/* eslint-disable no-unused-vars */
import useAuthContext from 'libs/Apollo/hooks/useAuthContext';
import { FC, createContext } from 'react';
import { BrowserRouter } from 'react-router-dom';

import { storageClient } from '../libs/StorageClient';
import { GuestSwitch } from './GuestSwitch';
import { UserSwitch } from './UserSwitch';

export enum RouterCase {
  GUEST = 'GUEST',
  USER = 'USER',
}

type AuthContextProps = ReturnType<typeof useAuthContext>['authContext'];

export const AuthContext = createContext<Partial<AuthContextProps>>({});

const AppRouter: FC = () => {
  const { meQuery, authContext } = useAuthContext();
  const token = storageClient.getAuthToken() || sessionStorage.getItem('token');
  const isAuthenticated = Boolean(meQuery.data?.doctorMe.id) && Boolean(token);

  const RouterSwitch = {
    [RouterCase.GUEST]: !isAuthenticated,
    [RouterCase.USER]: isAuthenticated,
  };

  let Router;
  const route = Object.keys(RouterSwitch).find(key => RouterSwitch[key as RouterCase]);

  switch (route) {
    case RouterCase.GUEST:
      Router = <GuestSwitch />;
      break;
    case RouterCase.USER:
      Router = <UserSwitch />;
      break;
    default:
      Router = <GuestSwitch />;
      break;
  }

  return (
    <BrowserRouter>
      <AuthContext.Provider value={authContext}>{Router}</AuthContext.Provider>
    </BrowserRouter>
  );
};

export default AppRouter;
