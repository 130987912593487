import { Form, FormikProvider, useFormik } from 'formik';
import React, { useState } from 'react';
import styled, { css } from 'styled-components';
import * as Yup from 'yup';

import { QuestionType, SurveyTemplateQuestionModel } from '../../../../../../__generated__/types';
import { InformationBlueIcon } from '../../../../../../assets/svg';
import PlusIcon from '../../../../../../assets/svg/plus-icon/PlusIcon';
import { useCreateCustomQuestion } from '../../../../../../common/mutation/__generated__/create-custom-question';
import { GetQuestionsByCategoryAndTitleDocument } from '../../../../../../common/query/__generated__/get-questions-by-category-and-title';
import { Button, Dropdown, Input, Scrollbar, Textarea } from '../../../../../../ui';

type OptionsType = {
  label: string;
  value: QuestionType;
};
const options: OptionsType[] = [
  { label: 'Один вариант ответа', value: QuestionType.Radio },
  { label: 'Несколько вариантов ответа', value: QuestionType.Checkbox },
  { label: 'Числовое значение', value: QuestionType.Numeric },
  { label: 'Шкала', value: QuestionType.Scale },
];

let initialInputs = [
  { placeholder: 'Введите вариант ответа', label: 'Вариант ответа', id: 0, hasDeleteButton: false },
  { placeholder: 'Введите вариант ответа', label: 'Вариант ответа', id: 1, hasDeleteButton: false },
  { placeholder: 'Введите вариант ответа', label: 'Вариант ответа', id: 2, hasDeleteButton: true },
];

type Props = {
  setQuestion: (question: SurveyTemplateQuestionModel) => void;
};

type Values = {
  title?: string;
  selectedType: QuestionType | undefined;
  minValue: number;
  maxValue: number;
  inputValues?: string[];
  value0: string;
  value1: string;
  value2?: string;
  value3?: string;
  value4?: string;
  value5?: string;
};

export const CustomQuestion = ({ setQuestion }: Props) => {
  const [selectedType, setSelectedType] = useState<QuestionType | undefined | null>(undefined);
  const [inputsForRadioCheckboxQuestion, setInputsForRadioCheckboxQuestion] = useState(initialInputs);

  const validationSchema = () => {
    switch (selectedType) {
      case QuestionType.Numeric:
        return Yup.object({
          title: Yup.string().required('Пожалуйста, введите вопрос'),
        });
      case QuestionType.Scale:
        return Yup.object({
          title: Yup.string().required('Пожалуйста, введите вопрос'),
          minValue: Yup.number()
            .lessThan(Yup.ref('maxValue'), 'Значение должно быть меньше максимального')
            .typeError('Пожалуйста, введите числовое значение')
            .required('Пожалуйста, введите значение')
            .min(0, 'Пожалуйста, введите положительное число'),
          maxValue: Yup.number()
            .typeError('Пожалуйста, введите числовое значение')
            .required('Пожалуйста, введите значение')
            .positive('Пожалуйста, введите положительное число'),
        });
      case QuestionType.Radio:
        return Yup.object({
          title: Yup.string().required('Пожалуйста, введите вопрос'),
          value0: Yup.string().required('Пожалуйста, введите значение'),
          value1: Yup.string().required('Пожалуйста, введите значение'),
        });
      case QuestionType.Checkbox:
        return Yup.object({
          title: Yup.string().required('Пожалуйста, введите вопрос'),
          value0: Yup.string().required('Пожалуйста, введите значение'),
          value1: Yup.string().required('Пожалуйста, введите значение'),
        });
    }
  };

  const addAnswerVariant = () => {
    setInputsForRadioCheckboxQuestion([
      ...inputsForRadioCheckboxQuestion,
      {
        placeholder: 'Введите вариант ответа',
        label: 'Вариант ответа',
        id: inputsForRadioCheckboxQuestion[inputsForRadioCheckboxQuestion.length - 1].id + 1,
        hasDeleteButton: true,
      },
    ]);
  };
  const [createCustomQuestion] = useCreateCustomQuestion();
  const resetForm = () => {
    setSelectedType(null);
    formik.resetForm();
  };
  const formik = useFormik({
    initialValues: {
      title: '',
      selectedType: undefined,
      maxValue: 10,
      minValue: 0,
      value0: '',
      value1: '',
    },
    validationSchema: validationSchema(),
    onSubmit: async (values: Values) => {
      if (selectedType === QuestionType.Numeric) {
        const res = await createCustomQuestion({
          variables: {
            input: {
              title: values.title || '',
              type: selectedType,
            },
          },
          refetchQueries: [{ query: GetQuestionsByCategoryAndTitleDocument, variables: { title: values.title } }],
        });
        const question = res.data?.doctorCreateCustomQuestion.question;
        if (!res.data?.doctorCreateCustomQuestion.problem && question) {
          setQuestion({
            question: {
              id: question.id,
              title: question.title,
              type: question.type,
              isCustom: true,
              isActual: question.isActual,
            },
          });
          resetForm();
        }
      }

      if (selectedType === QuestionType.Scale) {
        const res = await createCustomQuestion({
          variables: {
            input: {
              title: values.title || '',
              type: selectedType,
              scaleIndicators: {
                minValue: +values.minValue,
                maxValue: +values.maxValue,
              },
            },
          },
        });
        const question = res.data?.doctorCreateCustomQuestion.question;
        if (!res.data?.doctorCreateCustomQuestion.problem && question) {
          setQuestion({
            question: {
              id: question.id,
              title: question.title,
              type: question.type,
              isCustom: true,
              indicators: {
                scale: {
                  maxValue: question.indicators?.scale?.maxValue || 10,
                  minValue: question.indicators?.scale?.minValue || 0,
                },
              },
              isActual: question.isActual,
            },
            criticalIndicators: {
              scale: {
                value: question.indicators?.scale?.maxValue || 10,
              },
            },
          });
          resetForm();
        }
      }

      if (selectedType === QuestionType.Radio || selectedType === QuestionType.Checkbox) {
        const options = [
          { id: '0', index: 1, text: values.value0 },
          { id: '1', index: 2, text: values.value1 },
          { id: '2', index: 3, text: values.value2 || '' },
          { id: '3', index: 4, text: values.value3 || '' },
          { id: '4', index: 5, text: values.value4 || '' },
          { id: '5', index: 6, text: values.value5 || '' },
        ];
        const res = await createCustomQuestion({
          variables: {
            input: {
              title: values.title || '',
              type: selectedType,
              options: options.map(option => option.text).filter(text => text !== ''),
            },
          },
        });
        const question = res.data?.doctorCreateCustomQuestion.question;
        if (!res.data?.doctorCreateCustomQuestion.problem && question) {
          if (selectedType === QuestionType.Radio) {
            setQuestion({
              question: {
                id: question.id,
                title: question.title,
                type: question.type,
                isCustom: true,
                options: question.options,
                isActual: question.isActual,
              },
              criticalAnswerId: null,
            });
          }
          if (selectedType === QuestionType.Checkbox) {
            setQuestion({
              question: {
                id: question.id,
                title: question.title,
                type: question.type,
                isCustom: true,
                options: question.options,
                isActual: question.isActual,
              },
              criticalAnswersIds: null,
            });
          }
          resetForm();
        }
      }
    },
  });

  const deleteInput = (id: number) => {
    formik.setFieldValue(`value${id}`, undefined);
    setInputsForRadioCheckboxQuestion(inputsForRadioCheckboxQuestion.filter(input => input.id !== id));
  };

  const isSmallWindow = selectedType === QuestionType.Numeric || !selectedType;

  return (
    <Root>
      <ScrollbarWrapper>
        <Scrollbar>
          <QuestionsWrapper $isSmallWindow={isSmallWindow}>
            <FormikProvider value={formik}>
              <Form onSubmit={formik.handleSubmit}>
                <Textarea
                  placeholder="Введите вопрос"
                  label="Свой вопрос"
                  {...formik.getFieldProps('title')}
                  error={formik.touched && formik.errors.title}
                />
                <DropdownWrapper>
                  <Dropdown
                    placeholder="Выбор типа ответов"
                    options={options}
                    width={'406px'}
                    setSelectedValue={setSelectedType}
                    selectedValue={selectedType}
                  />
                </DropdownWrapper>

                {selectedType === QuestionType.Scale && (
                  <ScaleInputs>
                    <ScaleInputWrapper>
                      <Input
                        placeholder="1"
                        label="Мин значение"
                        {...formik.getFieldProps('minValue')}
                        error={formik.touched && formik.errors.minValue}
                      />
                    </ScaleInputWrapper>
                    <ScaleInputWrapper>
                      <Input
                        placeholder="10"
                        label="Макс значение"
                        {...formik.getFieldProps('maxValue')}
                        error={formik.touched && formik.errors.maxValue}
                      />
                    </ScaleInputWrapper>
                  </ScaleInputs>
                )}

                {selectedType === QuestionType.Radio && (
                  <Information>
                    <InfoIconWrapper>
                      <InformationBlueIcon />
                    </InfoIconWrapper>
                    <InformationText>
                      <Text1>Пожалуйста, указывайте варианты ответов в порядке ухудшения симптомов</Text1>
                      <Text2>(Первый вариант ответа - самый слабый симптом)</Text2>
                    </InformationText>
                  </Information>
                )}

                {(selectedType === QuestionType.Radio || selectedType === QuestionType.Checkbox) && (
                  <>
                    {inputsForRadioCheckboxQuestion.map(input => (
                      <InputWrapper key={input.id}>
                        <Input
                          placeholder={input.placeholder}
                          label={`${input.label} ${input.id + 1}`}
                          {...formik.getFieldProps(`value${input.id}`)}
                          error={
                            formik.touched && input.id === 0
                              ? formik.errors.value0
                              : input.id === 1
                              ? formik.errors.value1
                              : ''
                          }
                        />
                        {input.hasDeleteButton && (
                          <DeleteButton onClick={() => deleteInput(input.id)}>Удалить</DeleteButton>
                        )}
                      </InputWrapper>
                    ))}
                    {inputsForRadioCheckboxQuestion.length < 6 && (
                      <AddingAnswerVariant onClick={addAnswerVariant}>
                        <Plus>
                          <PlusIcon />
                        </Plus>
                        <AddingAnswerVariantText>Добавить вариант ответа</AddingAnswerVariantText>
                      </AddingAnswerVariant>
                    )}
                  </>
                )}

                <Button
                  variant="primary"
                  label="Создать"
                  isDisabled={!selectedType || !(formik.isValid && formik.dirty)}
                  type="submit"
                />
              </Form>
            </FormikProvider>
          </QuestionsWrapper>
        </Scrollbar>
      </ScrollbarWrapper>
    </Root>
  );
};

const Root = styled.div`
  height: 100%;
`;
const ScrollbarWrapper = styled.div`
  width: calc(100% + 32px);
  height: 100%;
`;
const QuestionsWrapper = styled.div<{ $isSmallWindow: boolean }>`
  ${({ $isSmallWindow }) => css`
    width: calc(100% - 32px);
    min-height: 40vh;
    padding-bottom: ${$isSmallWindow ? '100' : '31'}px;
  `}
`;
const DropdownWrapper = styled.div`
  margin-bottom: 32px;
`;
const ScaleInputs = styled.div`
  display: flex;
  justify-content: space-between;
`;
const ScaleInputWrapper = styled.div`
  width: 192px;
`;
const Information = styled.div`
  padding: 22px;
  border-radius: 10px;
  background-color: ${({ theme: { colors } }) => colors.blueLight};
  display: flex;
  margin-bottom: 32px;
`;
const InfoIconWrapper = styled.div`
  width: fit-content;
`;
const InformationText = styled.div`
  margin-left: 16px;
  display: flex;
  flex-direction: column;
`;
const Text1 = styled.span`
  color: ${({ theme: { colors } }) => colors.grayDark};
  ${({ theme: { typography } }) => typography.footnote1};
  letter-spacing: -0.165px;
`;
const Text2 = styled(Text1)`
  color: ${({ theme: { colors } }) => colors.grayDarkMode};
`;
const InputWrapper = styled.div`
  position: relative;
`;
const DeleteButton = styled.div`
  cursor: pointer;
  color: ${({ theme: { colors } }) => colors.colorError};
  ${({ theme: { typography } }) => typography.body3};
  position: absolute;
  top: 0;
  right: 0;
`;
const AddingAnswerVariant = styled.div`
  display: flex;
  align-items: center;
  margin: 44px 0 44px 21.5px;
  cursor: pointer;
`;
const Plus = styled.div`
  padding: 7px 5px 3px;
`;
const AddingAnswerVariantText = styled.div`
  color: ${({ theme: { colors } }) => colors.grayDark};
  ${({ theme: { typography } }) => typography.title3};
  margin-left: 12px;
`;
