import _ from 'lodash';
import React, { useEffect, useState } from 'react';
import { RangeModifier } from 'react-day-picker';
import { useHistory, useParams } from 'react-router-dom';
import styled from 'styled-components';
import ModalAlertMessage from 'ui/ModalAlertMessage';

import {
  DrugModel,
  HospitalPatientModel,
  QuestionInput,
  QuestionType,
  SurveyTemplatePeriod,
  SurveyTemplateQuestionModel,
} from '../../__generated__/types';
import { ArrowLeftIcon } from '../../assets/svg';
import { useSendSurveyToPatient } from '../../common/mutation/__generated__/send-survey-to-patient';
import { useGetDrugsFromDB } from '../../common/query/__generated__/get-drugs-from-db';
import { GetPassedSurveyTemplatesForPatientDocument } from '../../common/query/__generated__/get-passed-survey-templates-for-patient';
import { GetPatientDocument } from '../../common/query/__generated__/get-patient';
import { useGetQuestions } from '../../hooks';
import { modalVar } from '../../libs/Apollo';
import { Button, ModalChecked, ModalContainer } from '../../ui';
import { deleteTypename } from '../../utils/deleteTypename';
import { AddingAnswers, NewQuiz } from './components';

export type FullModelType = [DrugModel, SurveyTemplateQuestionModel[]][];

const CreatingQuiz = () => {
  const history = useHistory();
  const goBack = () => history.goBack();

  //modals
  const [isOpenModalSaveSurveyTemplate, setIsOpenModalSaveSurveyTemplate] = useState(false);
  const [isOpenModalSendSurvey, setIsOpenModalSendSurvey] = useState(false);
  const [isModalAlertVisible, setIsModalAlertVisible] = useState(false);

  const openModalSaveSurveyTemplate = () => {
    setIsOpenModalSaveSurveyTemplate(true);
    modalVar({ isOpenModal: true });
  };
  const closeModalSaveSurveyTemplate = () => {
    setIsOpenModalSaveSurveyTemplate(false);
    modalVar({ isOpenModal: false });
  };
  const openModalSendSurvey = () => {
    setIsOpenModalSendSurvey(true);
    modalVar({ isOpenModal: true });
  };
  const closeModalSendSurvey = () => {
    setIsOpenModalSendSurvey(false);
    modalVar({ isOpenModal: false });
  };

  //drugs
  const [addedDrugs, setAddedDrugs] = useState<DrugModel[]>([]);
  const [selectedDrug, setSelectedDrug] = useState<undefined | string>(undefined);
  const [deletingDrugId, setDeletingDrugId] = useState<undefined | string>(undefined);
  const { data: drugs } = useGetDrugsFromDB({
    variables: {
      filter: selectedDrug,
    },
  });

  //questions
  const [addedQuestions, setAddedQuestions] = useState<SurveyTemplateQuestionModel[]>([]);
  const [deletingQuestionId, setDeletingQuestionId] = useState<undefined | string>(undefined);

  const questions = useGetQuestions(selectedDrug !== undefined ? drugs?.drugsSearch[0].id : undefined);

  const setNewQuestion = (question: SurveyTemplateQuestionModel) => {
    const addedQuestionIds = addedQuestions.map(question => question.question?.id);
    if (!addedQuestionIds.includes(question.question?.id)) {
      setFullModel([[{ id: '', name: '' }, [question]], ...fullModel]);
      setCriticalParams([
        ...criticalParams,
        { questionId: question.question?.id, questionType: question.question?.type || QuestionType.Checkbox },
      ]);
    }
  };

  //drugs + questions
  const [fullModel, setFullModel] = useState<FullModelType>([]);
  const drugsFromFullModel = Array.from(new Set(fullModel.map(array => array[0]))).filter(
    item => item.id !== '' && item.name !== '',
  );
  const questionsFromFullModel = fullModel.map(array => array[1]).flat(Infinity);

  const filteredAddedQuestions = _.uniqBy(_.flatten(questionsFromFullModel), 'question.id');
  const addedQuestionIds = addedQuestions.map(question => question.question?.id);

  useEffect(() => {
    if (selectedDrug !== undefined && drugs?.drugsSearch[0] && questions) {
      setFullModel([...fullModel, [drugs?.drugsSearch[0], questions.map(question => ({ question }))]]);
      setSelectedDrug(undefined);
    }

    if (deletingDrugId) {
      const sortingFullModel = fullModel
        .map(array => {
          return array[0].id === deletingDrugId ? undefined : array;
        })
        .filter(array => array !== undefined);
      // @ts-ignore
      setFullModel(sortingFullModel);
      setDeletingDrugId(undefined);
      setAddedDrugs(drugsFromFullModel);
      setAddedQuestions(filteredAddedQuestions);
    }

    if (deletingQuestionId) {
      const sortingFullModel = fullModel.map(array => {
        const questions = array[1].map(({ question }) => question?.id === deletingQuestionId);
        const newArray = [{ id: '', name: '' }, array[1].filter(({ question }) => question?.id !== deletingQuestionId)];
        return questions.includes(true) ? newArray : array;
      });
      // @ts-ignore
      setFullModel(sortingFullModel);
      setAddedDrugs(drugsFromFullModel);
      setAddedQuestions(filteredAddedQuestions);
      setDeletingQuestionId(undefined);
    }
    // eslint-disable-next-line
  }, [questions, drugs?.drugsSearch, deletingDrugId, deletingQuestionId, selectedDrug]);

  useEffect(() => {
    setAddedDrugs(drugsFromFullModel);
    setAddedQuestions(filteredAddedQuestions);
    // eslint-disable-next-line
  }, [fullModel]);

  //critical parameters
  const [criticalParams, setCriticalParams] = useState<QuestionInput[]>([]);

  useEffect(() => {
    const addedQuestionsMapped = addedQuestions.map((question: SurveyTemplateQuestionModel) => {
      return {
        criticalAnswerId: question.criticalAnswerId,
        criticalAnswersIds: question.criticalAnswersIds,
        criticalIndicators: question.criticalIndicators,
        questionId: question.question?.id || '1',
        questionType: question.question?.type || QuestionType.Radio,
      };
    });
    const arr = [...criticalParams, ...addedQuestionsMapped];
    const uniqQuestions = _.uniqBy(arr, 'questionId');
    const questions = _.intersectionBy(uniqQuestions, addedQuestionsMapped, 'questionId');

    setCriticalParams(questions);
    // eslint-disable-next-line
  }, [addedQuestions]);

  //title
  const [quizTitle, setQuizTitle] = useState('Новый опрос');
  const [isSurveyTemplateTitle, setIsSurveyTemplateTitle] = useState(false);
  const setQuizTemplateTitle = () => setIsSurveyTemplateTitle(true);
  useEffect(() => {
    if (addedQuestions.length === 0) {
      setIsSurveyTemplateTitle(false);
    }
  }, [addedQuestions]);

  //save and send survey template
  const { id: patientId } = useParams<{ id: string }>();
  const [period, setPeriod] = useState<SurveyTemplatePeriod | undefined>(undefined);
  const [dateRange, setDateRange] = useState<RangeModifier | undefined>(undefined);
  const [isEmptyPeriod, setIsEmptyPeriod] = useState(false);
  const [isEmptyDateRange, setIsEmptyDateRange] = useState(false);
  const [sendSurveyToPatient, { loading: isSendSurvey }] = useSendSurveyToPatient({
    refetchQueries: [
      { query: GetPassedSurveyTemplatesForPatientDocument, variables: { patientId }, fetchPolicy: 'network-only' },
      { query: GetPatientDocument, variables: { patientId }, fetchPolicy: 'network-only' },
    ],
  });
  const onClickSendSurveyTemplate = async () => {
    if (dateRange === undefined || period === undefined) {
      setIsEmptyDateRange(!dateRange);
      setIsEmptyPeriod(!period);
      return null;
    }
    if (!isEmptyDateRange && !isEmptyPeriod) {
      const questionsCriticalParams = criticalParams.map(param => {
        const indicators = deleteTypename(param.criticalIndicators);
        return { ...param, criticalIndicators: indicators };
      });
      const res = await sendSurveyToPatient({
        variables: {
          input: {
            title: quizTitle,
            drugsIds: addedDrugs.map(drug => drug.id).filter(id => id !== ''),
            patientId,
            period: period || SurveyTemplatePeriod.Everyday,
            questions: questionsCriticalParams,
            startAt: dateRange?.from,
            endAt: dateRange?.to,
            timezoneOffset: new Date().getTimezoneOffset(),
          },
        },
        update: cache => {
          const patient: { doctorFindHospitalPatient: HospitalPatientModel } | null = cache.readQuery({
            query: GetPatientDocument,
            variables: { patientId },
          });
          patient &&
            cache.writeQuery({
              query: GetPatientDocument,
              variables: { patientId },
              data: { doctorFindHospitalPatient: { ...patient.doctorFindHospitalPatient, hasActiveSurvey: true } },
            });
        },
        refetchQueries: [
          { query: GetPassedSurveyTemplatesForPatientDocument, variables: { patientId }, fetchPolicy: 'network-only' },
        ],
      });
      try {
        if (!res.data?.doctorCreatePrivateSurveyTemplate.problem) {
          const surveyId = res.data?.doctorCreatePrivateSurveyTemplate.surveyTemplate?.id;
          openModalSendSurvey();
          setTimeout(() => {
            closeModalSendSurvey();
            history.push(`/patient/${patientId}/${surveyId}`);
          }, 2000);
        }
      } catch {
        setIsModalAlertVisible(true);
      }
    }
  };

  return (
    <Root>
      <Container $isOpenModal={isOpenModalSaveSurveyTemplate || isOpenModalSendSurvey}>
        <Heading>
          <Back>
            <BackIcon onClick={goBack}>
              <ArrowLeftIcon />
            </BackIcon>
            <CurrentPage>Создание опроса</CurrentPage>
          </Back>
          <Button
            variant="primary"
            label="Отправить"
            width={270}
            type="submit"
            isLoading={isSendSurvey}
            onClick={onClickSendSurveyTemplate}
            isDisabled={!dateRange || !period || addedQuestions?.length === 0}
          />
        </Heading>
        <MainContainer>
          <NewQuiz
            addedDrugs={addedDrugs}
            setAddedDrugs={setAddedDrugs}
            addedQuestions={addedQuestions}
            setAddedQuestions={setAddedQuestions}
            quizTitle={quizTitle}
            setQuizTitle={setQuizTitle}
            criticalParams={criticalParams}
            setCriticalParams={setCriticalParams}
            isSurveyTemplateTitle={isSurveyTemplateTitle}
            period={period}
            setPeriod={setPeriod}
            dateRange={dateRange}
            setDateRange={setDateRange}
            isEmptyPeriod={isEmptyPeriod}
            setIsEmptyPeriod={setIsEmptyPeriod}
            isEmptyDateRange={isEmptyDateRange}
            setIsEmptyDateRange={setIsEmptyDateRange}
            openModalSaveSurveyTemplate={openModalSaveSurveyTemplate}
            setDeletingDrugId={setDeletingDrugId}
            setDeletingQuestionId={setDeletingQuestionId}
          />
          <AddingAnswers
            setSelectedDrug={setSelectedDrug}
            setQuestion={setNewQuestion}
            setQuizTitle={setQuizTitle}
            setCriticalParams={setCriticalParams}
            setSurveyTemplateTitle={setQuizTemplateTitle}
            setPeriod={setPeriod}
            addedQuestionIds={addedQuestionIds}
            fullModel={fullModel}
            setFullModel={setFullModel}
          />
        </MainContainer>
      </Container>
      {isOpenModalSendSurvey && (
        <ModalContainer closeModal={closeModalSendSurvey}>
          <ModalChecked text="Опрос отправлен" />
        </ModalContainer>
      )}
      {isOpenModalSaveSurveyTemplate && (
        <ModalContainer closeModal={closeModalSaveSurveyTemplate}>
          <ModalChecked text="Опрос сохранен в шаблоны" />
        </ModalContainer>
      )}
      {isModalAlertVisible && (
        <ModalContainer closeModal={() => setIsModalAlertVisible(false)}>
          <ModalAlertMessage text="Что-то пошло не так" />
        </ModalContainer>
      )}
    </Root>
  );
};

export default React.memo(CreatingQuiz);

const Root = styled.div`
  width: 100%;
`;
const Container = styled.div<{ $isOpenModal: boolean }>`
  width: 100%;
  height: calc(100vh - 100px);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  position: absolute;
  z-index: 1;
  background-color: ${({ theme: { colors } }) => colors.blueExtraLight};
  ${({ $isOpenModal }) => $isOpenModal && `filter: blur(3px)`}
`;
const Heading = styled.div`
  width: 1280px;
  height: 86px;
  display: flex;
  align-items: center;
  justify-content: space-between;
`;
const Back = styled.div`
  display: flex;
  align-items: center;
  justify-content: start;
`;
const BackIcon = styled.div`
  cursor: pointer;
`;
const Title = styled.div`
  color: ${({ theme: { colors } }) => colors.grayDark};
  ${({ theme: { typography } }) => typography.title2};
  line-height: 44px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;
const CurrentPage = styled(Title)`
  margin-left: 16px;
`;
const MainContainer = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: 34px;
  width: 1280px;
  height: calc(100vh - 100px - 120px);
`;
