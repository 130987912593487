export const LANGUAGES = {
  ru: 'ru',
  en: 'en',
  de: 'de',
  kz: 'kz',
} as const;

export const DEFAULT_APP_LANGUAGE = LANGUAGES.ru;

export const TERMS_OF_USE_LINK = 'https://storage.yandexcloud.net/policy-terms/terms.pdf';

export const PRIVACY_POLICY_LINK = 'https://storage.yandexcloud.net/policy-terms/privacy.pdf';
