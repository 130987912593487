import * as Apollo from '@apollo/client';
import { DocumentNode } from 'graphql';

import * as Types from '../../../__generated__/types';

const defaultOptions = {};
export type CreatePatientVariables = Types.Exact<{
  input: Types.PatientCreateInput;
}>;

export type CreatePatient = {
  __typename: 'Mutation';
  patientCreate: {
    __typename: 'PatientCreatePayload';
    problem?: Types.Maybe<
      { __typename: 'ExistEmailProblem'; message: string } | { __typename: 'TooManyRequestsProblem'; message: string }
    >;
  };
};

export const CreatePatientDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'CreatePatient' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'input' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'PatientCreateInput' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'patientCreate' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'input' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'input' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'problem' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'InlineFragment',
                        typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'ExistEmailProblem' } },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [{ kind: 'Field', name: { kind: 'Name', value: 'message' } }],
                        },
                      },
                      {
                        kind: 'InlineFragment',
                        typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'TooManyRequestsProblem' } },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [{ kind: 'Field', name: { kind: 'Name', value: 'message' } }],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode;
export type CreatePatientMutationFn = Apollo.MutationFunction<CreatePatient, CreatePatientVariables>;

/**
 * __useCreatePatient__
 *
 * To run a mutation, you first call `useCreatePatient` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreatePatient` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createPatient, { data, loading, error }] = useCreatePatient({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreatePatient(baseOptions?: Apollo.MutationHookOptions<CreatePatient, CreatePatientVariables>) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<CreatePatient, CreatePatientVariables>(CreatePatientDocument, options);
}
export type CreatePatientHookResult = ReturnType<typeof useCreatePatient>;
export type CreatePatientMutationResult = Apollo.MutationResult<CreatePatient>;
export type CreatePatientMutationOptions = Apollo.BaseMutationOptions<CreatePatient, CreatePatientVariables>;
