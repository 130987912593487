import React from 'react';

function EllipseIcon({ ...rest }) {
  return (
    <svg width="10" height="10" viewBox="0 0 10 10" fill="none" xmlns="http://www.w3.org/2000/svg" {...rest}>
      <circle cx="5" cy="5" r="5" fill="#3380EE" />
    </svg>
  );
}

export default EllipseIcon;
