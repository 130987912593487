import * as React from 'react';
import { SVGProps } from 'react';

const AttentionIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg width={101} height={89} fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      d="m13.045 79-.433.75h75.775l-.433-.75-37.021-64.125-.433-.75-.433.75L13.045 79ZM1.571 81.875l-.432-.25.432.25L46.819 3.5a4.25 4.25 0 0 1 7.361 0L99.43 81.875a4.25 4.25 0 0 1-3.68 6.375H5.25a4.25 4.25 0 0 1-3.68-6.375ZM54.75 65.5V74h-8.5v-8.5h8.5Zm0-33.25V55h-8.5V32.25h8.5Z"
      fill="#3380EE"
      stroke="#fff"
    />
  </svg>
);

export default AttentionIcon;
