import { DocumentNode } from 'graphql';

import * as Types from '../../../__generated__/types';

export type Doctor = {
  __typename: 'DoctorModel';
  id: string;
  role?: Types.Maybe<Types.PatientRole>;
  email?: Types.Maybe<string>;
  language?: Types.Maybe<Types.Language>;
  createdAt?: Types.Maybe<any>;
  deletedAt?: Types.Maybe<any>;
  updatedAt?: Types.Maybe<any>;
  hospital?: Types.Maybe<{ __typename: 'HospitalModel'; id: string; name: string; patientsLimit: number }>;
  notificationsSettings?: Types.Maybe<{
    __typename: 'DoctorNotificationSettingsModel';
    contactMeRequest?: Types.Maybe<boolean>;
    criticalIndicators?: Types.Maybe<boolean>;
    uploadAnalyzesByPatients?: Types.Maybe<boolean>;
  }>;
};

export const Doctor = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'Doctor' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'DoctorModel' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'role' } },
          { kind: 'Field', name: { kind: 'Name', value: 'email' } },
          { kind: 'Field', name: { kind: 'Name', value: 'language' } },
          { kind: 'Field', name: { kind: 'Name', value: 'createdAt' } },
          { kind: 'Field', name: { kind: 'Name', value: 'deletedAt' } },
          { kind: 'Field', name: { kind: 'Name', value: 'updatedAt' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'hospital' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                { kind: 'Field', name: { kind: 'Name', value: 'patientsLimit' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'notificationsSettings' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'contactMeRequest' } },
                { kind: 'Field', name: { kind: 'Name', value: 'criticalIndicators' } },
                { kind: 'Field', name: { kind: 'Name', value: 'uploadAnalyzesByPatients' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode;
