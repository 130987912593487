import * as Apollo from '@apollo/client';
import { DocumentNode } from 'graphql';

import * as Types from '../../../__generated__/types';

const defaultOptions = {};
export type GetUnreadNotificationsCountVariables = Types.Exact<{ [key: string]: never }>;

export type GetUnreadNotificationsCount = { __typename: 'Query'; doctorGetUnreadNotificationsCount: number };

export const GetUnreadNotificationsCountDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'GetUnreadNotificationsCount' },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [{ kind: 'Field', name: { kind: 'Name', value: 'doctorGetUnreadNotificationsCount' } }],
      },
    },
  ],
} as unknown as DocumentNode;

/**
 * __useGetUnreadNotificationsCount__
 *
 * To run a query within a React component, call `useGetUnreadNotificationsCount` and pass it any options that fit your needs.
 * When your component renders, `useGetUnreadNotificationsCount` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetUnreadNotificationsCount({
 *   variables: {
 *   },
 * });
 */
export function useGetUnreadNotificationsCount(
  baseOptions?: Apollo.QueryHookOptions<GetUnreadNotificationsCount, GetUnreadNotificationsCountVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetUnreadNotificationsCount, GetUnreadNotificationsCountVariables>(
    GetUnreadNotificationsCountDocument,
    options,
  );
}
export function useGetUnreadNotificationsCountLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<GetUnreadNotificationsCount, GetUnreadNotificationsCountVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetUnreadNotificationsCount, GetUnreadNotificationsCountVariables>(
    GetUnreadNotificationsCountDocument,
    options,
  );
}
export type GetUnreadNotificationsCountHookResult = ReturnType<typeof useGetUnreadNotificationsCount>;
export type GetUnreadNotificationsCountLazyQueryHookResult = ReturnType<typeof useGetUnreadNotificationsCountLazyQuery>;
export type GetUnreadNotificationsCountQueryResult = Apollo.QueryResult<
  GetUnreadNotificationsCount,
  GetUnreadNotificationsCountVariables
>;
