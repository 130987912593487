import * as Apollo from '@apollo/client';
import { DocumentNode } from 'graphql';

import * as Types from '../../../__generated__/types';
import { Question } from '../../fragments/__generated__/question.fragment';

const defaultOptions = {};
export type CreateCustomQuestionVariables = Types.Exact<{
  input: Types.CreateCustomQuestionInput;
}>;

export type CreateCustomQuestion = {
  __typename: 'Mutation';
  doctorCreateCustomQuestion: {
    __typename: 'CreateCustomQuestionPayload';
    problem?: Types.Maybe<
      { __typename: 'ExistEmailProblem'; message: string } | { __typename: 'TooManyRequestsProblem'; message: string }
    >;
    question?: Types.Maybe<{
      __typename: 'QuestionModel';
      id: string;
      title: string;
      type: Types.QuestionType;
      isCustom: boolean;
      isActual: boolean;
      indicators?: Types.Maybe<{
        __typename: 'QuestionIndicators';
        numeric?: Types.Maybe<{ __typename: 'NumericQuestionIndicators'; maxValue: any; minValue: any }>;
        scale?: Types.Maybe<{ __typename: 'ScaleQuestionIndicators'; maxValue: any; minValue: any }>;
      }>;
      options?: Types.Maybe<Array<{ __typename: 'QuestionOptionModel'; id: string; index: number; text: string }>>;
    }>;
  };
};

export const CreateCustomQuestionDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'CreateCustomQuestion' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'input' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'CreateCustomQuestionInput' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'doctorCreateCustomQuestion' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'input' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'input' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'problem' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'InlineFragment',
                        typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'ExistEmailProblem' } },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [{ kind: 'Field', name: { kind: 'Name', value: 'message' } }],
                        },
                      },
                      {
                        kind: 'InlineFragment',
                        typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'TooManyRequestsProblem' } },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [{ kind: 'Field', name: { kind: 'Name', value: 'message' } }],
                        },
                      },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'question' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'Question' } }],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    ...Question.definitions,
  ],
} as unknown as DocumentNode;
export type CreateCustomQuestionMutationFn = Apollo.MutationFunction<
  CreateCustomQuestion,
  CreateCustomQuestionVariables
>;

/**
 * __useCreateCustomQuestion__
 *
 * To run a mutation, you first call `useCreateCustomQuestion` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateCustomQuestion` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createCustomQuestion, { data, loading, error }] = useCreateCustomQuestion({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateCustomQuestion(
  baseOptions?: Apollo.MutationHookOptions<CreateCustomQuestion, CreateCustomQuestionVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<CreateCustomQuestion, CreateCustomQuestionVariables>(CreateCustomQuestionDocument, options);
}
export type CreateCustomQuestionHookResult = ReturnType<typeof useCreateCustomQuestion>;
export type CreateCustomQuestionMutationResult = Apollo.MutationResult<CreateCustomQuestion>;
export type CreateCustomQuestionMutationOptions = Apollo.BaseMutationOptions<
  CreateCustomQuestion,
  CreateCustomQuestionVariables
>;
