import React from 'react';
import styled from 'styled-components';

function ArrowLeftIcon() {
  return (
    <Root>
      <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
        <Path
          d="M3.828 6.99992H16V8.99992H3.828L9.192 14.3639L7.778 15.7779L0 7.99992L7.778 0.221924L9.192 1.63592L3.828 6.99992Z"
          fill="#777998"
        />
      </svg>
    </Root>
  );
}

export default ArrowLeftIcon;

const Root = styled.div`
  padding: 6px 4px 2px;
`;

const Path = styled.path`
  ${Root}:hover & {
    fill: ${({ theme: { colors } }) => colors.blue};
  }
`;
