import { useEffect, useState } from 'react';
import styled from 'styled-components';

import { DoctorNotificationModel, Maybe } from '../../__generated__/types';
import { useReadAllNotifications } from '../../common/mutation/__generated__/read-all-notifications';
import { useGetNotifications } from '../../common/query/__generated__/get-notifications';
import {
  GetUnreadNotificationsCountDocument,
  useGetUnreadNotificationsCount,
} from '../../common/query/__generated__/get-unread-notifications-count';
import { defaultTheme } from '../../themes';
import { BackBlock, Loader, NotificationItem, Scrollbar } from '../../ui';

export const Notifications = () => {
  const { data, loading, error, fetchMore } = useGetNotifications({
    variables: {
      first: 10,
    },
    fetchPolicy: 'network-only',
  });
  const [cursor, setCursor] = useState<Maybe<string> | undefined>(undefined);
  const [hasNextPage, setHasNextPage] = useState<boolean>(false);
  const [notifications, setNotifications] = useState<DoctorNotificationModel[]>([]);

  useEffect(() => {
    if (data?.doctorGetNotifications) {
      setNotifications(data?.doctorGetNotifications.nodes);
      setCursor(data?.doctorGetNotifications.pageInfo.endCursor);
      setHasNextPage(data?.doctorGetNotifications.pageInfo.hasNextPage);
    }
  }, [data?.doctorGetNotifications]);

  const onLoadMoreNotifications = async () => {
    if (hasNextPage) {
      const { data } = await fetchMore({
        variables: {
          after: cursor,
        },
      });
      setNotifications([...notifications, ...data.doctorGetNotifications.nodes]);
      if (data.doctorGetNotifications.pageInfo.hasNextPage) {
        setCursor(data.doctorGetNotifications.pageInfo.endCursor);
      } else {
        setCursor(undefined);
      }
      setHasNextPage(data.doctorGetNotifications.pageInfo.hasNextPage);
    }
  };

  const { data: dataNotificationsCount } = useGetUnreadNotificationsCount();
  const hasUnreadNotifications = Boolean(dataNotificationsCount?.doctorGetUnreadNotificationsCount);

  const [readAllNotifications] = useReadAllNotifications({
    refetchQueries: [{ query: GetUnreadNotificationsCountDocument }],
  });

  useEffect(() => {
    (async () => {
      if (hasUnreadNotifications) {
        await readAllNotifications();
      }
    })();
  }, [hasUnreadNotifications, readAllNotifications]);

  return (
    <Root>
      <BackBlock title="Уведомления" />
      <Content>
        <MainContainer>
          {(loading || error) && (
            <CenteringWrapper>
              {loading ? (
                <Loader color={defaultTheme.colors.grayDarkMode} />
              ) : (
                <CenteringText>{error?.message}</CenteringText>
              )}
            </CenteringWrapper>
          )}
          {notifications.length > 0 ? (
            <NotificationsBlock>
              <Scrollbar onLoadMore={onLoadMoreNotifications}>
                <NotificationsWrapper>
                  {notifications.map(notification => {
                    return <NotificationItem key={notification.id} notification={notification} />;
                  })}
                </NotificationsWrapper>
              </Scrollbar>
            </NotificationsBlock>
          ) : (
            <CenteringWrapper>
              <CenteringText>Нет уведомлений</CenteringText>
            </CenteringWrapper>
          )}
        </MainContainer>
      </Content>
    </Root>
  );
};

const Root = styled.div`
  width: 100%;
  height: calc(100vh - 100px);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-color: ${({ theme: { colors } }) => colors.blueExtraLight};
`;
const Content = styled.div`
  width: 1280px;
  background-color: ${({ theme: { colors } }) => colors.white};
  box-sizing: border-box;
  border: 1px solid ${({ theme: { colors } }) => colors.blueExtraLight};
  box-shadow: 0 6px 30px #e4ecf7;
  border-radius: 10px;
  padding: 32px;
  height: calc(100vh - 100px - 116px);
  margin-bottom: 32px;
`;
const MainContainer = styled.div`
  width: 100%;
  height: calc(100vh - 100px - 64px - 86px);
`;
const CenteringWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
`;
const CenteringText = styled.div`
  width: 256px;
  text-align: center;
  color: ${({ theme: { colors } }) => colors.grayDarkMode};
  ${({ theme: { typography } }) => typography.title3};
  letter-spacing: -0.165px;
`;
const NotificationsBlock = styled.div`
  width: calc(100% + 32px);
  height: 100%;
`;
const NotificationsWrapper = styled.div`
  width: calc(100% - 32px);
  margin-bottom: 32px;
`;
