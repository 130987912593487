import * as Apollo from '@apollo/client';
import { DocumentNode } from 'graphql';

import * as Types from '../../../__generated__/types';
import { SurveyTemplate } from '../../fragments/__generated__/survey-template.fragment';

const defaultOptions = {};
export type GetPassedSurveyTemplatesForPatientVariables = Types.Exact<{
  patientId: Types.Scalars['UUID'];
}>;

export type GetPassedSurveyTemplatesForPatient = {
  __typename: 'Query';
  doctorFindPatientPrivateSurveyTemplates: Array<{
    __typename: 'SurveyTemplateModel';
    id: string;
    kind: Types.SurveyTemplateKind;
    period: Types.SurveyTemplatePeriod;
    title: string;
    createdAt: any;
    endAt: any;
    startAt: any;
    questionsCount: number;
    timezoneOffset: number;
    drugs: Array<{ __typename: 'DrugModel'; id: string; name: string }>;
    questions: Array<{
      __typename: 'SurveyTemplateQuestionModel';
      criticalAnswerId?: Types.Maybe<any>;
      criticalAnswersIds?: Types.Maybe<Array<any>>;
      criticalIndicators?: Types.Maybe<{
        __typename: 'SurveyTemplateQuestionCriticalIndicators';
        numeric?: Types.Maybe<{
          __typename: 'SurveyTemplateNumericQuestionCriticalIndicators';
          maxValue?: Types.Maybe<any>;
          minValue?: Types.Maybe<any>;
        }>;
        scale?: Types.Maybe<{ __typename: 'SurveyTemplateScaleQuestionCriticalIndicators'; value: any }>;
      }>;
      question?: Types.Maybe<{
        __typename: 'QuestionModel';
        id: string;
        title: string;
        type: Types.QuestionType;
        isCustom: boolean;
        isActual: boolean;
        indicators?: Types.Maybe<{
          __typename: 'QuestionIndicators';
          numeric?: Types.Maybe<{ __typename: 'NumericQuestionIndicators'; maxValue: any; minValue: any }>;
          scale?: Types.Maybe<{ __typename: 'ScaleQuestionIndicators'; maxValue: any; minValue: any }>;
        }>;
        options?: Types.Maybe<Array<{ __typename: 'QuestionOptionModel'; id: string; index: number; text: string }>>;
      }>;
    }>;
  }>;
};

export const GetPassedSurveyTemplatesForPatientDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'GetPassedSurveyTemplatesForPatient' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'patientId' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'UUID' } } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'doctorFindPatientPrivateSurveyTemplates' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'patientId' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'patientId' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'SurveyTemplate' } }],
            },
          },
        ],
      },
    },
    ...SurveyTemplate.definitions,
  ],
} as unknown as DocumentNode;

/**
 * __useGetPassedSurveyTemplatesForPatient__
 *
 * To run a query within a React component, call `useGetPassedSurveyTemplatesForPatient` and pass it any options that fit your needs.
 * When your component renders, `useGetPassedSurveyTemplatesForPatient` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetPassedSurveyTemplatesForPatient({
 *   variables: {
 *      patientId: // value for 'patientId'
 *   },
 * });
 */
export function useGetPassedSurveyTemplatesForPatient(
  baseOptions: Apollo.QueryHookOptions<GetPassedSurveyTemplatesForPatient, GetPassedSurveyTemplatesForPatientVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetPassedSurveyTemplatesForPatient, GetPassedSurveyTemplatesForPatientVariables>(
    GetPassedSurveyTemplatesForPatientDocument,
    options,
  );
}
export function useGetPassedSurveyTemplatesForPatientLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetPassedSurveyTemplatesForPatient,
    GetPassedSurveyTemplatesForPatientVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetPassedSurveyTemplatesForPatient, GetPassedSurveyTemplatesForPatientVariables>(
    GetPassedSurveyTemplatesForPatientDocument,
    options,
  );
}
export type GetPassedSurveyTemplatesForPatientHookResult = ReturnType<typeof useGetPassedSurveyTemplatesForPatient>;
export type GetPassedSurveyTemplatesForPatientLazyQueryHookResult = ReturnType<
  typeof useGetPassedSurveyTemplatesForPatientLazyQuery
>;
export type GetPassedSurveyTemplatesForPatientQueryResult = Apollo.QueryResult<
  GetPassedSurveyTemplatesForPatient,
  GetPassedSurveyTemplatesForPatientVariables
>;
