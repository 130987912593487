import * as Apollo from '@apollo/client';
import { DocumentNode } from 'graphql';

import * as Types from '../../../__generated__/types';
import { Notification } from '../../fragments/__generated__/notification.fragment';

const defaultOptions = {};
export type GetNotificationsVariables = Types.Exact<{
  after?: Types.Maybe<Types.Scalars['String']>;
  first?: Types.Maybe<Types.Scalars['Int']>;
}>;

export type GetNotifications = {
  __typename: 'Query';
  doctorGetNotifications: {
    __typename: 'DoctorNotificationConnection';
    pageInfo: { __typename: 'PageInfo'; hasNextPage: boolean; endCursor?: Types.Maybe<string> };
    nodes: Array<{
      __typename: 'DoctorNotificationModel';
      createdAt: any;
      description: string;
      doctorId: any;
      id: any;
      isRead: boolean;
      kind: Types.DoctorNotificationKind;
      patientId: any;
      title: string;
      extraData?: Types.Maybe<{ __typename: 'DoctorNotificationsExtraData'; surveyTemplateId: string }>;
      hospitalPatient?: Types.Maybe<{
        __typename: 'HospitalPatientModel';
        createdAt: any;
        hospitalId: any;
        doctorId?: Types.Maybe<string>;
        patientId: any;
        medicalCardNumber: string;
        firstName?: Types.Maybe<string>;
        lastName?: Types.Maybe<string>;
        id: any;
        hasActiveSurvey: boolean;
      }>;
    }>;
  };
};

export const GetNotificationsDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'GetNotifications' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'after' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'first' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'Int' } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'doctorGetNotifications' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'after' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'after' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'first' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'first' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'pageInfo' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'hasNextPage' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'endCursor' } },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'nodes' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'Notification' } }],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    ...Notification.definitions,
  ],
} as unknown as DocumentNode;

/**
 * __useGetNotifications__
 *
 * To run a query within a React component, call `useGetNotifications` and pass it any options that fit your needs.
 * When your component renders, `useGetNotifications` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetNotifications({
 *   variables: {
 *      after: // value for 'after'
 *      first: // value for 'first'
 *   },
 * });
 */
export function useGetNotifications(
  baseOptions?: Apollo.QueryHookOptions<GetNotifications, GetNotificationsVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetNotifications, GetNotificationsVariables>(GetNotificationsDocument, options);
}
export function useGetNotificationsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<GetNotifications, GetNotificationsVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetNotifications, GetNotificationsVariables>(GetNotificationsDocument, options);
}
export type GetNotificationsHookResult = ReturnType<typeof useGetNotifications>;
export type GetNotificationsLazyQueryHookResult = ReturnType<typeof useGetNotificationsLazyQuery>;
export type GetNotificationsQueryResult = Apollo.QueryResult<GetNotifications, GetNotificationsVariables>;
