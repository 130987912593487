import { useField } from 'formik';
import React, { useEffect } from 'react';
import styled from 'styled-components';

import { ThemeType, defaultTheme } from '../../../../themes';

type IndicatorProps = {
  isDisabled?: boolean;
  name: string;
  value: string;
  setIsChecked: (isChecked: boolean) => void;
};

function RadioIndicator({ isDisabled = false, name, value, setIsChecked }: IndicatorProps) {
  const [field] = useField({ name, type: 'radio', value });
  const isChecked = field.checked || false;
  const variant = isChecked ? 'checked' : 'notChecked';
  const themeCSS = themes[variant](isDisabled, isChecked, defaultTheme);

  useEffect(() => {
    setIsChecked(isChecked);
  }, [isChecked, setIsChecked]);

  return (
    <RadioInput>
      <Input checked={field.value} disabled={isDisabled} {...field} />
      <RadioControl $isChecked={isChecked} $isDisabled={isDisabled} $themeCSS={themeCSS}>
        {isChecked && <Indicator $isDisabled={isDisabled} />}
      </RadioControl>
    </RadioInput>
  );
}

export default RadioIndicator;

const themes = {
  checked: (isDisabled: boolean, isChecked: boolean, theme: ThemeType) => `
    border: 1px solid ${isDisabled ? theme.colors.grayAverage : theme.colors.blueAverage};
    background: ${theme.colors.blueExtraLight};
  `,
  notChecked: (isDisabled: boolean, isChecked: boolean, theme: ThemeType) => `
    border: 1px solid ${isDisabled ? theme.colors.grayAverage : theme.colors.blueAverage};
    background: ${isDisabled ? theme.colors.blueLight : theme.colors.grayExtraLight};
  `,
};

type StyleProps = {
  $isChecked?: boolean;
  $isDisabled?: boolean;
};
const Input = styled.input.attrs({ type: 'radio' })`
  opacity: 0;
  width: 0;
  height: 0;
`;
const RadioInput = styled.div<StyleProps>`
  display: flex;
`;
const RadioControl = styled.div<StyleProps & { $themeCSS: string }>`
  box-sizing: border-box;
  display: flex;
  width: 28px;
  height: 28px;
  border-radius: 6px;

  ${({ $themeCSS }) => $themeCSS}
  &:hover {
    background: ${({ theme: { colors } }) => colors.blueExtraLight};
    border: 1px solid ${({ theme: { colors } }) => colors.blue};
  }

  ${Input}:focus + & {
    background: ${({ theme: { colors } }) => colors.blueExtraLight};
    border: 1px solid ${({ theme: { colors } }) => colors.blue};
    box-shadow: 0 0 0 4px ${({ theme: { colors } }) => colors.blueAverage};
  }

  ${({ $isDisabled }) =>
    $isDisabled &&
    `
    pointer-events: none;
  `}
`;
const Indicator = styled.div<StyleProps>`
  background: ${({ theme: { colors } }) => colors.blue};
  width: 20px;
  height: 20px;
  border-radius: 6px;
  margin: 3px;

  ${Input}:hover & + {
    background: ${({ theme: { colors } }) => colors.blueDark};
  }

  ${Input}:focus & + {
    background: ${({ theme: { colors } }) => colors.blueAverage};
  }

  ${({ $isDisabled, theme: { colors } }) =>
    $isDisabled &&
    `
    background: ${colors.grayAverage};
    pointer-events: none;
    `}
`;
