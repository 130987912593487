import { useGetCategoryOptions } from 'hooks';
import React, { useState } from 'react';
import styled from 'styled-components';

import {
  Maybe,
  QuestionIndicators,
  QuestionOptionModel,
  QuestionType,
  SurveyTemplateQuestionModel,
} from '../../../../../../__generated__/types';
import { useGetQuestionsByCategoryAndTitle } from '../../../../../../common/query/__generated__/get-questions-by-category-and-title';
import { defaultTheme } from '../../../../../../themes';
import { Dropdown, QuestionListItem, Scrollbar, Search } from '../../../../../../ui';
import Loader from '../../../../../../ui/Loader/Loader';

type Props = {
  setQuestion: (question: SurveyTemplateQuestionModel) => void;
  addedQuestionIds: (string | undefined)[];
};
const category = [{ label: 'Все категории', value: '' }];

export const QuestionsList = ({ setQuestion, addedQuestionIds }: Props) => {
  const addQuestion = (
    id: string,
    title: string,
    type: QuestionType,
    isActual: boolean,
    options?: Maybe<Array<QuestionOptionModel>>,
    indicators?: Maybe<QuestionIndicators>,
  ) => {
    setQuestion({ question: { id, indicators, options, title, type, isCustom: false, isActual } });
  };

  //categories
  const [categoryId, setCategoryId] = useState<string | undefined>(undefined);
  const { optionsCategory, categoriesLoading } = useGetCategoryOptions();

  //questions
  const [inputValue, setInputValue] = useState('');
  const { data: questionsData, loading: questionLoading } = useGetQuestionsByCategoryAndTitle({
    variables: {
      categoryId: categoryId,
      title: inputValue,
    },
  });
  const questions = questionsData?.doctorSearch;
  const optionsCat = category.concat(optionsCategory);
  return (
    <Root>
      <Search placeholder="Поиск вопроса" inputValue={inputValue} setInputValue={setInputValue} />
      <DropdownWrapper>
        <Dropdown
          placeholder={'Выбор категории'}
          options={categoriesLoading ? category : optionsCat}
          width={'406px'}
          isLoading={categoriesLoading}
          setSelectedValue={setCategoryId}
          isSearchable={false}
        />
      </DropdownWrapper>
      {questionLoading ? (
        <LoaderWrapper>
          <Loader color={defaultTheme.colors.grayDarkMode} />
        </LoaderWrapper>
      ) : (
        <ScrollbarWrapper>
          <Scrollbar>
            <QuestionsWrapper>
              {questions?.length === 0 ? (
                <EmptyStateText>Не найдено</EmptyStateText>
              ) : (
                questions?.map((question, index) => {
                  return (
                    <QuestionListItem
                      title={question.title}
                      index={++index}
                      isDisabled={addedQuestionIds.includes(question.id)}
                      addQuestion={() =>
                        addQuestion(
                          question.id,
                          question.title,
                          question.type,
                          question.isActual,
                          question?.options,
                          question?.indicators,
                        )
                      }
                    />
                  );
                })
              )}
            </QuestionsWrapper>
          </Scrollbar>
        </ScrollbarWrapper>
      )}
    </Root>
  );
};

const Root = styled.div`
  width: 100%;
  height: calc(100% - 86px);
`;
const DropdownWrapper = styled.div`
  margin-bottom: 20px;
`;
const LoaderWrapper = styled.div`
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  top: -10px;
`;
const ScrollbarWrapper = styled.div`
  width: calc(100% + 32px);
  height: calc(100% - 37px);
`;
const QuestionsWrapper = styled.div`
  width: calc(100% - 32px);
`;

const EmptyStateText = styled.p`
  color: ${({ theme: { colors } }) => colors.grayDark};
  ${({ theme: { typography } }) => typography.title3};
  line-height: 30px;
  text-align: center;
`;
