import { useQuery } from '@apollo/client';
import React, { useState } from 'react';
import styled from 'styled-components';

import { MeIsAuthDocument } from '../../common/query/__generated__/me-is-auth';
import { modalVar } from '../../libs/Apollo';
import { BackBlock, ModalChecked, ModalContainer } from '../../ui';
import { NotificationsSettings, ProfileSettings } from './components';

export const Profile = () => {
  const [isOpenModalUpdatePassword, setIsOpenModalUpdatePassword] = useState<boolean>();
  const openModalUpdatePassword = () => {
    setIsOpenModalUpdatePassword(true);
    modalVar({ isOpenModal: true });
  };
  const closeModalUpdatePassword = () => {
    setIsOpenModalUpdatePassword(false);
    modalVar({ isOpenModal: false });
  };

  const { data: doctor } = useQuery(MeIsAuthDocument, { fetchPolicy: 'network-only' });
  return (
    <Root>
      <Container>
        <BackBlock title="Настройки"></BackBlock>
        <MainContainer>
          <ProfileSettings openModal={openModalUpdatePassword} email={doctor.doctorMe.email} />
          <NotificationsSettings settings={doctor.doctorMe.notificationsSettings} />
        </MainContainer>
      </Container>

      {isOpenModalUpdatePassword && (
        <ModalContainer closeModal={closeModalUpdatePassword}>
          <ModalChecked text="Пароль успешно изменен" />
        </ModalContainer>
      )}
    </Root>
  );
};

const Root = styled.div`
  width: 100%;
`;
const Container = styled.div`
  width: 100%;
  height: calc(100vh - 100px);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-color: ${({ theme: { colors } }) => colors.blueExtraLight};
`;
// const LangWrapper = styled.div`
//   width: 100%;
//   display: flex;
//   justify-content: flex-end;
// `;
const MainContainer = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: 34px;
  width: 1280px;
  height: calc(100vh - 100px - 120px);
`;
