import { FullModelType } from 'pages/CreatingQuiz/CreatingQuiz';
import { Dispatch, SetStateAction, useState } from 'react';
import { useParams } from 'react-router-dom';
import styled from 'styled-components';

import {
  DrugModel,
  QuestionInput,
  SurveyTemplatePeriod,
  SurveyTemplateQuestionModel,
} from '../../../../../../__generated__/types';
import { useGetDrugsFromDB } from '../../../../../../common/query/__generated__/get-drugs-from-db';
import { useGetSavedSurveyTemplatesForPatient } from '../../../../../../common/query/__generated__/get-saved-survey-templates-for-patient';
import { Dropdown, NewQuestionnaire, Scrollbar } from '../../../../../../ui';

type Props = {
  setSelectedDrug: (value: string | undefined) => void;
  setQuizTitle: (quizTitle: string) => void;
  setCriticalParams: (questions: QuestionInput[]) => void;
  addQuizTemplate: () => void;
  setPeriod: Dispatch<SetStateAction<SurveyTemplatePeriod | undefined>>;
  fullModel: FullModelType;
  setFullModel: Dispatch<SetStateAction<[DrugModel, SurveyTemplateQuestionModel[]][]>>;
};

export const SurveyTemplates = ({
  setSelectedDrug,
  setQuizTitle,
  setCriticalParams,
  addQuizTemplate,
  setPeriod,
  fullModel,
  setFullModel,
}: Props) => {
  const [inputValue, setInputValue] = useState('');
  const [selectedValue, setSelectedValue] = useState<undefined | string>(undefined);

  const { id: patientId } = useParams<{ id: string }>();

  const { data: drugs, loading: drugsLoading } = useGetDrugsFromDB({
    fetchPolicy: 'no-cache',
    variables: {
      filter: inputValue.length > 1 ? inputValue : undefined,
    },
  });
  const { data: templates } = useGetSavedSurveyTemplatesForPatient({
    variables: {
      patientId,
    },
    fetchPolicy: 'network-only',
  });

  const options = drugs?.drugsSearch.map((opt: DrugModel) => ({ label: opt.name, value: opt.name }));

  return (
    <Root>
      <DropdownWrapper>
        <Dropdown
          placeholder="Выбор лекарства"
          options={options}
          width="100%"
          selectedValue={selectedValue}
          setSelectedValue={setSelectedValue}
          inputValue={inputValue}
          setInputValue={setInputValue}
          setSelectedDrug={setSelectedDrug}
          isLoading={drugsLoading}
        />
      </DropdownWrapper>
      <MainWrapper>
        <Title>Сохраненные опросники</Title>
        {templates?.doctorFindPatientPublicSurveyTemplates.length ? (
          <ScrollbarWrapper>
            <Scrollbar>
              <QuestionnaireWrapper>
                {templates?.doctorFindPatientPublicSurveyTemplates.map(questionnaire => {
                  return (
                    <NewQuestionnaire
                      id={questionnaire.id}
                      title={questionnaire.title}
                      questionsCount={questionnaire.questionsCount}
                      createdAt={questionnaire.createdAt}
                      key={questionnaire.id}
                      setQuizTitle={setQuizTitle}
                      setCriticalParams={setCriticalParams}
                      setSurveyTemplateTitle={addQuizTemplate}
                      setPeriod={setPeriod}
                      fullModel={fullModel}
                      setFullModel={setFullModel}
                    />
                  );
                })}
              </QuestionnaireWrapper>
            </Scrollbar>
          </ScrollbarWrapper>
        ) : (
          <CenteringWrapper>
            <CenteringText>Нет опросов</CenteringText>
          </CenteringWrapper>
        )}
      </MainWrapper>
    </Root>
  );
};

const Root = styled.div`
  height: calc(100% - 54px);
`;
const Title = styled.div`
  font-family: 'Lato', sans-serif;
  font-weight: 400;
  font-size: 18px;
  line-height: 26px;
  color: ${({ theme: { colors } }) => colors.grayDark};
`;
const DropdownWrapper = styled.div`
  margin-bottom: 32px;
`;
const MainWrapper = styled.div`
  width: 100%;
  height: calc(100% - 27px);
`;
const ScrollbarWrapper = styled.div`
  width: calc(100% + 32px);
  height: calc(100% - 16px);
`;
const QuestionnaireWrapper = styled.div`
  width: calc(100% - 32px);
  height: 100%;
`;
const CenteringWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  height: calc(100% - 32px);
`;
const CenteringText = styled.div`
  width: 256px;
  text-align: center;
  color: ${({ theme: { colors } }) => colors.grayDarkMode};
  ${({ theme: { typography } }) => typography.title3};
  letter-spacing: -0.165px;
`;
