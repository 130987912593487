import * as Apollo from '@apollo/client';
import { DocumentNode } from 'graphql';

import * as Types from '../../../__generated__/types';
import { Patient } from '../../fragments/__generated__/patient.fragment';

const defaultOptions = {};
export type SearchPatientsVariables = Types.Exact<{
  after?: Types.Maybe<Types.Scalars['String']>;
  filter: Types.Scalars['String'];
  first?: Types.Maybe<Types.Scalars['Int']>;
}>;

export type SearchPatients = {
  __typename: 'Query';
  searchPatient: {
    __typename: 'HospitalsPatientsConnection';
    nodes: Array<{
      __typename: 'HospitalPatientModel';
      createdAt: any;
      hospitalId: any;
      doctorId?: Types.Maybe<string>;
      patientId: any;
      medicalCardNumber: string;
      firstName?: Types.Maybe<string>;
      lastName?: Types.Maybe<string>;
      id: any;
      hasActiveSurvey: boolean;
    }>;
    pageInfo: { __typename: 'PageInfo'; endCursor?: Types.Maybe<string>; hasNextPage: boolean };
  };
};

export const SearchPatientsDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'SearchPatients' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'after' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'filter' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'first' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'Int' } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'searchPatient' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'after' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'after' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'filter' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'filter' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'first' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'first' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'nodes' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'Patient' } }],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'pageInfo' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'endCursor' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'hasNextPage' } },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    ...Patient.definitions,
  ],
} as unknown as DocumentNode;

/**
 * __useSearchPatients__
 *
 * To run a query within a React component, call `useSearchPatients` and pass it any options that fit your needs.
 * When your component renders, `useSearchPatients` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSearchPatients({
 *   variables: {
 *      after: // value for 'after'
 *      filter: // value for 'filter'
 *      first: // value for 'first'
 *   },
 * });
 */
export function useSearchPatients(baseOptions: Apollo.QueryHookOptions<SearchPatients, SearchPatientsVariables>) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<SearchPatients, SearchPatientsVariables>(SearchPatientsDocument, options);
}
export function useSearchPatientsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<SearchPatients, SearchPatientsVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<SearchPatients, SearchPatientsVariables>(SearchPatientsDocument, options);
}
export type SearchPatientsHookResult = ReturnType<typeof useSearchPatients>;
export type SearchPatientsLazyQueryHookResult = ReturnType<typeof useSearchPatientsLazyQuery>;
export type SearchPatientsQueryResult = Apollo.QueryResult<SearchPatients, SearchPatientsVariables>;
