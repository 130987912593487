import React from 'react';
import { Scrollbars } from 'react-custom-scrollbars-2';

type Props = {
  onLoadMore: () => void;
};

const HorizontalScrollbar = ({ onLoadMore, ...props }: Props & ScrollAreaProps) => {
  const handleScrolls = (event: React.UIEvent<HTMLElement>) => {
    // @ts-ignore
    if (event.target.scrollWidth - event.target.offsetWidth - 200 <= event.target.scrollLeft) {
      onLoadMore();
    }
  };
  return <Scrollbars onScroll={handleScrolls}>{props.children}</Scrollbars>;
};

export default HorizontalScrollbar;
