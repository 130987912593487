import React, { useEffect, useState } from 'react';
import styled from 'styled-components';

import { placeholderArray } from './utils/placeholder-array';

const DecimalInput: React.FC<DecimalInputProps> = ({ onChange, error, disabled, variant, ...props }) => {
  const [text, setText] = useState({
    first: '',
    second: '',
  });

  const firstRef = React.createRef<HTMLInputElement>();
  const secondRef = React.createRef<HTMLInputElement>();

  const hasError = !!error;
  const placeholder = placeholderArray(variant);
  const maxLengthFirst = variant === 'temperature' ? 2 : 3;

  useEffect(() => {
    onChange(text.first + text.second);
  }, [onChange, text, maxLengthFirst, secondRef]);

  useEffect(() => {
    if (text.first.length >= maxLengthFirst) {
      secondRef.current?.focus();
    }
  }, [text.first, secondRef, maxLengthFirst]);

  const onBackspace = (e: { key: string }) => {
    if (e.key === 'Backspace') {
      if (text.second.length === 0) {
        firstRef.current?.focus();
      }
    }
  };

  const onChangeFirst = (e: { target: { value: string } }) => {
    setText({
      first: e.target.value,
      second: text.second,
    });
  };

  const onChangeSecond = (e: { target: { value: string } }) => {
    setText({
      first: text.first,
      second: e.target.value,
    });
  };
  return (
    <Root>
      <InputContainer>
        <InputBox
          onChange={onChangeFirst}
          maxLength={maxLengthFirst}
          ref={firstRef}
          $hasError={hasError}
          disabled={disabled}
          placeholder={`${placeholder[0]}`}
        />
        <Mark>{variant === 'range' ? '/' : ','}</Mark>
        <InputBox
          onChange={onChangeSecond}
          onKeyDown={onBackspace}
          maxLength={variant === 'range' ? 3 : 1}
          ref={secondRef}
          $hasError={hasError}
          disabled={disabled}
          placeholder={`${placeholder[1]}`}
        />
      </InputContainer>
      {error && <Error>{`*${error}`}</Error>}
    </Root>
  );
};

export type DecimalInputVariant = 'weight' | 'temperature' | 'range';

export interface DecimalInputProps {
  error?: string;
  disabled?: boolean;
  onChange: (val: string) => void;
  variant: DecimalInputVariant;
}

interface InputBoxProps {
  $hasError?: boolean;
  $isFocus?: boolean;
}

const Root = styled.div`
  display: flex;
  position: relative;
  width: 267px;
  flex-direction: column;
`;

const InputContainer = styled.div`
  display: flex;
  position: relative;
  justify-content: space-between;
`;

const InputBox = styled.input<InputBoxProps>`
  all: unset;
  box-sizing: border-box;
  background-color: ${({ theme: { colors } }) => colors.blueExtraLight};
  border: 1px solid ${({ theme: { colors } }) => colors.blueAverage};
  border-radius: 10px;
  width: 122px;
  height: 56px;
  text-align: center;

  color: ${({ theme: { colors } }) => colors.grayDark};
  ${({ theme: { typography } }) => typography.title2};
  caret-color: ${({ theme: { colors } }) => colors.blue};

  ::placeholder {
    color: ${({ theme: { colors } }) => colors.grayAverage};
  }

  ${({ theme: { colors } }) =>
    `:disabled {
      border: none;
      background-color: ${colors.grayExtraLight};
      color: ${colors.grayAverage}
    }`};

  ${({ theme: { colors } }) => `
    :focus {
      border: 1px solid ${colors.blue}
    }
  `};
  ${({ $hasError, theme: { colors } }) => $hasError && `border: 1px solid ${colors.colorError};`}
`;

const Mark = styled.span`
  color: ${({ theme: { colors } }) => colors.grayDarkMode};
  ${({ theme: { typography } }) => typography.title2};
  line-height: 56px;
`;

const Error = styled.div`
  color: ${({ theme: { colors } }) => `${colors.colorError}`};
  ${({ theme: { typography } }) => typography.body3};
  margin-left: 20px;
  margin-top: 6px;
`;

export default DecimalInput;
