import { Form, Formik } from 'formik';
import { useEffect } from 'react';
import { Redirect, useHistory, useLocation } from 'react-router-dom';
import styled from 'styled-components';

import { Language } from '../../__generated__/types';
import { storageClient } from '../../libs/StorageClient';
import { ROUTES } from '../../routers';
import { Button, LanguageOption } from '../../ui';
import { languages } from '../../utils/constants';

export const LanguageSelection = () => {
  const history = useHistory();
  const setLanguage = (lang: Language) => storageClient.setLanguage(lang);
  const location = useLocation();
  const hash = new URLSearchParams(location.search).get('hash');

  useEffect(() => {
    if (hash) storageClient.setHash(hash);
  }, [hash]);

  if (!hash) {
    return <Redirect to={ROUTES['/sign-in']} />;
  }

  return (
    <Root>
      <Container>
        <Modal>
          <Formik
            initialValues={{
              language: Language.Ru,
            }}
            onSubmit={async () => {
              history.push(ROUTES['/sign-up']);
            }}>
            {({ values }) => {
              setLanguage(values.language);
              return (
                <Form>
                  <Title>Выберите язык</Title>
                  <LanguageBlock>
                    {languages.map((lang, index) => (
                      <LanguageOption key={index} label={lang.label} value={lang.value} />
                    ))}
                  </LanguageBlock>
                  <Button variant="primary" label="Продолжить" type="submit" />
                </Form>
              );
            }}
          </Formik>
        </Modal>
      </Container>
    </Root>
  );
};

const Root = styled.div`
  width: 100%;
`;
const Container = styled.div`
  width: 100%;
  height: calc(100vh - 100px);
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: ${({ theme: { colors } }) => colors.blueExtraLight};
`;
const Modal = styled.div`
  width: 367px;
  background-color: ${({ theme: { colors } }) => colors.white};
  box-sizing: border-box;
  border: 1px solid ${({ theme: { colors } }) => colors.blueExtraLight};
  box-shadow: 0 6px 30px #e4ecf7;
  border-radius: 10px;
  padding: 40px;
  height: fit-content;
`;
const Title = styled.span`
  color: ${({ theme: { colors } }) => colors.grayDark};
  ${({ theme: { typography } }) => typography.title2};
  line-height: 44px;
`;
const LanguageBlock = styled.div`
  margin-top: 25px;
  margin-bottom: 40px;
`;
