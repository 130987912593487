import * as Apollo from '@apollo/client';
import { DocumentNode } from 'graphql';

import * as Types from '../../../__generated__/types';

const defaultOptions = {};
export type MarkAnalyzesAsViewedVariables = Types.Exact<{
  patientId: Types.Scalars['String'];
}>;

export type MarkAnalyzesAsViewed = { __typename: 'Mutation'; doctorMarkPatientAnalyzesAsViewed: boolean };

export const MarkAnalyzesAsViewedDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'MarkAnalyzesAsViewed' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'patientId' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'doctorMarkPatientAnalyzesAsViewed' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'patientId' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'patientId' } },
              },
            ],
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode;
export type MarkAnalyzesAsViewedMutationFn = Apollo.MutationFunction<
  MarkAnalyzesAsViewed,
  MarkAnalyzesAsViewedVariables
>;

/**
 * __useMarkAnalyzesAsViewed__
 *
 * To run a mutation, you first call `useMarkAnalyzesAsViewed` within a React component and pass it any options that fit your needs.
 * When your component renders, `useMarkAnalyzesAsViewed` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [markAnalyzesAsViewed, { data, loading, error }] = useMarkAnalyzesAsViewed({
 *   variables: {
 *      patientId: // value for 'patientId'
 *   },
 * });
 */
export function useMarkAnalyzesAsViewed(
  baseOptions?: Apollo.MutationHookOptions<MarkAnalyzesAsViewed, MarkAnalyzesAsViewedVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<MarkAnalyzesAsViewed, MarkAnalyzesAsViewedVariables>(MarkAnalyzesAsViewedDocument, options);
}
export type MarkAnalyzesAsViewedHookResult = ReturnType<typeof useMarkAnalyzesAsViewed>;
export type MarkAnalyzesAsViewedMutationResult = Apollo.MutationResult<MarkAnalyzesAsViewed>;
export type MarkAnalyzesAsViewedMutationOptions = Apollo.BaseMutationOptions<
  MarkAnalyzesAsViewed,
  MarkAnalyzesAsViewedVariables
>;
