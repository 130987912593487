import { useField } from 'formik';
import React from 'react';
import styled from 'styled-components';

function CheckboxText({ ...props }) {
  const { value, name, type } = props;
  const [field] = useField({ name, type, value });
  const isChecked = field.checked || false;

  return (
    <Root $isChecked={isChecked}>
      <Input checked={field.value.includes(value)} {...field} {...props} type={type} />
      <Text $isChecked={isChecked}>{value}</Text>
    </Root>
  );
}

export type CheckboxTextPropsType = {
  value: string;
  name: string;
};
type StylePropsType = {
  $isChecked?: boolean;
};

export default CheckboxText;

const Root = styled.label<StylePropsType>`
  width: fit-content;
  height: fit-content;
  border-radius: 6px;
  display: flex;
  cursor: pointer;
  overflow: hidden;
  margin-right: 12px;
  margin-bottom: 20px;
  background-color: ${({ $isChecked, theme: { colors } }) => ($isChecked ? colors.blueAverage : colors.grayExtraLight)};
`;
const Input = styled.input`
  opacity: 0;
  width: 0;
  height: 0;
`;
const Text = styled.span<StylePropsType>`
  color: ${({ $isChecked, theme: { colors } }) => ($isChecked ? colors.blue : colors.grayDarkMode)};
  font-family: 'lato', sans-serif;
  overflow: hidden;
  word-wrap: break-word;
  text-overflow: ellipsis;
  font-weight: 400;
  font-size: 16px;
  line-height: 20px;
  margin: 6px 12px;
`;
