import React from 'react';
import styled from 'styled-components';

import { Scrollbar } from '../Scrollbars';

export type AnswerTablePropsType = {
  options:
    | Array<{
        id: string;
        answer: string | undefined;
        createdAt: string;
        isCritical: boolean;
      }>
    | undefined;
  onLoadMoreAnswers?: () => void;
};

function AnswerTable({ options, onLoadMoreAnswers }: AnswerTablePropsType) {
  return (
    <Root>
      <TableHead>
        <HeaderItem>Ответ</HeaderItem>
        <HeaderItem>Дата</HeaderItem>
      </TableHead>
      <Wrapper $optionsLength={options?.length}>
        <Scrollbar onLoadMore={onLoadMoreAnswers}>
          <TableBody>
            {options?.map(option => {
              return (
                <TableRow key={option.id}>
                  <BodyItem $isCritical={option.isCritical}>{option.answer}</BodyItem>
                  <BodyItem $isCritical={option.isCritical}>{option.createdAt}</BodyItem>
                </TableRow>
              );
            })}
          </TableBody>
        </Scrollbar>
      </Wrapper>
    </Root>
  );
}

export default AnswerTable;

const Root = styled.div`
  height: fit-content;
`;
const TableHead = styled.div`
  margin-top: 32px;
  width: 100%;
  display: flex;
`;
const Wrapper = styled.div<{ $optionsLength: number | undefined }>`
  width: calc(100% + 32px);
  height: ${({ $optionsLength }) => ($optionsLength && $optionsLength > 4 ? '200px' : 'fit-content')};
`;
const TableBody = styled.div`
  width: 100%;
  height: 100%;
`;
const HeaderItem = styled.div`
  ${({ theme: { typography } }) => typography.body3};
  color: ${({ theme: { colors } }) => colors.grayDarkMode};
  padding-bottom: 26px;
  text-align: left;

  &:first-child {
    width: 400px;
  }
`;
const TableRow = styled.div`
  padding-bottom: 20px;
  display: flex;

  &:last-child {
    padding-bottom: 32px;
  }
`;
const BodyItem = styled.div<{ $isCritical?: boolean }>`
  ${({ theme: { typography } }) => typography.body1};
  color: ${({ $isCritical, theme: { colors } }) => ($isCritical ? colors.blue : colors.grayDark)};
  box-sizing: border-box;
  padding-right: 10px;

  &:first-child {
    max-width: 400px;
    width: 100%;
    overflow-wrap: break-word;
    white-space: break-spaces;
  }
`;
