import { TextareaAutosize } from '@material-ui/core';
import React from 'react';
import styled from 'styled-components';

type Props = {
  placeholder: string;
  label: string;
  error?: string;
};

function Textarea({ placeholder, label, error, ...props }: Props) {
  return (
    <Root>
      <Label>{label}</Label>
      <CustomTextareaAutosize placeholder={placeholder} {...props} autoFocus $hasError={Boolean(error)} />
      {error && <Error>*{error}</Error>}
    </Root>
  );
}

export default Textarea;

const Root = styled.div`
  margin-bottom: 32px;
`;
const Label = styled.div`
  margin-left: 20px;
  margin-bottom: 6px;
  width: 100%;
  ${({ theme: { typography } }) => typography.body3};
  color: ${({ theme: { colors } }) => colors.grayDarkMode};
`;
const CustomTextareaAutosize = styled(TextareaAutosize)<{ $hasError: boolean }>`
  width: 100%;
  border-radius: 10px;
  background-color: ${({ theme: { colors } }) => colors.blueExtraLight};
  border: 1px solid ${({ theme: { colors } }) => colors.blueAverage};
  box-sizing: border-box;
  ${({ theme: { typography } }) => typography.body3};
  min-height: 56px;
  padding: 15px 20px;
  resize: none;
  outline: none;
  caret-color: ${({ theme: { colors } }) => colors.blue};

  ::placeholder {
    color: ${({ theme: { colors } }) => colors.grayAverage};
  }
  ${({ $hasError, theme: { colors } }) => $hasError && `border-color: ${colors.colorError}`};

  ${({ theme: { colors } }) => `
    :hover {
      border: 1px solid ${colors.blue}
    };
    :focus {
      border: 1px solid ${colors.blue}
    };
  `}
`;
const Error = styled.span`
  margin-left: 20px;
  margin-top: 6px;
  ${({ theme: { typography } }) => typography.body3};
  color: ${({ theme: { colors } }) => colors.colorError};
`;
