import { useEffect, useState } from 'react';
import styled from 'styled-components';

import { useUpdateNotificationSettings } from '../../../../common/mutation/__generated__/update-notification-settings';
import { MeIsAuthDocument } from '../../../../common/query/__generated__/me-is-auth';
import { Toggle } from '../../../../ui';

type Notifications = {
  contactMeRequest: boolean;
  criticalIndicators: boolean;
  uploadAnalyzesByPatients: boolean;
};
type Props = {
  settings: Notifications;
};

export const NotificationsSettings = ({ settings }: Props) => {
  const [notifications, setNotifications] = useState<Notifications>({
    contactMeRequest: settings.contactMeRequest || true,
    criticalIndicators: settings.criticalIndicators || true,
    uploadAnalyzesByPatients: settings.uploadAnalyzesByPatients || true,
  });
  const [updateNotificationSettings] = useUpdateNotificationSettings({
    variables: {
      input: {
        contactMeRequestNotifications: notifications.contactMeRequest,
        criticalIndicatorsNotifications: notifications.criticalIndicators,
        uploadAnalyzesByPatientsNotifications: notifications.uploadAnalyzesByPatients,
      },
    },
    refetchQueries: [{ query: MeIsAuthDocument }],
  });
  const onClickUploadAnalyzesByPatients = async () => {
    await setNotifications({ ...notifications, uploadAnalyzesByPatients: !notifications.uploadAnalyzesByPatients });
    await updateNotificationSettings();
  };
  const onClickContactMeRequest = async () => {
    await setNotifications({ ...notifications, contactMeRequest: !notifications.contactMeRequest });
    await updateNotificationSettings();
  };
  const onClickCriticalIndicators = async () => {
    await setNotifications({ ...notifications, criticalIndicators: !notifications.criticalIndicators });
    await updateNotificationSettings();
  };

  useEffect(() => {
    setNotifications({ ...settings });
    // eslint-disable-next-line
  }, []);

  return (
    <Root>
      <Title>Настройки уведомлений</Title>
      <form>
        <Setting>
          <SettingTitle>Уведомлять меня о загрузке анализов пациентами</SettingTitle>
          <Toggle onClickToggle={onClickUploadAnalyzesByPatients} isChecked={notifications.uploadAnalyzesByPatients} />
        </Setting>
        <Setting>
          <SettingTitle>Уведомлять меня о превышении пациентами критических показателей</SettingTitle>
          <Toggle onClickToggle={onClickCriticalIndicators} isChecked={notifications.criticalIndicators} />
        </Setting>
        <Setting>
          <SettingTitle>Уведомлять меня о запросах пациентов связаться с врачом</SettingTitle>
          <Toggle onClickToggle={onClickContactMeRequest} isChecked={notifications.contactMeRequest} />
        </Setting>
      </form>
    </Root>
  );
};

const Root = styled.div`
  background-color: ${({ theme: { colors } }) => colors.white};
  width: 620px;
  height: 100%;
  box-sizing: border-box;
  border: 1px solid ${({ theme: { colors } }) => colors.blueExtraLight};
  box-shadow: 0 6px 30px #e4ecf7;
  border-radius: 10px;
  padding: 32px;
`;
const Title = styled.div`
  color: ${({ theme: { colors } }) => colors.grayDark};
  ${({ theme: { typography } }) => typography.title2};
  line-height: 44px;
  margin-bottom: 32px;
`;
const Setting = styled.div`
  height: 96px;
  border-bottom: 1px solid ${({ theme: { colors } }) => colors.blueAverage};
  display: flex;
  align-items: center;
  justify-content: space-between;
`;
const SettingTitle = styled.div`
  color: ${({ theme: { colors } }) => colors.grayDark};
  ${({ theme: { typography } }) => typography.body2};
  max-width: 450px;
`;
