import React from 'react';
import { useHistory } from 'react-router-dom';
import styled from 'styled-components';

import { ArrowLeftIcon } from '../../../../assets/svg';
import { IconButton } from '../../../../ui';

type Props = {
  patientName: string | undefined;
  cardNumber: string;
  hasActiveSurvey: boolean;
  analyzesNumber: number;
  patientId: string;
};

export const BackContainer = ({ patientName, cardNumber, hasActiveSurvey, analyzesNumber, patientId }: Props) => {
  const history = useHistory();
  const viewAnalyzes = () => {
    history.push(`/patient/${patientId}/analyzes`);
  };
  return (
    <Root>
      <Container>
        <LeftSide>
          <BackIcon onClick={() => history.goBack()}>
            <ArrowLeftIcon />
          </BackIcon>
          <BackText>{patientName ? `${patientName}, ${cardNumber}` : cardNumber}</BackText>
          {hasActiveSurvey && (
            <SurveyInfo>
              <Text>Проходит опрос</Text>
            </SurveyInfo>
          )}
        </LeftSide>
        <RightSide>
          <IconButton label="Анализы пациента" badgeContent={analyzesNumber} onClick={viewAnalyzes} />
        </RightSide>
      </Container>
    </Root>
  );
};

const Root = styled.header`
  width: 100%;
  display: flex;
  justify-content: center;
  background-color: ${({ theme: { colors } }) => colors.blueExtraLight};
  margin: 20px 0;
`;
const Container = styled.div`
  width: 1280px;
  height: 100%;
  display: flex;
  justify-content: space-between;
`;

const LeftSide = styled.div`
  display: inline-flex;
  align-items: center;
  justify-content: flex-start;
`;
const BackIcon = styled.div`
  display: inline-flex;
  align-items: center;
  position: relative;
  left: 0;
`;
const BackText = styled.div`
  margin-left: 16px;
  color: ${({ theme: { colors } }) => colors.grayDark};
  ${({ theme: { typography } }) => typography.title2};
  line-height: 44px;
`;
const SurveyInfo = styled.div`
  width: fit-content;
  height: 32px;
  border-radius: 6px;
  display: flex;
  background-color: ${({ theme: { colors } }) => colors.blueAverage};
  margin-left: 20px;
`;
const Text = styled.span`
  color: ${({ theme: { colors } }) => colors.blue};
  font-family: 'lato', sans-serif;
  font-weight: 400;
  font-size: 16px;
  line-height: 20px;
  margin: 6px 15px;
`;

const RightSide = styled.div`
  display: inline-flex;
  align-items: center;
  padding: 10px;
`;
