import React from 'react';
import styled from 'styled-components';

import { FileTextBigIcon, VerticalLineIcon } from '../../assets/svg';
import { AnalysisType } from '../../pages/Analyzes';
import { defaultTheme } from '../../themes';

type Props = {
  data: AnalysisType;
};

function Analysis({ data }: Props) {
  const { createdAt, fileKey, filename } = data;
  return (
    <Root>
      <Wrapper href={fileKey} target="_blank">
        <FileTextBigIcon />
        <Vertical color={defaultTheme.colors.grayAverage} />
        <FileData>
          <FileName>{filename}</FileName>
          <UploadDate>{new Date(createdAt).toLocaleDateString()}</UploadDate>
        </FileData>
      </Wrapper>
    </Root>
  );
}

export default Analysis;

const Root = styled.div`
  width: 100%;
  max-width: 1190px;
  height: 96px;
  box-sizing: border-box;
  border-radius: 10px;
  background-color: ${({ theme: { colors } }) => colors.grayExtraLight};
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 20px;
  margin-bottom: 22px;

  &:hover {
    border: 1px solid ${({ theme: { colors } }) => colors.blueDarkMode};
    padding: 0 19px;
  }
`;
const Wrapper = styled.a`
  display: flex;
  align-items: center;
  text-decoration: none;
`;
const Vertical = styled(VerticalLineIcon)`
  margin: 0 16px;
`;
const FileData = styled.div`
  display: flex;
  flex-direction: column;
`;
const FileName = styled.span`
  color: ${({ theme: { colors } }) => colors.grayDark};
  ${({ theme: { typography } }) => typography.title3};
  margin: 0 20px 2px 0;
`;
const UploadDate = styled.span`
  color: ${({ theme: { colors } }) => colors.grayDarkMode};
  ${({ theme: { typography } }) => typography.body2};
`;
