import React from 'react';
import styled from 'styled-components';

import { ThemeType, defaultTheme } from '../../themes';
import Loader from '../Loader/Loader';

const Button: React.VFC<ButtonProps> = ({
  label,
  type = 'button',
  isDisabled = false,
  isLoading = false,
  variant = 'primary',
  ...props
}) => {
  let _loading = isLoading;

  if (isDisabled) {
    _loading = false;
  }
  const themeCSS = themes[variant](isDisabled, isLoading, defaultTheme);

  return (
    <Root
      disabled={isDisabled || isLoading}
      $isDisabled={isDisabled}
      $isLoading={_loading}
      type={type}
      variant={variant}
      $themeCSS={themeCSS}
      $width={props.width}
      {...props}>
      {_loading ? (
        <Loader color={variant === 'primary' ? defaultTheme.colors.white : defaultTheme.colors.grayDarkMode} />
      ) : (
        label
      )}
    </Root>
  );
};

export interface ButtonProps {
  variant: ButtonVariant;
  label: string;
  type?: ButtonType;
  isDisabled?: boolean;
  isLoading?: boolean;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  onClick?: (...args: any[]) => any;
  width?: number;
}

export type ButtonVariant = 'primary' | 'secondary';

export type ButtonType = 'button' | 'reset' | 'submit';

interface RootProps extends Omit<ButtonProps, 'isDisabled' | 'isLoading' | 'label' | 'onClick'> {
  $isLoading: boolean;
  $isDisabled: boolean;
  $themeCSS: string;
  $width?: number;
}

const themes = {
  primary: (isDisabled: boolean, isLoading: boolean, theme: ThemeType) => `
      color: ${theme.colors.white};
      background-color: ${theme.colors.blue};
      &:hover {
        background-color: ${!isDisabled && !isLoading && theme.colors.blueDark}
      };
    `,
  secondary: (isDisabled: boolean, isLoading: boolean, theme: ThemeType) => `
      color: ${theme.colors.grayDarkMode};
      padding: ${!isDisabled && !isLoading && '11px 17px'};
      border: ${!isDisabled && `1px solid ${theme.colors.blueAverage}`};
      background-color: ${theme.colors.white};
      &:hover {
        border: ${!isDisabled && !isLoading && `1px solid ${theme.colors.blue}`};
      };
    `,
};

const Root = styled.button<RootProps>`
  width: ${({ $width }) => ($width ? `${$width}px` : '100%')};
  height: 54px;
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.05);
  box-sizing: border-box;
  border-radius: 10px;
  border: none;
  padding: 12px 17px;
  transition: 0.3s;
  ${({ theme: { typography } }) => typography.title3}
  cursor: ${({ $isLoading, $isDisabled }) => {
    return $isDisabled || $isLoading ? 'default' : 'pointer';
  }};
  ${({ $themeCSS }) => $themeCSS}

  color: ${({ $isDisabled, theme: { colors } }) => $isDisabled && colors.white};
  background-color: ${({ $isDisabled, theme: { colors } }) => $isDisabled && colors.grayAverage};
`;

export default Button;
