import React from 'react';

function CheckIcon() {
  return (
    <svg width="114" height="114" viewBox="0 0 114 114" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M47.5 72.0669L91.162 28.4001L97.8833 35.1166L47.5 85.4999L17.271 55.2709L23.9875 48.5544L47.5 72.0669Z"
        fill="#3380EE"
      />
    </svg>
  );
}

export default CheckIcon;
