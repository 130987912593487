import React from 'react';
import styled from 'styled-components';

import { ButtonFileIcon } from '../../assets/svg';
import { defaultTheme } from '../../themes';
import Loader from '../Loader/Loader';
import { Badge } from './components';

const IconButton: React.VFC<IconButtonProps> = ({ label, badgeContent, isDisabled, isLoading, onClick }) => {
  let _loading = isLoading;

  if (isDisabled) {
    _loading = false;
  }

  return (
    <Root $isDisabled={isDisabled} onClick={onClick}>
      {_loading ? (
        <Loader color={defaultTheme.colors.grayDarkMode} />
      ) : (
        <>
          <ButtonFileIcon color={isDisabled ? defaultTheme.colors.grayAverage : defaultTheme.colors.blue} />
          <Label>{label}</Label>
          <Badge badgeContent={badgeContent} isDisabled={isDisabled} isLoading={_loading} />
        </>
      )}
    </Root>
  );
};

export interface IconButtonProps {
  label: string;
  badgeContent?: number;
  isDisabled?: boolean;
  isLoading?: boolean;
  onClick?: () => void;
}

export interface RootProps {
  $isDisabled?: boolean;
}

const Root = styled.button<RootProps>`
  all: unset;
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 0;
  ${({ theme: { typography } }) => typography.title3}
  color: ${({ $isDisabled, theme: { colors } }) => ($isDisabled ? colors.grayAverage : colors.grayDark)};
  width: inherit;
  justify-content: flex-end;
  cursor: pointer;

  &:hover {
    color: ${({ $isDisabled, theme: { colors } }) => !$isDisabled && colors.blue};
  }
`;

const Label = styled.span`
  margin-left: 15px;
`;

export default IconButton;
