import * as Apollo from '@apollo/client';
import { DocumentNode } from 'graphql';

import * as Types from '../../../__generated__/types';
import { AnswerValue } from '../../fragments/__generated__/answer-value.fragment';

const defaultOptions = {};
export type GetQuestionAnswersVariables = Types.Exact<{
  endAt?: Types.Maybe<Types.Scalars['DateTime']>;
  patientId: Types.Scalars['UUID'];
  questionId: Types.Scalars['UUID'];
  startAt?: Types.Maybe<Types.Scalars['DateTime']>;
  surveyTemplateId?: Types.Maybe<Types.Scalars['String']>;
  after?: Types.Maybe<Types.Scalars['String']>;
  take?: Types.Maybe<Types.Scalars['Int']>;
}>;

export type GetQuestionAnswers = {
  __typename: 'Query';
  doctorFindPatientQuestionAnswers: {
    __typename: 'DoctorPatientSurveyAnswerConnection';
    pageInfo: { __typename: 'PageInfo'; endCursor?: Types.Maybe<string>; hasNextPage: boolean };
    nodes: Array<{
      __typename: 'SurveyAnswerModel';
      id: string;
      isCritical: boolean;
      questionId: any;
      surveyId: any;
      createdAt: any;
      answerQuestionOptionId?: Types.Maybe<any>;
      answerQuestionOptionsIds?: Types.Maybe<Array<any>>;
      answerQuestionOption?: Types.Maybe<{
        __typename: 'QuestionOptionModel';
        id: string;
        index: number;
        text: string;
      }>;
      answerQuestionOptions?: Types.Maybe<
        Array<{ __typename: 'QuestionOptionModel'; id: string; index: number; text: string }>
      >;
      answerValue?: Types.Maybe<{
        __typename: 'SurveyAnswerValue';
        numeric?: Types.Maybe<{ __typename: 'SurveyNumericAnswerValue'; value: number }>;
        pressure?: Types.Maybe<{ __typename: 'SurveyPressureAnswerValue'; lowerValue: number; upperValue: number }>;
        pulse?: Types.Maybe<{ __typename: 'SurveyPulseAnswerValue'; value: number }>;
        scale?: Types.Maybe<{ __typename: 'SurveyScaleAnswerValue'; value: number }>;
        temperature?: Types.Maybe<{ __typename: 'SurveyTemperatureAnswerValue'; value: number }>;
        weight?: Types.Maybe<{ __typename: 'SurveyWeightAnswerValue'; value: number }>;
      }>;
    }>;
  };
};

export const GetQuestionAnswersDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'GetQuestionAnswers' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'endAt' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'DateTime' } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'patientId' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'UUID' } } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'questionId' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'UUID' } } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'startAt' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'DateTime' } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'surveyTemplateId' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'after' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'take' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'Int' } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'doctorFindPatientQuestionAnswers' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'endAt' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'endAt' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'patientId' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'patientId' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'questionId' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'questionId' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'startAt' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'startAt' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'surveyTemplateId' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'surveyTemplateId' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'after' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'after' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'take' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'take' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'pageInfo' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'endCursor' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'hasNextPage' } },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'nodes' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'isCritical' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'questionId' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'surveyId' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'createdAt' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'answerQuestionOption' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'index' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'text' } },
                          ],
                        },
                      },
                      { kind: 'Field', name: { kind: 'Name', value: 'answerQuestionOptionId' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'answerQuestionOptions' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'index' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'text' } },
                          ],
                        },
                      },
                      { kind: 'Field', name: { kind: 'Name', value: 'answerQuestionOptionsIds' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'answerValue' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'AnswerValue' } }],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    ...AnswerValue.definitions,
  ],
} as unknown as DocumentNode;

/**
 * __useGetQuestionAnswers__
 *
 * To run a query within a React component, call `useGetQuestionAnswers` and pass it any options that fit your needs.
 * When your component renders, `useGetQuestionAnswers` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetQuestionAnswers({
 *   variables: {
 *      endAt: // value for 'endAt'
 *      patientId: // value for 'patientId'
 *      questionId: // value for 'questionId'
 *      startAt: // value for 'startAt'
 *      surveyTemplateId: // value for 'surveyTemplateId'
 *      after: // value for 'after'
 *      take: // value for 'take'
 *   },
 * });
 */
export function useGetQuestionAnswers(
  baseOptions: Apollo.QueryHookOptions<GetQuestionAnswers, GetQuestionAnswersVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetQuestionAnswers, GetQuestionAnswersVariables>(GetQuestionAnswersDocument, options);
}
export function useGetQuestionAnswersLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<GetQuestionAnswers, GetQuestionAnswersVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetQuestionAnswers, GetQuestionAnswersVariables>(GetQuestionAnswersDocument, options);
}
export type GetQuestionAnswersHookResult = ReturnType<typeof useGetQuestionAnswers>;
export type GetQuestionAnswersLazyQueryHookResult = ReturnType<typeof useGetQuestionAnswersLazyQuery>;
export type GetQuestionAnswersQueryResult = Apollo.QueryResult<GetQuestionAnswers, GetQuestionAnswersVariables>;
