import React from 'react';
import { NavbarElementProps } from 'react-day-picker';
import styled from 'styled-components';

import { ArrowDownIcon } from '../../../../assets/svg';

function Navbar({ onPreviousClick, onNextClick, localeUtils, month, locale }: NavbarElementProps) {
  const date = localeUtils.formatMonthTitle(month, locale);

  return (
    <Root>
      <Button type="button" onClick={() => onPreviousClick()}>
        <LeftIcon />
      </Button>
      <DateDisplay>{date}</DateDisplay>
      <Button type="button" onClick={() => onNextClick()}>
        <RightIcon />
      </Button>
    </Root>
  );
}

const Root = styled.div`
  display: flex;
  align-items: center;
  padding: 0;
  margin: 0;
`;

const Button = styled.button`
  border: none;
  cursor: pointer;
  background-color: white;
`;

const Icon = styled(ArrowDownIcon)`
  transition: 0.3s ease-out;
`;

const LeftIcon = styled(Icon)`
  transform: rotate(90deg);
  margin-left: 15px;
`;

const RightIcon = styled(Icon)`
  transform: rotate(-90deg);
  margin-right: 15px;
`;

const DateDisplay = styled.p`
  flex-grow: 1;
  text-align: center;
  ${({ theme: { typography } }) => typography.body2};
  color: ${({ theme: { colors } }) => colors.grayDark};
  margin: 0;
`;

export default Navbar;
