import React from 'react';

function NotVisibleIcon({ color }: React.SVGProps<SVGSVGElement>) {
  return (
    <svg width="22" height="22" viewBox="0 0 22 22" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M16.8817 18.2971C15.1229 19.4126 13.0824 20.0034 10.9997 20.0001C5.60766 20.0001 1.12166 16.1201 0.180664 11.0001C0.610694 8.67078 1.78229 6.54296 3.52066 4.93407L0.391664 1.80807L1.80666 0.393066L21.6057 20.1931L20.1907 21.6071L16.8807 18.2971H16.8817ZM4.93466 6.35007C3.57567 7.58566 2.62898 9.20885 2.22266 11.0001C2.53496 12.3665 3.16193 13.6412 4.05367 14.7227C4.94541 15.8041 6.07729 16.6625 7.35922 17.2294C8.64114 17.7963 10.0377 18.0561 11.4378 17.9882C12.8378 17.9203 14.2026 17.5264 15.4237 16.8381L13.3957 14.8101C12.5324 15.3539 11.5099 15.5882 10.4959 15.4745C9.48194 15.3608 8.53671 14.906 7.81523 14.1845C7.09376 13.463 6.63889 12.5178 6.52523 11.5038C6.41156 10.4899 6.64585 9.46738 7.18966 8.60407L4.93466 6.35007ZM11.9137 13.3281L8.67166 10.0861C8.49373 10.539 8.45185 11.034 8.55117 11.5104C8.65049 11.9868 8.88668 12.4238 9.23078 12.7679C9.57488 13.1121 10.0119 13.3482 10.4883 13.4476C10.9647 13.5469 11.4597 13.505 11.9127 13.3271L11.9137 13.3281ZM19.8067 15.5921L18.3757 14.1621C19.0441 13.2094 19.5201 12.1353 19.7767 11.0001C19.5049 9.80979 18.9939 8.68721 18.2748 7.70056C17.5557 6.71391 16.6435 5.88379 15.5935 5.26067C14.5436 4.63755 13.378 4.23443 12.1674 4.07583C10.9568 3.91723 9.72667 4.00645 8.55166 4.33807L6.97366 2.76007C8.22066 2.27007 9.57966 2.00007 10.9997 2.00007C16.3917 2.00007 20.8777 5.88007 21.8187 11.0001C21.5122 12.6658 20.8235 14.2376 19.8067 15.5921ZM10.7227 6.50807C11.3592 6.46873 11.9968 6.56513 12.5932 6.79088C13.1896 7.01663 13.7313 7.36658 14.1822 7.81752C14.6332 8.26846 14.9831 8.81009 15.2088 9.40652C15.4346 10.003 15.531 10.6406 15.4917 11.2771L10.7217 6.50807H10.7227Z"
        fill={color}
      />
    </svg>
  );
}

export default NotVisibleIcon;
