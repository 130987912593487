import { useGetDrugQuestions } from '../common/query/__generated__/get-drug-questions';

export const useGetQuestions = (id: string | undefined) => {
  const { data: questions } = useGetDrugQuestions({
    variables: {
      id,
    },
    fetchPolicy: 'no-cache',
  });
  return questions?.drugFindQuestions;
};
