import { FieldProps, useField } from 'formik';
import * as React from 'react';
import { ReactElement, useEffect } from 'react';
import Select, { CommonProps } from 'react-select';
import { Option } from 'react-select/src/filters';
import styled from 'styled-components';

import { Language } from '../../../../../__generated__/types';
import { LangIconDE, LangIconEN, LangIconKZ, LangIconRU } from '../../../../../assets/svg';
import { useUpdateLanguage } from '../../../../../common/mutation/__generated__/update-language';

// @ts-ignore
type Props = FieldProps & CommonProps & { initialLanguage: Language };

export default function SelectField(props: Props): ReactElement {
  const [updateLanguage] = useUpdateLanguage();
  const { field, options, initialLanguage } = props;
  const [, , helpers] = useField(field.name);
  const { setValue } = helpers;

  useEffect(() => {
    if (field.value.value) {
      updateLanguage({
        variables: {
          input: {
            language: field.value.value,
          },
        },
      });
    }
  }, [field.value.value, updateLanguage]);
  const onChangeHandler = (selectedOption: Option) => {
    setValue(selectedOption);
  };
  const setFlag = (lang: Language) => {
    switch (lang) {
      case Language.Ru:
        return <LangIconRU />;
      case Language.En:
        return <LangIconEN />;
      case Language.De:
        return <LangIconDE />;
      case Language.Kz:
        return <LangIconKZ />;
    }
  };

  return (
    <Root>
      {setFlag(initialLanguage)}
      <CustomSelect
        {...field}
        options={options}
        name={field.name}
        value={options ? options.find((option: { value: string }) => option.value === field.value) : ''}
        onChange={onChangeHandler}
        onBlur={field.onBlur}
        classNamePrefix={'Select'}
      />
    </Root>
  );
}
const Root = styled.div`
  display: flex;
  align-items: center;
  padding-left: 10px;
`;
const CustomSelect = styled(Select)`
  .Select__indicator-separator {
    width: 0;
  }

  .Select__indicator {
    padding: 0;
    color: ${({ theme: { colors } }) => colors.grayDarkMode};
  }

  .Select__control {
    background-color: ${({ theme: { colors } }) => colors.blueExtraLight};
    border: none;
    ${({ theme: { typography } }) => typography.body2};
    letter-spacing: -0.165px;
    width: 144px;
    height: fit-content;
    box-shadow: none;
    padding: 0 2px 0 16px;

    &:hover {
      border: none;
    }
  }

  .Select__value-container {
    padding: 0;
  }

  .Select__control--is-focused {
    border: none;
  }

  .Select__control--menu-is-open {
    border: none;
  }

  .Select__placeholder {
    color: ${({ theme: { colors } }) => colors.grayDarkMode};
    caret-color: ${({ theme: { colors } }) => colors.blue};
  }

  .Select__menu {
    background-color: ${({ theme: { colors } }) => colors.white};
    border: 1px solid ${({ theme: { colors } }) => colors.blueLight};
    border-radius: 10px;
    box-shadow: none;
  }

  .Select__menu-list {
    padding: 0;
    ${({ theme: { typography } }) => typography.body3};
    color: ${({ theme: { colors } }) => colors.grayDark};
  }

  .Select__option {
    background-color: transparent;

    &:first-child {
      border-top-left-radius: 10px;
      border-top-right-radius: 10px;
    }

    &:last-child {
      border-bottom-left-radius: 10px;
      border-bottom-right-radius: 10px;
    }

    &:hover {
      background-color: ${({ theme: { colors } }) => colors.blueLight};
    }
  }

  .Select__option--is-selected {
    color: ${({ theme: { colors } }) => colors.grayDark};
    background-color: ${({ theme: { colors } }) => colors.blueLight};
  }
`;
