import React from 'react';
import styled from 'styled-components';

import { CrossIcon } from '../../assets/svg';
import { defaultTheme } from '../../themes';

type Props = {
  title: string;
  deleteDrug?: () => void;
};

function QuizTitleItem({ title, deleteDrug }: Props) {
  return (
    <Root>
      <Title>{title}</Title>
      <DeleteIcon onClick={deleteDrug}>
        <CrossIcon color={defaultTheme.colors.blue} />
      </DeleteIcon>
    </Root>
  );
}

export default QuizTitleItem;

const Root = styled.div`
  border-radius: 6px;
  background-color: ${({ theme: { colors } }) => colors.blueAverage};
  width: fit-content;
  padding: 4px 15px;
  display: flex;
  align-items: center;
  margin: 0 10px 10px 0;
`;
const Title = styled.span`
  color: ${({ theme: { colors } }) => colors.blue};
  ${({ theme: { typography } }) => typography.body1};
  letter-spacing: -0.165px;
  line-height: 20px;
`;
const DeleteIcon = styled.div`
  margin-left: 6px;
  padding: 5px 5px 1px;
  cursor: pointer;
`;
