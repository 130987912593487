import * as Apollo from '@apollo/client';
import { DocumentNode } from 'graphql';

import * as Types from '../../../__generated__/types';

const defaultOptions = {};
export type UpdatePasswordVariables = Types.Exact<{
  input: Types.DoctorPasswordUpdateInput;
}>;

export type UpdatePassword = {
  __typename: 'Mutation';
  doctorPasswordUpdate: {
    __typename: 'DoctorPasswordUpdatePayload';
    token?: Types.Maybe<string>;
    refreshToken?: Types.Maybe<string>;
    problem?: Types.Maybe<{ __typename: 'PasswordsNotMatchProblem'; message: string }>;
  };
};

export const UpdatePasswordDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'UpdatePassword' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'input' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'DoctorPasswordUpdateInput' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'doctorPasswordUpdate' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'input' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'input' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'problem' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [{ kind: 'Field', name: { kind: 'Name', value: 'message' } }],
                  },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'token' } },
                { kind: 'Field', name: { kind: 'Name', value: 'refreshToken' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode;
export type UpdatePasswordMutationFn = Apollo.MutationFunction<UpdatePassword, UpdatePasswordVariables>;

/**
 * __useUpdatePassword__
 *
 * To run a mutation, you first call `useUpdatePassword` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdatePassword` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updatePassword, { data, loading, error }] = useUpdatePassword({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdatePassword(baseOptions?: Apollo.MutationHookOptions<UpdatePassword, UpdatePasswordVariables>) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<UpdatePassword, UpdatePasswordVariables>(UpdatePasswordDocument, options);
}
export type UpdatePasswordHookResult = ReturnType<typeof useUpdatePassword>;
export type UpdatePasswordMutationResult = Apollo.MutationResult<UpdatePassword>;
export type UpdatePasswordMutationOptions = Apollo.BaseMutationOptions<UpdatePassword, UpdatePasswordVariables>;
