import React from 'react';
import { useHistory } from 'react-router-dom';
import styled from 'styled-components';

import { useGetSurveyAnswers } from '../../../../common/query/__generated__/get-survey-answers';
import { modalVar } from '../../../../libs/Apollo';
import { defaultTheme } from '../../../../themes';
import { Button, Loader, Scrollbar } from '../../../../ui';
import { AnswerItem } from './components';

type Props = {
  hasActiveSurvey: boolean;
  setIsOpenModalStopSurvey: (isOpenModalStopSurvey: boolean) => void;
  setIsOpenModalCreateNewSurvey: (isOpenModalCreateNewSurvey: boolean) => void;
  patientId: string;
  surveyId: string | undefined;
  hasCompletedSurveys: boolean;
};

export const SurveyDetails = ({
  hasActiveSurvey,
  setIsOpenModalStopSurvey,
  setIsOpenModalCreateNewSurvey,
  patientId,
  surveyId,
  hasCompletedSurveys,
}: Props) => {
  const {
    data: dataAnswers,
    loading: loadingAnswers,
    error: errorAnswers,
  } = useGetSurveyAnswers({
    variables: {
      patientId,
      surveyTemplateId: surveyId,
    },
    fetchPolicy: 'network-only',
    pollInterval: 100000,
  });
  const surveyAnswers = dataAnswers?.doctorFindPatientSurveyAnswers;

  //modals
  const history = useHistory();
  const createNewSurvey = () => {
    history.push(`/patient/${patientId}/create-quiz`);
  };
  const openModalCreateNewSurvey = () => {
    if (hasActiveSurvey) {
      setIsOpenModalCreateNewSurvey(true);
      modalVar({ isOpenModal: true });
    } else {
      createNewSurvey();
    }
  };
  const openModalStopSurvey = () => {
    setIsOpenModalStopSurvey(true);
    modalVar({ isOpenModal: true });
  };

  return (
    <Root>
      <ButtonsBlock>
        <Button variant="primary" label="Создать новый опрос" width={270} onClick={openModalCreateNewSurvey} />
        <EmptyDiv />
        {hasActiveSurvey && (
          <Button variant="secondary" label="Остановить опрос" width={270} onClick={openModalStopSurvey} />
        )}
      </ButtonsBlock>
      {loadingAnswers || errorAnswers ? (
        <CenteredWrapper>
          <CenteredText>{<Loader color={defaultTheme.colors.grayDarkMode} /> || errorAnswers?.message}</CenteredText>
        </CenteredWrapper>
      ) : hasCompletedSurveys && surveyAnswers && surveyAnswers?.length > 0 ? (
        <Table>
          <TableHead>
            <HeaderItem>Вопрос</HeaderItem>
            <HeaderItem>Последний ответ</HeaderItem>
            <HeaderItem>{surveyId === undefined ? '' : 'Мин/Макс'}</HeaderItem>
          </TableHead>
          <AnswerContainer>
            <Scrollbar>
              <AnswerWrapper>
                {surveyAnswers?.map(answer => (
                  <AnswerItem
                    question={answer.questionTitle}
                    answerRadio={answer.answerQuestionOptionText}
                    answerCheckbox={answer.answerQuestionOptionsTexts}
                    answerValue={answer.answerValue}
                    minValue={answer.minAnswer}
                    maxValue={answer.maxAnswer}
                    key={answer.questionId}
                    isShowAllSurveys={surveyId === undefined}
                    questionType={answer.questionType}
                    questionId={answer.questionId}
                    isCritical={answer.isCritical}
                    surveyTemplateId={surveyId}
                    isCustom={answer.isQuestionCustom}
                  />
                ))}
              </AnswerWrapper>
            </Scrollbar>
          </AnswerContainer>
        </Table>
      ) : (
        <CenteredWrapper>
          <CenteredText>Этот пациент еще не прошел ни одного опроса</CenteredText>
        </CenteredWrapper>
      )}
    </Root>
  );
};

const Root = styled.div`
  width: 770px;
  background-color: ${({ theme: { colors } }) => colors.white};
  box-sizing: border-box;
  border: 1px solid ${({ theme: { colors } }) => colors.blueExtraLight};
  box-shadow: 0 6px 30px #e4ecf7;
  border-radius: 10px;
  padding: 31px;
  height: calc(100vh - 100px - 129px);
`;
const ButtonsBlock = styled.div`
  display: flex;
`;
const EmptyDiv = styled.div`
  width: 32px;
`;
const Table = styled.div`
  height: calc(100% - 54px);
`;
const TableHead = styled.div`
  margin-top: 32px;
  display: flex;
  align-items: center;
`;
const HeaderItem = styled.div`
  ${({ theme: { typography } }) => typography.body3};
  line-height: 26px;
  color: ${({ theme: { colors } }) => colors.grayDarkMode};
  padding: 0 0 20px 20px;
  text-align: left;
  width: 194px;
`;
const AnswerContainer = styled.div`
  width: calc(100% + 32px);
  height: calc(100% - 23px);
`;
const AnswerWrapper = styled.div`
  width: calc(100% - 32px);
  height: 100%;
`;
const CenteredWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  height: calc(100% - 20px);
`;
const CenteredText = styled.div`
  width: 256px;
  text-align: center;
  color: ${({ theme: { colors } }) => colors.grayDarkMode};
  ${({ theme: { typography } }) => typography.title3};
  letter-spacing: -0.165px;
`;
