import * as Apollo from '@apollo/client';
import { DocumentNode } from 'graphql';

import * as Types from '../../../__generated__/types';

const defaultOptions = {};
export type PatientMedicalCardNumberIsUniqVariables = Types.Exact<{
  hospitalId: Types.Scalars['UUID'];
  medicalCardNumber: Types.Scalars['String'];
}>;

export type PatientMedicalCardNumberIsUniq = { __typename: 'Query'; patientMedicalCardNumberIsUniq: boolean };

export const PatientMedicalCardNumberIsUniqDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'PatientMedicalCardNumberIsUniq' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'hospitalId' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'UUID' } } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'medicalCardNumber' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'patientMedicalCardNumberIsUniq' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'hospitalId' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'hospitalId' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'medicalCardNumber' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'medicalCardNumber' } },
              },
            ],
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode;

/**
 * __usePatientMedicalCardNumberIsUniq__
 *
 * To run a query within a React component, call `usePatientMedicalCardNumberIsUniq` and pass it any options that fit your needs.
 * When your component renders, `usePatientMedicalCardNumberIsUniq` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePatientMedicalCardNumberIsUniq({
 *   variables: {
 *      hospitalId: // value for 'hospitalId'
 *      medicalCardNumber: // value for 'medicalCardNumber'
 *   },
 * });
 */
export function usePatientMedicalCardNumberIsUniq(
  baseOptions: Apollo.QueryHookOptions<PatientMedicalCardNumberIsUniq, PatientMedicalCardNumberIsUniqVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<PatientMedicalCardNumberIsUniq, PatientMedicalCardNumberIsUniqVariables>(
    PatientMedicalCardNumberIsUniqDocument,
    options,
  );
}
export function usePatientMedicalCardNumberIsUniqLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<PatientMedicalCardNumberIsUniq, PatientMedicalCardNumberIsUniqVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<PatientMedicalCardNumberIsUniq, PatientMedicalCardNumberIsUniqVariables>(
    PatientMedicalCardNumberIsUniqDocument,
    options,
  );
}
export type PatientMedicalCardNumberIsUniqHookResult = ReturnType<typeof usePatientMedicalCardNumberIsUniq>;
export type PatientMedicalCardNumberIsUniqLazyQueryHookResult = ReturnType<
  typeof usePatientMedicalCardNumberIsUniqLazyQuery
>;
export type PatientMedicalCardNumberIsUniqQueryResult = Apollo.QueryResult<
  PatientMedicalCardNumberIsUniq,
  PatientMedicalCardNumberIsUniqVariables
>;
