import React from 'react';

function FeedbackIcon() {
  return (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M6.455 19L2 22.5V4C2 3.73478 2.10536 3.48043 2.29289 3.29289C2.48043 3.10536 2.73478 3 3 3H21C21.2652 3 21.5196 3.10536 21.7071 3.29289C21.8946 3.48043 22 3.73478 22 4V18C22 18.2652 21.8946 18.5196 21.7071 18.7071C21.5196 18.8946 21.2652 19 21 19H6.455ZM4 18.385L5.763 17H20V5H4V18.385ZM11 13H13V15H11V13ZM11 7H13V12H11V7Z"
        fill="#777998"
      />
    </svg>
  );
}

export default FeedbackIcon;
