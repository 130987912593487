import { Language, SurveyTemplatePeriod } from '../__generated__/types';

export const API_URL = process.env.REACT_APP_API_BASE_URL!;
export const API_URL_GRAPHQL = process.env.REACT_APP_API_BASE_URL_GRAPHQL!;
export const WS_API_URL = process.env.REACT_APP_WS_API_BASE_URL!;

export const languages = [
  { label: 'Русский', value: Language.Ru },
  { label: 'English', value: Language.En },
  { label: 'Deutsch', value: Language.De },
  { label: 'Qazaq tili', value: Language.Kz },
];

export const periodOptions = [
  { label: 'Каждый день', value: SurveyTemplatePeriod.Everyday },
  { label: 'Каждые два дня', value: SurveyTemplatePeriod.EveryTwoDays },
  { label: 'Раз в неделю', value: SurveyTemplatePeriod.OnceAWeek },
  { label: 'Раз в две недели', value: SurveyTemplatePeriod.OnceInTwoWeeks },
];

export const WEEKDAYS_SHORT = {
  ru: ['Вс', 'Пн', 'Вт', 'Ср', 'Чт', 'Пт', 'Сб'],
};
export const MONTHS = {
  ru: [
    'Январь',
    'Февраль',
    'Март',
    'Апрель',
    'Май',
    'Июнь',
    'Июль',
    'Август',
    'Сентябрь',
    'Октябрь',
    'Ноябрь',
    'Декабрь',
  ],
};

export const fakeIdCompletedSurvey = '1';
