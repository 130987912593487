import * as Apollo from '@apollo/client';
import { DocumentNode } from 'graphql';

import * as Types from '../../../__generated__/types';

const defaultOptions = {};
export type CancelActiveSurveyVariables = Types.Exact<{
  patientId: Types.Scalars['UUID'];
}>;

export type CancelActiveSurvey = { __typename: 'Mutation'; doctorCancelActivePatientSurvey: boolean };

export const CancelActiveSurveyDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'CancelActiveSurvey' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'patientId' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'UUID' } } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'doctorCancelActivePatientSurvey' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'patientId' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'patientId' } },
              },
            ],
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode;
export type CancelActiveSurveyMutationFn = Apollo.MutationFunction<CancelActiveSurvey, CancelActiveSurveyVariables>;

/**
 * __useCancelActiveSurvey__
 *
 * To run a mutation, you first call `useCancelActiveSurvey` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCancelActiveSurvey` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [cancelActiveSurvey, { data, loading, error }] = useCancelActiveSurvey({
 *   variables: {
 *      patientId: // value for 'patientId'
 *   },
 * });
 */
export function useCancelActiveSurvey(
  baseOptions?: Apollo.MutationHookOptions<CancelActiveSurvey, CancelActiveSurveyVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<CancelActiveSurvey, CancelActiveSurveyVariables>(CancelActiveSurveyDocument, options);
}
export type CancelActiveSurveyHookResult = ReturnType<typeof useCancelActiveSurvey>;
export type CancelActiveSurveyMutationResult = Apollo.MutationResult<CancelActiveSurvey>;
export type CancelActiveSurveyMutationOptions = Apollo.BaseMutationOptions<
  CancelActiveSurvey,
  CancelActiveSurveyVariables
>;
