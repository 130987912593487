import * as Apollo from '@apollo/client';
import { DocumentNode } from 'graphql';

import * as Types from '../../../__generated__/types';
import { Question } from '../../fragments/__generated__/question.fragment';

const defaultOptions = {};
export type GetDrugQuestionsVariables = Types.Exact<{
  id: Types.Scalars['UUID'];
}>;

export type GetDrugQuestions = {
  __typename: 'Query';
  drugFindQuestions: Array<{
    __typename: 'QuestionModel';
    id: string;
    title: string;
    type: Types.QuestionType;
    isCustom: boolean;
    isActual: boolean;
    indicators?: Types.Maybe<{
      __typename: 'QuestionIndicators';
      numeric?: Types.Maybe<{ __typename: 'NumericQuestionIndicators'; maxValue: any; minValue: any }>;
      scale?: Types.Maybe<{ __typename: 'ScaleQuestionIndicators'; maxValue: any; minValue: any }>;
    }>;
    options?: Types.Maybe<Array<{ __typename: 'QuestionOptionModel'; id: string; index: number; text: string }>>;
  }>;
};

export const GetDrugQuestionsDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'GetDrugQuestions' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'UUID' } } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'drugFindQuestions' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'id' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'Question' } }],
            },
          },
        ],
      },
    },
    ...Question.definitions,
  ],
} as unknown as DocumentNode;

/**
 * __useGetDrugQuestions__
 *
 * To run a query within a React component, call `useGetDrugQuestions` and pass it any options that fit your needs.
 * When your component renders, `useGetDrugQuestions` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetDrugQuestions({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetDrugQuestions(baseOptions: Apollo.QueryHookOptions<GetDrugQuestions, GetDrugQuestionsVariables>) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetDrugQuestions, GetDrugQuestionsVariables>(GetDrugQuestionsDocument, options);
}
export function useGetDrugQuestionsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<GetDrugQuestions, GetDrugQuestionsVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetDrugQuestions, GetDrugQuestionsVariables>(GetDrugQuestionsDocument, options);
}
export type GetDrugQuestionsHookResult = ReturnType<typeof useGetDrugQuestions>;
export type GetDrugQuestionsLazyQueryHookResult = ReturnType<typeof useGetDrugQuestionsLazyQuery>;
export type GetDrugQuestionsQueryResult = Apollo.QueryResult<GetDrugQuestions, GetDrugQuestionsVariables>;
