import { FullModelType } from 'pages/CreatingQuiz/CreatingQuiz';
import React, { Dispatch, SetStateAction } from 'react';
import styled from 'styled-components';

import {
  DrugModel,
  QuestionInput,
  QuestionType,
  SurveyTemplatePeriod,
  SurveyTemplateQuestionModel,
} from '../../__generated__/types';
import { TrashIcon } from '../../assets/svg';
import PlusIcon from '../../assets/svg/plus-icon/PlusIcon';
import { useRemoveSurveyTemplate } from '../../common/mutation/__generated__/remove-survey-template';
import { useGetSurveyTemplate } from '../../common/query/__generated__/get-survey-template';
import { wordDeclension } from '../../utils/word-declension';

type Props = {
  id: string;
  title: string;
  questionsCount: number;
  createdAt: Date | string;
  setQuizTitle: (quizTitle: string) => void;
  setCriticalParams: (questions: QuestionInput[]) => void;
  setSurveyTemplateTitle: () => void;
  setPeriod: Dispatch<SetStateAction<SurveyTemplatePeriod | undefined>>;
  fullModel: FullModelType;
  setFullModel: Dispatch<SetStateAction<[DrugModel, SurveyTemplateQuestionModel[]][]>>;
};

function NewQuestionnaire({
  id,
  title,
  questionsCount,
  createdAt,
  setQuizTitle,
  setCriticalParams,
  setSurveyTemplateTitle,
  setPeriod,
  fullModel,
  setFullModel,
}: Props) {
  const questionWord = wordDeclension(questionsCount, ['Вопрос', 'Вопроса', 'Вопросов']);
  const { data } = useGetSurveyTemplate({
    variables: {
      id,
    },
  });

  const setSurveyTemplate = () => {
    if (!(data && data.surveyTemplateFindOne)) {
      return;
    }

    const templateQuestions: (SurveyTemplateQuestionModel | undefined)[] = data.surveyTemplateFindOne.questions.map(
      (template: SurveyTemplateQuestionModel) => {
        return template.question
          ? {
              question: { ...template.question },
              criticalAnswerId: template.criticalAnswerId,
              criticalAnswersIds: template.criticalAnswersIds,
              criticalIndicators: { ...template.criticalIndicators },
            }
          : undefined;
      },
    );

    const templateModel =
      data.surveyTemplateFindOne.drugs.length < 1
        ? [[{ id: '', name: data.surveyTemplateFindOne?.title || '' }, templateQuestions]]
        : [];

    const drugModels = data.surveyTemplateFindOne.drugs.map(d => {
      const drug = d;
      const questions = data.surveyTemplateFindOne.questions;
      return [drug, [...questions]];
    });

    //@ts-ignore
    setFullModel([...fullModel, ...templateModel, ...drugModels]);

    if (!templateQuestions.includes(undefined)) {
      const critical: QuestionInput[] = templateQuestions.map(question => {
        return {
          questionId: question?.question?.id || '1',
          questionType: question?.question?.type || QuestionType.Checkbox,
          criticalAnswerId: question?.criticalAnswerId,
          criticalAnswersIds: question?.criticalAnswersIds,
          criticalIndicators: { ...question?.criticalIndicators },
        };
      });
      setCriticalParams(critical);
    }
    setSurveyTemplateTitle();
    setQuizTitle(data.surveyTemplateFindOne.title);
    setPeriod(data.surveyTemplateFindOne.period);
  };

  const [removeSurveyTemplate] = useRemoveSurveyTemplate();

  const deleteSurveyTemplate = async () => {
    await removeSurveyTemplate({
      variables: { id },
      update(cache) {
        const normalizedId = cache.identify({ id, __typename: 'SurveyTemplateModel' });
        cache.evict({ id: normalizedId });
        cache.gc();
      },
    });
  };

  return (
    <Root>
      <Wrapper>
        <Header>
          <Title>{title}</Title>
          <Plus onClick={setSurveyTemplate}>
            <PlusIcon />
          </Plus>
        </Header>
        <Container>
          <Details>{`${new Date(createdAt).toLocaleDateString()} • ${questionsCount} ${questionWord}`}</Details>
          <Trash onClick={deleteSurveyTemplate}>
            <TrashIcon />
          </Trash>
        </Container>
      </Wrapper>
    </Root>
  );
}

export default NewQuestionnaire;

const Root = styled.div`
  width: 406px;
  min-height: 112px;
  border-radius: 10px;
  background-color: ${({ theme: { colors } }) => colors.grayExtraLight};
  margin-top: 20px;

  &:last-child {
    margin-bottom: 32px;
  }
`;
const Wrapper = styled.div`
  height: fit-content;
  padding: 20px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
`;
const Container = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;
const Header = styled(Container)`
  margin-bottom: 20px;
`;
const Title = styled.span`
  ${({ theme: { typography } }) => typography.title3};
  color: ${({ theme: { colors } }) => colors.grayDark};
  max-width: 320px;
`;
const Plus = styled.div`
  margin-right: 5px;
  cursor: pointer;
`;
const Details = styled.div`
  ${({ theme: { typography } }) => typography.footnote1};
  color: ${({ theme: { colors } }) => colors.grayDarkMode};
`;
const Trash = styled.div`
  margin-right: 2px;
  cursor: pointer;
`;
