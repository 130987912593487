import 'react-day-picker/lib/style.css';

import React, { useState } from 'react';
import useOnclickOutside from 'react-cool-onclickoutside';
import DayPicker, { DateUtils, LocaleUtils, Modifier, RangeModifier } from 'react-day-picker';
import styled from 'styled-components';

import { MONTHS, WEEKDAYS_SHORT } from '../../utils/constants';
import { Button } from '../Button';
import { DatesField, Navbar } from './components';

type Props = {
  setDateRange?: (value: RangeModifier | undefined) => void;
  isEmpty?: boolean;
  setIsEmpty?: (isEmpty: boolean) => void;
  disabledDays?: { before: Date; after?: Date }[];
  startAt?: Date | null;
  endAt?: Date | null;
};

function Calendar({ setDateRange, isEmpty, setIsEmpty, disabledDays = [], endAt, startAt }: Props) {
  const [selectedDates, setSelectedDates] = useState<Modifier>({ from: startAt, to: endAt });
  const [isOpen, setIsOpen] = useState(false);
  const [date, setDate] = useState('');
  // @ts-ignore
  const { from, to } = selectedDates;
  const handleDayClick = (day: Date, { disabled }: { disabled: boolean }) => {
    let range;
    if (!disabled) {
      range = DateUtils.addDayToRange(day, selectedDates as RangeModifier);
      setSelectedDates(range);
    }
  };

  const locale = 'ru';

  const openDayPicker = () => {
    setIsOpen(!isOpen);
  };
  const ref = useOnclickOutside(() => {
    setIsOpen(false);
  });
  const selectDay = () => {
    setIsOpen(false);
    from &&
      to &&
      setDate(
        `${from
          .toLocaleDateString('ru', {
            year: 'numeric',
            month: 'long',
            day: 'numeric',
          })
          .replace(/\s*г\./, '')} - ${to
          .toLocaleDateString('ru', {
            year: 'numeric',
            month: 'long',
            day: 'numeric',
          })
          .replace(/\s*г\./, '')}`,
      );
    if (setDateRange) {
      // @ts-ignore
      setDateRange(selectedDates);
      if (setIsEmpty) {
        setIsEmpty(false);
      }
    }
  };

  const modifiers: Partial<Modifier> = { start: from, end: to };
  return (
    <div ref={ref}>
      <DatesField text={date} openDayPicker={openDayPicker} isOpen={isOpen} isEmpty={isEmpty} />
      {isOpen && (
        <Wrapper>
          <DayPickerStylized
            selectedDays={[from, { from, to }]}
            modifiers={modifiers}
            // @ts-ignore
            onDayClick={handleDayClick}
            showOutsideDays
            firstDayOfWeek={1}
            localeUtils={{
              ...LocaleUtils,
              formatMonthTitle(month: Date): string {
                const currentMonth = month.getMonth();
                const currentYear = month.getFullYear();
                return `${MONTHS[locale][currentMonth]} ${currentYear}`;
              },
            }}
            locale={locale}
            weekdaysShort={WEEKDAYS_SHORT[locale]}
            navbarElement={Navbar}
            disabledDays={disabledDays}
          />
          <Button variant={'primary'} label={'Подтвердить'} width={311} isDisabled={!to} onClick={selectDay} />
        </Wrapper>
      )}
    </div>
  );
}

const DayPickerStylized = styled(DayPicker)`
  .DayPicker-Day--today {
    color: black;
  }

  .DayPicker-Day {
    width: 41px;
    height: 32px;
    padding: 0;
    ${({ theme: { typography } }) => typography.footnote1};

    &:hover {
      background-color: ${({ theme: { colors } }) => colors.blueAverage} !important;
    }
  }

  .DayPicker-Day:not(.DayPicker-Day--start):not(.DayPicker-Day--end) {
    border-radius: 4px !important;
  }

  .DayPicker-Day--outside {
    color: ${({ theme: { colors } }) => colors.grayAverage} !important;
  }

  .DayPicker-Day--selected:not(.DayPicker-Day--start):not(.DayPicker-Day--end):not(.DayPicker-Day--outside) {
    border-radius: 0 !important;
    background-color: ${({ theme: { colors } }) => colors.blueAverage} !important;
    color: black;
  }

  .DayPicker-Day--start {
    border-radius: 0 !important;
    border-top-left-radius: 4px !important;
    border-bottom-left-radius: 4px !important;
    background: ${({ theme: { colors } }) => colors.blue} !important;
    ${({ theme: { typography } }) => typography.footnote2};

    &:hover {
      background-color: ${({ theme: { colors } }) => colors.blueAverage} !important;
      color: black;
    }
  }

  .DayPicker-Day--end {
    border-radius: 0 !important;
    border-top-right-radius: 4px !important;
    border-bottom-right-radius: 4px !important;
    background: ${({ theme: { colors } }) => colors.blue} !important;
    ${({ theme: { typography } }) => typography.footnote2};

    &:hover {
      background-color: ${({ theme: { colors } }) => colors.blueAverage} !important;
      color: black;
    }
  }

  .DayPicker-Day--disabled {
    color: ${({ theme: { colors } }) => colors.grayAverage} !important;
    cursor: default;
    &:hover {
      background-color: unset !important;
    }
  }

  .DayPicker-Weekday {
    ${({ theme: { typography } }) => typography.footnote1};
    color: ${({ theme: { colors } }) => colors.grayDarkMode} !important;
    padding: 8px;
  }

  .DayPicker-Caption {
    display: none;
  }

  .DayPicker-Month {
    margin-top: 8px;
  }

  .DayPicker-wrapper {
    padding: 16px 9px;
  }
`;
const Wrapper = styled.div`
  border: 1px solid ${({ theme: { colors } }) => colors.blueLight};
  border-radius: 10px;
  margin-top: 10px;
  display: flex;
  flex-direction: column;
  align-items: center;
  width: fit-content;
  padding-bottom: 16px;
  position: absolute;
  z-index: 1;
  background-color: ${({ theme: { colors } }) => colors.white};
`;

export default React.memo(Calendar);
