import * as Apollo from '@apollo/client';
import { DocumentNode } from 'graphql';

import * as Types from '../../../__generated__/types';

const defaultOptions = {};
export type RemoveNotificationVariables = Types.Exact<{
  id: Types.Scalars['String'];
}>;

export type RemoveNotification = { __typename: 'Mutation'; doctorNotificationRemove: boolean };

export const RemoveNotificationDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'RemoveNotification' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'doctorNotificationRemove' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'id' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
              },
            ],
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode;
export type RemoveNotificationMutationFn = Apollo.MutationFunction<RemoveNotification, RemoveNotificationVariables>;

/**
 * __useRemoveNotification__
 *
 * To run a mutation, you first call `useRemoveNotification` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRemoveNotification` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [removeNotification, { data, loading, error }] = useRemoveNotification({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useRemoveNotification(
  baseOptions?: Apollo.MutationHookOptions<RemoveNotification, RemoveNotificationVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<RemoveNotification, RemoveNotificationVariables>(RemoveNotificationDocument, options);
}
export type RemoveNotificationHookResult = ReturnType<typeof useRemoveNotification>;
export type RemoveNotificationMutationResult = Apollo.MutationResult<RemoveNotification>;
export type RemoveNotificationMutationOptions = Apollo.BaseMutationOptions<
  RemoveNotification,
  RemoveNotificationVariables
>;
