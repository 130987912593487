import React from 'react';
import styled from 'styled-components';

import { CheckedIcon } from '../../assets/svg';
import { ThemeType, defaultTheme } from '../../themes';

function SingleCheckbox({ ...props }) {
  const { isDisabled = false, isChecked, label } = props;
  const variant = isChecked ? 'checked' : 'notChecked';
  const themeCSS = themes[variant](isDisabled, isChecked, defaultTheme);

  return (
    <Root $isDisabled={isDisabled}>
      <CheckboxInput>
        <Input checked={isChecked} disabled={isDisabled} {...props} type="checkbox" />
        <CheckboxControl $isChecked={isChecked} $isDisabled={isDisabled} $themeCSS={themeCSS}>
          {isChecked && (
            <CheckedIcon color={isDisabled ? defaultTheme.colors.grayDarkMode : defaultTheme.colors.white} />
          )}
        </CheckboxControl>
      </CheckboxInput>
      <Label $isChecked={isChecked}>{label}</Label>
    </Root>
  );
}

export default SingleCheckbox;

export type SingleCheckboxProps = {
  isChecked?: boolean;
  isDisabled?: boolean;
  label?: string;
};
type StyleProps = {
  $isChecked?: boolean;
  $isDisabled?: boolean;
};

const themes = {
  checked: (isDisabled: boolean, isChecked: boolean, theme: ThemeType) => `
    border: none;
    background: ${isDisabled ? theme.colors.grayAverage : theme.colors.blue};
    
    &:hover {
      background: ${theme.colors.blueDark};
      border: none;
    }
    
    ${Input}:focus:checked + & {
      background: ${theme.colors.blueDark};
      border: none;
      box-shadow: 0 0 0 4px ${theme.colors.blueAverage};
    }
  `,
  notChecked: (isDisabled: boolean, isChecked: boolean, theme: ThemeType) => `
    border: 1px solid ${isDisabled ? theme.colors.grayAverage : theme.colors.blueAverage};
    background: ${isDisabled ? theme.colors.blueLight : theme.colors.grayExtraLight};
    
    &:hover {
    background: ${theme.colors.blueExtraLight};
    border: 1px solid ${theme.colors.blue};
    }
    
    ${Input}:focus + & {
    background: ${theme.colors.blueExtraLight};
    border: 1px solid ${theme.colors.blue};
    box-shadow: 0 0 0 4px ${theme.colors.blueAverage};
    }
  `,
};

const Root = styled.label<StyleProps>`
  display: flex;
  flex-direction: row;
  align-items: center;
  cursor: pointer;
  margin-bottom: 32px;
  width: fit-content;
  ${({ $isDisabled }) => $isDisabled && `cursor: default`}
`;
const CheckboxInput = styled.span`
  display: flex;
`;
const Input = styled.input`
  opacity: 0;
  width: 0;
  height: 0;
`;
const CheckboxControl = styled.span<StyleProps & { $themeCSS: string }>`
  display: inline-grid;
  box-sizing: border-box;
  border-radius: 6px;
  width: 28px;
  height: 28px;
  padding-top: 8px;
  padding-left: 6px;

  ${({ $isDisabled }) =>
    $isDisabled &&
    `
    pointer-events: none;
  `}
  ${({ $themeCSS }) => $themeCSS}
`;

const Label = styled.span<StyleProps>`
  margin-left: 16px;
  ${({ theme: { typography } }) => typography.body2};

  ${({ $isChecked, theme: { colors } }) =>
    $isChecked
      ? `
    color: ${colors.grayDark};
    `
      : `
    color: ${colors.grayAverage};
    `}
`;
