import React from 'react';
import styled from 'styled-components';

import PlusIcon from '../../assets/svg/plus-icon/PlusIcon';

type Props = {
  title: string;
  index: number;
  addQuestion: () => void;
  isDisabled?: boolean;
};

function QuestionListItem({ title, index, addQuestion, isDisabled = true }: Props) {
  return (
    <Root $isDisabled={isDisabled}>
      <Title $isDisabled={isDisabled}>{`${index}. ${title}`}</Title>
      {!isDisabled && (
        <Plus onClick={addQuestion}>
          <PlusIcon />
        </Plus>
      )}
    </Root>
  );
}

export default QuestionListItem;

const Root = styled.div<{ $isDisabled: boolean }>`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 20px 30px;
  background-color: ${({ $isDisabled, theme: { colors } }) => ($isDisabled ? colors.grayLight : colors.grayExtraLight)};
  border-radius: 10px;
  margin-bottom: 20px;

  &:last-child {
    margin-bottom: 32px;
  }
`;
const Title = styled.div<{ $isDisabled: boolean }>`
  max-width: 300px;
  color: ${({ $isDisabled, theme: { colors } }) => ($isDisabled ? colors.grayAverage : colors.grayDark)};
  ${({ theme: { typography } }) => typography.title3};
  line-height: 30px;
`;
const Plus = styled.div`
  padding: 6px 5px 2px;
  cursor: pointer;
`;
