import React, { useState } from 'react';
import styled from 'styled-components';

import { Maybe, QuestionOptionModel } from '../../__generated__/types';
import { OneAnswerOption } from './components';

export type OneAnswerOptionGroupPropsType = {
  options: Maybe<Array<QuestionOptionModel>>;
  criticalValue?: QuestionOptionModel;
  setCriticalValue?: (value?: QuestionOptionModel) => void;
};

function OneAnswerOptionGroup({ options, criticalValue, setCriticalValue }: OneAnswerOptionGroupPropsType) {
  const [selectedCriticalAnswer, setSelectedCriticalAnswer] = useState<QuestionOptionModel | undefined>();

  const isFakeChecked = (index: number) => {
    return criticalValue && criticalValue.index <= index;
  };

  const handleCriticalAnswerClick = (option: QuestionOptionModel) => () => {
    if (setCriticalValue) {
      if (criticalValue?.text === option.text) {
        setCriticalValue(undefined);
        setSelectedCriticalAnswer(undefined);
      } else {
        setCriticalValue(option);
        setSelectedCriticalAnswer(option);
      }
    }
  };

  return (
    <Wrapper>
      {options &&
        options.map(option => {
          return (
            option.text !== '' && (
              <OneAnswerOption
                key={option.id}
                isChecked={selectedCriticalAnswer?.text === option.text}
                text={option.text}
                onClick={handleCriticalAnswerClick(option)}
                isFakeChecked={isFakeChecked(option.index)}
              />
            )
          );
        })}
    </Wrapper>
  );
}

export default React.memo(OneAnswerOptionGroup);

const Wrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  margin-top: 20px;
`;
