import * as Apollo from '@apollo/client';
import { DocumentNode } from 'graphql';

import * as Types from '../../../__generated__/types';

const defaultOptions = {};
export type AssignPatientToDoctorVariables = Types.Exact<{
  input: Types.PatientAssignToDoctorInput;
}>;

export type AssignPatientToDoctor = {
  __typename: 'Mutation';
  patientAssignToDoctor: {
    __typename: 'PatientAssignToDoctorPayload';
    success?: Types.Maybe<boolean>;
    problem?: Types.Maybe<{ __typename: 'NotExistPatientProblem'; message: string }>;
  };
};

export const AssignPatientToDoctorDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'AssignPatientToDoctor' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'input' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'PatientAssignToDoctorInput' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'patientAssignToDoctor' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'input' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'input' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'problem' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [{ kind: 'Field', name: { kind: 'Name', value: 'message' } }],
                  },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'success' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode;
export type AssignPatientToDoctorMutationFn = Apollo.MutationFunction<
  AssignPatientToDoctor,
  AssignPatientToDoctorVariables
>;

/**
 * __useAssignPatientToDoctor__
 *
 * To run a mutation, you first call `useAssignPatientToDoctor` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAssignPatientToDoctor` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [assignPatientToDoctor, { data, loading, error }] = useAssignPatientToDoctor({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useAssignPatientToDoctor(
  baseOptions?: Apollo.MutationHookOptions<AssignPatientToDoctor, AssignPatientToDoctorVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<AssignPatientToDoctor, AssignPatientToDoctorVariables>(
    AssignPatientToDoctorDocument,
    options,
  );
}
export type AssignPatientToDoctorHookResult = ReturnType<typeof useAssignPatientToDoctor>;
export type AssignPatientToDoctorMutationResult = Apollo.MutationResult<AssignPatientToDoctor>;
export type AssignPatientToDoctorMutationOptions = Apollo.BaseMutationOptions<
  AssignPatientToDoctor,
  AssignPatientToDoctorVariables
>;
