import React from 'react';

import { defaultTheme } from '../../../themes';

type TickPropsType = {
  x: number;
  y: number;
  payload: {
    value: string;
  };
};
export const renderTick = ({ x, y, payload }: TickPropsType) => {
  return (
    <g>
      <text
        x={x}
        y={y}
        dy={20}
        fontSize="16"
        fontFamily="Lato, sans-serif"
        fontWeight={400}
        fill={defaultTheme.colors.grayDarkMode}
        textAnchor="middle">
        {payload.value}
      </text>
    </g>
  );
};
