import * as Apollo from '@apollo/client';
import { DocumentNode } from 'graphql';

import * as Types from '../../../__generated__/types';
import { SurveyTemplate } from '../../fragments/__generated__/survey-template.fragment';

const defaultOptions = {};
export type GetSurveyTemplateVariables = Types.Exact<{
  id: Types.Scalars['UUID'];
}>;

export type GetSurveyTemplate = {
  __typename: 'Query';
  surveyTemplateFindOne: {
    __typename: 'SurveyTemplateModel';
    id: string;
    kind: Types.SurveyTemplateKind;
    period: Types.SurveyTemplatePeriod;
    title: string;
    createdAt: any;
    endAt: any;
    startAt: any;
    questionsCount: number;
    timezoneOffset: number;
    drugs: Array<{ __typename: 'DrugModel'; id: string; name: string }>;
    questions: Array<{
      __typename: 'SurveyTemplateQuestionModel';
      criticalAnswerId?: Types.Maybe<any>;
      criticalAnswersIds?: Types.Maybe<Array<any>>;
      criticalIndicators?: Types.Maybe<{
        __typename: 'SurveyTemplateQuestionCriticalIndicators';
        numeric?: Types.Maybe<{
          __typename: 'SurveyTemplateNumericQuestionCriticalIndicators';
          maxValue?: Types.Maybe<any>;
          minValue?: Types.Maybe<any>;
        }>;
        scale?: Types.Maybe<{ __typename: 'SurveyTemplateScaleQuestionCriticalIndicators'; value: any }>;
      }>;
      question?: Types.Maybe<{
        __typename: 'QuestionModel';
        id: string;
        title: string;
        type: Types.QuestionType;
        isCustom: boolean;
        isActual: boolean;
        indicators?: Types.Maybe<{
          __typename: 'QuestionIndicators';
          numeric?: Types.Maybe<{ __typename: 'NumericQuestionIndicators'; maxValue: any; minValue: any }>;
          scale?: Types.Maybe<{ __typename: 'ScaleQuestionIndicators'; maxValue: any; minValue: any }>;
        }>;
        options?: Types.Maybe<Array<{ __typename: 'QuestionOptionModel'; id: string; index: number; text: string }>>;
      }>;
    }>;
  };
};

export const GetSurveyTemplateDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'GetSurveyTemplate' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'UUID' } } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'surveyTemplateFindOne' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'id' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'SurveyTemplate' } }],
            },
          },
        ],
      },
    },
    ...SurveyTemplate.definitions,
  ],
} as unknown as DocumentNode;

/**
 * __useGetSurveyTemplate__
 *
 * To run a query within a React component, call `useGetSurveyTemplate` and pass it any options that fit your needs.
 * When your component renders, `useGetSurveyTemplate` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetSurveyTemplate({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetSurveyTemplate(
  baseOptions: Apollo.QueryHookOptions<GetSurveyTemplate, GetSurveyTemplateVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetSurveyTemplate, GetSurveyTemplateVariables>(GetSurveyTemplateDocument, options);
}
export function useGetSurveyTemplateLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<GetSurveyTemplate, GetSurveyTemplateVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetSurveyTemplate, GetSurveyTemplateVariables>(GetSurveyTemplateDocument, options);
}
export type GetSurveyTemplateHookResult = ReturnType<typeof useGetSurveyTemplate>;
export type GetSurveyTemplateLazyQueryHookResult = ReturnType<typeof useGetSurveyTemplateLazyQuery>;
export type GetSurveyTemplateQueryResult = Apollo.QueryResult<GetSurveyTemplate, GetSurveyTemplateVariables>;
