import React from 'react';

function ArrowDownIcon({ ...rest }) {
  return (
    <svg width="14" height="8" viewBox="0 0 14 8" fill="none" xmlns="http://www.w3.org/2000/svg" {...rest}>
      <path
        d="M6.99999 5.17205L11.95 0.222046L13.364 1.63605L6.99999 8.00005L0.635986 1.63605L2.04999 0.222046L6.99999 5.17205Z"
        fill="#777998"
      />
    </svg>
  );
}

export default ArrowDownIcon;
