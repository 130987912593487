import * as Apollo from '@apollo/client';
import { DocumentNode } from 'graphql';

import * as Types from '../../../__generated__/types';

const defaultOptions = {};
export type GetNewAnalyzesCountVariables = Types.Exact<{
  patientId: Types.Scalars['String'];
}>;

export type GetNewAnalyzesCount = { __typename: 'Query'; doctorGetNewPatientAnalyzesCount: number };

export const GetNewAnalyzesCountDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'GetNewAnalyzesCount' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'patientId' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'doctorGetNewPatientAnalyzesCount' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'patientId' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'patientId' } },
              },
            ],
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode;

/**
 * __useGetNewAnalyzesCount__
 *
 * To run a query within a React component, call `useGetNewAnalyzesCount` and pass it any options that fit your needs.
 * When your component renders, `useGetNewAnalyzesCount` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetNewAnalyzesCount({
 *   variables: {
 *      patientId: // value for 'patientId'
 *   },
 * });
 */
export function useGetNewAnalyzesCount(
  baseOptions: Apollo.QueryHookOptions<GetNewAnalyzesCount, GetNewAnalyzesCountVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetNewAnalyzesCount, GetNewAnalyzesCountVariables>(GetNewAnalyzesCountDocument, options);
}
export function useGetNewAnalyzesCountLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<GetNewAnalyzesCount, GetNewAnalyzesCountVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetNewAnalyzesCount, GetNewAnalyzesCountVariables>(GetNewAnalyzesCountDocument, options);
}
export type GetNewAnalyzesCountHookResult = ReturnType<typeof useGetNewAnalyzesCount>;
export type GetNewAnalyzesCountLazyQueryHookResult = ReturnType<typeof useGetNewAnalyzesCountLazyQuery>;
export type GetNewAnalyzesCountQueryResult = Apollo.QueryResult<GetNewAnalyzesCount, GetNewAnalyzesCountVariables>;
