import React from 'react';
import styled from 'styled-components';

import { CalendarIcon } from '../../../../assets/svg';
import { defaultTheme } from '../../../../themes';

function DatesField({ error, openDayPicker, text, isOpen, isEmpty, ...props }: FieldProps) {
  const hasError = !!error;
  return (
    <Root>
      <Field {...props} $hasError={hasError} onClick={openDayPicker} $isOpen={isOpen} $isEmpty={isEmpty}>
        <Text $hasDate={!!text}>{text || 'Выбор периода'}</Text>
        <CalendarIcon color={defaultTheme.colors.grayDarkMode} />
      </Field>
      {error && <Error>*{error}</Error>}
    </Root>
  );
}

interface FieldProps {
  text: string;
  isDisabled?: boolean;
  error?: string;
  openDayPicker: () => void;
  isOpen: boolean;
  isEmpty?: boolean;
}

interface RootProps {
  $hasError: boolean;
  $isDisabled?: boolean;
  $isOpen: boolean;
  $isEmpty?: boolean;
}

const Root = styled.div`
  display: flex;
  flex-direction: column;
  width: 343px;
`;

const Field = styled.div<RootProps>`
  box-sizing: border-box;
  min-height: 54px;
  padding: 0 20px;
  background-color: ${({ theme: { colors } }) => colors.blueExtraLight};
  border: 1px solid ${({ $isOpen, theme: { colors } }) => ($isOpen ? colors.blue : colors.blueAverage)};
  border-radius: 10px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;

  ${({ theme: { typography } }) => typography.body3};
  ${({ $hasError, theme: { colors } }) => $hasError && `border-color: ${colors.colorError}`};
  ${({ $isEmpty, theme: { colors } }) => $isEmpty && `border-color: ${colors.colorError}`};
  ${({ $isDisabled, theme: { colors } }) =>
    $isDisabled &&
    `
          border: none; 
          background-color: ${colors.grayExtraLight};
          `};
  color: ${({ theme: { colors } }) => colors.grayDark};
  ${({ theme: { colors } }) => `
    :hover {
      border: 1px solid ${colors.blue}
    };
  `}
`;
const Text = styled.span<{ $hasDate: boolean }>`
  ${({ theme: { typography } }) => typography.body3};
  ${({ $hasDate, theme: { colors } }) => `color: ${$hasDate ? colors.grayDark : colors.grayDarkMode}`};
`;
const Error = styled.span`
  margin-left: 20px;
  margin-top: 6px;
  ${({ theme: { typography } }) => typography.body3};
  color: ${({ theme: { colors } }) => colors.colorError};
`;

export default DatesField;
