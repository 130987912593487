import * as Apollo from '@apollo/client';
import { DocumentNode } from 'graphql';

import * as Types from '../../../__generated__/types';

const defaultOptions = {};
export type GetAnalyzesVariables = Types.Exact<{
  patientId: Types.Scalars['String'];
  after?: Types.Maybe<Types.Scalars['String']>;
  startAt?: Types.Maybe<Types.Scalars['DateTime']>;
  endAt?: Types.Maybe<Types.Scalars['DateTime']>;
  first?: Types.Maybe<Types.Scalars['Int']>;
  orderBy?: Types.Maybe<Types.SearchOrder>;
}>;

export type GetAnalyzes = {
  __typename: 'Query';
  searchPatientAnalyzes: {
    __typename: 'PatientAnalyzeConnection';
    nodes: Array<{
      __typename: 'PatientAnalyzeModel';
      patientId: string;
      doctorId: string;
      id: string;
      filename: string;
      fileKey: string;
      createdAt: any;
      isViewed: boolean;
    }>;
    pageInfo: { __typename: 'PageInfo'; hasNextPage: boolean; endCursor?: Types.Maybe<string> };
  };
};

export const GetAnalyzesDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'GetAnalyzes' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'patientId' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'after' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'startAt' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'DateTime' } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'endAt' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'DateTime' } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'first' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'Int' } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'orderBy' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'SearchOrder' } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'searchPatientAnalyzes' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'patientId' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'patientId' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'after' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'after' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'startAt' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'startAt' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'endAt' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'endAt' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'first' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'first' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'orderBy' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'orderBy' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'nodes' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'patientId' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'doctorId' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'filename' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'fileKey' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'createdAt' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'isViewed' } },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'pageInfo' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'hasNextPage' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'endCursor' } },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode;

/**
 * __useGetAnalyzes__
 *
 * To run a query within a React component, call `useGetAnalyzes` and pass it any options that fit your needs.
 * When your component renders, `useGetAnalyzes` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetAnalyzes({
 *   variables: {
 *      patientId: // value for 'patientId'
 *      after: // value for 'after'
 *      startAt: // value for 'startAt'
 *      endAt: // value for 'endAt'
 *      first: // value for 'first'
 *      orderBy: // value for 'orderBy'
 *   },
 * });
 */
export function useGetAnalyzes(baseOptions: Apollo.QueryHookOptions<GetAnalyzes, GetAnalyzesVariables>) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetAnalyzes, GetAnalyzesVariables>(GetAnalyzesDocument, options);
}
export function useGetAnalyzesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetAnalyzes, GetAnalyzesVariables>) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetAnalyzes, GetAnalyzesVariables>(GetAnalyzesDocument, options);
}
export type GetAnalyzesHookResult = ReturnType<typeof useGetAnalyzes>;
export type GetAnalyzesLazyQueryHookResult = ReturnType<typeof useGetAnalyzesLazyQuery>;
export type GetAnalyzesQueryResult = Apollo.QueryResult<GetAnalyzes, GetAnalyzesVariables>;
