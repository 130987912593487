import { storageClient } from 'libs/StorageClient';
import React from 'react';

import { MeIsAuthDocument, useMeIsAuth } from '../../../common/query/__generated__/me-is-auth';

const useAuthContext = () => {
  const meQuery = useMeIsAuth();

  const { refetch, data, client } = meQuery;

  const authContext = React.useMemo(
    () => ({
      signIn: async (token: string, refreshToken: string) => {
        storageClient.setAuthToken(token);
        storageClient.setRefreshToken(refreshToken);
        return refetch();
      },
      signInWithoutRemember: async (token: string, refreshToken: string) => {
        sessionStorage.setItem('token', token);
        sessionStorage.setItem('refreshToken', refreshToken);
        return refetch();
      },
      signUp: async (token: string, refreshToken: string) => {
        storageClient.setAuthToken(token);
        storageClient.setRefreshToken(refreshToken);
        storageClient.removeHash();
        return refetch();
      },
      signOut: async () => {
        storageClient.removeAuthToken();
        storageClient.removeRefreshToken();
        sessionStorage.removeItem('token');
        sessionStorage.removeItem('refreshToken');
        client.writeQuery({
          query: MeIsAuthDocument,
          data: {
            doctorMe: {
              ...data?.doctorMe,
              id: null,
              role: null,
            },
          },
        });
        await client.clearStore();
      },
    }),
    [client, data, refetch],
  );

  return { meQuery, authContext };
};

export default useAuthContext;
