import * as Apollo from '@apollo/client';
import { DocumentNode } from 'graphql';

import * as Types from '../../../__generated__/types';

const defaultOptions = {};
export type CheckPatientLimitVariables = Types.Exact<{ [key: string]: never }>;

export type CheckPatientLimit = { __typename: 'Query'; hospitalLimitNotExhausted: boolean };

export const CheckPatientLimitDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'CheckPatientLimit' },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [{ kind: 'Field', name: { kind: 'Name', value: 'hospitalLimitNotExhausted' } }],
      },
    },
  ],
} as unknown as DocumentNode;

/**
 * __useCheckPatientLimit__
 *
 * To run a query within a React component, call `useCheckPatientLimit` and pass it any options that fit your needs.
 * When your component renders, `useCheckPatientLimit` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCheckPatientLimit({
 *   variables: {
 *   },
 * });
 */
export function useCheckPatientLimit(
  baseOptions?: Apollo.QueryHookOptions<CheckPatientLimit, CheckPatientLimitVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<CheckPatientLimit, CheckPatientLimitVariables>(CheckPatientLimitDocument, options);
}
export function useCheckPatientLimitLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<CheckPatientLimit, CheckPatientLimitVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<CheckPatientLimit, CheckPatientLimitVariables>(CheckPatientLimitDocument, options);
}
export type CheckPatientLimitHookResult = ReturnType<typeof useCheckPatientLimit>;
export type CheckPatientLimitLazyQueryHookResult = ReturnType<typeof useCheckPatientLimitLazyQuery>;
export type CheckPatientLimitQueryResult = Apollo.QueryResult<CheckPatientLimit, CheckPatientLimitVariables>;
