import React from 'react';

type LabelPropsType = {
  x: number;
  y: number;
  value: string;
  fill: string;
};
export const renderLabelTemp = ({ x, y, value, fill }: LabelPropsType) => {
  return (
    <text
      x={x + 26}
      y={y > 292 ? y - 30 : y}
      dy={26}
      fontSize="16"
      fontFamily="Lato, sans-serif"
      fontWeight={700}
      fill={fill}
      textAnchor="middle">
      {value}
    </text>
  );
};
