import React from 'react';
import { Bar, BarChart, Cell, XAxis, YAxis } from 'recharts';

import { defaultTheme } from '../../themes';
import { renderBarPress, renderLabelPres, renderTick } from './utils';

const ItemWidth = 52;
const SpaceBetweenItems = 20;
const LeftRightPadding = 60;

function PressureChart({ data }: { data: PressureDataType }) {
  const width = data && data.length * ItemWidth + (data.length - 1) * SpaceBetweenItems + LeftRightPadding;
  const arr = data && data.map(item => ({ ...item, sys: item.sys && item.dia && item.sys - item.dia }));

  return (
    <BarChart
      width={width}
      height={353}
      data={arr}
      margin={{
        top: 20,
        right: 30,
        left: 30,
        bottom: 5,
      }}>
      <XAxis
        dataKey="name"
        tickSize={0}
        tick={renderTick}
        axisLine={{ stroke: defaultTheme.colors.grayAverage, strokeWidth: 2, strokeLinecap: 'round' }}
      />
      <YAxis type={'number'} domain={[30, 'maxData']} hide />
      <Bar dataKey="dia" label={renderLabelPres} barSize={52} stackId="a" isAnimationActive={false}>
        {data?.map((entry, index) => (
          <Cell
            fill={entry.isCritical ? defaultTheme.colors.blue : defaultTheme.colors.blueExtraLight}
            key={`cell-${index}`}
            stroke={entry.isCritical ? defaultTheme.colors.blue : defaultTheme.colors.blueDark}
          />
        ))}
      </Bar>
      <Bar
        dataKey="sys"
        label={renderLabelPres}
        barSize={52}
        stackId="a"
        shape={renderBarPress}
        isAnimationActive={false}>
        {data?.map((entry, index) => (
          <Cell
            fill={entry.isCritical ? defaultTheme.colors.blueDark : defaultTheme.colors.blueAverage}
            key={`cell-${index}`}
          />
        ))}
      </Bar>
    </BarChart>
  );
}

export type PressureDataType =
  | Array<{
      name: string;
      sys: number | undefined;
      dia: number | undefined;
      isCritical: boolean;
    }>
  | undefined;

export default PressureChart;
