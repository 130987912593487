type PropsType = {
  x: number;
  y: number;
  width: number;
  height: number;
};

export const getPath = ({ x, y, width, height }: PropsType) => {
  return `M ${x}, ${y + height} 
  A 0 0 0 0 1, ${x}, ${y + height} 
  L ${x}, ${y + 4} 
  A 4 4 0 0 1, ${x + 4}, ${y} 
  L ${x + width - 4}, ${y} 
  A 4 4 0 0 1, ${x + width}, ${y + 4} 
  L ${x + width}, ${y + height} 
  A 0 0 0 0 1, ${x + width}, ${y + height} 
   z`;
};
