import React from 'react';

function CheckedIcon({ color, ...props }: React.SVGProps<SVGSVGElement>) {
  return (
    <svg width="14" height="11" viewBox="0 0 14 11" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M13 1L4.75 10L1 5.90909"
        stroke={color}
        stroke-width="2"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </svg>
  );
}

export default CheckedIcon;
