import { Form, Formik } from 'formik';
import React from 'react';

import { Radio } from './components';

export type RadioGroupPropsType = {
  name: string;
  label: string;
  options: Array<{
    value: string;
    isDisabled: boolean;
    id: string;
  }>;
};

function RadioGroup({ label, options, name }: RadioGroupPropsType) {
  return (
    <div>
      {label}
      <Formik initialValues={{}} onSubmit={() => {}}>
        {() => (
          <Form>
            {options.map(option => {
              return <Radio key={option.id} value={option.value} isDisabled={option.isDisabled} name={name} />;
            })}
            <button type="submit">Submit</button>
          </Form>
        )}
      </Formik>
    </div>
  );
}

export default RadioGroup;
