import React from 'react';
import styled from 'styled-components';

const TextButton: React.VFC<TextButtonProps> = ({ label, onClick }) => <Root onClick={onClick}>{label}</Root>;

export interface TextButtonProps {
  label: string;
  onClick: () => void;
}

const Root = styled.button`
  all: unset;
  ${({ theme: { typography } }) => typography.body2}
  color: ${({ theme: { colors } }) => colors.blue};

  &:hover {
    color: ${({ theme: { colors } }) => colors.blueDark};
    text-decoration-line: underline;
  }
`;

export default TextButton;
